import { Theme } from '@mui/material';
import { FirestoreTimestamp } from '@kaepla/types';

import { isOld } from './isOld';
import { isVeryOld } from './isVeryOld';

export const agoColor = (
  theme: Theme,
  timestamp: FirestoreTimestamp,
  from?: FirestoreTimestamp,
) => {
  if (isVeryOld(timestamp, from)) {
    return theme.palette.error.main;
  }

  if (isOld(timestamp, from)) {
    return theme.palette.warning.main;
  }

  return theme.palette.text.primary;
};
