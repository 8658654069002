import { DateTime } from 'luxon';
import { FirestoreTimestamp } from '@kaepla/types';

import { OLD_IN_HOURS } from './timingConstants';

export const isOld = (timestamp: FirestoreTimestamp, from?: FirestoreTimestamp) => {
  let diffInHours = (DateTime.now().toMillis() - timestamp.toMillis()) / 1000 / 60 / 60;
  if (from) {
    diffInHours = (from.toMillis() - timestamp.toMillis()) / 1000 / 60 / 60;
  }
  return diffInHours > OLD_IN_HOURS;
};
