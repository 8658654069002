import BenchmarkIcon from '@mui/icons-material/SpeedOutlined';
import { ListItemIcon, MenuItem } from '@mui/material';
import { clone } from 'rambda';
import { useRecoilState, useRecoilValue } from 'recoil';

import { applicationState } from '../../../services/recoil/nonpersistent/applicationState.js';
import { kaeplaAssignmentState } from '../../../services/recoil/persistent/kaeplaAssignmentState.js';

export const BenchmarkToggle = () => {
  const [application, setApplication] = useRecoilState(applicationState);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  if (!kaeplaAssignment?.devTeamMember) return null;
  return (
    <MenuItem
      onClick={() => {
        const newApplication = clone(application);
        newApplication.benchmarks = !newApplication.benchmarks;
        setApplication(newApplication);
      }}
    >
      <ListItemIcon>
        <BenchmarkIcon sx={{ color: application.benchmarks ? 'green' : 'inherit' }} />
      </ListItemIcon>
      {application.benchmarks ? 'Hide' : 'Show'} Benchmarks & Code
    </MenuItem>
  );
};
