import { KaeplaConnectorCredentialsField } from '@kaepla/types';

export const salesForceConnectorCredentials: KaeplaConnectorCredentialsField[] = [
  { name: 'url', type: 'text', description: 'The URL of your SalesForce instance' },
  { name: 'clientId', type: 'text', description: 'The client ID of your SalesForce app' },
  {
    name: 'clientSecret',
    type: 'password',
    description: 'The client secret of your SalesForce app',
  },
  { name: 'user', type: 'text', description: 'The username of your SalesForce account' },
  { name: 'password', type: 'password', description: 'The password of your SalesForce account' },
  {
    name: 'securityToken',
    type: 'password',
    description: 'The security token of your SalesForce account',
  },
];
