import { KaeplaPerspectiveBlock, KaeplaQueryType } from '@kaepla/types';
import { Alert, Card, CircularProgress, Grid2 as Grid } from '@mui/material';
import { ChartOptions, TooltipItem } from 'chart.js';
import iwanthue from 'iwanthue';
import { useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Md5 } from 'ts-md5';

import { useAuth } from '../../../../../../../AuthReactProvider.js';
import { getFromKaepla } from '../../../../../../../services/api/getFromKaepla.js';
import { chartDataState } from '../../../../../../../services/recoil/nonpersistent/chartDataState.js';
import { matrixFilteredState } from '../../../../../../../services/recoil/nonpersistent/matrixFilteredState.js';
import { perspectiveState } from '../../../../../../../services/recoil/nonpersistent/perspectiveState.js';
import { projectState } from '../../../../../../../services/recoil/nonpersistent/projectState.js';
import { simulationState } from '../../../../../../../services/recoil/nonpersistent/simulationState.js';
import { snapShotState } from '../../../../../../../services/recoil/nonpersistent/snapshotState.js';
import { currentScopePathState } from '../../../../../../../services/recoil/persistent/currentScopePathState.js';
import { filterSettingsState } from '../../../../../../../services/recoil/persistent/filterSettingState.js';
import { filterSqlState } from '../../../../../../../services/recoil/persistent/filterSqlState.js';
import { timeSliceState } from '../../../../../../../services/recoil/persistent/timeSliceState.js';
import { CardContentMinPadding } from '../../../../../../features/CardContentMinPadding.js';
import { cleanColumnName } from '../../../../../../helpers/cleanColumnName.js';
import { useIsVisible } from '../../../../../../helpers/useIsVisible.js';
import { matrixDataColor } from '../../../../../defaults.js';
import { simulationDataColorShift } from '../../helpers/chartColors.js';
import { chartParameters } from '../../helpers/chartParameters.js';
import { KaeplaChartLabel, createChartLabels } from '../../helpers/createChartLabels.js';
import { ChartEditor } from '../ChartEditor.js';
import { ChartLabels } from '../ChartLabels.js';

import { KaeplaChartDatasets } from './getChartDataForStackedBarChart';
import { getStackedBarChartBorders } from './getStackedBarChartBorders.js';
import { stackedBarChartConfig } from './stackedBarChart.config.js';
import { StackedBarChart } from './StackedBarChart.js';
import { StackedBarChartHeader } from './StackedBarChartHeader.js';
import { stackedBarChartConfigWoCurrency } from './stackedBarChartWoCurrency.config.js';

interface AOptions {
  block: KaeplaPerspectiveBlock;
  blockNumber: number;
  locked?: boolean;
}

export const StackedBarChartBlock = ({ block, blockNumber, locked }: AOptions) => {
  const { kaeplaUser } = useAuth();
  const matrixFiltered = useRecoilValue(matrixFilteredState);
  const project = useRecoilValue(projectState);
  const perspective = useRecoilValue(perspectiveState);
  const timeSlice = useRecoilValue(timeSliceState);
  const simulation = useRecoilValue(simulationState);
  const snapshot = useRecoilValue(snapShotState);
  const filterSql = useRecoilValue(filterSqlState);
  const filterSettings = useRecoilValue(filterSettingsState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const scopePathStringified = JSON.stringify(currentScopePath);
  const chartKey = Md5.hashStr(
    `sbchart-${perspective.id ?? 'p'}${simulation?.id ?? snapshot?.id ?? 's'}${blockNumber}${scopePathStringified}${filterSql ?? 'f'}${filterSettings.isActive ? 't' : 'f'}`,
  );
  const [allChartData, setAllChartData] = useRecoilState(chartDataState);
  const [wasVisible, setWasVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [editing, setEditing] = useState(false);
  const [error, setError] = useState<string>();
  const [chartLabels, setChartLabels] = useState<KaeplaChartLabel[]>([]);
  const [chartData, setChartData] = useState<KaeplaChartDatasets>(
    allChartData?.[chartKey] as KaeplaChartDatasets,
  );
  const [chartOptions, setChartOptions] = useState<ChartOptions>(stackedBarChartConfig);
  const chartReference = useRef();
  const reference = useRef(null);
  const isVisible = useIsVisible(reference);

  const toggleLabel = (label: KaeplaChartLabel) => {
    const newChartLabels = [...chartLabels];
    const labelReference = newChartLabels.find((l) => l.datasetName === label.datasetName);
    if (labelReference && chartData?.datasets) {
      labelReference.isToggled = !labelReference.isToggled;
      const newDatasets = [...chartData.datasets];
      const datasetReference = newDatasets.filter((l) => l.label === label.datasetName);
      if (datasetReference) {
        datasetReference.forEach((d) => {
          d.hidden = !d.hidden;
        });
        setChartData({ ...chartData, datasets: newDatasets });
        setChartLabels(newChartLabels);
      }
    }
  };

  // pre-use the locally stored chart data
  useEffect(() => {
    if (perspective.id && allChartData?.[chartKey]) {
      const _chartData = allChartData[chartKey] as KaeplaChartDatasets;
      setLoading(false);
      setChartData(_chartData);
    }
  }, [setChartData, allChartData, perspective.id, blockNumber, chartKey]);

  // only load visible charts
  useEffect(() => {
    if (!isVisible) return;
    setWasVisible(true);
  }, [isVisible]);

  useEffect(() => {
    if (!wasVisible || !currentScopePath) return;
    if (!block.aggregationDimension) {
      setEditing(true);
      setLoading(false);
      return;
    }

    setUpdating(true);

    getFromKaepla({
      errorCallBack: (_error) => {
        setChartLabels([]);
        setChartData({
          labels: [],
          datasets: [],
        });
        setError(`Chart data backend error: ${_error.message}`);
        setLoading(false);
      },
      callBack: (apiResponse) => {
        if (!apiResponse || !block.aggregationDimension) {
          setLoading(false);
          return;
        }
        const result = apiResponse.response as KaeplaChartDatasets;

        // TODO: get rid of the Monthly_Volume exception, implement proper currency concept
        if (result.labels) {
          // set chart options
          let newChartOptions = { ...stackedBarChartConfig };
          const dimensionMeta = matrixFiltered?.dimensions?.dimensions.find(
            (d) => d.columnName === block.valueDimension,
          );
          if (dimensionMeta?.isCurrency !== true) {
            newChartOptions = { ...stackedBarChartConfigWoCurrency };
          }
          if (block.splitDimension) {
            newChartOptions.interaction = {
              mode: 'point',
            };
            if (newChartOptions.plugins) {
              newChartOptions.plugins.tooltip = {
                callbacks: {
                  title: (tooltipItems: TooltipItem<'bar'>[]) => {
                    return `${cleanColumnName(tooltipItems[0].label)}`;
                  },
                  afterTitle: (tooltipItems: TooltipItem<'bar'>[]) => {
                    return `${cleanColumnName(tooltipItems[0].dataset.stack ?? 'n/a')}`;
                  },
                },
              };
              /*

              TODO: explore this approach to show the stack label on the chart
              alternatively, try annotations plugin

              newChartOptions.plugins.datalabels = {
                anchor: 'end',
                align: 'end',
                formatter: (_value, x) => {
                  if (x.dataset.stack !== 'Contract') return '';
                  console.log(
                    '->',
                    x.dataIndex,
                    x.chart.data.datasets.filter((d) => d.stack === x.dataset.stack),
                    x.datasetIndex,
                    x.dataset.stack,
                  );
                  if (x.dataset.stack) {
                    console.log('----->', x.dataIndex, x.datasetIndex);
                    return x.dataset.stack;
                  } else {
                    return '';
                  }
                },
              };
              */
            }
          }
          setChartOptions(newChartOptions);

          const _chartLabels = createChartLabels(result, block.aggregationDimension);
          setChartLabels(_chartLabels);

          const enhancedDatasets = result.datasets.map((d, index) => {
            const palette = iwanthue(20);
            const color = palette[index];
            if (simulation && d.stack?.endsWith('__Simulated')) {
              return {
                ...d,
                backgroundColor: simulationDataColorShift(
                  _chartLabels?.find((c) => c.datasetName === d.label)?.color ?? color,
                ),
                borderWidth: {
                  top: index === result.datasets.length - 1 ? 1 : 0,
                  bottom: 0,
                  left: 1,
                  right: 1,
                },
                borderStyle: 'dashed',
                borderColor: simulationDataColorShift(matrixDataColor),
                hidden: false,
              };
            }
            if (snapshot && d.stack?.endsWith('__Snapshot')) {
              return {
                ...d,
                backgroundColor: simulationDataColorShift(
                  _chartLabels?.find((c) => c.datasetName === d.label)?.color ?? color,
                ),
                borderWidth: {
                  top: index === result.datasets.length - 1 ? 1 : 0,
                  bottom: 0,
                  left: 1,
                  right: 1,
                },
                borderStyle: 'dashed',
                borderColor: simulationDataColorShift(matrixDataColor),
                hidden: false,
              };
            }
            return {
              ...d,
              backgroundColor: _chartLabels?.find((c) => c.datasetName === d.label)?.color ?? color,
              borderWidth: getStackedBarChartBorders(result.labels, index, !!simulation),
              borderColor: matrixDataColor,
              hidden: false,
            };
          });
          result.datasets = enhancedDatasets;
          setAllChartData((previous) => {
            return {
              ...previous,
              [chartKey]: result,
            };
          });

          setUpdating(false);
        }
      },
      params: chartParameters({
        project,
        queryType: 'aggregationOverTime' as KaeplaQueryType,
        currentScopePath,
        filterSql,
        filterActive: filterSettings.isActive,
        block,
        simulation,
        snapshot,
        timeSlice,
      }),
      uid: kaeplaUser?.uid,
    });
  }, [
    block,
    currentScopePath,
    filterSettings.isActive,
    filterSql,
    wasVisible,
    kaeplaUser?.uid,
    perspective.id,
    blockNumber,
    project,
    simulation,
    snapshot,
    timeSlice,
    setAllChartData,
    chartKey,
    matrixFiltered?.dimensions?.dimensions,
  ]);

  return (
    <Card sx={{ minHeight: 320, height: '100%' }} ref={reference}>
      <Grid container>
        <Grid size={{ xs: editing ? 8 : 12 }} sx={{ position: 'relative' }}>
          <CardContentMinPadding sx={{ pt: 1.1 }}>
            <StackedBarChartHeader
              chartData={chartData}
              chartLabels={chartLabels}
              chartReference={chartReference}
              block={block}
              setEditing={setEditing}
              editing={editing}
              blockNumber={blockNumber}
              locked={locked}
              canZoomAndPan
              highlightFilterOverride
            />
            {error && <Alert severity="error">{error}</Alert>}
            {!error && block.valueDimension && (
              <StackedBarChart
                chartReference={chartReference}
                chartData={chartData}
                loading={loading}
                chartOptions={chartOptions}
              />
            )}
            <ChartLabels
              block={block}
              chartLabels={chartLabels}
              simulation={simulation}
              toggleLabel={toggleLabel}
            />
            {updating && (
              <CircularProgress size={15} sx={{ position: 'absolute', right: 10, bottom: 10 }} />
            )}
          </CardContentMinPadding>
        </Grid>
        {editing && (
          <Grid size={4}>
            <ChartEditor
              chartReference={chartReference}
              block={block}
              blockNumber={blockNumber}
              setEditing={setEditing}
            />
          </Grid>
        )}
      </Grid>
    </Card>
  );
};
