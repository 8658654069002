import { KaeplaPerspectiveBlock } from '@kaepla/types';
import CloseIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { Box, Grid2 as Grid, IconButton, Link, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';

import { isEligibleForAggregationDimension } from '../../helpers/isEligibleForAggregationDimension.js';
import { isEligibleForSplitDimension } from '../../helpers/isEligibleForSplitDimension.js';
import { isEligibleForTimeDimension } from '../../helpers/isEligibleForTimeDimension.js';
import { isEligibleForValueDimension } from '../../helpers/isEligibleForValueDimension.js';
import { AggregationSelect } from '../AggregationSelect.js';
import { DimensionSelect } from '../DimensionSelect.js';

interface Options {
  block: KaeplaPerspectiveBlock;
  setAggregation: Dispatch<SetStateAction<string>>;
  setValueDimension: Dispatch<SetStateAction<string | undefined>>;
  setAggregationDimension: Dispatch<SetStateAction<string | undefined>>;
  setTimeDimension: Dispatch<SetStateAction<string | undefined>>;
  setSplitDimension: Dispatch<SetStateAction<string | undefined>>;
}

export const StackedBarChartChartEditor = ({
  block,
  setAggregation,
  setValueDimension,
  setAggregationDimension,
  setTimeDimension,
  setSplitDimension,
}: Options) => {
  const [split, setSplit] = useState(!!block.splitDimension);
  return (
    <Grid container spacing={1}>
      <Grid size={12}>
        <AggregationSelect setAggregation={setAggregation} aggregation={block.aggregation} />
      </Grid>
      <Grid size={12}>
        <Typography variant="subtitle2">OF</Typography>
      </Grid>
      <Grid size={12}>
        <DimensionSelect
          setDimension={setValueDimension}
          dimension={block.valueDimension}
          isEligibleForDimension={isEligibleForValueDimension}
        />
      </Grid>
      <Grid size={12}>
        <Typography variant="subtitle2">PER</Typography>
      </Grid>
      <Grid size={12}>
        <DimensionSelect
          setDimension={setAggregationDimension}
          dimension={block.aggregationDimension}
          isEligibleForDimension={isEligibleForAggregationDimension}
        />
      </Grid>
      <Grid size={12}>
        <Typography variant="subtitle2">OVER</Typography>
      </Grid>
      <Grid size={12}>
        <DimensionSelect
          setDimension={setTimeDimension}
          dimension={block.timeDimension}
          isEligibleForDimension={isEligibleForTimeDimension}
        />
      </Grid>
      {!split && (
        <Grid size={12}>
          <Link
            href="#"
            onClick={(event) => {
              setSplit(true);
              event.preventDefault();
            }}
          >
            split
          </Link>
        </Grid>
      )}
      {split && (
        <>
          <Grid size={10}>
            <Typography variant="subtitle2">SPLIT BY</Typography>
          </Grid>
          <Grid size={2} container justifyContent="flex-end">
            <IconButton
              data-testid="customer-menu-toggle"
              edge="end"
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                setSplitDimension(undefined);
                setSplit(false);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
          <Grid size={12}>
            <DimensionSelect
              setDimension={setSplitDimension}
              dimension={block.splitDimension}
              isEligibleForDimension={isEligibleForSplitDimension}
            />
          </Grid>
        </>
      )}
      <Box sx={{ m: 2 }} />
    </Grid>
  );
};
