import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { KaeplaApiParameters } from '@kaepla/types';

import { requestLog } from '../../Frontend/helpers/logger.js';

export const generateDataOnCall = (parameters: KaeplaApiParameters) => {
  const functions = getFunctions(getApp('kaepla'), 'europe-west3');
  const generateDummyData = httpsCallable(functions, 'generateDummyData', { timeout: 700_000 });
  requestLog.log(`generateDummyData({data: ${JSON.stringify(parameters)}})`);
  void generateDummyData(parameters);
};
