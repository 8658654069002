import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Button, Paper, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { dataSourceState } from '../../../../services/recoil/nonpersistent/dataSourceState';

import { VisuallyHiddenInput } from './VisuallyHiddenInput';

interface DOptions {
  handleFileUpload: () => void;
}

export const DataSourceUpload = ({ handleFileUpload }: DOptions) => {
  const selectedDataSource = useRecoilValue(dataSourceState);
  return (
    <Paper variant="elevation" sx={{ p: 2 }}>
      <Box sx={{ maxWidth: 400 }}>
        <Typography variant="h5" gutterBottom>
          Upload a new {selectedDataSource.label} *.csv file
        </Typography>
        {selectedDataSource?.config && (
          <Typography variant="body1" gutterBottom>
            {selectedDataSource.config.description}
          </Typography>
        )}
        <Box sx={{ m: 2 }} />
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
          onChange={() => {
            handleFileUpload();
          }}
        >
          Upload csv file
          <VisuallyHiddenInput type="file" />
        </Button>
      </Box>
    </Paper>
  );
};
