import FullScreenIcon from '@mui/icons-material/FullscreenOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Stack, Typography } from '@mui/material';
import { GridApi } from 'ag-grid-enterprise';
import { MouseEvent, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useAuth } from '../../../../../../AuthReactProvider';
import { getProjectSettingQuery } from '../../../../../../services/firestore/projectSettings/getProjectSetting.query';
import { dataGridState } from '../../../../../../services/recoil/nonpersistent/dataGridState';
import { projectState } from '../../../../../../services/recoil/nonpersistent/projectState';
import { kaeplaAssignmentState } from '../../../../../../services/recoil/persistent/kaeplaAssignmentState';
import { KaeplaDataView } from '../../../../../../typings/KaeplaDataView';

import { GridMenuMoreMenu } from './GridMenuMoreMenu';
import { GridSearch } from './GridSearch';

interface GOptions {
  searchTerm: string | undefined;
  setSearchTerm: (value: string) => void;
  onSearchTermChange: () => void;
  setDataView: (value: KaeplaDataView) => void;
  api: GridApi | undefined;
}

export const GridMenuTableView = ({
  searchTerm,
  setSearchTerm,
  onSearchTermChange,
  setDataView,
  api,
}: GOptions) => {
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const setGridState = useSetRecoilState(dataGridState);

  const canManageProject =
    kaeplaAssignment?.devTeamMember ?? (project.ownedBy ?? project.createdBy) === kaeplaUser?.uid;

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [successMessage, setSuccessMessage] = useState<string>();

  const handleMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const loadGridView = async () => {
    const setting = await getProjectSettingQuery({
      projectId: project.id,
      settingId: `default${KaeplaDataView.Grid}Config`,
    });
    if (setting) {
      const gridSettings = setting.setting;
      gridSettings.sideBar!.visible = true;
      gridSettings.sideBar!.openToolPanel = 'columns';
      setGridState(gridSettings);
    }
    setDataView(KaeplaDataView.Grid);
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={2}
      padding={1}
    >
      <GridSearch
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        onSearchTermChange={onSearchTermChange}
      />
      <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
      {successMessage && (
        <Typography sx={{ p: 1 }} color="success" noWrap>
          {successMessage}
        </Typography>
      )}
      <IconButton
        size="small"
        data-testid="table-view-buttons"
        onClick={() => {
          void loadGridView();
        }}
      >
        <FullScreenIcon />
      </IconButton>
      {canManageProject && (
        <>
          <IconButton
            edge="end"
            size="small"
            data-testid="project-menu-toggle"
            onClick={handleMenuClick}
          >
            <MoreVertIcon data-testid="project-menu-icon" />
          </IconButton>
          <GridMenuMoreMenu
            anchorElement={anchorElement}
            setAnchorElement={setAnchorElement}
            setSuccessMessage={setSuccessMessage}
            api={api}
          />
        </>
      )}
    </Stack>
  );
};
