import { Box, Button, Toolbar } from '@mui/material';
import {
  Utils as QbUtils,
  Query,
  Builder,
  Config,
  ImmutableTree,
  BuilderProps,
  GroupProperties,
  JsonTree,
} from '@react-awesome-query-builder/mui';
// eslint-disable-next-line import/no-unassigned-import
import '@react-awesome-query-builder/mui/css/compact_styles.css';
// eslint-disable-next-line import/no-unassigned-import
import './helpers/filters.css';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useDebouncedCallback } from 'use-debounce';
import { KaeplaSimulation, KaeplaSimulationRuleset } from '@kaepla/types';

import { useAuth } from '../../../AuthReactProvider.js';
import { updateSimulation } from '../../../services/firestore/updateSimulation.js';
import { matrixFilteredState } from '../../../services/recoil/nonpersistent/matrixFilteredState.js';
import { projectState } from '../../../services/recoil/nonpersistent/projectState.js';
import { currentScopePathState } from '../../../services/recoil/persistent/currentScopePathState.js';

import { FilterSQL } from './features/FilterSQL.js';
import { KaeplaConfig, buttonsOnHover, filterConfigInit } from './helpers/filterConfig.js';
import { getFieldSettings } from './helpers/getFieldSettings.js';
import { getRuleCountFromFilterTree } from './helpers/getRuleCountFromFilterTree.js';


interface Options {
  simulation: KaeplaSimulation;
  ruleset: KaeplaSimulationRuleset;
  filterHasChangedCallback?: (value: boolean) => void;
}

export const SimulationRulesetFilters = ({
  simulation,
  ruleset,
  filterHasChangedCallback,
}: Options) => {
  const { kaeplaUser } = useAuth();
  const matrixFiltered = useRecoilValue(matrixFilteredState);
  const project = useRecoilValue(projectState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const [filters, setFilters] = useState<{
    tree: ImmutableTree;
    config: Config;
  }>();
  const [config, setConfig] = useState<Config>(filterConfigInit);
  const [isApplied, setIsApplied] = useState(true);
  const [rulesCount, setRulesCount] = useState<number>(0);

  useEffect(() => {
    if (!matrixFiltered?.dimensions || !kaeplaUser) return;
    const load = () => {
      const UserSettings: Config = {
        ...KaeplaConfig,
      };

      const queryBuilderConfig: Config = {
        ...UserSettings,
        fields: getFieldSettings({
          project,
          currentScopePath,
          uid: kaeplaUser.uid,
          matrixFiltered,
          alias: 'mv',
        }),
      };

      setConfig(queryBuilderConfig);

      let jsonTreeStringified = JSON.stringify({ id: 'init', type: 'group' });
      if (ruleset?.filter?.filterTreeStringified) {
        jsonTreeStringified = ruleset.filter.filterTreeStringified;
      }

      const { fixedTree: immutableTree } = QbUtils.sanitizeTree(
        QbUtils.loadTree(JSON.parse(jsonTreeStringified) as JsonTree),
        queryBuilderConfig,
      );

      // const newSQL = QbUtils.sqlFormat(immutableTree, queryBuilderConfig);
      // console.log('setting filter', newSQL);

      setFilters({
        tree: immutableTree,
        config: queryBuilderConfig,
      });

      const _ruleCount = getRuleCountFromFilterTree(jsonTreeStringified);
      setRulesCount(_ruleCount);
    };

    void load();
  }, [
    kaeplaUser?.uid,
    matrixFiltered,
    kaeplaUser,
    project,
    currentScopePath,
    ruleset?.filter?.filterTreeStringified,
  ]);

  const onChange = useDebouncedCallback((immutableTree: ImmutableTree, _config: Config) => {
    // we want the outer group always to have AND as a conjunction
    let checkedTree = immutableTree;
    let tree = QbUtils.getTree(immutableTree);
    const treeProperties = { ...tree }.properties as GroupProperties;
    if (treeProperties && treeProperties.conjunction === 'OR') {
      // console.log('!! - changing outer conjunction to AND');
      treeProperties.conjunction = 'AND';
      // checkedTree = QbUtils.checkTree(QbUtils.loadTree(tree), _config);
      const { fixedTree } = QbUtils.sanitizeTree(QbUtils.loadTree(tree), _config);
      checkedTree = fixedTree;
      tree = QbUtils.getTree(checkedTree);
    }

    const newFilters = { tree: checkedTree, config: _config };
    setFilters((previousState) => ({ ...previousState, ...newFilters }));
    const _ruleCount = getRuleCountFromFilterTree(JSON.stringify(tree));
    setRulesCount(_ruleCount);
    const newSQL = QbUtils.sqlFormat(immutableTree, config);
    let oldSQL = 'SELECT NULL';
    if (filters?.tree) {
      oldSQL = QbUtils.sqlFormat(filters.tree, config) ?? 'SELECT NULL';
    }
    // console.log('- old vs new', oldSQL === newSQL);
    // console.log('isApplied', isApplied);
    if (oldSQL !== newSQL) {
      setIsApplied(false);
    }
  }, 1000);

  const applyFilter = async () => {
    if (!filters) return;
    setIsApplied(true);
    const jsonTree = QbUtils.getTree(filters.tree);
    const filterSql = QbUtils.sqlFormat(filters.tree, config) ?? '';
    const newSimulation: KaeplaSimulation = { ...simulation };
    const newRulesets = newSimulation.rulesets.map((r) => {
      if (r.id === ruleset.id) {
        const newRuleset = { ...ruleset };
        newRuleset.filter = {
          filterSql,
          filterTreeStringified: JSON.stringify(jsonTree),
          rulesCount,
        };
        return newRuleset;
      }
      return r;
    });
    newSimulation.rulesets = newRulesets;
    newSimulation.filterHasChanged = true;
    await updateSimulation({ project, simulation: newSimulation });
    if (filterHasChangedCallback) {
      filterHasChangedCallback(true);
    }
  };

  // withput class qb-lite buttons are always rendered
  const renderBuilder = useCallback(
    (properties: BuilderProps) => (
      <Box className="query-builder-container">
        <Box className={`query-builder ${buttonsOnHover ? 'qb-lite' : ''}`}>
          <Builder {...properties} />
        </Box>
      </Box>
    ),
    [],
  );

  return (
    <Box>
      {filters && <FilterSQL tree={filters.tree} config={filters.config} />}
      {filters && (
        <Query {...config} value={filters.tree} onChange={onChange} renderBuilder={renderBuilder} />
      )}
      <Toolbar sx={{ minHeight: 16 }} disableGutters variant="dense">
        <Button
          sx={{ mr: 1 }}
          variant="contained"
          onClick={() => {
            void applyFilter();
          }}
          disabled={isApplied || rulesCount === 0}
        >
          Apply
        </Button>
      </Toolbar>
    </Box>
  );
};
