/* eslint-disable import/no-unassigned-import */
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReactProps, AgGridReact as Base } from 'ag-grid-react';
import { useMemo } from 'react';

import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-quartz.css';
import './style.css';

LicenseManager.setLicenseKey(
  `Using_this_{AG_Grid}_Enterprise_key_{AG-064886}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Atrigam_GmbH}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Kaepla}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Kaepla}_need_to_be_licensed___{Kaepla}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{12_August_2025}____[v3]_[01]_MTc1NDk1MzIwMDAwMA==4fb0cd1dbd971b5836c40b4bead31a90`,
);

export function AgGridReact<TData>(properties: AgGridReactProps<TData>) {
  const containerStyle = useMemo(
    () => ({
      display: 'flex',
      flex: 1,
      //   backgroundColor: 'red',
      //   width: '100%',
      //   height: '90%',
    }),
    [],
  );
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  return (
    <div style={containerStyle}>
      <div style={gridStyle} className="ag-theme-quartz">
        <Base<TData> {...properties} />
      </div>
    </div>
  );
}
