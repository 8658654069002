import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { dataSourceImportState } from '../../../services/recoil/nonpersistent/dataSourceImportState';

import { AiRules } from './AI/AiRules';
import { AddDataSourceImportFab } from './components/AddDataSourceImportFab';
import { ConfigPanel } from './components/ConfigPanel';
import { ListImportsForDataSource } from './switcher/ListImportsForDataSource';
import { NewDataSourceImport } from './switcher/NewDataSourceImport';
import { SelectedDataSourceImportForDataSource } from './switcher/SelectedDataSourceImportForDataSource';

interface Options {
  fullScreen: boolean;
  setFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
  viewDetails: boolean;
  setViewDetails: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SelectedDataSource = ({
  viewDetails,
  setViewDetails,
  fullScreen,
  setFullScreen,
}: Options) => {
  const [newDataSourceImport, setNewDataSourceImport] = useState(false);
  const [manageAiRules, setManageAiRules] = useState(false);

  const selectedDataSourceImport = useRecoilValue(dataSourceImportState);

  // when we come back from another Tab, we want to close the details view
  useEffect(() => {
    if (!selectedDataSourceImport) {
      setViewDetails(false);
    }
  }, [selectedDataSourceImport, setViewDetails]);

  if (viewDetails && selectedDataSourceImport) {
    return (
      <SelectedDataSourceImportForDataSource
        fullScreen={fullScreen}
        setFullScreen={setFullScreen}
        setViewDetails={setViewDetails}
        setManageAiRules={setManageAiRules}
      />
    );
  }

  if (newDataSourceImport) {
    return <NewDataSourceImport setNewDataSourceImport={setNewDataSourceImport} />;
  }

  if (manageAiRules) {
    return <AiRules setManageAiRules={setManageAiRules} />;
  }

  return (
    <Stack direction="column" gap={2}>
      <ConfigPanel setManageAiRules={setManageAiRules} />
      <ListImportsForDataSource setViewDetails={setViewDetails} />
      {!newDataSourceImport && (
        <AddDataSourceImportFab setNewDataSourceImport={setNewDataSourceImport} />
      )}
    </Stack>
  );
};
