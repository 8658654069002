import AiRulesIcon from '@mui/icons-material/AutoAwesomeOutlined';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import FilterIcon from '@mui/icons-material/FilterAltOffOutlined';
import FullScreenExitIcon from '@mui/icons-material/FullscreenExitOutlined';
import FullScreenIcon from '@mui/icons-material/FullscreenOutlined';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import ConfigurationIcon from '@mui/icons-material/SettingsOutlined';
import CollapseAllIcon from '@mui/icons-material/UnfoldLessOutlined';
import ExpandAllIcon from '@mui/icons-material/UnfoldMoreOutlined';
import { Badge, Divider, IconButton, Paper, Snackbar, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { SetterOrUpdater, useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { dataSourceImportState } from '../../../services/recoil/nonpersistent/dataSourceImportState';
import { dataSourceState } from '../../../services/recoil/nonpersistent/dataSourceState';
import { dataSourceUseAiRulesState } from '../../../services/recoil/nonpersistent/dataSourceUseAiRules';
import { bucketState } from '../../../services/recoil/nonpersistent/ihsBucketState';

import { AiDialog } from './AI/AiDialog';
import { BucketActions } from './components/BucketActions';
import { DataSourceImportHeaderSmall } from './components/DataSourceImportHeaderSmall';
import { DataSourceImportView } from './switcher/DataSourceImportView';
import { ManualReviewIndicator } from './switcher/ManualReviewIndicator';
import { MatchBucketPanel } from './switcher/MatchBucketPanel';
import { ErpOrder } from './types/ErpOrder';
import { IhsVehicleFull } from './types/IhsVehicleFull';

interface IOptions {
  fullScreen: boolean;
  setFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
  setViewDetails: React.Dispatch<React.SetStateAction<boolean>>;
  setManageAiRules: React.Dispatch<React.SetStateAction<boolean>>;
  items: (IhsVehicleFull | ErpOrder)[];
  setItems: SetterOrUpdater<(IhsVehicleFull | ErpOrder)[]>;
}

export const SelectedDataSourceImport = ({
  setViewDetails,
  fullScreen,
  setFullScreen,
  setManageAiRules,
  items,
  setItems,
}: IOptions) => {
  const [expandAll, setExpandAll] = useState(false);
  const [expandAllFields, setExpandAllFields] = useState(false);
  const [aiDialogOpen, setAiDialogOpen] = useState(false);
  const [aiDialogAnswered, setAiDialogAnswered] = useState(false);
  const [openNotImplemented, setOpenNotImplemented] = useState(false);

  const dataSource = useRecoilValue(dataSourceState);
  const selectedBucket = useRecoilValue(bucketState);
  const resetDataSourceImport = useResetRecoilState(dataSourceImportState);
  const resetBucket = useResetRecoilState(bucketState);
  const [useAiRules, setUseAiRules] = useRecoilState(dataSourceUseAiRulesState);

  const handleClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenNotImplemented(false);
  };

  useEffect(() => {
    if (useAiRules || aiDialogOpen || aiDialogAnswered) return;
    if (items.filter((v) => v.isReviewed).length % 3 === 0 && items.some((v) => v.isReviewed)) {
      setAiDialogOpen(true);
    }
  }, [aiDialogAnswered, aiDialogOpen, useAiRules, items]);

  const toggleExpandAll = () => {
    setExpandAll(!expandAll);
    setItems((previous) =>
      previous.map((item) => {
        return { ...item, isExpanded: !expandAll };
      }),
    );
  };

  const toggleExpandAllFields = () => {
    setExpandAllFields(!expandAllFields);
    setItems((previous) =>
      previous.map((vehicle) => {
        return { ...vehicle, isFieldsExpanded: !expandAllFields };
      }),
    );
  };

  const acceptAiRules = (value: boolean) => {
    setAiDialogOpen(false);
    setUseAiRules(value);
    setAiDialogAnswered(true);
  };

  return (
    <Paper variant="elevation">
        {!useAiRules && <AiDialog open={aiDialogOpen} onClose={acceptAiRules} />}
        <Snackbar
          open={openNotImplemented}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          message="Not implemented yet"
        />
        <Paper
          elevation={0}
          square
          sx={{ zIndex: 3000, position: fullScreen ? 'sticky' : 'initial', top: 0, p: 1 }}
        >
          <Stack direction="row" spacing={1} alignItems="center" sx={{ pb: 1 }}>
            <DataSourceImportHeaderSmall />
            <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
            {!fullScreen && (
              <IconButton
                size="small"
                data-testid="fullscreen"
                onClick={() => {
                  setFullScreen(true);
                }}
              >
                <FullScreenIcon fontSize="small" />
              </IconButton>
            )}
            {fullScreen && (
              <IconButton
                size="small"
                data-testid="close-fullscreen"
                onClick={() => {
                  setFullScreen(false);
                }}
              >
                <FullScreenExitIcon fontSize="small" />
              </IconButton>
            )}
            <IconButton
              size="small"
              edge="end"
              onClick={() => {
                resetDataSourceImport();
                setViewDetails(false);
                setFullScreen(false);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center" sx={{ pb: 1 }}>
            <MatchBucketPanel />
            <ManualReviewIndicator />
            <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
            <IconButton
              size="small"
              data-testid="search"
              onClick={() => {
                setOpenNotImplemented(true);
              }}
            >
              <FilterIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              data-testid="search"
              onClick={() => {
                setOpenNotImplemented(true);
              }}
            >
              <SearchIcon fontSize="small" />
            </IconButton>
            {useAiRules && (
              <Badge variant="dot" color="info">
                <IconButton
                  size="small"
                  data-testid="ai-rules"
                  onClick={() => {
                    setViewDetails(false);
                    setManageAiRules(true);
                    setFullScreen(false);
                  }}
                >
                  <AiRulesIcon fontSize="small" />
                </IconButton>
              </Badge>
            )}
            <IconButton
              size="small"
              data-testid="config"
              onClick={() => {
                setOpenNotImplemented(true);
              }}
            >
              <ConfigurationIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              color="info"
              data-testid="search"
              disabled={!selectedBucket}
              onClick={() => {
                resetBucket();
              }}
            >
              <InfoIcon fontSize="small" />
            </IconButton>
            <Typography color="inherit" noWrap sx={{ width: 20 }} />
            {dataSource.config.hasCollapsibleFields && (
              <IconButton
                size="small"
                disabled={!selectedBucket}
                onClick={() => {
                  toggleExpandAllFields();
                }}
              >
                {expandAllFields ? (
                  <CollapseAllIcon sx={{ transform: 'rotate(90deg)' }} fontSize="small" />
                ) : (
                  <ExpandAllIcon sx={{ transform: 'rotate(90deg)' }} fontSize="small" />
                )}
              </IconButton>
            )}
            <IconButton
              size="small"
              edge="end"
              disabled={!selectedBucket}
              onClick={() => {
                toggleExpandAll();
              }}
            >
              {expandAll ? (
                <CollapseAllIcon fontSize="small" />
              ) : (
                <ExpandAllIcon fontSize="small" />
              )}
            </IconButton>
          </Stack>
        </Paper>
        <Divider />
        <DataSourceImportView fullScreen={fullScreen} />
        <Divider />
        <Paper
          elevation={0}
          square
          sx={{ zIndex: 3000, position: fullScreen ? 'sticky' : 'initial', bottom: 0, p: 1 }}
        >
          <BucketActions setViewDetails={setViewDetails} />
        </Paper>
      </Paper>
  );
};
