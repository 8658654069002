import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';

interface DDOptions {
  title: string;
  description: string;
  confirm: string;
  openDelete: boolean;
  processDelete: () => void;
  handleCloseDelete: () => void;
}

export const GenericConfirmDialog = ({
  openDelete,
  handleCloseDelete,
  processDelete,
  title,
  description,
  confirm,
}: DDOptions) => {
  return (
    <Dialog
      open={openDelete}
      // hideBackdrop
      data-testid="confirm-dialog"
      // onClick={(event) => event.stopPropagation()}
      // onClose={(_event, reason) => {
      //   if (reason && reason === 'backdropClick') return;
      // }}
    >
      <DialogTitle data-testid="confirm-dialog-title">
        {title}
        <IconButton
          size="small"
          data-testid="confirm-dialog-cancel"
          sx={{ position: 'absolute', top: 0, right: 0 }}
          onClick={(event) => {
            event.stopPropagation();
            handleCloseDelete();
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent data-testid="confirm-dialog-description">{description}</DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="contained"
          data-testid="confirm-dialog-confirm"
          color="error"
          onClick={(event) => {
            event.stopPropagation();
            handleCloseDelete();
            processDelete();
          }}
        >
          {confirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
