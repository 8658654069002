import { WhereClause } from '@atrigam/atrigam-service-firebase-watcher';
import { clientEventService } from '@kaepla/events';
import { KaeplaImport } from '@kaepla/types';
import ArchiveIcon from '@mui/icons-material/ArchiveOutlined';
import DisabledIcon from '@mui/icons-material/CancelOutlined';
import DoneIcon from '@mui/icons-material/CheckCircleOutline';
import UpdateSettingsIcon from '@mui/icons-material/SettingsOutlined';
import SyncIcon from '@mui/icons-material/SyncOutlined';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Grid2 as Grid,
  IconButton,
  LinearProgress,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { useAuth } from '../../../AuthReactProvider.js';
import { deltaImportOnCall } from '../../../services/api/deltaImport.js';
import { fullImportOnCall } from '../../../services/api/fullImport.js';
import { addFirestoreCollectionListener } from '../../../services/firestore/addFirestoreCollectionListener.js';
import { projectState } from '../../../services/recoil/nonpersistent/projectState.js';
import { projectAssignmentsState } from '../../../services/recoil/nonpersistent/userAssignmentState.js';
import { kaeplaAssignmentState } from '../../../services/recoil/persistent/kaeplaAssignmentState.js';
import { Layout } from '../../Layout/Layout.js';
import { ProjectDisabled } from '../../features/ProjectDisabled.js';
import { ProjectLoading } from '../../features/ProjectLoading.js';

import { ImportCard } from './ImportCard.js';
import { SyncDataHeader } from './SyncDataHeader.js';
import { SycDataSettings } from './SyncDataSettings.js';
import { updateIntervalMap } from './updateIntervalMap.js';

export const SyncData = () => {
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const projectAssignments = useRecoilValue(projectAssignmentsState);
  const [loading, setLoading] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [imports, setImports] = useState<KaeplaImport[]>([]);
  const [showUpdateSettings, setShowUpdateSettings] = useState(false);
  const [updateRequested, setUpdateRequested] = useState<DateTime>();

  const isAutoUpdateEnabled = () => {
    if (!project?.deltaUpdateIntervalInHours && !project?.fullUpdateIntervalInHours) return false;
    return true;
  };

  const getUpdateInterval = () => {
    const updateIntervals = Object.values(updateIntervalMap);
    if (project?.deltaUpdateIntervalInHours) {
      return updateIntervals.find(
        (interval) => interval.value === project.deltaUpdateIntervalInHours,
      )?.label;
    }
    if (project?.fullUpdateIntervalInHours) {
      return updateIntervals.find(
        (interval) => interval.value === project.fullUpdateIntervalInHours,
      )?.label;
    }
    return 'off';
  };

  const isUpdateLocked = () => {
    if (!project?.importConfigurationId) return true;
    if (project?.currentImportId) {
      return true;
    }
    if (updateRequested) {
      return updateRequested > DateTime.now().minus({ minutes: 3 });
    }
    return false;
  };

  const userHasRootAssignment = () => {
    const rootAssignments = projectAssignments.filter(
      (assignment) => assignment.projectId === project.id && assignment.scopePath.length === 0,
    );
    if (rootAssignments.length > 0) return true;

    return false;
  };

  const fullUpdate = () => {
    if (!kaeplaUser) return;
    fullImportOnCall({
      params: {
        projectId: project.id,
      },
    });
    void clientEventService.createEvent({
      assignmentScope: clientEventService.assignmentScope.PROJECT,
      eventGroup: clientEventService.eventGroup.IMPORT,
      eventName: clientEventService.eventName.IMPORT_START_FULL_IMPORT,
      uid: kaeplaUser.uid,
      projectId: project.id,
      customerId: project.customerId,
      resellerId: project.resellerId,
    });
    setUpdateRequested(DateTime.now());
  };

  const deltaUpdate = () => {
    if (!kaeplaUser) return;
    deltaImportOnCall({
      params: {
        projectId: project.id,
      },
    });
    void clientEventService.createEvent({
      assignmentScope: clientEventService.assignmentScope.PROJECT,
      eventGroup: clientEventService.eventGroup.IMPORT,
      eventName: clientEventService.eventName.IMPORT_START_IMPORT,
      uid: kaeplaUser.uid,
      projectId: project.id,
      customerId: project.customerId,
      resellerId: project.resellerId,
    });
    setUpdateRequested(DateTime.now());
  };

  useEffect(() => {
    if (!project?.id) return;
    if (!kaeplaUser?.uid) return;

    setLoading(true);
    const fireStorePath = `imports`;
    const queryWhere: WhereClause[] = [
      {
        fieldPath: 'projectId',
        opStr: '==',
        value: project.id,
      },
    ];

    if (!showArchived) {
      queryWhere.push({
        fieldPath: 'archived',
        opStr: '==',
        value: false,
      });
    }

    const unsubscribe = addFirestoreCollectionListener({
      fireStorePath,
      queryWhere,
      orderBy: {
        fieldPath: 'createdAt',
        direction: 'desc',
      },
      limit: 50,
      callback: (data) => {
        const _imports = data as KaeplaImport[];
        setImports(_imports);
        setLoading(false);
      },
    });
    return () => {
      unsubscribe();
    };
  }, [kaeplaUser?.uid, project?.id, showArchived]);

  useEffect(() => {
    if (!project?.id) return;
    if (!kaeplaUser?.uid) return;
    if (updateRequested && updateRequested < DateTime.now().minus({ minutes: 1 })) {
      setUpdateRequested(undefined);
    }
  }, [project?.id, kaeplaUser?.uid, updateRequested]);

  if (!project?.id) {
    return <ProjectLoading />;
  }

  if (project?.disabledReason && !kaeplaAssignment) {
    return (
      <Layout>
        <ProjectDisabled reason={project?.disabledReason} />
      </Layout>
    );
  }

  return (
    <Layout hasScopeNavigation showCustomerSelector>
      <Grid container spacing={2}>
        <SyncDataHeader />
        {loading && <LinearProgress />}
        {!project?.importConfigurationId && (
          <Grid size={12}>
            <Alert severity="warning">
              <AlertTitle>No configuration</AlertTitle>
              This project is missing a synchronization configuration.
            </Alert>
          </Grid>
        )}
        {updateRequested && (
          <Grid size={12}>
            <Alert severity="info">
              <AlertTitle>Update is scheduled</AlertTitle>
              The next update will start within approximately a minute.
            </Alert>
          </Grid>
        )}
        <Grid size={12}>
          <Toolbar disableGutters variant="dense">
            {userHasRootAssignment() && (
              <Button
                variant="contained"
                disabled={isUpdateLocked()}
                onClick={() => {
                  deltaUpdate();
                }}
                endIcon={<SyncIcon />}
              >
                Update Now
              </Button>
            )}
            {kaeplaAssignment && (
              <Button
                sx={{ ml: 1 }}
                variant="contained"
                color="secondary"
                disabled={isUpdateLocked()}
                onClick={() => {
                  deltaUpdate();
                }}
                endIcon={<SyncIcon />}
              >
                Admin Δ Update
              </Button>
            )}
            {kaeplaAssignment && (
              <Button
                sx={{ ml: 1 }}
                variant="contained"
                color="secondary"
                disabled={isUpdateLocked()}
                onClick={() => {
                  fullUpdate();
                }}
                endIcon={<SyncIcon />}
              >
                Admin Full Update
              </Button>
            )}
            <Stack
              direction="row"
              gap={1}
              justifyContent="center"
              alignItems="center"
              sx={{ ml: 2 }}
            >
              <Box>Auto-Updates</Box>
              <Box>{getUpdateInterval()}</Box>
              <IconButton disabled>
                {isAutoUpdateEnabled() && <DoneIcon color="success" fontSize="small" />}
                {!isAutoUpdateEnabled() && <DisabledIcon color="error" fontSize="small" />}
              </IconButton>
              <IconButton
                data-testid="update-settings-toggle"
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  setShowUpdateSettings(!showUpdateSettings);
                }}
              >
                <UpdateSettingsIcon fontSize="small" />
              </IconButton>
            </Stack>
            <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
            <Button
              onClick={() => {
                setShowArchived(!showArchived);
              }}
              endIcon={<ArchiveIcon />}
            >
              {showArchived ? `Hide` : `Show`} Archived
            </Button>
          </Toolbar>
        </Grid>
        {showUpdateSettings && <SycDataSettings />}

        {imports.map((dataImport) => (
          <ImportCard key={dataImport.id} dataImport={dataImport} />
        ))}
      </Grid>
    </Layout>
  );
};
