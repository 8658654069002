import { Box } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { dataSourceOrdersState } from '../../../../services/recoil/nonpersistent/dataSourceOrdersState';
import { LinearProgressWithLabel } from '../components/LinearProgressWithLabel';

import { manualReviewOrders } from './ErpOrdersMatchBuckets';

export const ErpOrdersManualReviewIndicator = () => {
  const orders = useRecoilValue(dataSourceOrdersState);
  return (
    <Box sx={{ width: 250 }}>
      <LinearProgressWithLabel
        value={
          (orders.filter((v) => v.isReviewed).length / manualReviewOrders(orders).length) * 100
        }
      />
    </Box>
  );
};
