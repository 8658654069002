import {
  KaeplaSimulation,
  KaeplaSimulationParameterGranularity,
  KaeplaSimulationParameterNumeric,
  KaeplaSimulationParameterText,
  KaeplaSimulationRulesetWithParameters,
  MatrixTimeSlice,
} from '@kaepla/types';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Collapse,
  FormControlLabel,
  Grid2 as Grid,
  IconButton,
  Stack,
  Switch,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { matrixFilteredState } from '../../../../services/recoil/nonpersistent/matrixFilteredState.js';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState.js';
import { timeSliceState } from '../../../../services/recoil/persistent/timeSliceState.js';
import { ExpandMore } from '../../../features/ExpandMore.js';
import { cleanColumnName } from '../../../helpers/cleanColumnName.js';
import { mapCurrency } from '../../../helpers/mapCurrency.js';

import { getAliasFor } from './_helpers/getAliasFor.js';
import { granularityLevel } from './_helpers/granularityLevel.js';
import { levelGranularity } from './_helpers/levelGranularity.js';
import { KaeplaSlider } from './KaeplaSlider.js';
import { NumericParameterSlider } from './NumericParameterSlider.js';
import { SimulationResizeContainer } from './SimulationResizeContainer.js';

interface Options {
  parameter: KaeplaSimulationParameterNumeric | KaeplaSimulationParameterText;
  simulation: KaeplaSimulation;
  ruleset: KaeplaSimulationRulesetWithParameters;
  resizeParentReference: RefObject<HTMLInputElement>;
  simulationRulesets: KaeplaSimulationRulesetWithParameters[];
  setSimulationRulesets: Dispatch<
    SetStateAction<KaeplaSimulationRulesetWithParameters[] | undefined>
  >;
  setPreview: Dispatch<SetStateAction<boolean>>;
  own: boolean;
}

export const SimulationParameterNumeric = ({
  parameter,
  simulation,
  ruleset,
  resizeParentReference,
  simulationRulesets,
  setSimulationRulesets,
  setPreview,
  own,
}: Options) => {
  const project = useRecoilValue(projectState);
  const matrixFiltered = useRecoilValue(matrixFilteredState);
  const setTimeSlice = useSetRecoilState(timeSliceState);
  const [years, setYears] = useState<number[]>(matrixFiltered?.simulationYears?.years ?? []);

  const dimension = useMemo(
    () => matrixFiltered?.dimensions?.dimensions.find((d) => d.columnName === parameter.dimension),
    [matrixFiltered?.dimensions?.dimensions, parameter.dimension],
  );

  const [allowNegativePercentage, setAllowNegativePercentage] = useState(false);
  const [granularity, setGranularity] = useState(KaeplaSimulationParameterGranularity.max);
  const [yearExpanded, setYearExpanded] = useState<string>();

  const handleNegativePercentageChange = () => {
    setAllowNegativePercentage(!allowNegativePercentage);
  };

  const autoToggleNegativeToggle = useCallback(() => {
    if (!ruleset?.parameters) return;
    const _parameter = [...ruleset.parameters].find(
      (p) => p.dimension === parameter.dimension,
    ) as KaeplaSimulationParameterNumeric;
    if (!_parameter) return;

    const _years = matrixFiltered?.simulationYears?.years ?? [];
    setYears(_years);
    const lowest = _years
      .map((year) => {
        let _percentage = 0;
        if (parameter?.dimension && _parameter?.percentages) {
          const percentageOfYear = [..._parameter.percentages].find((_p) => _p.key === `${year}`);
          if (percentageOfYear !== undefined) {
            _percentage = percentageOfYear.percentage || 0;
          }
        }
        return _percentage;
      })
      .reduce((a, b) => Math.min(a, b), 0);

    if (lowest < 0) {
      setAllowNegativePercentage(true);
    }
  }, [parameter.dimension, ruleset?.parameters, matrixFiltered]);

  useEffect(() => {
    autoToggleNegativeToggle();
  }, [autoToggleNegativeToggle]);

  if (!project) return null;

  return (
    <Grid container spacing={1} sx={{ height: '100%' }}>
      <Grid size={12}>
        <Toolbar disableGutters variant="dense">
          <ExpandMore
            expand={yearExpanded === undefined}
            onClick={() => {
              setTimeSlice(MatrixTimeSlice.year);
              setYearExpanded(undefined);
            }}
            disabled={yearExpanded === undefined}
          >
            <ExpandMoreIcon />
          </ExpandMore>
          {dimension &&
            cleanColumnName(getAliasFor(dimension, matrixFiltered?.dimensions?.dimensions))}{' '}
          {dimension?.currency && mapCurrency(dimension?.currency)}
          <Box sx={{ pl: '0.3em' }}>Years</Box>
          <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
          <Box sx={{ mr: 2 }}>{granularity}</Box>
          <KaeplaSlider
            sx={{ width: 200, mr: 5 }}
            color="primary"
            value={granularityLevel(granularity)}
            onChange={(_event, value) => {
              const level = levelGranularity(value as number);
              if (!level) return;
              setGranularity(level);
            }}
            step={50}
            marks
          />
          <FormControlLabel
            control={
              <Switch
                checked={allowNegativePercentage}
                onChange={handleNegativePercentageChange}
                disabled={!own}
                size="small"
              />
            }
            label="-100/100"
          />
          <IconButton
            aria-label="delete"
            color="primary"
            size="small"
            disabled={!own}
            onClick={() => {
              const newRulesets = [...simulationRulesets].map((r) => {
                if (r.id === ruleset.id) {
                  const newRuleset = { ...ruleset };
                  newRuleset.parameters = [...newRuleset.parameters].filter(
                    (p) => p.dimension !== parameter.dimension,
                  );
                  return newRuleset;
                }
                return r;
              });
              setSimulationRulesets(newRulesets);
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Toolbar>
      </Grid>
      <Grid size={12}>
        <Collapse in={yearExpanded === undefined} timeout="auto" unmountOnExit>
          <SimulationResizeContainer
            resizeParentReference={resizeParentReference}
            transparent={simulation?.isBeingSimulated}
            minHeight={allowNegativePercentage ? 450 : 320}
          >
            <Stack
              sx={{
                justifyContent: 'center',
                alignItems: 'stretch',
                height: '100%',
              }}
            >
              <Stack direction="row" justifyContent="space-between" height="100%">
                {years.map((year) => (
                  <NumericParameterSlider
                    yearOrMonth={`${year}`}
                    ruleset={ruleset}
                    parameter={parameter}
                    granularity={granularity}
                    simulation={simulation}
                    own={own}
                    simulationRulesets={simulationRulesets}
                    setSimulationRulesets={setSimulationRulesets}
                    setPreview={setPreview}
                    allowNegativePercentage={allowNegativePercentage}
                    setYearExpanded={setYearExpanded}
                    yearExpanded={yearExpanded}
                    timeSlice={MatrixTimeSlice.year}
                  />
                ))}
              </Stack>
            </Stack>
          </SimulationResizeContainer>
        </Collapse>
        <Collapse in={yearExpanded !== undefined} timeout="auto" unmountOnExit>
          <Toolbar variant="dense">
            <Box sx={{ pr: '0.3em' }}>Months for</Box>
            {dimension &&
              cleanColumnName(getAliasFor(dimension, matrixFiltered?.dimensions?.dimensions))}{' '}
            {dimension?.currency && mapCurrency(dimension?.currency)}
            <Box sx={{ pl: '0.3em' }}>Year</Box>
            <Box sx={{ pl: '0.3em', fontWeight: 800 }}>{yearExpanded?.split('-')[0]}</Box>
          </Toolbar>
          <SimulationResizeContainer
            resizeParentReference={resizeParentReference}
            transparent={simulation?.isBeingSimulated}
            minHeight={allowNegativePercentage ? 400 : 320}
          >
            <Stack
              sx={{
                justifyContent: 'center',
                alignItems: 'stretch',
                height: '100%',
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                height="100%"
                sx={{ border: '0px solid red', px: 2, overflow: 'auto' }}
              >
                {Array.from({ length: 12 }, (_, index) => (
                  <NumericParameterSlider
                    yearOrMonth={`${yearExpanded?.split('-')[0]}-${String(index + 1).padStart(2, '0')}-01`}
                    ruleset={ruleset}
                    parameter={parameter}
                    granularity={granularity}
                    simulation={simulation}
                    own={own}
                    simulationRulesets={simulationRulesets}
                    setSimulationRulesets={setSimulationRulesets}
                    setPreview={setPreview}
                    allowNegativePercentage={allowNegativePercentage}
                    setYearExpanded={setYearExpanded}
                    yearExpanded={yearExpanded}
                    timeSlice={MatrixTimeSlice.month}
                  />
                ))}
              </Stack>
            </Stack>
          </SimulationResizeContainer>
        </Collapse>
      </Grid>
    </Grid>
  );
};
