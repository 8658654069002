import { useEffect, useState } from 'react';
import mermaid from 'mermaid';
import classnames from 'classnames';

interface Props {
  mmd: string;
  id: string;
  className?: string;
  onRender?: (svg?: string) => void;
  onClick?: () => void;
}

const arrEntities = { lt: '<', gt: '>', nbsp: ' ', amp: '&', quot: '"' };

function escape2Html(str: string) {
  return str
    .replace(/&(lt|gt|nbsp|amp|quot);/gi, function (_, t) {
      return arrEntities[t as keyof typeof arrEntities];
    })
    .trim();
}

export const MermaidReact = ({ mmd, id, className, onRender, onClick }: Props) => {
  const [svg, setSvg] = useState<string>('');
  useEffect(() => {
    mermaid.initialize({ startOnLoad: false });
  }, []);

  useEffect(() => {
    if (!mmd) return;
    mermaid.render(id, escape2Html(mmd)).then(({ svg }) => {
      setSvg(svg);
      if (onRender) {
        onRender(svg);
      }
    });
  }, [mmd]);

  return (
    <div
      onClick={onClick}
      className={classnames('mermaid', className)}
      dangerouslySetInnerHTML={{ __html: svg }}
    ></div>
  );
};
