import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { KaeplaReseller } from '@kaepla/types';

import { updateReseller } from '../../../../services/firestore/updateReseller.js';
import { knownResellersState } from '../../../../services/recoil/nonpersistent/knownResellersState.js';
import { updateKnownReseller } from '../../../helpers/updateKnownReseller.js';

interface DDOptions {
  openRename: boolean;
  handleCloseRename: () => void;
  reseller: KaeplaReseller;
}
export const ResellerRenameDialog = ({ openRename, handleCloseRename, reseller }: DDOptions) => {
  const [knownResellers, setKnownResellers] = useRecoilState(knownResellersState);
  const [name, setName] = useState(reseller.name);
  const [appName, setAppName] = useState(reseller.appName);

  return (
    <Dialog open={openRename} onClose={handleCloseRename}>
      <DialogTitle sx={{ minWidth: 400 }}>
        Rename reseller
        <IconButton
          size="small"
          sx={{ position: 'absolute', top: 0, right: 0 }}
          onClick={handleCloseRename}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          variant="standard"
          label="reseller name"
          value={name}
          onChange={(event) => {
            setName(event.target.value);
          }}
          fullWidth
        />
      </DialogContent>
      <DialogContent>
        <TextField
          variant="standard"
          label="application name"
          value={appName}
          onChange={(event) => {
            setAppName(event.target.value);
          }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="contained"
          color="error"
          onClick={() => {
            handleCloseRename();
            const newReseller: KaeplaReseller = { ...reseller };
            newReseller.name = name;
            newReseller.appName = appName;
            updateKnownReseller({ reseller: newReseller, knownResellers, setKnownResellers });
            void updateReseller({ reseller: newReseller });
          }}
        >
          rename
        </Button>
      </DialogActions>
    </Dialog>
  );
};
