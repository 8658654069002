import { Alert, Box, Collapse, Paper } from '@mui/material';
import {
  Builder,
  BuilderProps,
  Config,
  ImmutableTree,
  JsonTree,
  Utils as QbUtils,
  Query,
} from '@react-awesome-query-builder/mui';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useDebouncedCallback } from 'use-debounce';

import { useAuth } from '../../../AuthReactProvider.js';
import { knownProjectFiltersState } from '../../../services/recoil/nonpersistent/knownProjectFiltersState.js';
import { matrixFilteredState } from '../../../services/recoil/nonpersistent/matrixFilteredState.js';
import { matrixLoadingState } from '../../../services/recoil/nonpersistent/matrixLoadingState';
import { projectState } from '../../../services/recoil/nonpersistent/projectState.js';
import { currentScopePathState } from '../../../services/recoil/persistent/currentScopePathState.js';
import { filterSettingsState } from '../../../services/recoil/persistent/filterSettingState.js';
import { filterSqlState } from '../../../services/recoil/persistent/filterSqlState';
import {
  defaultFilterState,
  filterTreeState,
} from '../../../services/recoil/persistent/filterTreeState.js';

import { FilterActions } from './features/FilterActions.js';
import { FilterSQL } from './features/FilterSQL.js';
import { FilterToolbar } from './features/FilterToolbar.js';
import { buttonsOnHover, filterConfigInit, KaeplaConfig } from './helpers/filterConfig.js';
import { getFieldSettings } from './helpers/getFieldSettings.js';
import { getRuleCountFromFilterTree } from './helpers/getRuleCountFromFilterTree.js';

// eslint-disable-next-line import/no-unassigned-import
import '@react-awesome-query-builder/mui/css/compact_styles.css';
// eslint-disable-next-line import/no-unassigned-import
import './helpers/filters.css';

interface InitialFilters {
  tree: ImmutableTree;
  config: Config;
}

export const Filters = () => {
  const { kaeplaUser } = useAuth();
  const matrixFiltered = useRecoilValue(matrixFilteredState);
  const matrixLoading = useRecoilValue(matrixLoadingState);
  const project = useRecoilValue(projectState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const knownProjectFilters = useRecoilValue(knownProjectFiltersState);
  const [filterSettings, setFilterSettings] = useRecoilState(filterSettingsState);
  const [filterTree, setFilterTree] = useRecoilState<JsonTree>(filterTreeState);
  const setFilterSql = useSetRecoilState<string | undefined>(filterSqlState);
  const [filters, setFilters] = useState<InitialFilters | undefined>();
  const [config, setConfig] = useState<Config>(filterConfigInit);
  const [choosePreset, setChoosePreset] = useState(false);
  const [autosave, setAutosave] = useState(false);

  const toggleFilterActive = useCallback(() => {
    // if we do have children in the current tree, the filter is active
    if (
      !filterTree?.children1 ||
      (filterTree?.children1 && (filterTree.children1 as unknown as []).length === 0)
    ) {
      setFilterSettings((_filterSettings) => ({ ..._filterSettings, isActive: false }));
    }
  }, [filterTree?.children1, setFilterSettings]);

  const applyFilter = () => {
    if (!filters) return;
    const jsonTree = QbUtils.getTree(filters.tree);
    setFilterTree(jsonTree);
    const newFilterSql = QbUtils.sqlFormat(filters.tree, config);
    setFilterSql(newFilterSql);
    setFilterSettings({ ...filterSettings, isApplied: true, isActive: true });
    setAutosave(true);
  };

  useEffect(() => {
    if (matrixLoading) return;
    if (!currentScopePath) return;
    if (!matrixFiltered?.dimensions || !kaeplaUser?.uid) return;

    setFilterSettings((_filterSettings) => ({
      ..._filterSettings,
      isInitialized: false,
    }));

    const load = () => {
      let UserSettings: Config = {
        ...KaeplaConfig,
      };

      const filterFromKnownFilters = knownProjectFilters?.find(
        (k) => k.id === filterSettings?.filterPresetId,
      );

      if (filterFromKnownFilters) {
        UserSettings = {
          ...KaeplaConfig,
          settings: {
            ...KaeplaConfig.settings,
            immutableGroupsMode: kaeplaUser.uid !== filterFromKnownFilters.createdBy,
            immutableFieldsMode: kaeplaUser.uid !== filterFromKnownFilters.createdBy,
            immutableOpsMode: kaeplaUser.uid !== filterFromKnownFilters.createdBy,
            immutableValuesMode: kaeplaUser.uid !== filterFromKnownFilters.createdBy,
          },
        };
      }

      const queryBuilderConfig: Config = {
        ...UserSettings,
        fields: getFieldSettings({
          project,
          currentScopePath,
          uid: kaeplaUser.uid,
          matrixFiltered,
        }),
      };

      setConfig(queryBuilderConfig);

      let _filterTree = defaultFilterState;
      try {
        _filterTree = structuredClone(filterTree);
      } catch {
        // console.log(error);
      }

      const { fixedTree: immutableTree } = QbUtils.sanitizeTree(
        QbUtils.loadTree(_filterTree),
        queryBuilderConfig,
      );

      setFilters({
        tree: immutableTree,
        config: queryBuilderConfig,
      });

      const ruleCount = getRuleCountFromFilterTree(JSON.stringify(filterTree));

      setFilterSettings((_filterSettings) => ({
        ..._filterSettings,
        isInitialized: true,
        isApplied: false,
        ruleCount,
        filterCreatedBy: filterFromKnownFilters?.createdBy ?? kaeplaUser?.uid,
      }));
    };

    void load();
  }, [
    matrixFiltered,
    filterSettings?.filterPresetId,
    project.id,
    kaeplaUser?.uid,
    knownProjectFilters,
    project,
    currentScopePath,
    toggleFilterActive,
    filterTree,
    setFilterSettings,
    matrixLoading,
  ]);

  const onChange = useDebouncedCallback((immutableTree: ImmutableTree, _config: Config) => {
    // we want the outer group always to have AND as a conjunction
    // update 19.9.2023: this assumptions didn't survive reality

    const checkedTree = immutableTree;
    const tree = QbUtils.getTree(immutableTree);

    // checkedTree = QbUtils.checkTree(QbUtils.loadTree(tree), _config);
    // tree = QbUtils.getTree(checkedTree);

    toggleFilterActive();

    setFilters((previousState) => ({ ...previousState, tree: checkedTree, config: _config }));
    const isApplied = JSON.stringify(tree) === JSON.stringify(filterTree);
    const ruleCount = getRuleCountFromFilterTree(JSON.stringify(tree));
    setFilterSettings({ ...filterSettings, isApplied, ruleCount });
  }, 1000);

  // without class qb-lite buttons are always rendered
  const renderBuilder = useCallback(
    (properties: BuilderProps) => (
      <Box className="query-builder-container">
        <Box className={`query-builder ${buttonsOnHover ? 'qb-lite' : ''}`}>
          <Builder {...properties} />
        </Box>
      </Box>
    ),
    [],
  );

  if (!filters) {
    return null;
  }

  return (
    <Paper sx={{ padding: 1 }}>
      <FilterToolbar
        tree={filters.tree}
        config={filters.config}
        choosePreset={choosePreset}
        setChoosePreset={setChoosePreset}
        autosave={autosave}
        setAutosave={setAutosave}
      />
      <FilterSQL tree={filters.tree} config={filters.config} />
      <Collapse in={filterSettings.isExpanded && !choosePreset} timeout="auto" unmountOnExit>
        {filterSettings.ruleCount === 0 && filterSettings.filterCreatedBy === kaeplaUser?.uid && (
          <Alert sx={{ mb: 0.5 }} severity="info">
            Create a new filter by adding a filter rule group!
          </Alert>
        )}
        {filterSettings.ruleCount > 0 && filterSettings.filterCreatedBy !== kaeplaUser?.uid && (
          <Alert sx={{ mb: 0.5 }} severity="info">
            You can't edit other user's filters, but you can copy (duplicate) them.
          </Alert>
        )}
        <Box sx={{ mb: 0.5 }}>
          <Query
            {...config}
            value={filters.tree}
            onChange={onChange}
            renderBuilder={renderBuilder}
          />
        </Box>
        <FilterActions applyFilter={applyFilter} />
      </Collapse>
    </Paper>
  );
};
