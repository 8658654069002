import { Alert } from '@mui/material';

import { Layout } from '../Layout/Layout';
import { camelToSentenceCase } from '../helpers/camelToSentenceCase';

interface Options {
  reason: 'licenseExpired' | 'maintenance';
}

export const ProjectDisabled = ({ reason }: Options) => {
  return (
    <Layout hasScopeNavigation showCustomerSelector>
      <Alert severity="error">{camelToSentenceCase(reason)}</Alert>
    </Layout>
  );
};
