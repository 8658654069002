import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import React from 'react';

import {
  EventLogFilterColumn,
  EventLogFilterContext,
} from '../../../../../../contexts/EventLogFilter.context';

interface Properties {
  column: EventLogFilterColumn;
  options: string[];
  isLoading: boolean;
}

export const FilterItemMultiple: React.FC<Properties> = ({ column, options, isLoading }) => {
  const { eventLogFilters, setFilter, clearFilter, disabledFilterColumns } =
    React.useContext(EventLogFilterContext);

  const value = (eventLogFilters[column] as string[]) ?? [];
  const [open, setOpen] = React.useState(value === null || value.length === 0);

  const onChange = React.useCallback(
    (_: React.SyntheticEvent, newValue: string[] | null) => {
      if (newValue === null || newValue.length === 0) {
        clearFilter({ column });
        return;
      }

      setFilter({ column, value: newValue });
      setOpen(false);
    },
    [clearFilter, column, setFilter],
  );

  const width = value ? (value?.length || 0) * 9 + 73 : 200;

  return (
    <Autocomplete
      id={`filter-${column}`}
      open={open}
      size="small"
      options={options}
      value={value}
      sx={{ width: `${width}px`, minWidth: '200px', maxWidth: '100%' }}
      onChange={onChange}
      onOpen={() => {
        setOpen(true);
      }}
      loading={isLoading}
      renderInput={(parameters) => {
        return (
          <TextField
            color="primary"
            {...parameters}
            autoFocus
            label={column}
            variant="outlined"
            slotProps={{
              input: {
                ...parameters.InputProps,
                endAdornment: (
                  <>
                    {isLoading && <CircularProgress color="inherit" size={20} />}
                    {parameters.InputProps?.endAdornment}
                  </>
                ),
              },
            }}
          />
        );
      }}
      disabled={isLoading || disabledFilterColumns.includes(column)}
      multiple
    />
  );
};
