import { KaeplaDimensionDetails, KaeplaPerspective } from '@kaepla/types';
import { Button, Divider, Grid2 as Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';

import { matrixFilteredState } from '../../../../services/recoil/nonpersistent/matrixFilteredState.js';

import { DimensionCard } from './components/DimensionCard.js';

const isChoosableAsTimeDimension = (detail: KaeplaDimensionDetails) => {
  if (detail.dataType !== 'DATE') return false;
  const nulls = detail.dimensionDetails.find((row) => row.value === null);
  let nullValues = 0;
  if (nulls) {
    nullValues = nulls.numberOfRecords;
  }
  if (nullValues > 0) return false;

  return true;
};

const eligibleDimensions = (details?: KaeplaDimensionDetails[]) => {
  if (!details) return [];
  return details.filter((detail) => isChoosableAsTimeDimension(detail));
};

const nonEligibleDimensions = (details?: KaeplaDimensionDetails[]) => {
  if (!details) return [];
  return details
    .filter((detail) => detail.dataType === 'DATE')
    .filter((detail) => !isChoosableAsTimeDimension(detail));
};

interface Options {
  defaultPerspective: Partial<KaeplaPerspective>;
  setDefaultPerspective: (step: Partial<KaeplaPerspective>) => void;
}

export const StepTimeDimension = ({ setDefaultPerspective, defaultPerspective }: Options) => {
  const matrixFiltered = useRecoilValue(matrixFilteredState);
  const [showNonEligible, setShowNonEligible] = useState(false);
  return (
    <Grid container spacing={2}>
      <Grid size={12}>
        <Typography variant="h5">Choose a time dimension</Typography>
      </Grid>
      {eligibleDimensions(matrixFiltered?.details)?.map((detail) => (
        <Grid key={detail.columnName} size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}>
          <DimensionCard
            detail={detail}
            ideal={true}
            totalCount={matrixFiltered?.totalCount?.totalCount ?? 0}
            setDefaultPerspective={setDefaultPerspective}
            defaultPerspective={defaultPerspective}
            dimensionType="timeDimension"
            desiredDataTypes={['DATE']}
          />
        </Grid>
      ))}
      <Grid size={12}>
        <Divider />
      </Grid>
      <Grid size={12}>
        <Typography variant="h6">
          {nonEligibleDimensions(matrixFiltered?.details).length} additional date dimensions are not
          suited for aggregation over all records
          <Button
            onClick={() => {
              setShowNonEligible(!showNonEligible);
            }}
          >
            {showNonEligible ? 'hide' : 'show'}
          </Button>
        </Typography>
      </Grid>
      {showNonEligible &&
        nonEligibleDimensions(matrixFiltered?.details).map((detail) => (
          <Grid key={detail.columnName} size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}>
            <DimensionCard
              detail={detail}
              ideal={false}
              totalCount={matrixFiltered?.totalCount?.totalCount ?? 0}
              dimensionType="timeDimension"
              desiredDataTypes={['DATE']}
            />
          </Grid>
        ))}
    </Grid>
  );
};
