import { WhereClause } from '@atrigam/atrigam-service-firebase-watcher';
import {
  KaeplaImport,
  KaeplaImportLog,
  KaeplaImportPhase,
  KaeplaOpsEventType,
  KaeplaUser,
} from '@kaepla/types';
import BackIcon from '@mui/icons-material/ArrowBackOutlined';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AdminIcon from '@mui/icons-material/Person2Outlined';
import AutomatedIcon from '@mui/icons-material/ScheduleOutlined';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Collapse,
  Grid2 as Grid,
  IconButton,
  IconButtonProps,
  Stack,
  Step,
  StepLabel,
  Stepper,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import { useRecoilValue } from 'recoil';

import { useAuth } from '../../../../AuthReactProvider.js';
import { addFirestoreCollectionListener } from '../../../../services/firestore/addFirestoreCollectionListener.js';
import { addFirestoreDocumentListener } from '../../../../services/firestore/addFirestoreDocumentListener.js';
import { requestCancelImport } from '../../../../services/firestore/requestCancelImport.js';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState.js';
import { knownUsersState } from '../../../../services/recoil/persistent/knownUsersState.js';
import { Layout } from '../../../Layout/Layout.js';
import { UserAvatar } from '../../../features/UserAvatar.js';
import { convertTimestamp, HumanReadableTimestampType } from '../../../helpers/convertTimestamp.js';
import { getDuration } from '../../../helpers/getDuration.js';
import { projectAwarePath } from '../../../helpers/projectAwarePath.js';
import { SyncDataHeader } from '../SyncDataHeader.js';
import { failedOrCancelledOrFinished } from '../helpers/failedOrFinished.js';

import { isImportDead } from './isImportDead.js';

interface TOptions {
  expanded: boolean;
  projectId: string;
  importId: string;
  setLastLog?: React.Dispatch<React.SetStateAction<KaeplaImportLog | undefined>>;
}

export const LogTerminal = ({ importId, expanded, projectId, setLastLog }: TOptions) => {
  const theme = useTheme();
  const { kaeplaUser } = useAuth();
  const [importLogs, setImportLogs] = useState<KaeplaImportLog[]>([]);
  const [lastUpdate, setLastUpdate] = useState<string>();

  useEffect(() => {
    if (!importId) return;
    if (!projectId) return;
    if (!kaeplaUser?.uid) return;

    const fireStorePath = `importLogs`;
    const queryWhere: WhereClause[] = [
      {
        fieldPath: 'projectId',
        opStr: '==',
        value: projectId,
      },
      {
        fieldPath: 'importId',
        opStr: '==',
        value: importId,
      },
    ];

    const unsubscribe = addFirestoreCollectionListener({
      fireStorePath,
      queryWhere,
      orderBy: {
        fieldPath: 'loggedAt',
        direction: 'desc',
      },
      limit: 3000,
      context: `LogTerminal-${projectId}-${importId}`,
      callback: (data) => {
        const _importLogs = data as KaeplaImportLog[];
        const _lastUpdate = _importLogs.map((l) =>
          convertTimestamp(l.loggedAt!, HumanReadableTimestampType.date),
        )[0];
        if (setLastLog) {
          setLastLog(_importLogs[0]);
        }
        if (setLastUpdate) {
          setLastUpdate((previousUpdate) => {
            if (previousUpdate !== _lastUpdate) {
              return _lastUpdate;
            }
            return previousUpdate;
          });
        }
        setImportLogs(_importLogs);
      },
    });
    return () => {
      unsubscribe();
    };
  }, [importId, kaeplaUser?.uid, projectId, setLastLog, setLastUpdate]);

  return (
    <Collapse in={expanded} timeout="auto" unmountOnExit>
      <Box
        bgcolor="black"
        color="white"
        p={2}
        maxHeight={500}
        sx={{ overflowY: 'scroll', fontFamily: 'monospace', fontSize: 12 }}
      >
        {lastUpdate} id:{importId}
        {importLogs.map((log) => (
          <Box key={log.id} sx={{ color: theme.palette[log.eventType].light }}>
            {log.event}
          </Box>
        ))}
      </Box>
    </Collapse>
  );
};

interface ExpandMoreProperties extends IconButtonProps {
  expand: boolean;
}

const steps = Object.values(KaeplaImportPhase);

const ExpandMore = styled((properties: ExpandMoreProperties) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { expand, ...other } = properties;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: expand ? 'rotate(180deg)' : 'rotate(0deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const ImportLog = () => {
  const navigate = useNavigate();
  const { logId, projectId } = useParams();
  const theme = useTheme();
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const knownUsers = useRecoilValue(knownUsersState);
  const [importUser, setImportUser] = useState<KaeplaUser>();
  const [lastLog, setLastLog] = useState<KaeplaImportLog>();
  const [expanded, setExpanded] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [dataImportUpdate, setImportUpdate] = useState<KaeplaImport>();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const isStepFailed = (index: number, _lastLog?: KaeplaImportLog) => {
    return index === (_lastLog?.phase ? steps.indexOf(_lastLog.phase) : 0);
  };

  const runtime = () => {
    if (dataImportUpdate?.endedAt && dataImportUpdate.startedAt) {
      return getDuration(dataImportUpdate.startedAt, dataImportUpdate.endedAt);
    }
    return false;
  };

  useEffect(() => {
    if (!logId) return;
    if (!projectId) return;
    if (!kaeplaUser?.uid) return;

    const fireStorePath = `imports/${logId}`;
    const unsubscribe = addFirestoreDocumentListener({
      fireStorePath,
      callback: (data) => {
        const _dataImportUpdate = data as KaeplaImport;
        const user = knownUsers.find((k) => k.uid === _dataImportUpdate.createdBy);
        setImportUser(user);
        setImportUpdate(_dataImportUpdate);
        if (!_dataImportUpdate.phase) return;
        const currentStep = steps.indexOf(_dataImportUpdate.phase);
        setActiveStep(currentStep);
      },
    });
    return () => {
      unsubscribe();
    };
  }, [logId, kaeplaUser?.uid, projectId, knownUsers]);

  if (!dataImportUpdate?.startedAt) {
    return (
      <Layout hasScopeNavigation showCustomerSelector>
        <Grid container spacing={2}>
          <SyncDataHeader />
          <Grid size={12}>
            <CircularProgress size={16} /> Update starts in a few seconds
          </Grid>
        </Grid>
      </Layout>
    );
  }

  return (
    <Layout hasScopeNavigation showCustomerSelector>
      <Grid container spacing={2}>
        <SyncDataHeader />
        <Grid size={12}>
          <Card
            elevation={dataImportUpdate.archived ? 0 : 2}
            sx={{ opacity: dataImportUpdate.archived ? 1 : 1 }}
          >
            <CardHeader
              sx={{
                color: isImportDead(dataImportUpdate) ? 'red' : 'inherit',
              }}
              title={
                <Box component="span">
                  #
                  {dataImportUpdate?.number ?? (
                    <Box component="span">{dataImportUpdate?.number}</Box>
                  )}
                  {isImportDead(dataImportUpdate) && (
                    <Box component="span">
                      {', '}
                      <Typography variant="caption">
                        {dataImportUpdate.updateStatus.toUpperCase()}
                      </Typography>
                    </Box>
                  )}
                  {', '}
                  <TimeAgo
                    date={dataImportUpdate?.startedAt.toDate()}
                    max={Number.MAX_SAFE_INTEGER}
                  />
                  {runtime() && `, ⟷ ${runtime()}`}
                  {', '}
                  <Box sx={{ fontStyle: 'italic' }} component="span">
                    {dataImportUpdate.updateType}
                  </Box>
                  {', '}
                  <Typography variant="caption">{dataImportUpdate.sourceUrl}</Typography>
                </Box>
              }
              subheader={`${dataImportUpdate.ingestedRecordsCount ?? 0} changes`}
              avatar={
                importUser ? (
                  <UserAvatar sx={{ mr: 1 }} user={importUser} />
                ) : dataImportUpdate.createdBy === 'kaepla-admin' ? (
                  <AdminIcon fontSize="large" />
                ) : (
                  <AutomatedIcon fontSize="large" />
                )
              }
              action={
                <IconButton
                  onClick={() => navigate(projectAwarePath('SyncData', project))}
                  aria-label="settings"
                >
                  <BackIcon />
                </IconButton>
              }
            />
            <CardContent>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const labelProperties: {
                    optional?: React.ReactNode;
                    error?: boolean;
                  } = {};
                  if (isStepFailed(index, lastLog) && isImportDead(dataImportUpdate)) {
                    labelProperties.optional = (
                      <Typography variant="caption" color="error">
                        {dataImportUpdate.updateStatus.toUpperCase()}
                      </Typography>
                    );
                    labelProperties.error = true;
                  }
                  return (
                    <Step key={label}>
                      <StepLabel {...labelProperties}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </CardContent>
            <CardActions disableSpacing>
              {lastLog && (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  sx={{ m: 1, mt: 3, color: theme.palette[lastLog.eventType].light }}
                >
                  {!failedOrCancelledOrFinished(dataImportUpdate) && (
                    <Stack direction="row" alignItems="center" gap={1}>
                      <CircularProgress size={16} sx={{ mr: 1 }} />
                      <Button
                        variant="text"
                        onClick={() => {
                          void requestCancelImport({ dataImport: dataImportUpdate });
                        }}
                        disabled={!dataImportUpdate}
                      >
                        cancel
                      </Button>
                    </Stack>
                  )}
                  {lastLog.eventType === KaeplaOpsEventType.error && <ErrorIcon sx={{ mr: 1 }} />}
                  <Typography
                    sx={{
                      fontFamily: 'monospace',
                      fontSize: 12,
                    }}
                  >
                    {lastLog.event}
                  </Typography>
                </Stack>
              )}
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </CardActions>
            {logId && (
              <LogTerminal
                expanded={expanded}
                importId={logId}
                projectId={project.id}
                setLastLog={setLastLog}
              />
            )}
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
};
