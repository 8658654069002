import { KaeplaOpsUpdateStatus } from '@kaepla/types';
import ArchiveIcon from '@mui/icons-material/ArchiveOutlined';
import ReviewCompleteIcon from '@mui/icons-material/CheckCircleOutline';
import PublishIcon from '@mui/icons-material/CloudUploadOutlined';
import InProductionIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import UnArchiveIcon from '@mui/icons-material/UnarchiveOutlined';
import NotInProductionIcon from '@mui/icons-material/WarningAmberOutlined';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  Grid2 as Grid,
  IconButton,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { dataSourceImportState } from '../../../../services/recoil/nonpersistent/dataSourceImportState';
import { dataSourceOrdersState } from '../../../../services/recoil/nonpersistent/dataSourceOrdersState';
import { dataSourceState } from '../../../../services/recoil/nonpersistent/dataSourceState';
import { dataSourceVehiclesState } from '../../../../services/recoil/nonpersistent/dataSourceVehiclesState';
import { manualReviewOrders } from '../ERP-Orders/ErpOrdersMatchBuckets';
import { manualReviewVehicles } from '../IHS-Vehicles/IhsVehiclesMatchBuckets';
import { DeleteDataSourceImport } from '../switcher/DeleteDataSourceImport';
import { DataSourceImport } from '../types/DataSourceImport';

import { DataSourceImportHeader } from './DataSourceImportHeader';
import { LinearProgressWithLabel } from './LinearProgressWithLabel';

interface Options {
  dataSourceImport: DataSourceImport;
  setViewDetails: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DataImportSourceCard = ({ dataSourceImport, setViewDetails }: Options) => {
  const selectedDataSource = useRecoilValue(dataSourceState);
  const [deletionInProgress, setDeletionInProgress] = useState(false);
  const [openNotImplemented, setOpenNotImplemented] = useState(false);

  const setDataSourceImportState = useSetRecoilState(dataSourceImportState);
  const vehicles = useRecoilValue(dataSourceVehiclesState);
  const orders = useRecoilValue(dataSourceOrdersState);

  let progress = 0;

  if (selectedDataSource.key === 'ihsVehicles') {
    progress = Math.round(
      (vehicles.filter((v) => v.isReviewed).length / manualReviewVehicles(vehicles).length) * 100,
    );
  } else if (selectedDataSource.key === 'erpOrders') {
    progress = Math.round(
      (orders.filter((v) => v.isReviewed).length / manualReviewOrders(orders).length) * 100,
    );
  }

  const handleClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenNotImplemented(false);
  };

  return (
    <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}>
      <Snackbar
        open={openNotImplemented}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message="Not implemented yet"
      />
      <Card
        id="data-sources-card"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <CardContent
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            if (dataSourceImport.unmatched === 0) return;
            setDataSourceImportState(dataSourceImport);
            setViewDetails(true);
          }}
        >
          <Stack direction="column" gap={1}>
            <DataSourceImportHeader dataSourceImport={dataSourceImport} />
            {(dataSourceImport.unmatched ?? 0) === 0 && (
              <Stack alignItems="center" direction="row" gap={1}>
                <ReviewCompleteIcon fontSize="small" color="success" />
                <Typography variant="body2" color="textSecondary">
                  manual review complete
                </Typography>
              </Stack>
            )}
            {(dataSourceImport.unmatched ?? 0) > 0 && <LinearProgressWithLabel value={progress} />}
            <Stack alignItems="center" direction="row" gap={1}>
              {dataSourceImport.inProduction ? (
                <InProductionIcon fontSize="small" color="success" />
              ) : (
                <NotInProductionIcon fontSize="small" color="warning" />
              )}
              <Typography variant="body2" color="textSecondary">
                {dataSourceImport.inProduction ? 'published to production' : 'not in production'}
              </Typography>
            </Stack>
          </Stack>
        </CardContent>
        <CardActions disableSpacing>
          {dataSourceImport.updateStatus !== KaeplaOpsUpdateStatus.finished &&
            dataSourceImport.updateStatus !== KaeplaOpsUpdateStatus.failed && (
              <CircularProgress size={20} sx={{ ml: 1 }} />
            )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 1,
              visibility:
                dataSourceImport.updateStatus === KaeplaOpsUpdateStatus.finished
                  ? 'visible'
                  : 'hidden',
            }}
          >
            <IconButton
              aria-label={dataSourceImport.archived ? 'unarchive' : 'archive'}
              onClick={() => {
                setOpenNotImplemented(true);
              }}
              color="primary"
              disabled={deletionInProgress}
            >
              {dataSourceImport.archived ? <UnArchiveIcon /> : <ArchiveIcon />}
            </IconButton>
            <Divider sx={{ marginY: 1.5 }} orientation="vertical" flexItem />
            <Button
              size="small"
              variant="text"
              disabled={deletionInProgress || dataSourceImport.unmatched === 0}
              onClick={() => {
                if (dataSourceImport.unmatched === 0) return;
                setDataSourceImportState(dataSourceImport);
                setViewDetails(true);
              }}
            >
              Details
            </Button>
            <Divider sx={{ marginY: 1.5 }} orientation="vertical" flexItem />
            <Button
              size="small"
              variant="text"
              disabled={deletionInProgress}
              onClick={() => {
                setOpenNotImplemented(true);
              }}
              endIcon={<PublishIcon />}
            >
              {`${dataSourceImport.inProduction ? 're-' : ''}publish`}
            </Button>
            {!dataSourceImport.inProduction && (
              <Divider sx={{ marginY: 1.5 }} orientation="vertical" flexItem />
            )}
            {!deletionInProgress && !dataSourceImport.inProduction && (
              <DeleteDataSourceImport
                dataSourceImport={dataSourceImport}
                setDeletionInProgress={setDeletionInProgress}
              />
            )}
          </Box>
          {deletionInProgress && <CircularProgress size={20} sx={{ ml: 1 }} />}
        </CardActions>
      </Card>
    </Grid>
  );
};
