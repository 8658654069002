import AiAssistantIcon from '@mui/icons-material/AssistantOutlined';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import {
  Dialog,
  DialogContent,
  Fab,
  IconButton,
  lighten,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { mainColor } from '../Theme/colors';

import { AiAssistant } from './Assistant/AIAssistant';

export const AiFab = () => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const [open, setOpen] = useState(false);
  const [threadId, setThreadId] = useState<string>();
  const descriptionElementReference = useRef<HTMLElement>(null);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementReference;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <>
      <Fab
        variant="extended"
        aria-label="add customer"
        data-testid="customer-add"
        sx={{
          position: 'fixed',
          bottom: 20,
          right: 20,
          backgroundColor: lighten(mainColor, 0.9),
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'primary.main',
        }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <AiAssistantIcon sx={{ mr: 1 }} /> Ai Assistant
      </Fab>

      <Dialog
        open={open}
        onClose={handleClose}
        scroll="body"
        fullWidth={smUp ? true : false}
        fullScreen={smUp ? false : true}
        maxWidth="md"
        aria-labelledby="ai-assistant-dialog-title"
        aria-describedby="ai-assistant-dialog-description"
        // PaperComponent={Box}
        PaperProps={{ sx: { backgroundColor: 'rgba(230, 230, 230, 0.9)' } }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: smUp ? 0 : 20,
            right: smUp ? 0 : 20,
            backgroundColor: smUp ? 'transparent' : theme.palette.grey[400],
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ backgroundColor: 'transparent', p: smUp ? 5 : 1 }}>
          <AiAssistant setThreadId={setThreadId} threadId={threadId} />
        </DialogContent>
      </Dialog>
    </>
  );
};
