import { Box } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  Point,
  Filler,
} from 'chart.js';
import numbro from 'numbro';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useRecoilValue } from 'recoil';
import { KaeplaTargetsFigure } from '@kaepla/types';

import { targetsState } from '../../../services/recoil/nonpersistent/targetsState.js';
import {
  matrixDataColor,
  matrixDataColorLight,
  targetsDataColor,
  targetsDataColorLight,
  targetsDataColorLowerLight,
  targetsDataColorUpperLight,
} from '../defaults';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    point: {
      radius: 2,
    },
    line: {
      borderWidth: 1,
    },
    filler: {
      propagate: false,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    datalabels: {
      display: false,
    },
  },
  scales: {
    y: {
      ticks: {
        callback: (value: string | number) => {
          return (
            '€' +
            numbro(value).format({
              average: true,
              mantissa: 1,
            })
          );
        },
      },
    },
  },
};

interface Options {
  loading: boolean;
  targetFigures: KaeplaTargetsFigure[];
}

export const TargetsChart = ({ loading, targetFigures }: Options) => {
  const targets = useRecoilValue(targetsState);
  const [data, setData] = useState<ChartData<'line', (number | Point | null)[], unknown>>();

  useEffect(() => {
    if (!targetFigures) return;
    const dataPoints = [...targetFigures]
      .sort((a, b) => a.validAsOf.localeCompare(b.validAsOf))
      .map((d) => ({
        x: d.validAsOf as unknown as number,
        y: d.originalValue,
      }));

    const dataPointsTarget = [...targetFigures]
      .sort((a, b) => a.validAsOf.localeCompare(b.validAsOf))
      .filter((d) => !!d.absoluteValue)
      .map((d) => ({
        x: d.validAsOf as unknown as number,
        y: d.absoluteValue,
      }));

    const dataPointsTargetUpperThresh = [...targetFigures]
      .sort((a, b) => a.validAsOf.localeCompare(b.validAsOf))
      .filter((d) => !!d.absoluteValue)
      .map((d) => {
        const dataX = dataPoints.find((dd) => `${dd.x}` === d.validAsOf);
        const diff = Math.abs((dataX?.y ?? 0) - d.absoluteValue);
        const thresh = d.absoluteValue + diff * 0.5;
        const point = {
          x: d.validAsOf as unknown as number,
          y: thresh,
        };
        return point;
      });

    const dataPointsTargetLowerThresh = [...targetFigures]
      .sort((a, b) => a.validAsOf.localeCompare(b.validAsOf))
      .filter((d) => !!d.absoluteValue)
      .map((d) => {
        const dataX = dataPoints.find((dd) => `${dd.x}` === d.validAsOf);
        const diff = Math.abs((dataX?.y ?? 0) - d.absoluteValue);
        const thresh = d.absoluteValue - diff * 0.25;
        const point = {
          x: d.validAsOf as unknown as number,
          y: thresh,
        };
        return point;
      });
    // console.log("dataPointsSimulation-->", dataPointsSimulation);

    const label = targets.dimension ?? 'n/a';
    const _data: ChartData<'line', (number | Point | null)[], unknown> = {
      datasets: [
        {
          label,
          data: dataPoints,
          borderColor: matrixDataColor,
          backgroundColor: matrixDataColorLight,
        },
        {
          label: `${label}`,
          data: dataPointsTargetUpperThresh,
          borderColor: targetsDataColorUpperLight,
          backgroundColor: targetsDataColorLight,
          pointRadius: 0,
          pointHoverRadius: 3,
          fill: '+2',
        },
        {
          label: `${label} Targets`,
          data: dataPointsTarget,
          borderColor: targetsDataColor,
          backgroundColor: targetsDataColorLight,
          pointRadius: 2,
          pointHoverRadius: 8,
        },
        {
          label: `${label}`,
          data: dataPointsTargetLowerThresh,
          borderColor: targetsDataColorLowerLight,
          backgroundColor: targetsDataColorLight,
          pointRadius: 0,
          pointHoverRadius: 3,
        },
      ],
    };
    setData(_data);
  }, [targetFigures, targets.dimension]);

  return (
    <Box
      height={200}
      sx={{
        opacity: loading ? 0.2 : 1,
      }}
    >
      {data && <Line options={options} data={data} />}
    </Box>
  );
};
