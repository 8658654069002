import { KaeplaPerspective } from '@kaepla/types';
import { Button, Grid2 as Grid } from '@mui/material';

import { StepLabels } from './StepLabels.js';

interface Options {
  activeStep: number;
  setStep: (step: number) => void;
  defaultPerspective: Partial<KaeplaPerspective>;
  setDefaultPerspective: (step: Partial<KaeplaPerspective>) => void;
}

export const StepButtons = ({
  activeStep,
  setStep,
  defaultPerspective,
  setDefaultPerspective,
}: Options) => {
  if (activeStep < 1) return null;
  return (
    <Grid container>
      <Grid size={4}>
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            setDefaultPerspective({});
            setStep(0);
          }}
        >
          Reset
        </Button>
      </Grid>
      <Grid size={4}>
        {activeStep > 0 && (
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              setStep(activeStep - 1);
            }}
          >
            Back
          </Button>
        )}
      </Grid>
      <Grid size={4}>
        {activeStep < StepLabels.length && (
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              setStep(activeStep + 1);
            }}
            disabled={
              (activeStep === 1 && !defaultPerspective.valueDimension) ||
              (activeStep === 2 && !defaultPerspective.timeDimension)
            }
          >
            Next
          </Button>
        )}
      </Grid>
    </Grid>
  );
};
