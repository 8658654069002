import { Box, CircularProgress, Stack, Typography } from '@mui/material';

import { useProjectQueryFind } from '../../../hooks/query';

export interface ColumnProjectProperties {
  projectId?: string;
}

export function ColumnProject({ projectId }: ColumnProjectProperties) {
  const projectQuery = useProjectQueryFind(projectId);

  if (projectQuery.isLoading) {
    return (
      <Box>
        <CircularProgress size={14} />
      </Box>
    );
  }

  if (!projectQuery.data) {
    return null;
  }

  return (
    <Stack spacing={1} display={'flex'} direction={'row'} alignItems={'center'} height={'100%'}>
      <Typography>{projectQuery.data.name}</Typography>
    </Stack>
  );
}
