import { Box, Grid2 as Grid } from '@mui/material';
import { useState } from 'react';
import Joyride from 'react-joyride';

import { Layout } from '../../Layout/Layout.js';
import { defaultFeatureTourOptions } from '../../features/defaultFeatureTourOptions.js';

import { DataSourcesTabs } from './components/DataSourceTabs.js';
import { DataSourcesHeader } from './components/DataSourcesHeader.js';
const tourSteps = [
  {
    target: '#data-sources-tabs',
    content: 'Here you can find your external data sources.',
    disableBeacon: true,
  },
  {
    target: '#data-sources-add',
    content: 'You can add more external data sources.',
    disableBeacon: true,
  },
  {
    target: '#data-sources-card',
    content:
      'One card represents one synchronization with the external data source. Check out the details to see how the data can be managed.',
    disableBeacon: true,
  },
  {
    target: '#data-sources-add-import',
    content: 'You can add a synchronization run for the selected data source manually.',
    disableBeacon: true,
  },
];

export const DataSources = () => {
  const [fullScreen, setFullScreen] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [tour, setTour] = useState(false);

  if (fullScreen && viewDetails) {
    return (
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            flexGrow: 1,
            height: '100vh',
          }}
        >
          <DataSourcesTabs
            viewDetails={viewDetails}
            setViewDetails={setViewDetails}
            fullScreen={fullScreen}
            setFullScreen={setFullScreen}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Layout showCustomerSelector hasScopeNavigation>
      <Grid container spacing={3}>
        <Joyride
          run={tour}
          steps={tourSteps}
          styles={{ options: defaultFeatureTourOptions }}
          continuous
          showProgress
          showSkipButton
          spotlightClicks
        />
        {!fullScreen && <DataSourcesHeader setTour={setTour} />}
        <DataSourcesTabs
          viewDetails={viewDetails}
          setViewDetails={setViewDetails}
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
        />
      </Grid>
    </Layout>
  );
};
