import { KaeplaAuthenticationProviderProperties } from '@kaepla/types';
import SamlActiveIcon from '@mui/icons-material/CheckCircle';
import { IconButton, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import { useAuth } from '../../../../../AuthReactProvider';
import { Icon } from '../../../../../primitive/Icon/Icon';
import { requestPasswordResetLink } from '../../../../../services/api/sendPasswordResetLink';

export const AuthProviderActions = ({
  provider,
  isActiveForUser,
  setAuthMessage,
}: KaeplaAuthenticationProviderProperties) => {
  const { removeAuthenticationProvider, addOAuthProvider } = useAuth();
  const mutation = useMutation({
    mutationFn: (providerId: string) => removeAuthenticationProvider(providerId),
    onSuccess() {
      //
      // this forces the upper to re render the provider list
      //
      setAuthMessage(`Provider ${provider.label} removed`);
    },
  });

  const addProvider = () => {
    if (provider.type === 'oAuth2') {
      void addOAuthProvider(provider.providerId);
      return;
    } else if (provider.type === 'password') {
      void requestPasswordResetLink();

      setAuthMessage('Link is on the way!');
      return;
    }
  };

  if (provider.alwaysAvailable && provider.type !== 'saml') {
    return <Typography variant="body2">always available</Typography>;
  }
  if (provider.alwaysAvailable && provider.type === 'saml') {
    return <SamlActiveIcon fontSize="small" color="success" />;
  }
  if (isActiveForUser) {
    return (
      <IconButton
        edge="end"
        aria-label="delete"
        data-testid={`auth-${provider.providerId}-remove`}
        disabled={!provider.active || mutation.isPending}
        onClick={() => mutation.mutate(provider.providerId)}
      >
        <Icon loading={mutation.isPending} name="DeleteOutline" />
      </IconButton>
    );
  }
  return (
    <IconButton
      edge="end"
      aria-label="add"
      data-testid={`auth-${provider.providerId}-add`}
      disabled={!provider.active || provider.alwaysAvailable}
      onClick={() => {
        addProvider();
      }}
    >
      <Icon name="Add" />
    </IconButton>
  );
};
