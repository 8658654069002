import { KaeplaEvent } from '@kaepla/events';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Box } from '@mui/material';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { knownUsersState } from '../../../../../services/recoil/persistent/knownUsersState.js';

import { EventGroup } from './features/EventGroup/EventGroup.js';
import { EventUser } from './features/EventUser/EventUser.js';
import { groupEvents } from './helpers/groupEvents.js';

interface Options {
  eventList: KaeplaEvent[];
}

export const EventLogGroupList = ({ eventList }: Options) => {
  const knownUsers = useRecoilValue(knownUsersState);

  const eventGroups = useMemo(() => groupEvents(eventList), [eventList]);

  return (
    <Box>
      {Object.entries(eventGroups).map(([key, group]) => (
        <Box key={key}>
          <TimelineItem>
            <TimelineOppositeContent color="textSecondary">
              <EventUser uid={group.uid} knownUsers={knownUsers} />
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <EventGroup group={group} />
          </TimelineItem>
        </Box>
      ))}
    </Box>
  );
};
