import { getApp } from 'firebase/app';
import { collection, doc, DocumentData, getFirestore, setDoc } from 'firebase/firestore';
import { KaeplaReseller } from '@kaepla/types';

interface Options {
  reseller: Partial<KaeplaReseller>;
}

export const createReseller = async ({ reseller }: Options) => {
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `resellers`;
  const firestoreCollectionReference = collection(firestoreDatabase, fireStorePath);
  const firestoreDocumentReference = doc(firestoreCollectionReference);
  const _reseller = {
    id: firestoreDocumentReference.id,
    ...reseller,
  } as KaeplaReseller;
  await setDoc(firestoreDocumentReference, _reseller as DocumentData);
  return _reseller;
};
