import { atom } from 'recoil';
import { KaeplaPerspective } from '@kaepla/types';

import { localForageEffect } from '../persistent/localForageEffect.js';

const defaultPerspectiveState: Partial<KaeplaPerspective> | undefined = undefined;

export const perspectiveState = atom<Partial<KaeplaPerspective>>({
  key: 'perspective',
  default: defaultPerspectiveState,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  effects_UNSTABLE: [localForageEffect],
});
