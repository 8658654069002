import {
  KaeplaSimulation,
  KaeplaSimulationParameterNumeric,
  KaeplaSimulationParameterText,
  KaeplaSimulationRulesetWithParameters,
} from '@kaepla/types';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Grid2 as Grid, IconButton, Toolbar, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { matrixFilteredState } from '../../../../services/recoil/nonpersistent/matrixFilteredState.js';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState.js';
import { cleanColumnName } from '../../../helpers/cleanColumnName.js';

import { ParameterText } from './ParameterText.js';
import { getAliasFor } from './_helpers/getAliasFor.js';

interface Options {
  parameter: KaeplaSimulationParameterNumeric | KaeplaSimulationParameterText;
  simulation: KaeplaSimulation;
  ruleset: KaeplaSimulationRulesetWithParameters;
  simulationRulesets: KaeplaSimulationRulesetWithParameters[];
  setSimulationRulesets: Dispatch<
    SetStateAction<KaeplaSimulationRulesetWithParameters[] | undefined>
  >;
  setPreview: Dispatch<SetStateAction<boolean>>;
  own: boolean;
}

export const SimulationParameterText = ({
  parameter,
  simulation,
  ruleset,
  simulationRulesets,
  setSimulationRulesets,
  setPreview,
  own,
}: Options) => {
  const theme = useTheme();
  const project = useRecoilValue(projectState);
  const matrixFiltered = useRecoilValue(matrixFilteredState);
  const [addValue, setAddValue] = useState(false);

  const dimension = useMemo(
    () => matrixFiltered?.dimensions?.dimensions.find((d) => d.columnName === parameter.dimension),
    [matrixFiltered?.dimensions?.dimensions, parameter.dimension],
  );

  if (!project) return null;

  return (
    <Box
      borderBottom={1}
      // borderRadius={1}
      borderColor={theme.palette.grey[300]}
      p={1}
      px={2}
      sx={{
        minHeight: 100,
        opacity: simulation?.isBeingSimulated ? 0.3 : 1,
      }}
    >
      <Grid container spacing={1} sx={{ height: '100%' }}>
        <Grid size={12}>
          <Toolbar disableGutters variant="dense">
            {dimension &&
              cleanColumnName(getAliasFor(dimension, matrixFiltered?.dimensions?.dimensions))}
            <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
            <IconButton
              aria-label="delete"
              color="primary"
              size="small"
              disabled={!own}
              onClick={() => {
                setAddValue(true);
              }}
            >
              <AddIcon fontSize="small" />
            </IconButton>
            <IconButton
              aria-label="delete"
              color="primary"
              size="small"
              disabled={!own}
              onClick={() => {
                const newRulesets = [...simulationRulesets].map((r) => {
                  if (r.id === ruleset.id) {
                    const newRuleset = { ...ruleset };
                    newRuleset.parameters = [...newRuleset.parameters].filter(
                      (p) => p.dimension !== parameter.dimension,
                    );
                    return newRuleset;
                  }
                  return r;
                });
                setSimulationRulesets(newRulesets);
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Toolbar>
        </Grid>
        <Grid size={12}>
          <ParameterText
            parameter={parameter}
            ruleset={ruleset}
            own={own}
            simulationRulesets={simulationRulesets}
            setSimulationRulesets={setSimulationRulesets}
            setPreview={setPreview}
            addValue={addValue}
            setAddValue={setAddValue}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
