/* eslint-disable @typescript-eslint/naming-convention */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import numbro from 'numbro';
import { ReactElement, useEffect, useState } from 'react';
import { KaeplaDimensionDetails } from '@kaepla/types';

import { ExpandMore } from '../../../features/ExpandMore.js';
import { camelToSentenceCase } from '../../../helpers/camelToSentenceCase';
import { cleanColumnName } from '../../../helpers/cleanColumnName.js';

interface ColoredCellOptions {
  rowNumber: number;
  colorArray: string[];
  cellValue: unknown;
  replaceUnderscores?: boolean;
}

const CellWithRowColor = ({
  rowNumber,
  colorArray,
  cellValue,
  replaceUnderscores = true,
}: ColoredCellOptions) => {
  const isDecimalOrNumber = /^-?\d+(\.\d+)?$/;

  let value = `${cellValue as string}`;
  if (isDecimalOrNumber.test(value)) {
    value = numbro(cellValue).format({
      average: true,
      mantissa: 1,
      trimMantissa: true,
    });
  } else {
    value = `${cellValue as string}`;
    if (replaceUnderscores) {
      value = cleanColumnName(`${cellValue as string}`);
    }
  }
  return (
    <Box>
      <Box
        component="span"
        sx={{
          width: 10,
          height: 10,
          mr: 1,
          backgroundColor: colorArray[rowNumber],
          display: 'inline-block',
        }}
      />
      {value}
    </Box>
  );
};

interface Options {
  detail: KaeplaDimensionDetails;
  chartColors: string[];
}

interface DimensionSummaryRow {
  cell: ReactElement;
  value: string;
  index: number;
  recordsFormatted?: string;
}
interface DimensionSummaryColumn {
  id: string;
  header: string;
}

const dimensionsOver = 35;
const dimensionsCap = 20;

export const DimensionSummaryTable = ({ detail, chartColors }: Options) => {
  const [data, setData] = useState<DimensionSummaryRow[]>([]);
  const [columns, setColumns] = useState<DimensionSummaryColumn[]>([]);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    const _data = [...detail.dimensionDetails]
      .map((_row, index) => {
        const row: DimensionSummaryRow = {
          value: _row.value,
          index,
          cell: (
            <CellWithRowColor rowNumber={index} colorArray={chartColors} cellValue={_row.value} />
          ),
        };
        if (_row.numberOfRecords) {
          row.recordsFormatted = numbro(_row.numberOfRecords).format({
            average: true,
            mantissa: 1,
            trimMantissa: true,
          });
        }
        // row.color = chartColors[index];
        return row;
      })
      .slice(0, detail.dimensionDetails.length > dimensionsOver ? dimensionsCap : dimensionsOver);
    setData(_data); // Setting the data that we want to display
    if (_data[0]) {
      const _columns = Object.keys(detail.dimensionDetails[0])
        .filter((d) => d !== 'value')
        .map((d) => ({
          id: d,
          header: camelToSentenceCase(cleanColumnName(d)),
        }));
      setColumns(_columns);
    }
  }, [detail, chartColors]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 80 }} size="small">
        <TableHead>
          <TableRow>
            {columns.map((col) => (
              <TableCell key={col.id}>{col.header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.slice(0, showMore ? data.length : 5).map((row) => (
            <TableRow
              key={row.index}
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0,
                },
              }}
            >
              <TableCell>{row.cell}</TableCell>
              <TableCell>{row.recordsFormatted}</TableCell>
            </TableRow>
          ))}
          {data.length > 5 && (
            <TableRow
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0,
                },
              }}
            >
              <TableCell>
                show {showMore ? 'less' : 'all'}{' '}
                <ExpandMore
                  expand={showMore}
                  onClick={() => {
                    setShowMore(!showMore);
                  }}
                  aria-expanded={showMore}
                  aria-label="show more"
                >
                  <ExpandMoreIcon fontSize="small" />
                </ExpandMore>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
