import { WhereClause } from '@atrigam/atrigam-service-firebase-watcher';
import { KaeplaGridSetStored, KaeplaGridSetting } from '@kaepla/types';
import ShowGridStateIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { Autocomplete, Box, FormControl, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useAuth } from '../../../../../../AuthReactProvider';
import { addFirestoreCollectionListener } from '../../../../../../services/firestore/addFirestoreCollectionListener';
import { dataGridSettings } from '../../../../../../services/recoil/nonpersistent/dataGridSets';
import { projectState } from '../../../../../../services/recoil/nonpersistent/projectState';
import { ExpandMore } from '../../../../../features/ExpandMore';

interface Options {
  showSavedGrids: boolean;
  setShowSavedGrids: React.Dispatch<React.SetStateAction<boolean>>;
  currentGridId: string | undefined;
  loadGridState: (gridSettings: KaeplaGridSetting) => void;
  selectedGridSet: KaeplaGridSetStored | null;
  setSelectedGridSet: React.Dispatch<React.SetStateAction<KaeplaGridSetStored | null>>;
  setTabNumber: React.Dispatch<React.SetStateAction<number>>;
}

export const GridMenuGridSets = ({
  showSavedGrids,
  setShowSavedGrids,
  currentGridId,
  loadGridState,
  selectedGridSet,
  setSelectedGridSet,
  setTabNumber,
}: Options) => {
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const [gridSettings, setGridSettings] = useRecoilState(dataGridSettings);
  const [gridSets, setGridSets] = useState<KaeplaGridSetStored[]>([]);
  const [gridSetsForAutocomplete, setGridSetsForAutocomplete] = useState<
    { name: string; id: string }[]
  >([]);
  const [selectedGridSetForAutocomplete, setSelectedGridSetForAutocomplete] = useState<{
    name: string;
    id: string;
  } | null>(null);

  useEffect(() => {
    if (!project?.id) return;
    if (!kaeplaUser?.uid) return;

    const fireStorePath = `projects/${project.id}/gridSets`;
    const queryWhere: WhereClause[] = [
      {
        fieldPath: 'projectId',
        opStr: '==',
        value: project.id,
      },
      {
        fieldPath: 'createdBy',
        opStr: '==',
        value: kaeplaUser.uid,
      },
    ];

    const unsubscribe = addFirestoreCollectionListener({
      fireStorePath,
      queryWhere,
      orderBy: {
        fieldPath: 'createdAt',
        direction: 'desc',
      },
      limit: 50,
      callback: (data) => {
        const gridSetsStored = data as KaeplaGridSetStored[];
        setGridSets(gridSetsStored);
        setGridSetsForAutocomplete(
          gridSetsStored
            .map((gridSet) => ({ name: gridSet.name, id: gridSet.id }))
            .sort((a, b) => a.name.localeCompare(b.name)),
        );
        if (
          selectedGridSet?.id &&
          gridSetsStored.some((gridSet) => gridSet.id === selectedGridSet?.id)
        ) {
          setSelectedGridSetForAutocomplete({ name: selectedGridSet.name, id: selectedGridSet.id });
        } else {
          setSelectedGridSetForAutocomplete(null);
        }
      },
    });
    return () => {
      unsubscribe();
    };
  }, [kaeplaUser?.uid, project.createdBy, project.id, selectedGridSet?.id, selectedGridSet?.name]);
  return (
    <Box>
      <FormControl sx={{ m: 0, minWidth: 220 }} size="small" margin="dense">
        <Autocomplete
          size="small"
          value={selectedGridSetForAutocomplete}
          getOptionLabel={(option) => option.name}
          options={gridSetsForAutocomplete ?? []}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(_event, newValue) => {
            if (!newValue?.id) return;
            const newValueGridSet = gridSets.find((gridSet) => gridSet.id === newValue.id) ?? null;
            if (!newValueGridSet?.gridSettingsStringified) return;
            setSelectedGridSet(newValueGridSet);
            const newGridSettings = JSON.parse(
              newValueGridSet.gridSettingsStringified,
            ) as KaeplaGridSetting[];
            setGridSettings(newGridSettings);
            if (newGridSettings.length > 0) {
              loadGridState(newGridSettings[0]);
              setTabNumber(0);
            }
            setShowSavedGrids(true);
          }}
          renderInput={(parameters) => <TextField {...parameters} label="Select Grid Set" />}
          onInputChange={(_event, newInputValue) => {
            if (newInputValue === '') {
              setSelectedGridSet(null);
            }
          }}
        />
      </FormControl>
      <ExpandMore
        expand={showSavedGrids}
        aria-expanded={showSavedGrids}
        aria-label="show grid sets"
        edge="end"
        sx={{ mr: -0.8 }}
        onClick={() => {
          if (!showSavedGrids && currentGridId) {
            loadGridState(gridSettings[0]);
          }
          setShowSavedGrids(!showSavedGrids);
        }}
      >
        <ShowGridStateIcon />
      </ExpandMore>
    </Box>
  );
};
