import { getApp } from 'firebase/app';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { KaeplaUser } from '@kaepla/types';

interface Options {
  email: string;
}

export const getUserByEmail = async ({ email }: Options) => {
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `users`;
  const firestoreCollection = collection(firestoreDatabase, fireStorePath);
  let firestoreQuery = query(firestoreCollection);
  firestoreQuery = query(firestoreQuery, where('email', '==', email));
  const snapshot = await getDocs(firestoreQuery);
  if (!snapshot.docs[0]) return;
  return snapshot.docs[0].data() as KaeplaUser;
};
