import { KaeplaImport } from '@kaepla/types';
import { collection, doc, FirestoreDataConverter, QueryDocumentSnapshot } from 'firebase/firestore';

import { FIREBASE } from '../base';

export type Data = KaeplaImport;

const converter = {
  toFirestore: (data: Data) => data,
  fromFirestore: (snap: QueryDocumentSnapshot<Data>) => snap.data(),
} satisfies FirestoreDataConverter<Data>;

export const getImportPath = 'imports';

export const getImportCollectionReference = collection(FIREBASE, getImportPath).withConverter(
  converter,
);

export const getImportReference = (id: string) => {
  return doc(getImportCollectionReference, id);
};
