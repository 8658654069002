import { KaeplaImport, KaeplaOpsUpdateStatus } from '@kaepla/types';
import ArchiveIcon from '@mui/icons-material/ArchiveOutlined';
import SucceededIcon from '@mui/icons-material/CheckCircle';
import FailedIcon from '@mui/icons-material/ErrorOutlineRounded';
import AdminIcon from '@mui/icons-material/Person2Outlined';
import AutomatedIcon from '@mui/icons-material/ScheduleOutlined';
import UnArchiveIcon from '@mui/icons-material/UnarchiveOutlined';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid2 as Grid,
  IconButton,
  IconButtonProps,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import { useRecoilValue } from 'recoil';

import { useAuth } from '../../../AuthReactProvider.js';
import { addFirestoreDocumentListener } from '../../../services/firestore/addFirestoreDocumentListener.js';
import { requestCancelImport } from '../../../services/firestore/requestCancelImport.js';
import { toggleArchiveImport } from '../../../services/firestore/toggleArchiveImport.js';
import { projectState } from '../../../services/recoil/nonpersistent/projectState.js';
import { knownUsersState } from '../../../services/recoil/persistent/knownUsersState.js';
import { UserAvatar } from '../../features/UserAvatar.js';
import { projectAwarePath } from '../../helpers/projectAwarePath.js';

import { dataImportRuntime } from './helpers/dataImportRuntime.js';
import { failedOrCancelledOrFinished } from './helpers/failedOrFinished.js';
import { ImportCardTitle } from './ImportCardTitle.js';

interface Options {
  dataImport: KaeplaImport;
}

const ToggleArchive = styled((properties: IconButtonProps) => {
  const { ...other } = properties;
  return <IconButton {...other} />;
})(() => ({
  marginLeft: 'auto',
}));

export const ImportCard = ({ dataImport }: Options) => {
  const navigate = useNavigate();
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const knownUsers = useRecoilValue(knownUsersState);
  const user = knownUsers.find((k) => k.uid === dataImport.createdBy);
  const [dataImportUpdate, setImportUpdate] = useState<KaeplaImport>(dataImport);

  const succeeded = () => {
    return dataImportUpdate.updateStatus === KaeplaOpsUpdateStatus.finished;
  };

  const failed = () => {
    return [
      KaeplaOpsUpdateStatus.failed,
      KaeplaOpsUpdateStatus.canceled,
      KaeplaOpsUpdateStatus.cancelRequested,
    ].includes(dataImportUpdate.updateStatus);
  };

  const started = () => {
    return dataImportUpdate.updateStatus === KaeplaOpsUpdateStatus.started;
  };

  const cancelUpdate = () => {
    if (!dataImportUpdate) return;
    void requestCancelImport({ dataImport: dataImportUpdate });
  };

  const changes = () => {
    if (!failed() && !succeeded()) {
      return 'running...';
    }
    if (dataImportUpdate.ingestedRecordsCount) {
      return `${dataImportUpdate.ingestedRecordsCount ?? 0} changes, `;
    }
    return 'no changes, ';
  };

  useEffect(() => {
    if (!project?.id) return;
    if (!kaeplaUser?.uid) return;

    const fireStorePath = `imports/${dataImport.id}`;
    const unsubscribe = addFirestoreDocumentListener({
      fireStorePath,
      callback: (data) => {
        const _dataImportUpdate = data as KaeplaImport;
        setImportUpdate(_dataImportUpdate);
      },
    });
    return () => {
      unsubscribe();
    };
  }, [dataImport.id, kaeplaUser?.uid, project.id]);

  return (
    <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}>
      <Card
        elevation={dataImportUpdate.archived ? 0 : 2}
        sx={{ height: '100%', opacity: dataImportUpdate.archived ? 1 : 1 }}
      >
        <CardHeader
          title={<ImportCardTitle dataImportUpdate={dataImportUpdate} />}
          subheader={
            <Stack>
              <Typography variant="body2">{`${changes()}${dataImportRuntime(dataImportUpdate)}`}</Typography>
            </Stack>
          }
          avatar={
            user ? (
              <UserAvatar sx={{ mr: 1 }} user={user} />
            ) : dataImportUpdate.createdBy === 'kaepla-admin' ? (
              <AdminIcon fontSize="large" />
            ) : (
              <AutomatedIcon fontSize="large" />
            )
          }
        />
        <CardContent>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            {!failedOrCancelledOrFinished(dataImportUpdate) && (
              <Stack direction="row" alignItems="center" gap={1}>
                <CircularProgress size={16} sx={{ mr: 1 }} />
                <Button
                  onClick={() => {
                    cancelUpdate();
                  }}
                  disabled={!dataImportUpdate}
                >
                  cancel
                </Button>
              </Stack>
            )}
            {succeeded() && <SucceededIcon color="success" />}
            {failed() && (
              <Stack direction="row" alignItems="center" gap={1}>
                <FailedIcon color="error" /> {dataImportUpdate?.updateStatus.toUpperCase()}
              </Stack>
            )}
            {(started() || succeeded() || failed()) && dataImportUpdate?.startedAt && (
              <TimeAgo date={dataImportUpdate?.startedAt.toDate()} max={Number.MAX_SAFE_INTEGER} />
            )}
          </Stack>
        </CardContent>
        <CardActions disableSpacing>
          <Button
            onClick={() =>
              navigate(projectAwarePath('SyncData', project) + '/' + dataImportUpdate.id)
            }
            disabled={!dataImportUpdate}
          >
            Show Log
          </Button>
          <ToggleArchive
            aria-label={dataImportUpdate.archived ? 'unarchive' : 'archive'}
            onClick={() => {
              void toggleArchiveImport({ project, dataImport: dataImportUpdate });
            }}
          >
            {dataImport.archived ? <UnArchiveIcon /> : <ArchiveIcon color="primary" />}
          </ToggleArchive>
        </CardActions>
      </Card>
    </Grid>
  );
};
