import AddIcon from '@mui/icons-material/AddOutlined';
import { Box, Button, Card, CardHeader } from '@mui/material';

import { Image } from '../../../../primitive/Image/Image';
import { avatarAppearDuration } from '../../defaults';
import { KaeplaConnectorType } from '../types';

interface Options {
  connectorType: KaeplaConnectorType;
  setNewConnector: React.Dispatch<React.SetStateAction<boolean>>;
  setNewConnectorType: React.Dispatch<React.SetStateAction<KaeplaConnectorType | undefined>>;
}

export const ConnectorTypeCard = ({
  connectorType,
  setNewConnectorType,
  setNewConnector,
}: Options) => {
  return (
    <Card
      sx={{ height: '100%', cursor: 'pointer', opacity: connectorType.implemented ? 1 : 0.5 }}
      onClick={() => {
        setNewConnector(false);
        setNewConnectorType(connectorType);
      }}
    >
      <CardHeader
        title={<Box sx={{ fontSize: '1.2em' }}>{connectorType.name}</Box>}
        avatar={
          <Image
            style={{ maxHeight: '30px' }}
            alt={connectorType.name}
            src={connectorType.base64Icon}
            duration={avatarAppearDuration}
          />
        }
        action={
          <Button
            variant="contained"
            size="small"
            disabled={!connectorType.implemented}
            endIcon={<AddIcon sx={{ ml: -1.5 }} />}
          />
        }
      />
    </Card>
  );
};
