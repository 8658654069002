import { WhereClause } from '@atrigam/atrigam-service-firebase-watcher';
import { ThreadMessage } from '@kaepla/types';

import { addFirestoreCollectionListener } from '../../../../services/firestore/addFirestoreCollectionListener';

const BATCH_SIZE = 50;
interface WatcherOptions {
  threadId: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setMessages: React.Dispatch<React.SetStateAction<ThreadMessage[]>>;
}
export const watchMessages = ({ threadId, setLoading, setMessages }: WatcherOptions) => {
  setLoading(true);

  const fireStorePath = `aiMessages`;
  const queryWhere: WhereClause[] = [];
  queryWhere.push({
    fieldPath: 'threadId',
    opStr: '==',
    value: threadId,
  });

  const limit = BATCH_SIZE;

  return addFirestoreCollectionListener({
    fireStorePath,
    queryWhere,
    orderBy: {
      fieldPath: 'createdAt',
      direction: 'asc',
    },
    limit,
    callback: (data) => {
      const _messages = data as ThreadMessage[];
      setMessages(_messages);
      setLoading(false);
    },
  });
};
