import { clientEventService } from '@kaepla/events';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import FilterIcon from '@mui/icons-material/FilterAlt';
import FilterOffIcon from '@mui/icons-material/FilterAltOffOutlined';
import DataSnapshotsIcon from '@mui/icons-material/History';
import HideIcon from '@mui/icons-material/VisibilityOffOutlined';
import ShowIcon from '@mui/icons-material/VisibilityOutlined';
import { Box, Grid2 as Grid, IconButton, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';
import { clone } from 'rambda';
import { useNavigate } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { useAuth } from '../../../../../../AuthReactProvider.js';
import { useUserPreferences } from '../../../../../../UserPreferencesProvider.js';
import { createNewPerspective } from '../../../../../../services/firestore/createNewPerspective.js';
import { applicationState } from '../../../../../../services/recoil/nonpersistent/applicationState.js';
import { perspectiveFavoritesState } from '../../../../../../services/recoil/nonpersistent/perspectiveFavoritesState.js';
import { perspectiveState } from '../../../../../../services/recoil/nonpersistent/perspectiveState.js';
import { perspectivesState } from '../../../../../../services/recoil/nonpersistent/perspectivesState.js';
import { projectState } from '../../../../../../services/recoil/nonpersistent/projectState.js';
import { scopePathsState } from '../../../../../../services/recoil/nonpersistent/scopePathsState.js';
import { snapShotState } from '../../../../../../services/recoil/nonpersistent/snapshotState.js';
import { currentScopePathState } from '../../../../../../services/recoil/persistent/currentScopePathState.js';
import { filterSettingsState } from '../../../../../../services/recoil/persistent/filterSettingState.js';
import { kaeplaAssignmentState } from '../../../../../../services/recoil/persistent/kaeplaAssignmentState.js';
import { projectAwarePath } from '../../../../../helpers/projectAwarePath.js';
import { dataSnapshotColor } from '../../../../defaults.js';
import { updatePerspectiveFavorites } from '../../../helpers/updatePerspectiveFavorites.js';

import { ProjectRecordsCount } from './ProjectRecordsCount.js';
import { ProjectSelectSimulation } from './ProjectSelectSimulation.js';
import { SelectFilter } from './SelectFilter.js';
import { SelectPerspective } from './SelectPerspective.js';

interface Options {
  disableSimulationSelect?: boolean;
  disableFilters?: boolean;
}

export const ProjectInfoNavigation = ({ disableSimulationSelect, disableFilters }: Options) => {
  const theme = useTheme();
  const { kaeplaUser } = useAuth();
  const navigate = useNavigate();
  const { setPreferences } = useUserPreferences();
  const project = useRecoilValue(projectState);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const [perspective, setPerspective] = useRecoilState(perspectiveState);
  const scopePath = useRecoilValue(scopePathsState);
  const snapshot = useRecoilValue(snapShotState);
  const resetSnapshot = useResetRecoilState(snapShotState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const [filterSettings, setFilterSettings] = useRecoilState(filterSettingsState);
  const [application, setApplication] = useRecoilState(applicationState);
  const [perspectiveFavorites, setPerspectiveFavorites] = useRecoilState(perspectiveFavoritesState);
  const setPerspectives = useSetRecoilState(perspectivesState);

  const createPerspective = async () => {
    if (!kaeplaUser || !currentScopePath) return;
    const newPerspective = await createNewPerspective({
      project,
      createdBy: kaeplaUser.uid,
      scopePath: scopePath[project.id] ?? [],
      perspective: perspective || project.defaultPerspective,
    });

    setPerspective(newPerspective);
    setPreferences({ lastPerspectiveId: newPerspective.id });

    updatePerspectiveFavorites({
      uid: kaeplaUser.uid,
      project,
      perspective: newPerspective,
      currentScopePath,
      perspectiveFavorites,
      setPerspectiveFavorites,
      isFav: true,
    });

    setPerspectives((perspectives) => [...perspectives, newPerspective]);

    void clientEventService.createEvent({
      assignmentScope: clientEventService.assignmentScope.PROJECT,
      eventGroup: clientEventService.eventGroup.PERSPECTIVES,
      eventName: clientEventService.eventName.PERSPECTIVES_CREATE_PERSPECTIVE,
      uid: kaeplaUser.uid,
      projectId: project.id,
      customerId: project.customerId,
      resellerId: project.resellerId,
      perspectiveId: newPerspective.id,
      scopePath: newPerspective.scopePath,
    });

    setPerspective(newPerspective);
    setPreferences({ lastPerspectiveId: newPerspective.id });

    navigate(`/Perspective/${project.id}/${newPerspective.id}`);
  };

  if (project.disabledReason && !kaeplaAssignment) {
    return <Box bgcolor="InactiveCaption" sx={{ width: '100%', height: 100 }} />;
  }

  return (
    <>
      <Grid size={6}>
        <ProjectRecordsCount />
        {snapshot?.id && (
          <Typography
            sx={{ fontSize: '90%', display: 'flex', gap: 0.5, alignItems: 'start' }}
            color="text.secondary"
          >
            <DataSnapshotsIcon sx={{ fontSize: 15, color: dataSnapshotColor }} />{' '}
            {DateTime.fromJSDate(snapshot.createdAt.toDate()).toFormat('yyyy-MM-dd hh:mm')}
          </Typography>
        )}
        {!snapshot?.id && (
          <Typography
            sx={{ fontSize: '90%', display: 'flex', gap: 0.5, alignItems: 'start' }}
            color="text.secondary"
          >
            latest
          </Typography>
        )}
      </Grid>
      <Grid size={6} onClick={() => navigate(projectAwarePath('SyncData', project))}>
        {project.lastUpdatedAt ? (
          <>
            updated <TimeAgo date={project.lastUpdatedAt.toDate()} max={Number.MAX_SAFE_INTEGER} />
          </>
        ) : (
          'Last Update unknown'
        )}
      </Grid>
      <Grid size={12}>
        <Box sx={{ m: 1 }} />
      </Grid>
      {!snapshot?.id && (
        <ProjectSelectSimulation disableSimulationSelect={disableSimulationSelect} />
      )}
      {snapshot?.id && (
        <Grid size={12}>
          <Typography variant="body1" sx={{ color: theme.palette.info.main }}>
            Latest vs. historic data
            <IconButton
              size="small"
              data-testid="cancel-button"
              onClick={() => {
                resetSnapshot();
              }}
            >
              <CancelIcon fontSize="small" />
            </IconButton>
          </Typography>
        </Grid>
      )}
      <Grid size={12}>
        <Box sx={{ m: 1 }} />
      </Grid>
      <Grid size={8}>
        <SelectPerspective />
      </Grid>
      <Grid size={2} />
      <Grid size={2} container sx={{ justifyContent: { xs: 'flex-end' } }}>
        <Box sx={{ alignSelf: { xs: 'end' } }}>
          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              void createPerspective();
            }}
          >
            <AddIcon />
          </IconButton>
        </Box>
      </Grid>
      <Grid size={12}>
        <Box sx={{ m: 1 }} />
      </Grid>
      <Grid size={8}>
        <SelectFilter disabled={disableFilters} />
      </Grid>
      <Grid size={2} container sx={{ justifyContent: { xs: 'flex-end' } }}>
        <Box sx={{ alignSelf: { xs: 'end' } }}>
          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              setFilterSettings({ ...filterSettings, isActive: !filterSettings.isActive });
            }}
            disabled={filterSettings.ruleCount === 0}
          >
            {filterSettings.isActive ? <FilterIcon /> : <FilterOffIcon />}
          </IconButton>
        </Box>
      </Grid>
      <Grid size={2} container sx={{ justifyContent: { xs: 'flex-end' } }}>
        <Box sx={{ alignSelf: { xs: 'end' } }}>
          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              const newApplication = clone(application);
              newApplication.showFilter = !newApplication.showFilter;
              setApplication(newApplication);
            }}
            disabled={disableFilters}
          >
            {application.showFilter ? <HideIcon /> : <ShowIcon />}
          </IconButton>
        </Box>
      </Grid>
      <Grid size={12}>
        <Box sx={{ m: 1 }} />
      </Grid>
    </>
  );
};
