import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { KaeplaDimensionDetails } from '@kaepla/types';

import { cleanColumnName } from '../../../../helpers/cleanColumnName';

interface COptions {
  dimensionDetails: KaeplaDimensionDetails;
  value: string;
  label: string;
  callback: (value: string) => void;
}
export const DimensionValueSelect = ({ dimensionDetails, label, value, callback }: COptions) => {
  const handleChange = (event: SelectChangeEvent) => {
    if (callback) {
      callback(event.target.value);
    }
  };

  return (
    <FormControl sx={{ m: 0, minWidth: 220 }} size="small" margin="dense">
      <InputLabel id={`input-${label}`}>{label}</InputLabel>
      <Select
        label={label}
        id="simulationSelectDimension"
        value={value || ''}
        onChange={handleChange}
        size="small"
        inputProps={{ name: 'dimensionSelectInput' }}
      >
        <MenuItem value="" dense>
          <em>None</em>
        </MenuItem>
        {dimensionDetails.dimensionDetails
          .slice()
          .sort((a, b) => a?.value?.localeCompare(b?.value))
          .map((detail) => (
            <MenuItem key={detail.value} value={detail.value} dense>
              {cleanColumnName(detail.value)}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
