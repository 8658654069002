import { Thread } from '@kaepla/types';
import { useQuery } from '@tanstack/react-query';

import {
  getAiThreadList,
  GetAiThreadListParameters,
  getAiThreadPath,
} from '../../repository/AiThread';
import { Pagination } from '../../repository/base';
import { useCreateQueryKey } from '../useCreateQueryKey';

interface UseQueryOptions {
  refetchInterval?: number;
}

export function useAiThreadQueryFindAll(
  parameters: GetAiThreadListParameters & Pagination<Thread>,
  options?: UseQueryOptions,
) {
  const { queryKey } = useCreateQueryKey(getAiThreadPath, parameters);
  const query = useQuery({
    queryKey,
    queryFn: () => {
      if (!parameters) {
        return [];
      }
      return getAiThreadList(parameters);
    },
    refetchInterval: options?.refetchInterval,
  });
  return query;
}
