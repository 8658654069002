import { Breadcrumbs, Divider, Link, Stack } from '@mui/material';
import { Outlet, useParams } from 'react-router-dom';

import { Layout } from '../../Frontend/Layout/Layout';
import { LinkTab, LinkTabs } from '../../primitive/LinkTabs';

export function AdminLayout() {
  const data = useParams<{ threadId: string }>();

  if (data.threadId) {
    //
    // TODO
    // - https://mui.com/material-ui/integrations/routing/#tabs
    //
    return (
      <Layout>
        <>
          <Stack justifyContent={'center'} spacing={2} height={48}>
            <Breadcrumbs>
              <Link fontSize={16} underline="hover" href="/">
                Home
              </Link>
              <Link underline="hover" href="/admin">
                Admin
              </Link>
              <Link underline="hover" href="/admin/ai-thread">
                Ai Threads
              </Link>
              <Link underline="hover" href={`/admin/ai-thread/${data.threadId}`}>
                {data.threadId}
              </Link>
            </Breadcrumbs>
          </Stack>
          <Divider />
          <Outlet />
        </>
      </Layout>
    );
  }

  return (
    <Layout>
      <>
        <LinkTabs>
          <LinkTab label="Imports" href="/admin/import" />
          <LinkTab label="AI Threads" href="/Admin/ai-thread" />
        </LinkTabs>
        <Outlet />
      </>
    </Layout>
  );
}
