import { KaeplaPerspectiveBlock } from '@kaepla/types';
import { Box, Grid2 as Grid, Typography } from '@mui/material';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';

import { KaeplaChartLabel } from '../../helpers/createChartLabels.js';
import { getGridSpanNumberForHeader } from '../../helpers/getGridSpanNumberForHeader.js';
import { ChartHeaderToolbar } from '../ChartHeaderToolbar.js';

import { KaeplaChartDatasets } from './getChartDataForStackedBarChart.js';
import { StackedBarChartTitle } from './StackedBarChartTitle.js';

interface Options {
  chartData?: KaeplaChartDatasets;
  chartLabels: KaeplaChartLabel[];
  chartReference: MutableRefObject<undefined>;
  block: KaeplaPerspectiveBlock;
  setEditing: Dispatch<SetStateAction<boolean>>;
  editing: boolean;
  blockNumber: number;
  locked?: boolean;
  canZoomAndPan?: boolean;
  highlightFilterOverride?: boolean;
}

export const StackedBarChartHeader = ({
  chartData,
  chartLabels,
  chartReference,
  block,
  setEditing,
  editing,
  blockNumber,
  locked,
  canZoomAndPan,
  highlightFilterOverride,
}: Options) => {
  return (
    <Grid container direction="row-reverse">
      <Grid
        size={{
          xs: getGridSpanNumberForHeader('xs', block.gridSpan),
          sm: getGridSpanNumberForHeader('sm', block.gridSpan),
          md: getGridSpanNumberForHeader('md', block.gridSpan),
          lg: getGridSpanNumberForHeader('lg', block.gridSpan),
          xl: getGridSpanNumberForHeader('xl', block.gridSpan),
        }}
      >
        <ChartHeaderToolbar
          {...{
            chartData,
            chartLabels,
            chartReference,
            block,
            setEditing,
            editing,
            blockNumber,
            locked,
            canZoomAndPan,
            highlightFilterOverride,
          }}
        />
      </Grid>
      <Grid
        size={{
          xs: getGridSpanNumberForHeader('xs', block.gridSpan),
          sm: getGridSpanNumberForHeader('sm', block.gridSpan),
          md: getGridSpanNumberForHeader('md', block.gridSpan),
          lg: getGridSpanNumberForHeader('lg', block.gridSpan),
          xl: getGridSpanNumberForHeader('xl', block.gridSpan),
        }}
      >
        <Typography sx={{ fontSize: 18, my: 1 }} color="text.secondary" gutterBottom>
          <Box component="span" sx={{ fontSize: '90%', fontWeight: '100', mr: 1 }}>
            <StackedBarChartTitle block={block} />
          </Box>
        </Typography>
      </Grid>
    </Grid>
  );
};
