import { Box } from '@mui/material';

import { GridView } from '../features/GridView/GridView';

export const DataViewGrid = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        sx={{
          flexGrow: 1,
          height: '100vh',
        }}
      >
        <GridView />
      </Box>
    </Box>
  );
};
