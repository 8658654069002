import { KaeplaSimulation, KaeplaUser } from '@kaepla/types';
import SimulationIcon from '@mui/icons-material/AutoGraphOutlined';
import { Box, Grid2 as Grid, Stack, Typography } from '@mui/material';

import { UserAvatar } from '../../../features/UserAvatar.js';
import { simulationsColor } from '../../defaults.js';

interface Options {
  simulation?: KaeplaSimulation;
  owner?: KaeplaUser;
  own: boolean;
}

export const SimulationEditorHeader = ({ own, simulation, owner }: Options) => {
  if (!simulation || !owner || own) {
    return null;
  }

  return (
    <Grid size={12}>
      <Stack direction="row" spacing={2}>
        {owner && <UserAvatar user={owner} />}
        <Stack direction="column">
          <Stack direction="row" spacing={1}>
            <SimulationIcon sx={{ color: simulationsColor }} fontSize="small" />
            <Box>{simulation.name}</Box>
          </Stack>
          <Typography>{simulation.description}</Typography>
        </Stack>
      </Stack>
    </Grid>
  );
};
