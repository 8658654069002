import { getDocs } from 'firebase/firestore';

import { getProjectListQuery } from './project.query';

export const getProjectList = async (
  parameters: Parameters<typeof getProjectListQuery>[number],
) => {
  const q = getProjectListQuery(parameters);
  const snapshot = await getDocs(q);
  //
  // TODO cleanup firebase data
  //
  return snapshot.docs
    .map((d) => d.data())
    .filter((c) => c !== undefined)
    .filter((c) => c.name !== undefined)
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name));
};

export type GetProjectListParameters = Parameters<typeof getProjectList>[number];
