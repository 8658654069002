import DisabledIcon from '@mui/icons-material/CancelOutlined';
import DoneIcon from '@mui/icons-material/CheckCircleOutline';
import {
  Box,
  FormControl,
  Grid2 as Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useRecoilValue } from 'recoil';

import { updateProject } from '../../../services/firestore/updateProject';
import { projectState } from '../../../services/recoil/nonpersistent/projectState';
import { kaeplaAssignmentState } from '../../../services/recoil/persistent/kaeplaAssignmentState';

import { updateIntervalMap } from './updateIntervalMap';

export const SycDataSettings = () => {
  const project = useRecoilValue(projectState);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);

  const handleDeltaUpdateChange = (event: SelectChangeEvent) => {
    const interval = event.target.value;
    const updatedProject = {
      ...project,
      deltaUpdateIntervalInHours: interval as unknown as number,
    };
    void updateProject({ project: updatedProject });
  };

  const handleFullUpdateChange = (event: SelectChangeEvent) => {
    const interval = event.target.value;
    const updatedProject = {
      ...project,
      fullUpdateIntervalInHours: interval as unknown as number,
    };
    void updateProject({ project: updatedProject });
  };

  const isAutoUpdateEnabled = () => {
    if (!project?.deltaUpdateIntervalInHours && !project?.fullUpdateIntervalInHours) return false;
    return true;
  };

  return (
    <Grid size={12}>
      <Paper sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid size={12}>
            <Typography variant="h4">Update Settings</Typography>
          </Grid>
          <Grid size={6}>
            {isAutoUpdateEnabled() ? 'Auto Update is enabled' : 'Auto Update is disabled'}
          </Grid>
          <Grid size={6}>
            {isAutoUpdateEnabled() && <DoneIcon color="success" fontSize="small" />}
            {!isAutoUpdateEnabled() && <DisabledIcon color="error" fontSize="small" />}
          </Grid>
          <Grid size={6}>
            <Box sx={{ width: 240 }}>
              <FormControl fullWidth>
                <InputLabel id="delta-updates-label">Delta Updates</InputLabel>
                <Select
                  value={
                    project?.deltaUpdateIntervalInHours
                      ? `${project?.deltaUpdateIntervalInHours}`
                      : '0'
                  }
                  label="Delta Updates"
                  onChange={handleDeltaUpdateChange}
                >
                  <MenuItem value={updateIntervalMap.off.value}>
                    {updateIntervalMap.off.label}
                  </MenuItem>
                  {kaeplaAssignment && (
                    <MenuItem value={updateIntervalMap.one.value}>
                      {updateIntervalMap.one.label}
                    </MenuItem>
                  )}
                  <MenuItem value={updateIntervalMap.three.value}>
                    {updateIntervalMap.three.label}
                  </MenuItem>
                  <MenuItem value={updateIntervalMap.six.value}>
                    {updateIntervalMap.six.label}
                  </MenuItem>
                  <MenuItem value={updateIntervalMap.twelve.value}>
                    {updateIntervalMap.twelve.label}
                  </MenuItem>
                  <MenuItem value={updateIntervalMap.twentyFour.value}>
                    {updateIntervalMap.twentyFour.label}
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid size={6}>
            <Box sx={{ width: 240 }}>
              <FormControl fullWidth>
                <InputLabel id="full-updates-label">Full Updates</InputLabel>
                <Select
                  value={
                    project?.fullUpdateIntervalInHours
                      ? `${project?.fullUpdateIntervalInHours}`
                      : '0'
                  }
                  label="Full Updates"
                  onChange={handleFullUpdateChange}
                >
                  <MenuItem value={updateIntervalMap.off.value}>
                    {updateIntervalMap.off.label}
                  </MenuItem>
                  {kaeplaAssignment && (
                    <MenuItem value={updateIntervalMap.one.value}>
                      {updateIntervalMap.one.label}
                    </MenuItem>
                  )}
                  <MenuItem value={updateIntervalMap.twelve.value}>
                    {updateIntervalMap.twelve.label}
                  </MenuItem>
                  <MenuItem value={updateIntervalMap.twentyFour.value}>
                    {updateIntervalMap.twentyFour.label}
                  </MenuItem>
                  <MenuItem value={updateIntervalMap.fortyEight.value}>
                    {updateIntervalMap.fortyEight.label}
                  </MenuItem>
                  <MenuItem value={updateIntervalMap.oneHundredAndTwenty.value}>
                    {updateIntervalMap.oneHundredAndTwenty.label}
                  </MenuItem>
                  <MenuItem value={updateIntervalMap.twoHundredAndForty.value}>
                    {updateIntervalMap.twoHundredAndForty.label}
                  </MenuItem>
                  <MenuItem value={updateIntervalMap.threeHundredAndThirtySix.value}>
                    {updateIntervalMap.threeHundredAndThirtySix.label}
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
