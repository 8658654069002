import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/EditOutlined';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import { Button, ButtonGroup } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { KaeplaPerspectiveBlock } from '@kaepla/types';

import { clonePerspectiveBlock } from '../../../../../../services/firestore/clonePerspectiveBlock.js';
import { removePerspectiveBlock } from '../../../../../../services/firestore/removePerspectiveBlock.js';
import { perspectiveState } from '../../../../../../services/recoil/nonpersistent/perspectiveState.js';
import { projectState } from '../../../../../../services/recoil/nonpersistent/projectState.js';

interface Options {
  block: KaeplaPerspectiveBlock;
  setEditing: Dispatch<SetStateAction<boolean>>;
  blockNumber: number;
}

export const ChartToolbarEditCopyDelete = ({ block, setEditing, blockNumber }: Options) => {
  const project = useRecoilValue(projectState);
  const [perspective, setPerspective] = useRecoilState(perspectiveState);

  const cloneBlock = async () => {
    const newPerspective = await clonePerspectiveBlock({ project, perspective, block });
    setPerspective(newPerspective);
  };

  const removeBlock = async () => {
    const newPerspective = await removePerspectiveBlock({ project, perspective, blockNumber });
    setPerspective(newPerspective);
  };

  return (
    <ButtonGroup size="small" disableElevation variant="outlined">
      <Button
        onClick={() => {
          setEditing(true);
        }}
        endIcon={<EditIcon sx={{ m: 0.25, ml: -1 }} />}
      />
      <Button
        onClick={() => {
          void cloneBlock();
        }}
        endIcon={<CopyIcon sx={{ m: 0.25, ml: -1 }} />}
      />
      <Button
        onClick={() => {
          void removeBlock();
        }}
        endIcon={<DeleteIcon sx={{ m: 0.25, ml: -1 }} />}
      />
    </ButtonGroup>
  );
};
