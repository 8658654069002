import {
  FirestoreTimestamp,
  KaeplaFirestoreProjectSettingsPath,
  KaeplaProjectSettings,
} from '@kaepla/types';
import { GridState } from 'ag-grid-community';
import { getApp } from 'firebase/app';
import { doc, DocumentData, getFirestore, setDoc, Timestamp } from 'firebase/firestore';

import { mapUndefinedToNullValue } from '../mapUndefinedToNullValue';

interface Options {
  settingId: string;
  projectId: string;
  updatedBy: string;
  setting: GridState;
}

export const saveProjectSettingsMutation = async ({
  settingId,
  projectId,
  updatedBy,
  setting,
}: Options) => {
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath: KaeplaFirestoreProjectSettingsPath = `/projects/${projectId}/settings/${settingId}`;
  const firestoreDocumentReference = doc(firestoreDatabase, fireStorePath);
  const projectSetting: KaeplaProjectSettings = {
    id: settingId,
    setting,
    updatedAt: Timestamp.now() as FirestoreTimestamp,
    updatedBy,
  };
  await setDoc(
    firestoreDocumentReference,
    mapUndefinedToNullValue(projectSetting) as DocumentData,
    {
      merge: true,
    },
  );
};
