import { KaeplaOpsUpdateStatus } from '@kaepla/types';
import {
  query as firestoreQuery,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore';
import { isEmpty } from 'rambda';

import { Pagination } from '../base';

import { Data, getImportCollectionReference } from './import.base';

export interface GetImportListQueryFilterBy {
  createdBy?: string;
  projectId?: string[];
  customerId?: string[];
  archived?: boolean;
  updateStatus?: KaeplaOpsUpdateStatus[];
}

const DEFAULT_LIMIT = 100;

export function getImportListQuery({
  updateStatus = [],
  customerId = [],
  projectId = [],
  ...parameters
}: GetImportListQueryFilterBy & Pagination<Data>) {
  let q = firestoreQuery(
    getImportCollectionReference,
    orderBy('createdAt', 'desc'),
    limit(parameters.limit ?? DEFAULT_LIMIT),
    where('archived', '==', false),
  );

  if (parameters.startAfter) {
    q = query(q, startAfter(parameters.startAfter));
  }

  if (parameters.archived) {
    q = query(q, where('archived', '==', parameters.archived));
  }

  if (!isEmpty(updateStatus)) {
    q = query(q, where('updateStatus', 'in', updateStatus));
  }

  if (!isEmpty(customerId)) {
    q = query(q, where('customerId', 'in', customerId));
  }

  if (!isEmpty(projectId)) {
    q = query(q, where('projectId', 'in', projectId));
  }

  if (parameters.createdBy) {
    q = query(
      q,
      // https://stackoverflow.com/questions/46568142/google-firestore-query-on-substring-of-a-property-value-text-search
      where('createdBy', '>=', parameters.createdBy),
      where('createdBy', '<=', parameters.createdBy + '\uF8FF'),
    );
  }
  return q;
}
