import { Box, Paper, Stack } from '@mui/material';
import React from 'react';

import { PrimaryButton } from '../../../../../primitive/Button/PrimaryButton';
import { SecondaryButton } from '../../../../../primitive/Button/SecondaryButton';
import { EventLogFilterColumn, EventLogFilterContext } from '../../contexts/EventLogFilter.context';

import { EventLogAddFilter } from './features/EventLogAddFilter/EventLogAddFilter';
import { EventLogFilterItem } from './features/EventLogFilterItem/EventLogFilterItem';

interface Properties {
  isLoading: boolean;
  onRefresh: () => void;
}

export const EventLogFilter: React.FC<Properties> = ({ isLoading, onRefresh }) => {
  const { eventLogFilters, disabledFilterColumns, clearAllFilters } =
    React.useContext(EventLogFilterContext);

  const [activeFilters, setActiveFilters] = React.useState<EventLogFilterColumn[]>([]);

  const onClick = React.useCallback(
    () => clearAllFilters({ includingDisabled: false }),
    [clearAllFilters],
  );

  React.useEffect(() => {
    const selectedFilters = Object.keys(eventLogFilters) as EventLogFilterColumn[];
    setActiveFilters(selectedFilters);
  }, [eventLogFilters]);

  const filterColumns = Object.keys(eventLogFilters) as EventLogFilterColumn[];
  const hasFilters = filterColumns.some((column) => !disabledFilterColumns.includes(column));

  return (
    <Paper sx={{ padding: 2, position: 'relative', marginBottom: 1 }}>
      <Box display="flex" flexWrap="wrap" gap={1}>
        {activeFilters.map((column) =>
          disabledFilterColumns.includes(column) ? null : (
            <EventLogFilterItem column={column} key={column} />
          ),
        )}

        <Box>
          <EventLogAddFilter activeFilters={activeFilters} setActiveFilters={setActiveFilters} />
        </Box>

        <Box marginLeft="auto" alignSelf="flex-end">
          <Stack spacing={2} direction={'row'} alignItems={'center'}>
            <PrimaryButton onClick={onClick} disabled={!hasFilters || isLoading}>
              Clear Filters
            </PrimaryButton>
            <SecondaryButton loading={isLoading} onClick={onRefresh}>
              Refresh
            </SecondaryButton>
          </Stack>
        </Box>
      </Box>
    </Paper>
  );
};
