import { KaeplaGridSetStored, KaeplaGridSetting } from '@kaepla/types';
import ChartIcon from '@mui/icons-material/BarChartOutlined';
import SucceededIcon from '@mui/icons-material/CheckCircleOutline';
import DownloadIcon from '@mui/icons-material/DownloadOutlined';
import FailedIcon from '@mui/icons-material/ErrorOutline';
import FilterIcon from '@mui/icons-material/FilterAlt';
import FilterOffIcon from '@mui/icons-material/FilterAltOffOutlined';
import TourIcon from '@mui/icons-material/HelpCenterOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TableIcon from '@mui/icons-material/TableViewOutlined';
import HideIcon from '@mui/icons-material/VisibilityOffOutlined';
import ShowIcon from '@mui/icons-material/VisibilityOutlined';
import { Box, CircularProgress, Divider, IconButton, Stack, Typography } from '@mui/material';
import { GridApi } from 'ag-grid-enterprise';
import { MouseEvent, useState } from 'react';
import Joyride from 'react-joyride';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { useAuth } from '../../../../../../AuthReactProvider';
import { getProjectSettingQuery } from '../../../../../../services/firestore/projectSettings/getProjectSetting.query';
import { applicationState } from '../../../../../../services/recoil/nonpersistent/applicationState';
import { dataGridState } from '../../../../../../services/recoil/nonpersistent/dataGridState';
import { projectState } from '../../../../../../services/recoil/nonpersistent/projectState';
import { filterSettingsState } from '../../../../../../services/recoil/persistent/filterSettingState';
import { kaeplaAssignmentState } from '../../../../../../services/recoil/persistent/kaeplaAssignmentState';
import { KaeplaDataView } from '../../../../../../typings/KaeplaDataView';
import { defaultFeatureTourOptions } from '../../../../../features/defaultFeatureTourOptions';

import { GridMenuGridSets } from './GridMenuGridSets';
import { GridMenuMoreMenu } from './GridMenuMoreMenu';
import { GridSearch } from './GridSearch';

const tourSteps = [
  {
    target: '#grid-menu-filter',
    content:
      'You can use filters just like you do on dashboards. A full Filter icon indicates that the filter is active, an outlined icon indicates that the filter is inactive.',
    disableBeacon: true,
  },
  {
    target: '#grid-menu-filter-visible',
    content: 'The visibility button lets you show and hide the filter element.',
    disableBeacon: true,
  },
  {
    target: '#grid-menu-export',
    content: 'You can export the current set of data as spreadsheet.',
    disableBeacon: true,
  },
  {
    target: '#grid-menu-grid-sets',
    content: 'You can manage settings for the data grid.',
    disableBeacon: true,
  },
  {
    target: '#grid-menu-minimize',
    content: 'Minimize the grid to get back to the dashboard.',
    disableBeacon: true,
  },
];

interface GOptions {
  searchTerm: string | undefined;
  setSearchTerm: (value: string) => void;
  onSearchTermChange: () => void;
  handleExportDialogOpen: () => void;
  downloading: boolean | undefined;
  downloadSuccess: boolean | undefined;
  downloadError: string | undefined;
  setDataView: (value: KaeplaDataView) => void;
  showSavedGrids: boolean;
  setShowSavedGrids: React.Dispatch<React.SetStateAction<boolean>>;
  currentGridId: string | undefined;
  loadGridState: (gridSettings: KaeplaGridSetting) => void;
  selectedGridSet: KaeplaGridSetStored | null;
  setSelectedGridSet: React.Dispatch<React.SetStateAction<KaeplaGridSetStored | null>>;
  setTabNumber: React.Dispatch<React.SetStateAction<number>>;
  addPivotChart: () => void;
  pivotActive: boolean;
  api: GridApi | undefined;
}

export const GridMenuGridView = ({
  searchTerm,
  setSearchTerm,
  onSearchTermChange,
  handleExportDialogOpen,
  downloading,
  downloadSuccess,
  downloadError,
  setDataView,
  showSavedGrids,
  setShowSavedGrids,
  currentGridId,
  loadGridState,
  selectedGridSet,
  setSelectedGridSet,
  setTabNumber,
  addPivotChart,
  pivotActive,
  api,
}: GOptions) => {
  const { kaeplaUser } = useAuth();
  const [application, setApplication] = useRecoilState(applicationState);
  const [filterSettings, setFilterSettings] = useRecoilState(filterSettingsState);
  const project = useRecoilValue(projectState);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const [tour, setTour] = useState(false);
  const setGridState = useSetRecoilState(dataGridState);

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [successMessage, setSuccessMessage] = useState<string>();

  const handleMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const loadGridView = async () => {
    const setting = await getProjectSettingQuery({
      projectId: project.id,
      settingId: `default${KaeplaDataView.Table}Config`,
    });
    if (setting) {
      setGridState(setting.setting);
    }
    setDataView(KaeplaDataView.Table);
  };

  const canManageProject =
    kaeplaAssignment?.devTeamMember ?? (project.ownedBy ?? project.createdBy) === kaeplaUser?.uid;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={2}
      padding={1}
    >
      <Joyride
        run={tour}
        steps={tourSteps}
        styles={{ options: defaultFeatureTourOptions }}
        continuous
        showProgress
        showSkipButton
        spotlightClicks
      />
      <GridSearch
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        onSearchTermChange={onSearchTermChange}
      />
      <IconButton
        size="small"
        color="primary"
        sx={{ p: '10px' }}
        id="grid-menu-filter"
        onClick={() => {
          setFilterSettings({ ...filterSettings, isActive: !filterSettings.isActive });
        }}
      >
        {filterSettings.isActive ? <FilterIcon /> : <FilterOffIcon />}
      </IconButton>
      <IconButton
        size="small"
        color="primary"
        sx={{ p: '10px' }}
        id="grid-menu-filter-visible"
        onClick={() => {
          const newApplication = { ...application };
          newApplication.showFilter = !newApplication.showFilter;
          setApplication(newApplication);
        }}
      >
        {application.showFilter ? <HideIcon /> : <ShowIcon />}
      </IconButton>
      <Divider flexItem orientation="vertical" sx={{ height: '2em', alignSelf: 'center' }} />
      <IconButton
        size="small"
        color="primary"
        sx={{ p: '10px' }}
        id="grid-menu-export"
        disabled={downloading}
        onClick={() => {
          handleExportDialogOpen();
        }}
      >
        <DownloadIcon />
      </IconButton>
      {downloading && (
        <Box sx={{ pt: 1.5 }}>
          <CircularProgress size={15} />
        </Box>
      )}
      {downloading && <Box sx={{ pt: 1.5 }}>Your data is being prepared</Box>}
      {downloadSuccess === false && <FailedIcon color="error" />}
      {downloadSuccess === true && <SucceededIcon color="success" />}
      {downloadError && <Typography color="error">{downloadError}</Typography>}
      <Divider flexItem orientation="vertical" sx={{ height: '2em', alignSelf: 'center' }} />
      <Box id="grid-menu-grid-sets">
        <GridMenuGridSets
          showSavedGrids={showSavedGrids}
          setShowSavedGrids={setShowSavedGrids}
          currentGridId={currentGridId}
          loadGridState={loadGridState}
          selectedGridSet={selectedGridSet}
          setSelectedGridSet={setSelectedGridSet}
          setTabNumber={setTabNumber}
        />
      </Box>

      {pivotActive && (
        <IconButton
          size="small"
          data-testid="user-dialog-table-view"
          id="grid-menu-minimize"
          onClick={() => {
            addPivotChart();
          }}
        >
          <ChartIcon />
        </IconButton>
      )}
      <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
      {successMessage && (
        <Typography sx={{ p: 1 }} color="success" noWrap>
          {successMessage}
        </Typography>
      )}
      <IconButton
        size="small"
        data-testid="user-dialog-table-view"
        id="grid-menu-minimize"
        onClick={() => {
          void loadGridView();
        }}
      >
        <TableIcon />
      </IconButton>
      <IconButton
        size="small"
        data-testid="user-dialog-table-view"
        color="info"
        onClick={() => {
          setTour(false);
          setTimeout(() => {
            setTour(true);
          }, 100);
        }}
      >
        <TourIcon />
      </IconButton>
      {canManageProject && (
        <>
          <IconButton
            edge="end"
            size="small"
            data-testid="project-menu-toggle"
            onClick={handleMenuClick}
          >
            <MoreVertIcon data-testid="project-menu-icon" />
          </IconButton>
          <GridMenuMoreMenu
            anchorElement={anchorElement}
            setAnchorElement={setAnchorElement}
            setSuccessMessage={setSuccessMessage}
            api={api}
          />
        </>
      )}
    </Stack>
  );
};
