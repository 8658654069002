import { Box } from '@mui/material';
import { ReactElement, useEffect, useRef } from 'react';
import { AiQuestionLog } from '@kaepla/types';

import { CONVERSATIONAL_UI_HEIGHT } from '../../../AI/lib/constants';

interface Options {
  conversation: ReactElement;
  aiQuestionLogs: AiQuestionLog[];
  processing: boolean;
}

export const AIScrollWrapper = ({ conversation, aiQuestionLogs, processing }: Options) => {
  useEffect(() => {
    if (aiQuestionLogs.length > 0 || processing) {
      reference.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [aiQuestionLogs.length, processing]);

  const reference = useRef<HTMLDivElement>(null);
  return (
    <Box height={CONVERSATIONAL_UI_HEIGHT} sx={{ overflowY: 'scroll', mr: -2, mb: -2, p: 1 }}>
      {conversation}
      <div ref={reference} />
    </Box>
  );
};
