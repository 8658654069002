import { KaeplaDimensionDetails, KaeplaPerspective } from '@kaepla/types';
import { Alert, AlertTitle, Button, Divider, Grid2 as Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';

import { matrixFilteredState } from '../../../../services/recoil/nonpersistent/matrixFilteredState.js';

import { DimensionCard } from './components/DimensionCard.js';

const isEligibleForAggregationDimension = (detail: KaeplaDimensionDetails) => {
  if (!['FLOAT64', 'DECIMAL', 'NUMERIC', 'BIGNUMERIC', 'INT', 'INT64'].includes(detail.dataType))
    return false;
  const nulls = detail.dimensionDetails.find((row) => row.value === null);
  let nullValues = 0;
  if (nulls) {
    nullValues = nulls.numberOfRecords;
  }
  if (nullValues > 0) return false;

  return true;
};

const eligibleDimensions = (details?: KaeplaDimensionDetails[]) => {
  if (!details) return [];
  return details.filter((detail) => isEligibleForAggregationDimension(detail));
};

const nonEligibleDimensions = (details?: KaeplaDimensionDetails[]) => {
  if (!details) return [];
  return details
    .filter((detail) => ['FLOAT64', 'NUMERIC', 'BIGNUMERIC'].includes(detail.dataType))
    .filter((detail) => !isEligibleForAggregationDimension(detail));
};

interface Options {
  defaultPerspective: Partial<KaeplaPerspective>;
  setDefaultPerspective: (step: Partial<KaeplaPerspective>) => void;
}

export const StepValueDimension = ({ setDefaultPerspective, defaultPerspective }: Options) => {
  const matrixFiltered = useRecoilValue(matrixFilteredState);
  const [showNonEligible, setShowNonEligible] = useState(false);

  if (!matrixFiltered?.details || matrixFiltered?.details.length === 0) {
    return (
      <Grid container spacing={2}>
        <Grid size={12} container justifyContent="center">
          <Alert severity="error">
            <AlertTitle>ERROR</AlertTitle>
            Matrix dimensions could not be read
          </Alert>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid size={12}>
        <Typography variant="h5">
          These Dimensions are ideal for aggregation over all data
        </Typography>
      </Grid>
      {eligibleDimensions(matrixFiltered?.details)?.map((detail) => (
        <Grid key={detail.columnName} size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
          <DimensionCard
            detail={detail}
            ideal={true}
            totalCount={matrixFiltered.totalCount?.totalCount ?? 0}
            setDefaultPerspective={setDefaultPerspective}
            defaultPerspective={defaultPerspective}
            dimensionType="valueDimension"
            desiredDataTypes={['FLOAT64', 'NUMERIC', 'BIGNUMERIC']}
          />
        </Grid>
      ))}
      {!eligibleDimensions(matrixFiltered?.details) ||
        (eligibleDimensions(matrixFiltered?.details).length === 0 && (
          <Grid container spacing={2}>
            <Grid size={12} container justifyContent="center">
              <Alert severity="warning">
                <AlertTitle>WARNING</AlertTitle>
                No ideal dimensions for aggregation over all records found
              </Alert>
            </Grid>
          </Grid>
        ))}
      <Grid size={12}>
        <Divider />
      </Grid>
      <Grid size={12}>
        <Typography variant="h6">
          {nonEligibleDimensions(matrixFiltered?.details).length} dimensions are not suited for
          aggregation over all records{' '}
          <Button
            onClick={() => {
              setShowNonEligible(!showNonEligible);
            }}
          >
            {showNonEligible ? 'hide' : 'show'}
          </Button>
        </Typography>
      </Grid>
      {showNonEligible &&
        nonEligibleDimensions(matrixFiltered?.details).map((detail) => (
          <Grid key={detail.columnName} size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
            <DimensionCard
              detail={detail}
              ideal={false}
              totalCount={matrixFiltered.totalCount?.totalCount ?? 0}
              dimensionType="valueDimension"
              desiredDataTypes={['FLOAT64', 'NUMERIC', 'BIGNUMERIC']}
            />
          </Grid>
        ))}
    </Grid>
  );
};
