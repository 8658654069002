import { Alert, Grid2 as Grid } from '@mui/material';

import { SelectedDataSource } from '../SelectedDataSource';
import { DataSource } from '../types/DataSource';

interface DOptions {
  dataSource: DataSource;
  viewDetails: boolean;
  setViewDetails: React.Dispatch<React.SetStateAction<boolean>>;
  fullScreen: boolean;
  setFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
}
export const DataSourceFullScreenContent = ({
  dataSource,
  viewDetails,
  setViewDetails,
  fullScreen,
  setFullScreen,
}: DOptions) => {
  if (dataSource.key !== 'ihsVehicles' && dataSource.key !== 'erpOrders') {
    return <Alert severity="info">No data yet.</Alert>;
  }
  return (
    <Grid size={12}>
      <SelectedDataSource
        viewDetails={viewDetails}
        setViewDetails={setViewDetails}
        fullScreen={fullScreen}
        setFullScreen={setFullScreen}
      />
    </Grid>
  );
};
