import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { Box, Button, Card } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { KaeplaPerspectiveBlock, KaeplaPerspectiveBlockType } from '@kaepla/types';

import { removePerspectiveBlock } from '../../../../../../services/firestore/removePerspectiveBlock.js';
import { perspectiveState } from '../../../../../../services/recoil/nonpersistent/perspectiveState.js';
import { projectState } from '../../../../../../services/recoil/nonpersistent/projectState.js';

import { DonutChartBlock } from './DonutChart/DonutChartBlock.js';
import { StackedBarChartBlock } from './StackedBarChart/StackedBarChartBlock.js';


interface Options {
  block: KaeplaPerspectiveBlock;
  blockNumber: number;
  locked?: boolean;
}

export const PerspectiveBlock = ({ block, blockNumber, locked }: Options) => {
  const project = useRecoilValue(projectState);
  const [perspective, setPerspective] = useRecoilState(perspectiveState);

  const removeBlock = async () => {
    const newPerspective = await removePerspectiveBlock({ project, perspective, blockNumber });
    setPerspective(newPerspective);
  };

  switch (block.type) {
    case KaeplaPerspectiveBlockType.aggregationOverTime: {
      return <StackedBarChartBlock block={block} blockNumber={blockNumber} locked={locked} />;
    }
    case KaeplaPerspectiveBlockType.aggregationPercentages: {
      return <DonutChartBlock block={block} blockNumber={blockNumber} locked={locked} />;
    }
    case KaeplaPerspectiveBlockType.treemap: {
      return (
        <Card sx={{ p: 2 }}>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              void removeBlock();
            }}
            endIcon={<DeleteIcon sx={{ m: 0.25, ml: -1 }} />}
          />
          <Box sx={{ ml: 2 }} component="span">
            Chart type TreeMap not implemented yet
          </Box>
        </Card>
      );
    }
    // No default
  }
  return (
    <Card sx={{ p: 2 }}>
      <Button
        variant="contained"
        size="small"
        onClick={() => {
          void removeBlock();
        }}
        endIcon={<DeleteIcon sx={{ m: 0.25, ml: -1 }} />}
      />
      <Box sx={{ ml: 2 }} component="span">
        Chart type not implemented yet
      </Box>
    </Card>
  );
};
