import { Box, CircularProgress } from '@mui/material';

export function PageLoading() {
  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      height={'100vh'}
      width={'full'}
    >
      <CircularProgress />
    </Box>
  );
}
