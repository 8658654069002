import { getDocs } from 'firebase/firestore';

import { getAiThreadListQuery } from './aiThread.query';

export type GetAiThreadListParameters = Parameters<typeof getAiThreadListQuery>[number];

export const getAiThreadList = async (
  parameters: Parameters<typeof getAiThreadListQuery>[number],
) => {
  const q = getAiThreadListQuery(parameters);
  const snapshot = await getDocs(q);

  return snapshot.docs.map((d) => d.data());
};
