import { KaeplaConnector, ManageConnectorResponse } from '@kaepla/types';
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { requestLog } from '../../Frontend/helpers/logger.js';

interface ManageConnectorOptions {
  uid?: string;
  customerId?: string;
  connector?: Partial<KaeplaConnector>;
  credentials?: Record<string, unknown>;
  deleteConnector?: boolean;
}

type DataReturn = (data: ManageConnectorResponse) => void;

interface KOptions {
  callBack?: DataReturn;
  errorCallBack?: (error: string) => void;
  setLoading?: (loading: boolean) => void;
  params: ManageConnectorOptions;
  uid?: string;
}
export const manageConnector = async ({
  callBack,
  errorCallBack,
  setLoading,
  params,
  uid,
}: KOptions) => {
  const functions = getFunctions(getApp('kaepla'), 'europe-west3');
  const mgConnector = httpsCallable(functions, 'manageConnector', { timeout: 700_000 });

  requestLog.log(`manageConnector({data:${JSON.stringify({ ...params, uid })}})`);

  if (typeof setLoading === 'function') {
    setLoading(true);
  }

  await new Promise((resolve) => setTimeout(resolve, 2000));

  void mgConnector(params)
    .then((result) => {
      const data = result.data as ManageConnectorResponse;
      if (typeof setLoading === 'function') {
        setLoading(false);
      }
      if (data.error && errorCallBack) {
        errorCallBack(data.error);
        return;
      }
      if (callBack) {
        callBack(data);
      }
    })
    .catch((error: Error) => {
      if (errorCallBack) {
        errorCallBack(error.message);
      }
    });
};
