import { KaeplaGridSetStored, KaeplaGridSetting } from '@kaepla/types';
import { GridApi } from 'ag-grid-enterprise';

import { KaeplaDataView } from '../../../../../../typings/KaeplaDataView';

import { GridMenuGridView } from './GridMenuGridView';
import { GridMenuTableView } from './GridMenuTableView';

interface GOptions {
  searchTerm: string | undefined;
  setSearchTerm: (value: string) => void;
  onSearchTermChange: () => void;
  dataView: KaeplaDataView;
  handleExportDialogOpen: () => void;
  downloading: boolean | undefined;
  downloadSuccess: boolean | undefined;
  downloadError: string | undefined;
  setDataView: (value: KaeplaDataView) => void;
  showSavedGrids: boolean;
  setShowSavedGrids: React.Dispatch<React.SetStateAction<boolean>>;
  currentGridId: string | undefined;
  loadGridState: (gridSettings: KaeplaGridSetting) => void;
  selectedGridSet: KaeplaGridSetStored | null;
  setSelectedGridSet: React.Dispatch<React.SetStateAction<KaeplaGridSetStored | null>>;
  setTabNumber: React.Dispatch<React.SetStateAction<number>>;
  addPivotChart: () => void;
  pivotActive: boolean;
  api: GridApi | undefined;
}
export const GridMenu = ({
  searchTerm,
  setSearchTerm,
  onSearchTermChange,
  handleExportDialogOpen,
  dataView,
  downloading,
  downloadSuccess,
  downloadError,
  setDataView,
  showSavedGrids,
  setShowSavedGrids,
  currentGridId,
  loadGridState,
  selectedGridSet,
  setSelectedGridSet,
  setTabNumber,
  addPivotChart,
  pivotActive,
  api,
}: GOptions) => {
  if (dataView === KaeplaDataView.Grid) {
    return (
      <GridMenuGridView
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        onSearchTermChange={onSearchTermChange}
        handleExportDialogOpen={handleExportDialogOpen}
        downloading={downloading}
        downloadSuccess={downloadSuccess}
        downloadError={downloadError}
        setDataView={setDataView}
        showSavedGrids={showSavedGrids}
        setShowSavedGrids={setShowSavedGrids}
        currentGridId={currentGridId}
        loadGridState={loadGridState}
        selectedGridSet={selectedGridSet}
        setSelectedGridSet={setSelectedGridSet}
        setTabNumber={setTabNumber}
        addPivotChart={addPivotChart}
        pivotActive={pivotActive}
        api={api}
      />
    );
  } else if (dataView === KaeplaDataView.Table) {
    return (
      <GridMenuTableView
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        onSearchTermChange={onSearchTermChange}
        setDataView={setDataView}
        api={api}
      />
    );
  }
  return null;
};
