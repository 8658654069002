import { getApp } from 'firebase/app';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import { KaeplaCustomerAssignment } from '@kaepla/types';

interface Options {
  assignmentId: string;
  canCreateProject: boolean;
}

export const updateCanCreateProjectForCustomerAssignment = async ({
  assignmentId,
  canCreateProject,
}: Options) => {
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `customerAssignments/${assignmentId}`;
  const firestoreDocumentReference = doc(firestoreDatabase, fireStorePath);
  const customerAssignment: Partial<KaeplaCustomerAssignment> = {
    canCreateProject,
  };
  await updateDoc(firestoreDocumentReference, customerAssignment);
};
