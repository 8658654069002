import DoneIcon from '@mui/icons-material/Done';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Typography,
  Box,
  Tooltip,
  Toolbar,
  Paper,
  Divider,
  Chip,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  lighten,
} from '@mui/material';
import pluralize from 'pluralize';
import { SyntheticEvent, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { dataSourceImportState } from '../../../../services/recoil/nonpersistent/dataSourceImportState';
import { dataSourceOrdersState } from '../../../../services/recoil/nonpersistent/dataSourceOrdersState';
import { bucketState } from '../../../../services/recoil/nonpersistent/ihsBucketState';
import { ExpandMore } from '../../../features/ExpandMore';
import { ErpOrder } from '../types/ErpOrder';

import { ordersSchema } from './data/ordersSchema';
import { ErpOrdersMatchDetail } from './ErpOrdersMatchDetail';

interface Options {
  erpOrder: ErpOrder;
}

export const ErpOrderView = ({ erpOrder }: Options) => {
  const theme = useTheme();
  const bucket = useRecoilValue(bucketState);
  const dataSource = useRecoilValue(dataSourceImportState);
  const setOrders = useSetRecoilState(dataSourceOrdersState);
  const [highlight, setHighlight] = useState(false);

  const handleExpandClick = (event: SyntheticEvent) => {
    event.stopPropagation();
    setOrders((previous) =>
      previous.map((vehicle) => {
        if (vehicle.id !== erpOrder.id) return vehicle;
        return { ...vehicle, isExpanded: !vehicle.isExpanded };
      }),
    );
  };

  if (!bucket) return null;

  return (
    <Box key={erpOrder.id}>
      <Paper
        elevation={0}
        square
        sx={{
          backgroundColor: highlight ? lighten(theme.palette.success.light, 0.8) : 'white',
        }}
      >
        <Toolbar
          sx={{
            minHeight: 16,
            alignItems: 'flex-start',
            cursor: 'pointer',
          }}
          disableGutters
          variant="dense"
          onClick={handleExpandClick}
        >
          <Chip
            sx={{ borderRadius: 1, m: 1 }}
            color={bucket.color}
            label={bucket.label}
            size="small"
            variant="outlined"
          />
          {erpOrder.isReviewed && (
            <Chip
              sx={{ borderRadius: 1, m: 1 }}
              color="success"
              label="Reviewed"
              size="small"
              variant="outlined"
              deleteIcon={<DoneIcon />}
            />
          )}{' '}
          <Tooltip title={dataSource.id}>
            <Box sx={{ m: 1 }}>
              <Typography variant="h6" color="textPrimary">
                {ordersSchema
                  .filter((field) => field.isVehicleLabelField)
                  .map((field) => (
                    <>{erpOrder[field.fieldName]} </>
                  ))}
              </Typography>
            </Box>
          </Tooltip>
          {!bucket.hideMatchIndicator && (
            <Typography color="inherit" variant="body1" sx={{ m: 1 }}>
              {erpOrder.matches.length} {pluralize('Match', erpOrder.matches.length)}
            </Typography>
          )}
          <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
          <ExpandMore
            expand={!!erpOrder.isExpanded}
            onClick={handleExpandClick}
            aria-expanded={erpOrder.isExpanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Toolbar>
        <TableContainer
          component={Box}
          sx={{ pb: 1, display: erpOrder.isExpanded ? 'block' : 'none' }}
        >
          <Table sx={{ minWidth: '100%' }} size="small">
            <TableHead>
              <TableRow>
                {ordersSchema
                  .filter((field) => {
                    if (!erpOrder.isFieldsExpanded && field.isCollapsible) return false;
                    return true;
                  })
                  .map((field) => (
                    <TableCell
                      key={field.fieldName}
                      sx={{
                        fontWeight: field.isKeyField ? 'bold' : 100,
                        width: field.width ?? 'auto',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {field.label}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <ErpOrdersMatchDetail erpOrder={erpOrder} setHighlight={setHighlight} />
          </Table>
        </TableContainer>
      </Paper>
      <Divider />
    </Box>
  );
};
