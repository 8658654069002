import { KaeplaDimensionDetails } from '@kaepla/types';
import { Box } from '@mui/material';
import { ChartOptions, TooltipItem } from 'chart.js';
import numbro from 'numbro';
import { clone } from 'rambda';
import { Doughnut } from 'react-chartjs-2';

import { cleanColumnName } from '../../../../Frontend/helpers/cleanColumnName.js';
import { hasDimensionPie } from '../../Perspectives/helpers/hasDimensionPie.js';

const formatLabel = (context: TooltipItem<'doughnut'>) => {
  const label = context.dataset.label ?? 'n/a';
  const value = context.parsed;
  const valueHumanReadable =
    '€' +
    numbro(value).format({
      average: true,
      mantissa: 1,
    });
  return `${cleanColumnName(label)}: ${valueHumanReadable}`;
};

const donutChartConfig: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index',
  },
  animation: {
    duration: 0,
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      enabled: true,
      callbacks: {
        label: formatLabel,
      },
    },
    datalabels: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
      border: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
      border: {
        display: false,
      },
    },
  },
};

interface Options {
  detail: KaeplaDimensionDetails;
  chartColors: string[];
  totalCount: number | undefined;
}

export const DimensionPie = ({ detail, chartColors, totalCount }: Options) => {
  if (!hasDimensionPie(detail, totalCount)) return null;

  const data = clone(detail.dimensionDetails).slice(
    0,
    detail.dimensionDetails.length > 25 ? 10 : 25,
  );

  const chartJsData = {
    labels: data.map((d) => d.value),
    datasets: [
      {
        data: data.map((d) => Math.abs(d.numberOfRecords)),
        backgroundColor: chartColors,
        hoverBackgroundColor: chartColors,
      },
    ],
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ minHeight: 220, m: 0 }}>
        <Doughnut options={donutChartConfig as Record<string, unknown>} data={chartJsData} />
      </Box>
    </Box>
  );
};
