import { KaeplaConnector } from '@kaepla/types';

import { SalesForceSetup } from '../connectorSetups/SalesForceSetup';
import { KaeplaConnectorType } from '../types';

interface Options {
  connectorType: KaeplaConnectorType;
  setConnectorType: React.Dispatch<React.SetStateAction<KaeplaConnectorType | undefined>>;
  connectorForEdit: KaeplaConnector | undefined;
  setConnectorForEdit: React.Dispatch<React.SetStateAction<KaeplaConnector | undefined>>;
}

export const SetupWizard = ({
  connectorType,
  setConnectorType,
  connectorForEdit,
  setConnectorForEdit,
}: Options) => {
  if (!connectorType.implemented) {
    return (
      <div>
        <h1>Setup Wizard {connectorType.name}</h1>
        <p>Sorry, this connector is not implemented yet.</p>
      </div>
    );
  }

  switch (connectorType.id) {
    case 'salesForce': {
      return (
        <SalesForceSetup
          connectorType={connectorType}
          setConnectorType={setConnectorType}
          connectorForEdit={connectorForEdit}
          setConnectorForEdit={setConnectorForEdit}
        />
      );
    }
    default: {
      return (
        <div>
          <h1>Setup Wizard {connectorType.name}</h1>
          <p>Sorry, this connector is not implemented yet.</p>
        </div>
      );
    }
  }
};
