import React from 'react';

import { GenericButton, GenericButtonProperties } from './GenericButton';

const SecondaryButtonComponent: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  GenericButtonProperties
> = ({ ...properties }, reference) => (
  <GenericButton color="primary" {...properties} ref={reference} type="submit" variant="outlined" />
);

export const SecondaryButton = React.forwardRef(SecondaryButtonComponent);
