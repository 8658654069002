import { Thread } from '@kaepla/types';
import { collection, doc, FirestoreDataConverter, QueryDocumentSnapshot } from 'firebase/firestore';

import { FIREBASE } from '../base';

export type Data = Thread;

const converter = {
  toFirestore: (data: Data) => data,
  fromFirestore: (snap: QueryDocumentSnapshot<Data>) => snap.data(),
} satisfies FirestoreDataConverter<Data>;

export const getAiThreadPath = 'aiThreads';

export const getAiThreadCollectionReference = collection(FIREBASE, getAiThreadPath).withConverter(
  converter,
);

export const getAiThreadReference = (id: string) => {
  return doc(getAiThreadCollectionReference, id);
};
