import { KaeplaProject } from '@kaepla/types';
import Timeline from '@mui/lab/Timeline';
import { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import { Box, LinearProgress, Paper, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { EventLogFilterContext } from '../../contexts/EventLogFilter.context';
import { useEventListQuery } from '../../hooks/useEventListQuery';
import { useEventLogFilters } from '../../hooks/useEventLogFilters';
import { EventLogFilter } from '../EventLogFilter/EventLogFilter';
import { EventLogGroupList } from '../EventLogGroupList/EventLogGroupList';

interface Properties {
  project: KaeplaProject;
  currentScopePath: string[] | undefined;
}

export const EventLogTimeline: React.FC<Properties> = ({ project, currentScopePath }) => {
  const {
    clearAllFilters,
    clearFilter,
    disabledFilterColumns,
    eventLogAvailableFilterColumns,
    eventLogFilters,
    setFilter,
    hasNonDisabledFilters,
  } = useEventLogFilters({
    initialFilters: {
      projectId: project.id,
      customerId: project.customerId,
      resellerId: project.resellerId,
      scopePathStringified: JSON.stringify(currentScopePath),
    },
    disableInitialFilters: true,
  });

  const eventLogFilterContextValue = useMemo(
    () => ({
      eventLogFilters,
      eventLogAvailableFilterColumns,
      disabledFilterColumns,
      hasNonDisabledFilters,
      setFilter,
      clearFilter,
      clearAllFilters,
    }),
    [
      clearAllFilters,
      clearFilter,
      disabledFilterColumns,
      eventLogAvailableFilterColumns,
      eventLogFilters,
      hasNonDisabledFilters,
      setFilter,
    ],
  );

  const eventListQuery = useEventListQuery({
    filterBy: eventLogFilters,
  });

  const [eventList, setEventList] = useState(
    eventListQuery.data?.pages.flatMap((page) => page.rows) ?? [],
  );
  useEffect(() => {
    if (eventListQuery.isFetched) {
      setEventList(eventListQuery.data!.pages.flatMap((page) => page.rows));
    }
  }, [eventListQuery.data, eventListQuery.isFetched]);

  const count = eventList.length ?? 0;
  const totalCount = eventListQuery.data?.pages[0]?.max ?? 0;

  const hasMoreEventList = count < totalCount;

  const onRefresh = useCallback(() => {
    void eventListQuery.refetch();
  }, [eventListQuery]);

  return (
    <EventLogFilterContext.Provider value={eventLogFilterContextValue}>
      <EventLogFilter
        isLoading={eventListQuery.isFetching ?? eventListQuery.isLoading}
        onRefresh={onRefresh}
      />
      <Box
        component={Paper}
        sx={{
          p: 2,
          height: window.innerHeight - 500,
          overflow: 'auto',
        }}
      >
        {eventListQuery.isLoading && <LinearProgress />}
        <InfiniteScroll
          dataLength={eventList.length ?? 0} // This is important field to render the next data
          next={eventListQuery.fetchNextPage}
          hasMore={hasMoreEventList}
          loader={
            <Box>
              <Typography variant="body2">
                {eventListQuery.isFetching || eventListQuery.isLoading ? (
                  'Loading... '
                ) : (
                  <Box
                    sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                    component="span"
                    onClick={() => {
                      void eventListQuery.fetchNextPage();
                    }}
                  >
                    click to load
                  </Box>
                )}
              </Typography>
            </Box>
          }
          scrollableTarget="events-scrollable"
        >
          <Timeline
            sx={{
              p: 0,
              [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.01,
              },
            }}
          >
            {eventList.length > 0 && <EventLogGroupList eventList={eventList} />}
          </Timeline>
        </InfiniteScroll>
      </Box>
    </EventLogFilterContext.Provider>
  );
};
