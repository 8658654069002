import DragHandleIcon from '@mui/icons-material/DragHandle';
import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Resizable } from 're-resizable';
import { RefObject, useLayoutEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { projectState } from '../../../../services/recoil/nonpersistent/projectState.js';

interface Options {
  transparent: boolean;
  resizeParentReference: RefObject<HTMLInputElement>;
  minHeight: number;
  children: React.ReactNode;
}

export const SimulationResizeContainer = ({
  transparent,
  resizeParentReference,
  minHeight,
  children,
}: Options) => {
  const theme = useTheme();
  const project = useRecoilValue(projectState);

  const resizeReference = useRef<HTMLInputElement>(null);

  const defaultWidth = 890;
  const defaultHeight = minHeight;
  const [width, setWidth] = useState(defaultWidth);
  const [height, setHeight] = useState(defaultHeight);

  useLayoutEffect(() => {
    const updateEqualizer = () => {
      setWidth(resizeParentReference.current?.offsetWidth ?? defaultWidth);
      setHeight(defaultHeight);
    };
    window.addEventListener('resize', updateEqualizer);
    setTimeout(() => {
      // the timeline needs a moment to adjust
      updateEqualizer();
    }, 100);
    updateEqualizer();
    return () => window.removeEventListener('resize', updateEqualizer);
  }, [defaultHeight, resizeParentReference]);

  if (!project) return null;

  return (
    <Resizable
      size={{ width, height }}
      minHeight={defaultHeight}
      minWidth={defaultWidth}
      enable={{
        top: false,
        right: false,
        bottom: true,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      onResize={(_event, _direction, _reference, _d) => {
        setHeight(resizeReference.current?.offsetHeight ?? defaultHeight);
      }}
    >
      <Box
        borderBottom={1}
        // borderRadius={1}
        borderColor={theme.palette.grey[300]}
        px={4}
        sx={{
          height: '100%',
          width: '100%',
          opacity: transparent ? 0.3 : 1,
        }}
        ref={resizeReference}
      >
        <Stack
          sx={{
            justifyContent: 'center',
            alignItems: 'stretch',
            height: '100%',
          }}
        >
          {children}
          <Stack sx={{ mb: -3 }} direction="row" justifyContent="center">
            <DragHandleIcon sx={{ color: 'lightgray' }} fontSize="small" />
          </Stack>
        </Stack>
      </Box>
    </Resizable>
  );
};
