import { KaeplaUser } from '@kaepla/types';
import { Autocomplete, Box, CircularProgress, Stack, TextField } from '@mui/material';
import React from 'react';

import { UserAvatar } from '../../Frontend/features/UserAvatar';

interface BaseProperties {
  options: KaeplaUser[];
  label: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  isOpen?: boolean;
  onOpen?: () => void;
}

interface SingleProperties extends BaseProperties {
  value?: KaeplaUser | null;
  multiple: false;
  onChange: (_: React.SyntheticEvent, user: KaeplaUser | null) => void;
}

interface MultipleProperties extends BaseProperties {
  value?: KaeplaUser[];
  multiple: true;
  onChange: (_: React.SyntheticEvent, user: KaeplaUser[]) => void;
}

type Properties = SingleProperties | MultipleProperties;

export const UserSelectBox: React.FC<Properties> = ({
  options,
  value,
  label,
  isLoading,
  isDisabled = false,
  isOpen,
  multiple,
  onOpen,
  onChange,
}) => {
  return (
    <Autocomplete
      id={`user-select-box`}
      multiple={multiple}
      open={isOpen}
      size="small"
      options={options}
      value={value}
      sx={{ width: 300 }}
      onChange={
        onChange as (_: React.SyntheticEvent, user: KaeplaUser[] | KaeplaUser | null) => void
      }
      onOpen={onOpen}
      getOptionKey={(option) => option.uid}
      getOptionLabel={(option) => option.displayName}
      loading={isLoading}
      renderInput={(parameters) => (
        <TextField
          {...parameters}
          label={label}
          slotProps={{
            input: {
              ...parameters.InputProps,
              endAdornment: (
                <>
                  {isLoading && <CircularProgress color="inherit" size={20} />}
                  {parameters.InputProps?.endAdornment}
                </>
              ),
            },
          }}
        />
      )}
      renderOption={(properties, user) => {
        const { key, ...optionProperties } = properties;
        return (
          <Box
            key={key}
            component="li"
            // eslint-disable-next-line @typescript-eslint/naming-convention
            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
            {...optionProperties}
          >
            <Stack spacing={2} direction={'row'} alignItems={'center'}>
              <UserAvatar user={user} size={25} />
              <p>{user.displayName}</p>
            </Stack>
          </Box>
        );
      }}
      disabled={isLoading ?? isDisabled}
    />
  );
};
