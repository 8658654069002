import { KaeplaImport, KaeplaOpsUpdateStatus } from '@kaepla/types';

export const failedOrCancelledOrFinished = (dataImportUpdate: KaeplaImport) => {
  return (
    dataImportUpdate.updateStatus === KaeplaOpsUpdateStatus.failed ||
    dataImportUpdate.updateStatus === KaeplaOpsUpdateStatus.canceled ||
    dataImportUpdate.updateStatus === KaeplaOpsUpdateStatus.cancelRequested ||
    dataImportUpdate.updateStatus === KaeplaOpsUpdateStatus.finished
  );
};
