import DoneIcon from '@mui/icons-material/Done';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Typography,
  Box,
  Tooltip,
  Toolbar,
  Paper,
  Divider,
  Chip,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  lighten,
} from '@mui/material';
import pluralize from 'pluralize';
import { SyntheticEvent, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { dataSourceImportState } from '../../../../services/recoil/nonpersistent/dataSourceImportState';
import { dataSourceVehiclesState } from '../../../../services/recoil/nonpersistent/dataSourceVehiclesState';
import { bucketState } from '../../../../services/recoil/nonpersistent/ihsBucketState';
import { ExpandMore } from '../../../features/ExpandMore';
import { IhsVehicleFull } from '../types/IhsVehicleFull';

import { vehicleSchema } from './data/vehicleSchema';
import { IhsMatchDetail } from './IhsMatchDetail';

interface Options {
  ihsVehicle: IhsVehicleFull;
}

export const IhsVehicleView = ({ ihsVehicle }: Options) => {
  const theme = useTheme();
  const dataSource = useRecoilValue(dataSourceImportState);
  const bucket = useRecoilValue(bucketState);
  const setVehicles = useSetRecoilState(dataSourceVehiclesState);
  const [highlight, setHighlight] = useState(false);

  const handleExpandClick = (event: SyntheticEvent) => {
    event.stopPropagation();
    setVehicles((previous) =>
      previous.map((vehicle) => {
        if (vehicle.id !== ihsVehicle.id) return vehicle;
        return { ...vehicle, isExpanded: !vehicle.isExpanded };
      }),
    );
  };

  if (!bucket) return null;

  return (
    <Box key={ihsVehicle.id}>
      <Paper
        elevation={0}
        square
        sx={{
          backgroundColor: highlight ? lighten(theme.palette.success.light, 0.8) : 'white',
        }}
      >
        <Toolbar
          sx={{
            minHeight: 16,
            alignItems: 'flex-start',
            cursor: 'pointer',
          }}
          disableGutters
          variant="dense"
          onClick={handleExpandClick}
        >
          <Chip
            sx={{ borderRadius: 1, m: 1 }}
            color={bucket.color}
            label={bucket.label}
            size="small"
            variant="outlined"
          />
          {ihsVehicle.isReviewed && (
            <Chip
              sx={{ borderRadius: 1, m: 1 }}
              color="success"
              label="Reviewed"
              size="small"
              variant="outlined"
              deleteIcon={<DoneIcon />}
            />
          )}{' '}
          <Tooltip title={dataSource.id}>
            <Box sx={{ m: 1 }}>
              <Typography variant="h6" color="textPrimary">
                {vehicleSchema
                  .filter((field) => field.isVehicleLabelField)
                  .map((field) => (
                    <>{ihsVehicle[field.fieldName]} </>
                  ))}
              </Typography>
            </Box>
          </Tooltip>
          {!bucket.hideMatchIndicator && (
            <Typography color="inherit" variant="body1" sx={{ m: 1 }}>
              {ihsVehicle.matches.length} {pluralize('Match', ihsVehicle.matches.length)}
            </Typography>
          )}
          <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
          <ExpandMore
            expand={!!ihsVehicle.isExpanded}
            onClick={handleExpandClick}
            aria-expanded={ihsVehicle.isExpanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Toolbar>
        <TableContainer
          component={Box}
          sx={{ pb: 1, display: ihsVehicle.isExpanded ? 'block' : 'none' }}
        >
          <Table sx={{ minWidth: '100%' }} size="small">
            <TableHead>
              <TableRow>
                {vehicleSchema
                  .filter((field) => {
                    if (!ihsVehicle.isFieldsExpanded && field.isCollapsible) return false;
                    return true;
                  })
                  .map((field) => (
                    <TableCell
                      key={field.fieldName}
                      sx={{
                        fontWeight: field.isKeyField ? 'bold' : 100,
                        width: field.width ?? 'auto',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {field.label}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <IhsMatchDetail ihsVehicle={ihsVehicle} setHighlight={setHighlight} />
          </Table>
        </TableContainer>
      </Paper>
      <Divider />
    </Box>
  );
};
