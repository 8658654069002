import { Avatar, Box, Link, Tooltip } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import { ReactElement } from 'react';

import { Image } from '../../primitive/Image/Image.js';
import { avatarAppearDuration } from '../Screens/defaults.js';

interface Options {
  icon?: string;
  title: string;
  small?: boolean;
  medium?: boolean;
  id: string;
}

export const ResellerAvatar = ({ icon, title, small, medium, id }: Options) => {
  const theme = useTheme();
  let content: ReactElement = <Box>{title.slice(0, 2)}</Box>;
  if (icon) {
    content = (
      <Image width="70%" height="70%" alt={title} src={icon} duration={avatarAppearDuration} />
    );
  }
  let size = 40;
  if (medium) {
    size = 30;
  }
  if (small) {
    size = 23;
  }

  return (
    <Tooltip title={id}>
      <Link href={`/Customers/${id}`}>
        <Avatar
          component="span"
          sx={{
            display: 'inline-flex',
            cursor: 'pointer',
            boxShadow: theme.shadows[1],
            bgcolor: '#fff',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: grey[400],
            width: size,
            height: size,
          }}
          variant="rounded"
        >
          {content}
        </Avatar>
      </Link>
    </Tooltip>
  );
};
