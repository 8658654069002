import { getApp } from 'firebase/app';
import {
  collection,
  deleteDoc,
  doc,
  DocumentData,
  getFirestore,
  setDoc,
  Timestamp,
} from 'firebase/firestore';
import {
  KaeplaProject,
  KaeplaUser,
  KaeplaProjectAssignment,
  FirestoreTimestamp,
} from '@kaepla/types';

import { getNamesFromEmail } from '../../Frontend/helpers/getNamesFromEmail.js';

import { createOrUpdateUser } from './createOrUpdateUser.js';
import { getProjectAssignmentsForUserAndScopePath } from './getProjectAssignmentsForUserAndScopePath.js';
import { getUID } from './getUID.js';
import { getUserByEmail } from './getUserByEmail.js';

interface Options {
  project: KaeplaProject;
  email: string;
  scopePath: string[];
  assignedBy: string;
  complete?: boolean;
}

export const createProjectAssignmentForEmail = async ({
  project,
  email,
  scopePath,
  assignedBy,
  complete,
}: Options) => {
  let user = await getUserByEmail({ email });
  if (!user) {
    const namesFromEmail = getNamesFromEmail(email);
    const userToCreate: Partial<KaeplaUser> = {
      uid: getUID(),
      email,
      displayName: namesFromEmail.displayName,
      acronym: namesFromEmail.acronym,
    };
    user = await createOrUpdateUser({ user: userToCreate });
  }
  if (!user || !project) return;
  const existingAssignments = await getProjectAssignmentsForUserAndScopePath({
    uid: user.uid,
    projectId: project.id,
    scopePathStringified: JSON.stringify(scopePath),
  });
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projectAssignments`;

  if (existingAssignments.length > 0) {
    for (const _assignment of existingAssignments) {
      const _firestoreCollectionReference = collection(firestoreDatabase, fireStorePath);
      const _firestoreDocumentReference = doc(_firestoreCollectionReference, _assignment.id);
      await deleteDoc(_firestoreDocumentReference);
    }
  }

  const firestoreCollectionReference = collection(firestoreDatabase, fireStorePath);
  const firestoreDocumentReference = doc(firestoreCollectionReference);
  const assignment: KaeplaProjectAssignment = {
    id: firestoreDocumentReference.id,
    projectId: project.id,
    inviteEmail: email,
    inviteAccessDomain: globalThis.location.hostname,
    customerId: project.customerId,
    resellerId: project.resellerId,
    uid: user.uid,
    scopePath,
    complete,
    scopePathStringified: JSON.stringify(scopePath),
    assignedAt: Timestamp.now() as FirestoreTimestamp,
    assignedBy,
    updatedAt: Timestamp.now() as FirestoreTimestamp,
  };

  await setDoc(firestoreDocumentReference, assignment as DocumentData);
};
