import { KaeplaSimulation, KaeplaSimulationRuleset } from '@kaepla/types';
import { Box, Grid2 as Grid } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

import { SimulationRulesetFilters } from '../../../features/Filters/SimulationRulesetFilters.js';

interface Options {
  simulation: KaeplaSimulation;
  ruleset: KaeplaSimulationRuleset;
  setPreview: Dispatch<SetStateAction<boolean>>;
}

export const SimulationRulesetFilter = ({ ruleset, simulation, setPreview }: Options) => {
  return (
    <Box m={2}>
      <Grid container spacing={1}>
        <Grid size={12}>
          <SimulationRulesetFilters
            ruleset={ruleset}
            simulation={simulation}
            filterHasChangedCallback={(hasChanged) => {
              if (hasChanged) {
                setPreview(true);
              }
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
