/* eslint-disable @typescript-eslint/naming-convention */

import {
  KaeplaSimulation,
  KaeplaSimulationParameter,
  KaeplaSimulationParameterGranularity,
  KaeplaSimulationParameterNumeric,
  KaeplaSimulationPercentage,
  KaeplaSimulationRulesetWithParameters,
  MatrixTimeSlice,
} from '@kaepla/types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Grid2 as Grid, Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { clone } from 'rambda';
import { Dispatch, SetStateAction, SyntheticEvent, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { matrixFilteredState } from '../../../../services/recoil/nonpersistent/matrixFilteredState.js';
import { perspectiveState } from '../../../../services/recoil/nonpersistent/perspectiveState.js';
import { timeSeriesState } from '../../../../services/recoil/nonpersistent/timeSeriesState.js';
import { timeSliceState } from '../../../../services/recoil/persistent/timeSliceState.js';
import { ExpandMore } from '../../../features/ExpandMore.js';
import { simulationDataColor } from '../../defaults.js';

import { KaeplaSlider } from './KaeplaSlider.js';
import { AbsoluteSimulationValueDialog } from './_features/AbsoluteSimulationValueDialog.js';
import { TimeSliceValueAndLabel } from './_features/TimeSliceValueAndLabel.js';
import { calculatePercentage } from './_helpers/calculatePercentage.js';
import { sliderGranularity } from './_helpers/sliderGranularity.js';

function valuetext(value: number) {
  return `${value}%`;
}

interface Options {
  yearOrMonth: string;
  parameter: KaeplaSimulationParameter;
  granularity: KaeplaSimulationParameterGranularity;
  simulation: KaeplaSimulation;
  ruleset: KaeplaSimulationRulesetWithParameters;
  allowNegativePercentage: boolean;
  simulationRulesets: KaeplaSimulationRulesetWithParameters[];
  setSimulationRulesets: Dispatch<
    SetStateAction<KaeplaSimulationRulesetWithParameters[] | undefined>
  >;
  setPreview: Dispatch<SetStateAction<boolean>>;
  own: boolean;
  yearExpanded: string | undefined;
  setYearExpanded: Dispatch<SetStateAction<string | undefined>>;
  timeSlice: MatrixTimeSlice;
}

export const NumericParameterSlider = ({
  yearOrMonth,
  parameter,
  ruleset,
  granularity,
  simulation,
  allowNegativePercentage,
  simulationRulesets,
  setSimulationRulesets,
  setPreview,
  own,
  yearExpanded,
  setYearExpanded,
  timeSlice,
}: Options) => {
  const matrixFiltered = useRecoilValue(matrixFilteredState);
  const setTimeSlice = useSetRecoilState(timeSliceState);
  const parameterDimension = matrixFiltered?.dimensions?.dimensions.find(
    (d) => d.columnName === parameter.dimension,
  );
  const timeSeries = useRecoilValue(timeSeriesState);
  const perspective = useRecoilValue(perspectiveState);
  const [percentage, setPercentage] = useState<number>(0);
  const [parameterValue, setParameterValue] = useState<number>(0);
  const [simulationValue, setSimulationValue] = useState<number>(0);
  const [absoluteSimulationValue, setAbsoluteSimulationValue] = useState<number>(0);
  const [isAbsolute, setIsAbsolute] = useState(false);
  const [isPrecalculated, setIsPrecalculated] = useState(false);
  const [open, setOpen] = useState(false);

  let yearOrMonthLabel = yearOrMonth.split('-')[0];
  if (timeSlice === MatrixTimeSlice.month) {
    const year = Number.parseInt(yearOrMonth.split('-')[0], 10);
    const month = Number.parseInt(yearOrMonth.split('-')[1], 10);
    yearOrMonthLabel = `${month}/${year}`;
  }

  const yearHasMonths = () => {
    if (timeSlice === MatrixTimeSlice.month) return false;
    const allParameters = simulationRulesets.flatMap((r) =>
      r.parameters.flatMap((p) => (p as KaeplaSimulationParameterNumeric).percentages ?? []),
    );
    const allMonthParameters = allParameters.filter((p) => p?.timeSlice === MatrixTimeSlice.month);
    if (!allMonthParameters) return false;
    const year = yearOrMonth.split('-')[0];
    const allMonthParametersForYear = allMonthParameters.some((p) => p.key.startsWith(year));
    return allMonthParametersForYear;
  };

  const hasSmallerPercentages = yearHasMonths();

  const handlePopoverOpen = () => {
    if (!own) return;
    setOpen(true);
  };

  const handlePopoverClose = () => {
    setOpen(false);
  };

  const saveAbsoluteSimulationValue = () => {
    if (!own) return;
    if (!parameterValue) return;
    const newPercentage = Math.ceil(
      calculatePercentage(parameterValue, absoluteSimulationValue) * 100,
    );
    saveParameterChange(newPercentage, true);
    setOpen(false);
  };

  useEffect(() => {
    if (!parameterDimension?.columnName) {
      // TODO: show error
      return;
    }

    if (simulation.isBeingSimulated) {
      setIsPrecalculated(false);
      return;
    }

    let timeSlotKey = yearOrMonth;
    if (timeSlice === MatrixTimeSlice.year) {
      timeSlotKey = `${yearOrMonth}-01-01`;
    }

    const _valuesForTimeSlot = timeSeries.timeseries.find(
      (series) => series.date === `${timeSlotKey}`,
    );

    const _parameterValue = _valuesForTimeSlot?.value ?? 0;
    setParameterValue(_parameterValue);
    if (!isPrecalculated) {
      setAbsoluteSimulationValue(_parameterValue);
    }

    let _percentage = 0;
    let _simulationValue = _parameterValue;
    const _parameter = [...ruleset.parameters].find(
      (p) => p.dimension === parameter.dimension,
    ) as KaeplaSimulationParameterNumeric;
    if (_parameter?.percentages) {
      const parameterOfYearOrMonth = clone(_parameter.percentages).find(
        (_p) => _p.key === `${yearOrMonth}`,
      );
      if (parameterOfYearOrMonth) {
        _percentage = parameterOfYearOrMonth.percentage || 0;
        if (parameterOfYearOrMonth.absoluteValue) {
          setAbsoluteSimulationValue(parameterOfYearOrMonth.absoluteValue);
          setIsAbsolute(true);
          _simulationValue = _parameterValue + _parameterValue * (_percentage / 100);
        } else {
          _simulationValue = _valuesForTimeSlot?.simulationValue ?? 0;
          setAbsoluteSimulationValue(_simulationValue || 0);
          setIsAbsolute(false);
        }
      }
    }

    setSimulationValue(_simulationValue);
    setPercentage(_percentage || 0);
  }, [
    simulation,
    parameter,
    yearOrMonth,
    timeSeries,
    ruleset.parameters,
    isPrecalculated,
    parameterDimension?.columnName,
    perspective.valueDimension,
    timeSlice,
  ]);

  const changeParameter = (
    _event: Event | SyntheticEvent<Element, Event>,
    newValue: number | number[],
  ) => {
    setPercentage(newValue as number);
  };

  const saveParameterChange = (newValue: number | number[], valueIsAbsolute: boolean) => {
    // const newSimulation: KaeplaSimulation = { ...simulation };
    const newRulesets = [...simulationRulesets].map((r) => {
      if (r.id === ruleset.id) {
        const newRuleset = { ...ruleset };
        const _parameter = [...newRuleset.parameters].find(
          (p) => p.dimension === parameter.dimension,
        );

        if (!_parameter) return r;
        const newParameter = { ..._parameter } as KaeplaSimulationParameterNumeric;

        let newPercentages: KaeplaSimulationPercentage[] = [];

        if (newParameter?.percentages) {
          newPercentages = [...newParameter.percentages].filter(
            (_percentage) => _percentage.key !== `${yearOrMonth}`,
          );
        }

        let dateFrom = `${yearOrMonth}-01-01`;
        let dateUntil = `${yearOrMonth}-12-31`;
        let key = `${yearOrMonth}`;
        if (timeSlice === MatrixTimeSlice.year) {
          dateFrom = `${yearOrMonth}-01-01`;
          dateUntil = `${yearOrMonth}-12-31`;
          key = `${yearOrMonth}`;
        } else if (timeSlice === MatrixTimeSlice.month) {
          dateFrom = `${yearOrMonth}`;
          const lastDayOfMonth = DateTime.fromISO(yearOrMonth)
            .endOf('month')
            .toFormat('yyyy-MM-dd');
          dateUntil = `${lastDayOfMonth}`;
          key = `${yearOrMonth}`;
        }

        const newPercentage: KaeplaSimulationPercentage = {
          key,
          timeSlice,
          dateFrom,
          dateUntil,
          percentage: newValue as number,
          originalValue: parameterValue,
          currency: parameterDimension?.currency,
          decimalPositions: parameterDimension?.decimalPositions,
        };

        if (
          valueIsAbsolute &&
          absoluteSimulationValue &&
          absoluteSimulationValue !== simulationValue
        ) {
          newPercentage.absoluteValue = absoluteSimulationValue;
        } else {
          delete newPercentage.absoluteValue;
        }

        newPercentages.push(newPercentage);
        newParameter.percentages = newPercentages;

        const newParameters = newRuleset.parameters.filter(
          (p) => p.dimension !== parameter.dimension,
        );
        newParameters.push(newParameter);
        newRuleset.parameters = newParameters.sort((a, b) =>
          a.dimension.localeCompare(b.dimension),
        );
        return newRuleset;
      }
      return r;
    });

    setSimulationRulesets(newRulesets);
    setPreview(true);
  };

  return (
    <Grid container spacing={0} sx={{ border: '0px solid green', minWidth: 140 }}>
      <Grid container size={12}>
        <Stack direction="column" alignItems="center" justifyContent="center" spacing={1}>
          <Box
            sx={{
              height: `100%`,
              border: '0px solid red',
            }}
          >
            <KaeplaSlider
              aria-label="Percentage"
              orientation="vertical"
              min={allowNegativePercentage ? -100 : 0}
              max={sliderGranularity(granularity).max}
              step={sliderGranularity(granularity).step}
              value={percentage}
              valueLabelFormat={valuetext}
              valueLabelDisplay="on"
              onChange={changeParameter}
              onChangeCommitted={(_event, value) => {
                saveParameterChange(value, false);
                const sliderValue = value as number;
                setIsAbsolute(false);

                // precalculate simulation value
                if (parameterValue) {
                  const newSimulationValue = parameterValue + (parameterValue / 100) * sliderValue;
                  setSimulationValue(newSimulationValue);
                  setAbsoluteSimulationValue(newSimulationValue || 0);
                  setIsPrecalculated(true);
                }
              }}
              disabled={!!simulation.isBeingSimulated || !own || hasSmallerPercentages}
            />
          </Box>
          <Box sx={{ border: '0px solid red', textAlign: 'center' }}>{yearOrMonthLabel}</Box>
          {hasSmallerPercentages && (
            <Box sx={{ minHeight: 40 }}>
              <Button
                onClick={() => {
                  const newRulesets = [...simulationRulesets].map((r) => {
                    if (r.id === ruleset.id) {
                      const newRuleset = { ...ruleset };
                      const _parameter = [...newRuleset.parameters].find(
                        (p) => p.dimension === parameter.dimension,
                      );

                      if (!_parameter) return r;
                      const newParameter = { ..._parameter } as KaeplaSimulationParameterNumeric;

                      let newPercentages: KaeplaSimulationPercentage[] = [];

                      if (newParameter?.percentages) {
                        newPercentages = [...newParameter.percentages].filter(
                          (_percentage) =>
                            _percentage.key.length === 4 ||
                            !_percentage.key.startsWith(yearOrMonth),
                        );
                      }

                      // console.log('yearOrMonth', yearOrMonth);
                      // console.log('newPercentages', newPercentages);

                      newParameter.percentages = newPercentages;

                      const newParameters = newRuleset.parameters.filter(
                        (p) => p.dimension !== parameter.dimension,
                      );
                      newParameters.push(newParameter);
                      newRuleset.parameters = newParameters.sort((a, b) =>
                        a.dimension.localeCompare(b.dimension),
                      );
                      return newRuleset;
                    }
                    return r;
                  });
                  // console.log('newRulesets', newRulesets);
                  // console.log(
                  //   'newRulesets flat',
                  //   newRulesets.flatMap((r) =>
                  //     r.parameters.flatMap(
                  //       (p) => (p as KaeplaSimulationParameterNumeric).percentages,
                  //     ),
                  //   ),
                  // );
                  setSimulationRulesets(newRulesets);
                  setPreview(true);
                }}
              >
                Clear months
              </Button>
            </Box>
          )}
          {!hasSmallerPercentages && (
            <Box sx={{ minHeight: 40 }}>
              <Box
                sx={{
                  textAlign: 'center',
                }}
              >
                <TimeSliceValueAndLabel
                  valueDimension={parameterDimension}
                  value={parameterValue}
                />
              </Box>
              <Box
                sx={{
                  border: `0px solid ${simulationDataColor}`,
                  borderBottomWidth: isAbsolute ? '1px' : '0px',
                  textAlign: 'center',
                  mb: 1,
                }}
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onClick={handlePopoverOpen}
              >
                <TimeSliceValueAndLabel
                  valueDimension={parameterDimension}
                  value={isAbsolute ? absoluteSimulationValue : simulationValue}
                  simulation
                  editable={own}
                  isPrecalculated={isPrecalculated}
                  isAbsolute={isAbsolute}
                />
              </Box>
            </Box>
          )}
          {timeSlice === MatrixTimeSlice.year && (
            <ExpandMore
              expand={yearExpanded !== undefined}
              onClick={() => {
                setTimeSlice(MatrixTimeSlice.month);
                setYearExpanded(yearOrMonth);
              }}
            >
              <ExpandMoreIcon />
            </ExpandMore>
          )}
          <Box sx={{ border: '0px solid red', textAlign: 'center' }} />
        </Stack>
      </Grid>
      {parameterDimension && own && (
        <AbsoluteSimulationValueDialog
          open={open}
          absoluteSimulationValue={absoluteSimulationValue}
          setAbsoluteSimulationValue={setAbsoluteSimulationValue}
          saveAbsoluteSimulationValue={saveAbsoluteSimulationValue}
          handlePopoverClose={handlePopoverClose}
          year={yearOrMonth}
          valueDimension={parameterDimension}
          parameterValue={parameterValue}
          simulationValue={simulationValue}
        />
      )}
    </Grid>
  );
};
