import { KaeplaEventFilter } from '@kaepla/events';
import React from 'react';

import { AvailableFilterColumn, EventLogFilterColumn } from '../contexts/EventLogFilter.context';

interface Options {
  initialFilters?: Partial<KaeplaEventFilter>;
  disableInitialFilters?: boolean;
}

export const useEventLogFilters = ({
  initialFilters = {},
  disableInitialFilters = false,
}: Options) => {
  const [eventLogFilters, setEventLogFilters] =
    React.useState<Partial<KaeplaEventFilter>>(initialFilters);

  const [eventLogAvailableFilterColumns] = React.useState<AvailableFilterColumn[]>([
    { column: 'eventGroup', label: 'Event Group' },
    { column: 'eventName', label: 'Event Name' },
    { column: 'logLevel', label: 'Log Level' },
    { column: 'originType', label: 'Origin Type' },
    { column: 'perspectiveId', label: 'Perspective ID' },
    { column: 'resellerId', label: 'Reseller ID' },
    { column: 'scopePathStringified', label: 'Scope Path' },
    { column: 'simulationId', label: 'Simulation ID' },
    { column: 'uid', label: 'User' },
  ]);

  const [disabledFilterColumns, setDisabledFilterColumns] = React.useState<EventLogFilterColumn[]>(
    disableInitialFilters ? (Object.keys(initialFilters) as (keyof KaeplaEventFilter)[]) : [],
  );

  const setFilter = React.useCallback(
    ({
      column,
      value,
      disabled = false,
    }: {
      column: EventLogFilterColumn;
      value: string | string[];
      disabled?: boolean;
    }) => {
      // cannot override disabled columns
      if (disabledFilterColumns.includes(column)) {
        return;
      }

      setEventLogFilters((previous) => ({
        ...previous,
        [column]: value,
      }));

      //
      if (disabled) {
        setDisabledFilterColumns([...disabledFilterColumns, column]);
      }
    },
    [disabledFilterColumns],
  );

  const clearFilter = React.useCallback(
    ({
      column,
      includingDisabled = false,
    }: {
      column: EventLogFilterColumn;
      includingDisabled?: boolean;
    }) => {
      if (disabledFilterColumns.includes(column) && !includingDisabled) {
        return;
      }

      const { [column]: _omitted, ...filtered } = eventLogFilters;

      setEventLogFilters(filtered);
    },
    [disabledFilterColumns, eventLogFilters],
  );

  const clearAllFilters = React.useCallback(
    ({ includingDisabled = false }: { includingDisabled?: boolean }) => {
      if (disabledFilterColumns.length > 0 && !includingDisabled) {
        const prepared = {} as typeof eventLogFilters;
        Object.keys(eventLogFilters).forEach((key_) => {
          const key = key_ as keyof KaeplaEventFilter;
          const value = eventLogFilters[key]!;

          if (disabledFilterColumns.includes(key)) {
            prepared[key] = value as unknown as undefined; // not sure why this is necessary?
          }
        });

        setEventLogFilters(prepared);

        return;
      }

      setEventLogFilters({} as Partial<KaeplaEventFilter>);
    },
    [disabledFilterColumns, eventLogFilters],
  );

  const hasNonDisabledFilters = React.useMemo(() => {
    return Object.keys(eventLogFilters).some(
      (key) => !disabledFilterColumns.includes(key as EventLogFilterColumn),
    );
  }, [disabledFilterColumns, eventLogFilters]);

  return {
    eventLogFilters,
    eventLogAvailableFilterColumns,
    disabledFilterColumns,
    hasNonDisabledFilters,
    setFilter,
    clearFilter,
    clearAllFilters,
  };
};
