import { KaeplaPerspectiveBlock } from '@kaepla/types';
import { Grid2 as Grid } from '@mui/material';

import { PerspectiveBlock } from './features/PerspectiveBlock.js';
import { getGridSpanNumber } from './helpers/getGridSpanNumber.js';

interface Options {
  blocks: KaeplaPerspectiveBlock[];
  locked?: boolean;
}

export const PerspectiveBlocks = ({ blocks, locked }: Options) => {
  return (
    <>
      {blocks.map((block, index) => (
        <Grid
          size={{
            xs: getGridSpanNumber('xs', block.gridSpan),
            sm: getGridSpanNumber('sm', block.gridSpan),
            md: getGridSpanNumber('md', block.gridSpan),
            lg: getGridSpanNumber('lg', block.gridSpan),
            xl: getGridSpanNumber('xl', block.gridSpan),
          }}
          key={`${block.type}${index}`}
        >
          <PerspectiveBlock block={block} blockNumber={index} locked={locked} />
        </Grid>
      ))}
    </>
  );
};
