/* eslint-disable @typescript-eslint/ban-types */
import { getApp } from 'firebase/app';
import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  getFirestore,
} from 'firebase/firestore';

export interface Pagination<T> {
  limit?: number;
  startAfter?: DocumentReference<T, DocumentData>;
}

export const FIREBASE = getFirestore(getApp('kaepla'));

// collectionPath

type KeyCollectionPath<T extends string> = `get${Capitalize<T>}Path`;
//
// References
//
type KeyGetCollectionReference<T extends string> = `get${Capitalize<T>}CollectionReference`;
type KeyGetDocumentReference<T extends string> = `get${Capitalize<T>}Reference`;

type KeyGetRepo<T extends string> = `get${Capitalize<T>}`;
type KeyGetRepoList<T extends string> = `get${Capitalize<T>}List`;
type KeyGetRepoListQuery<T extends string> = `get${Capitalize<T>}ListQuery`;

type RepoExportFunction<T extends string> = Record<
  KeyGetRepo<T> | KeyGetRepoList<T> | KeyGetRepoListQuery<T>,
  Function
>;

type RepoExportCollectionReference<RepoName extends string, RepoType extends DocumentData> = Record<
  KeyGetCollectionReference<RepoName>,
  CollectionReference<RepoType, RepoType>
>;

type RepoExportDocumentReference<RepoName extends string> = Record<
  KeyGetDocumentReference<RepoName>,
  Function
>;

type RepoExportCollectionPath<RepoName extends string> = Record<
  KeyCollectionPath<RepoName>,
  string
>;

// DocumentReference

export type Repository<Repo extends string, RepoType extends DocumentData> =
  // getCustomer, getCustomerList, getCustomerListQuery
  RepoExportFunction<Repo> &
    // customerCollectionReference
    RepoExportCollectionReference<Repo, RepoType> &
    // getCustomerReference
    RepoExportDocumentReference<Repo> &
    RepoExportCollectionPath<Repo>;
