export enum DataType {
  Bignumeric = 'BIGNUMERIC',
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Float64 = 'FLOAT64',
  Int64 = 'INT64',
  Numeric = 'NUMERIC',
  String = 'STRING',
  Timestamp = 'TIMESTAMP',
}
