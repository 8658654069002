import { KaeplaPerspective } from '@kaepla/types';
import { Alert, Divider, Grid2 as Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { useAuth } from '../../../AuthReactProvider.js';
import { perspectivesState } from '../../../services/recoil/nonpersistent/perspectivesState';
import { projectState } from '../../../services/recoil/nonpersistent/projectState';
import { projectAssignmentsState } from '../../../services/recoil/nonpersistent/userAssignmentState';
import { currentScopePathState } from '../../../services/recoil/persistent/currentScopePathState';
import { checkPathIsInScope } from '../Scopes/helpers/checkPathIsInScope.js';

import { PerspectiveCard } from './PerspectiveCard.js';

interface Options {
  showAllPerspectivesForProject: boolean;
}

export const PerspectivesOverview = ({ showAllPerspectivesForProject }: Options) => {
  const { kaeplaUser } = useAuth();
  const perspectives = useRecoilValue(perspectivesState);
  const [ownPerspectives, setOwnPerspectives] = useState<KaeplaPerspective[]>([]);
  const [otherPerspectives, setOtherPerspectives] = useState<KaeplaPerspective[]>([]);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const project = useRecoilValue(projectState);
  const projectAssignments = useRecoilValue(projectAssignmentsState);

  const isInScope = useCallback(
    (scopePath: string[]) => {
      if (project.id === 'default') return true;
      return checkPathIsInScope({
        project,
        projectAssignments,
        scopePath,
      });
    },
    [project, projectAssignments],
  );

  useEffect(() => {
    if (!kaeplaUser?.uid) return;
    const perspectivesFiltered = [...perspectives].filter((s) => {
      if (showAllPerspectivesForProject) {
        return true;
      } else {
        if (s.scopePathStringified === JSON.stringify(currentScopePath)) {
          return true;
        }
      }
      return false;
    });
    const own = perspectivesFiltered.filter((s) => s.createdBy === kaeplaUser.uid);
    if (project.defaultPerspective) {
      const defaultPerspective = { ...project.defaultPerspective };
      defaultPerspective.id = 'default';
      // make sure default perspective is not duplicated
      setOwnPerspectives([defaultPerspective, ...own.filter((p) => p.id !== 'default')]);
    } else {
      setOwnPerspectives(own);
    }
    const other = perspectivesFiltered
      .filter((p) => isInScope(p.scopePath))
      .filter((s) => s.createdBy !== kaeplaUser.uid);
    setOtherPerspectives(other);
  }, [
    currentScopePath,
    kaeplaUser?.uid,
    showAllPerspectivesForProject,
    perspectives,
    isInScope,
    project.defaultPerspective,
  ]);

  return (
    <Grid container spacing={1}>
      {ownPerspectives.length > 0 ? (
        <Grid size={12}>
          <Typography variant="h6">Own Dashboards</Typography>
        </Grid>
      ) : (
        <Grid size={12}>
          <Alert variant="outlined" severity="info">
            Your have no dashboards for this project. Create one!
          </Alert>
        </Grid>
      )}
      {ownPerspectives?.map((_simulation) => {
        return (
          <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }} key={_simulation.id}>
            <PerspectiveCard perspective={_simulation} own />
          </Grid>
        );
      })}
      {showAllPerspectivesForProject && (
        <Grid size={12}>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Typography variant="h6">Other Dashboards</Typography>
        </Grid>
      )}
      {showAllPerspectivesForProject && otherPerspectives.length === 0 && (
        <Grid size={12}>
          <Typography variant="body1">No other perspectives yet</Typography>
        </Grid>
      )}
      {showAllPerspectivesForProject &&
        otherPerspectives?.map((_perspective) => {
          return (
            <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }} key={_perspective.id}>
              <PerspectiveCard perspective={_perspective} />
            </Grid>
          );
        })}
    </Grid>
  );
};
