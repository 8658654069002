import { Fade, Grid2 as Grid } from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';

interface Options {
  q?: ReactElement;
  a: ReactElement;
  delay?: number;
  setProcessing?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const QuestionAndAnswer = ({ q, a, delay, setProcessing }: Options) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!setProcessing) {
      setShow(true);
      return;
    }
    const fakeProcessing = async () => {
      setProcessing(true);
      await new Promise((resolve) => setTimeout(resolve, delay ?? 200));
      setShow(true);
      setProcessing(false);
    };
    void fakeProcessing();
  }, [delay, setProcessing]);

  return (
    <Fade in={show}>
      <Grid size={12}>
        <Grid container spacing={2}>
          {q && <Grid size={10}>{q}</Grid>}
          {q && <Grid size={2} />}
          <Grid size={2} />
          <Grid size={10}>{a}</Grid>
          <Grid size={12} />
        </Grid>
      </Grid>
    </Fade>
  );
};
