/* eslint-disable @typescript-eslint/ban-ts-comment */
import { KaeplaProject } from '@kaepla/types';
import { useQuery } from '@tanstack/react-query';

import { Pagination } from '../../repository/base';
import { getProjectList, GetProjectListParameters, getProjectPath } from '../../repository/Project';
import { useCreateQueryKey } from '../useCreateQueryKey';

interface UseQueryOptions {
  refetchInterval?: number;
}

export function useProjectQueryFindAll(
  parameters: GetProjectListParameters & Pagination<KaeplaProject>,
  options?: UseQueryOptions,
) {
  const { queryKey } = useCreateQueryKey(getProjectPath, parameters);

  const query = useQuery({
    queryKey,
    throwOnError: true,
    queryFn: () => {
      if (!parameters) {
        return [];
      }
      return getProjectList(parameters);
    },
    refetchInterval: options?.refetchInterval,
  });
  return query;
}
