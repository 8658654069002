import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Grid2 as Grid, Stack, Tab, Typography } from '@mui/material';
import { useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { ihsVehicleImportsState } from '../../../../services/recoil/nonpersistent/dataSourceIhsVehicleImportsState';
import { dataSourceImportState } from '../../../../services/recoil/nonpersistent/dataSourceImportState';
import { dataSourceState } from '../../../../services/recoil/nonpersistent/dataSourceState';
import { dataSources } from '../data/dataSources';
import { DataSourceTabContent } from '../switcher/DataSourceTabContent';

import { DataSourceFullScreenContent } from './DataSourceFullScreenContent';

interface Options {
  viewDetails: boolean;
  setViewDetails: React.Dispatch<React.SetStateAction<boolean>>;
  fullScreen: boolean;
  setFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DataSourcesTabs = ({
  viewDetails,
  setViewDetails,
  fullScreen,
  setFullScreen,
}: Options) => {
  const [value, setValue] = useState('1');
  const [selectedDataSource, setSelectedDataSource] = useRecoilState(dataSourceState);
  const resetDataSourceImport = useResetRecoilState(dataSourceImportState);
  const resetIhsImportsSource = useResetRecoilState(ihsVehicleImportsState);

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    resetDataSourceImport();
    setValue(newValue);
    const newDataSource = dataSources.find((ds) => ds.id === newValue);
    if (!newDataSource) return;
    setSelectedDataSource(newDataSource);
  };

  if (fullScreen && selectedDataSource) {
    return (
      <Grid size={12}>
        <DataSourceFullScreenContent
          dataSource={selectedDataSource}
          viewDetails={viewDetails}
          setViewDetails={setViewDetails}
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
        />
      </Grid>
    );
  }

  return (
    <Grid size={12}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              id="data-sources-tabs"
            >
              {dataSources.map((dataSource) => (
                <Tab key={dataSource.id} label={dataSource.label} value={dataSource.id} />
              ))}
            </TabList>
            <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
            <Box>
              <Button
                id="data-sources-add"
                variant="contained"
                size="small"
                onClick={() => {
                  resetIhsImportsSource();
                }}
              >
                Add
              </Button>
            </Box>
          </Stack>
        </Box>
        {dataSources.map((dataSource) => (
          <TabPanel key={dataSource.id} sx={{ px: 0, pt: 2 }} value={dataSource.id}>
            <DataSourceTabContent
              viewDetails={viewDetails}
              setViewDetails={setViewDetails}
              fullScreen={fullScreen}
              setFullScreen={setFullScreen}
            />
          </TabPanel>
        ))}
      </TabContext>
    </Grid>
  );
};
