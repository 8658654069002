import { DateTime } from 'luxon';
import { KaeplaImport, KaeplaProject, KaeplaUpdateType } from '@kaepla/types';

export const dataImportTimeToGo = (dataImport: KaeplaImport, project?: KaeplaProject) => {
  if (!project) return '';
  const now = DateTime.now();
  let timeToGo = '';
  if (
    dataImport.updateType === KaeplaUpdateType.full &&
    project.fullUpdateIntervalInHours &&
    project.lastUpdatedAt
  ) {
    const lastUpdated = DateTime.fromJSDate(project.lastUpdatedAt.toDate());
    const diffInHours = Math.floor(now.diff(lastUpdated, 'hours').hours);
    timeToGo = `${project.fullUpdateIntervalInHours - diffInHours}`;
  } else if (
    dataImport.updateType === KaeplaUpdateType.delta &&
    project.deltaUpdateIntervalInHours &&
    project.lastUpdatedAt
  ) {
    const lastUpdated = DateTime.fromJSDate(project.lastUpdatedAt.toDate());
    const diffInHours = Math.floor(now.diff(lastUpdated, 'hours').hours);
    timeToGo = `${project.deltaUpdateIntervalInHours - diffInHours}`;
  } else {
    return '';
  }
  return `⏲ in ${timeToGo} hrs.`;
};
