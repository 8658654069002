import { KaeplaProject, KaeplaDataSnapshot } from '@kaepla/types';
import { getApp } from 'firebase/app';
import { DocumentData, getFirestore, doc, setDoc } from 'firebase/firestore';

interface Options {
  project: KaeplaProject;
  dataSnapShot: KaeplaDataSnapshot;
}

export const toggleArchiveDataSnapshot = async ({ project, dataSnapShot }: Options) => {
  if (!project.id || !dataSnapShot.id) return;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects/${project.id}/dataSnapshots/${dataSnapShot.id}`;
  const documentReference = doc(firestoreDatabase, fireStorePath);
  dataSnapShot.archived = !dataSnapShot.archived;
  await setDoc(documentReference, dataSnapShot as DocumentData);
  return dataSnapShot;
};
