import { KaeplaApiParameters, KaeplaProject, KaeplaQueryType, MatrixCheck } from '@kaepla/types';
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid2 as Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { useAuth } from '../../../../AuthReactProvider.js';
import { watcherServiceKaepla } from '../../../../firebaseInit.js';
import { generateDataOnCall } from '../../../../services/api/generateData.js';
import { getFromKaepla } from '../../../../services/api/getFromKaepla.js';
import { addProjectListener } from '../../../../services/firestore/addProjectListener.js';
import { updateProject } from '../../../../services/firestore/updateProject';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState.js';

import { DummyDataCard } from './DummyDataCard.js';

export const CreateDummyMatrix = () => {
  const { kaeplaUser } = useAuth();
  const [project, setProject] = useRecoilState(projectState);
  const [loading, setLoading] = useState(false);
  const [lookingForData, setLookingForData] = useState(true);
  const [checkMatrixIsAvailable, setCheckMatrixIsAvailable] = useState(false);
  const [matrixRecordCount, setMatrixRecordCount] = useState(0);

  // this is the projectListener
  useEffect(() => {
    // unmount project watcher first
    const watcherKeys = watcherServiceKaepla.get().getSubscriptionKeys();
    let projectWatcherKey;
    watcherKeys.forEach((watcher) => {
      const [watcherType] = watcher.split(':');
      if (watcherType === `projects/${project.id}`) {
        projectWatcherKey = watcher;
      }
    });
    if (projectWatcherKey) {
      watcherServiceKaepla.get().unsubscribe(projectWatcherKey);
    }

    const unsubscribe = addProjectListener({
      projectId: project.id,
      callback: (dataFromServer) => {
        const projectFromServer = dataFromServer as KaeplaProject;
        setProject(projectFromServer);
        // check matrix if there is one
        const parameters: KaeplaApiParameters = {
          q: 'checkIfMatrixExists' as KaeplaQueryType,
          p: [] as string[],
          projectId: project.id,
          s: 'CreateDummyMatrix',
        };
        getFromKaepla({
          callBack: (apiResponse) => {
            if (apiResponse?.response) {
              const response = apiResponse.response as MatrixCheck;
              if (response.totalCount && response.exists === true) {
                setCheckMatrixIsAvailable(true);
                setMatrixRecordCount(response.totalCount.totalCount);
              }
              if (response.exists === false) {
                setCheckMatrixIsAvailable(false);
              }
              setLookingForData(false);
            }
          },
          params: parameters,
          uid: kaeplaUser?.uid,
        });

        if (projectFromServer.matrixUnavailable === false) {
          setLoading(false);
        }
      },
    });
    return () => {
      unsubscribe();
    };
  }, [kaeplaUser?.uid, project.id, setProject]);

  const takeExistingDataset = () => {
    void updateProject({ project: { ...project, matrixUnavailable: false } });
    setProject({ ...project, matrixUnavailable: false });
  };

  const generateData = (factor: number) => {
    const parameters: KaeplaApiParameters = {
      q: 'generateData' as KaeplaQueryType,
      projectId: project.id,
      limit: factor,
      s: 'DummyDataGenerator',
    };
    generateDataOnCall(parameters);
  };

  return (
    <Grid container spacing={2}>
      <Grid size={12}>
        <Typography variant="h5" data-testid="create-dummy-data-header">
          Create Dummy Data
        </Typography>
      </Grid>
      {lookingForData && (
        <Grid size={12}>
          <LinearProgress />
          <Typography variant="subtitle1" data-testid="create-dummy-data-lookup">
            looking for an existing data set
          </Typography>
        </Grid>
      )}
      {checkMatrixIsAvailable && !project.matrixInPreparation && (
        <Grid size={{ xs: 12, lg: 6 }}>
          <Alert
            severity="warning"
            data-testid="create-dummy-data-warning"
            action={
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  takeExistingDataset();
                }}
              >
                use
              </Button>
            }
          >
            <Typography variant="subtitle1">
              An existing data set with {matrixRecordCount} records has been found for your project.
            </Typography>
            Creating a new dataset will erase this data.
          </Alert>
        </Grid>
      )}
      {checkMatrixIsAvailable && (
        <Grid size={12}>
          <Divider />
        </Grid>
      )}
      {!project.matrixInPreparation && (
        <Grid size={12}>
          <Alert severity="info" data-testid="create-dummy-data-info">
            <Typography variant="subtitle1">
              Currently your data can't be imported from here, but you can generate a set of dummy
              data.
            </Typography>
            Depending on the size of the dataset this may take from a few seconds to some minutes.
          </Alert>
        </Grid>
      )}
      {project.matrixInPreparation && (
        <Grid size={12} data-testid="dummy-is-being-prepared">
          <Alert severity="info" data-testid="create-dummy-data-info">
            Data is being prepared now
          </Alert>
        </Grid>
      )}
      <Grid size={12}>
        {loading || project.matrixInPreparation ? <LinearProgress /> : <Box sx={{ height: 4 }} />}
      </Grid>
      <Grid size={12}>
        <Typography variant="body2" data-testid="create-dummy-data-callout">
          Select ~ number of records
        </Typography>
      </Grid>
      {[
        { factor: 1, p: '~30k records', s: '~20sec.', d: false },
        { factor: 4, p: '~100k records', s: '~25sec.', d: false },
        { factor: 12, p: '~300k records', s: '~30sec.', d: false },
        { factor: 37, p: '~1m records', s: '~40sec.', d: false },
        { factor: 120, p: '~3m records', s: '~2min.', d: false },
        { factor: 200, p: '~5m records', s: '~3min.', d: true },
        { factor: 320, p: '~10m records', s: '~5min.', d: true },
      ].map((d) => (
        <Grid key={d.p} size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}>
          <DummyDataCard
            factor={d.factor}
            loading={loading}
            generateData={generateData}
            primary={d.p}
            secondary={d.s}
            disabled={d.d || !!project.matrixInPreparation}
          />
        </Grid>
      ))}
    </Grid>
  );
};
