import {
  FirestoreTimestamp,
  KaeplaPerspective,
  KaeplaProject,
  MatrixTimeSlice,
} from '@kaepla/types';
import { Button, Grid2 as Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { fireBaseTimestamp } from '../../../../services/firestore/_firestoreShorthands.js';
import { updateProject } from '../../../../services/firestore/updateProject.js';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState.js';
import { DataTimeline } from '../features/DataTimeline/DataTimeline.js';

interface Options {
  defaultPerspective: Partial<KaeplaPerspective>;
  setDefaultPerspective: (step: Partial<KaeplaPerspective>) => void;
}

export const StepDone = ({ defaultPerspective }: Options) => {
  const [project, setProject] = useRecoilState(projectState);

  useEffect(() => {
    setProject((_project) => {
      const newProject = { id: _project.id } as KaeplaProject;
      newProject.defaultPerspective = { ...defaultPerspective } as KaeplaPerspective;
      newProject.defaultPerspective.timeSlice = 'YEAR' as MatrixTimeSlice;
      newProject.defaultPerspective.scopePath = [];
      newProject.defaultPerspective.scopePathStringified = '[]';
      void updateProject({ project: newProject });
      return { ..._project, ...newProject };
    });
  }, [defaultPerspective, setProject]);

  return (
    <Grid container spacing={2}>
      <Grid size={12} container justifyContent="center">
        <Typography variant="h3">{project.defaultPerspective?.valueDimension}</Typography>
      </Grid>
      <Grid size={12}>
        <DataTimeline />
      </Grid>
      <Grid size={12} container justifyContent="center">
        <Button
          variant="contained"
          size="large"
          sx={{ mt: 4 }}
          onClick={() => {
            const newProject = { id: project.id } as KaeplaProject;
            newProject.initializedAt = fireBaseTimestamp() as FirestoreTimestamp;
            newProject.defaultPerspective = defaultPerspective as KaeplaPerspective;
            newProject.defaultPerspective.aggregationOverTimeName =
              defaultPerspective?.valueDimension ?? 'Turnover';
            setProject({ ...project, ...newProject });
            void updateProject({ project: newProject });
          }}
        >
          Done
        </Button>
      </Grid>
    </Grid>
  );
};
