import { FormControlLabel, FormGroup, Paper, Stack, Switch, Typography } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { AiThreadItems } from './components/AiThreadItems.js';

export function AiThreadDetail() {
  const { threadId } = useParams<{ threadId: string }>();
  const [showMarkdown, setShowMarkdown] = useState(false);

  return (
    <Stack
      test-id="AiThreadDetail"
      spacing={2}
      sx={{
        py: 2,
        display: 'flex',
        height: '80vh',
        flex: 1,
      }}
    >
      <Paper>
        <Stack p={2} spacing={4} direction={'row'} alignItems={'center'}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  aria-label="Show markdown"
                  value={showMarkdown}
                  onClick={() => setShowMarkdown(!showMarkdown)}
                  size="small"
                />
              }
              label="Show markdown"
            />
          </FormGroup>
        </Stack>
      </Paper>
      {threadId ? (
        <AiThreadItems threadId={threadId} showMarkdown={showMarkdown} />
      ) : (
        <Typography>No thread selected</Typography>
      )}
    </Stack>
  );
}
