import {
  KaeplaSimulation,
  KaeplaSimulationRulesetWithParameters,
  KaeplaTargets,
} from '@kaepla/types';
import SimulationIcon from '@mui/icons-material/AutoGraphOutlined';
import TargetsIcon from '@mui/icons-material/FilterCenterFocusOutlined';
import {
  Badge,
  Box,
  Button,
  Grid2 as Grid,
  Input,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useRecoilValue } from 'recoil';
import { useDebouncedCallback } from 'use-debounce';
import { v4 as uuidv4 } from 'uuid';

import { runSimulationOnCall } from '../../../../services/api/runSimulation.js';
import { updateSimulationParametersOnCall } from '../../../../services/api/updateSimulationParameters.js';
import { updateSimulationWithScope } from '../../../../services/firestore/updateSimulationWithScope.js';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState.js';
import { currentScopePathState } from '../../../../services/recoil/persistent/currentScopePathState.js';
import { InlineEdit } from '../../../features/InlineEdit';
import { simulationsColor } from '../../defaults.js';

const durationInMS = (simulation: KaeplaSimulation) => {
  if (!simulation?.simulationFinishedAt || !simulation?.simulationStartedAt) {
    return 30_000;
  }
  return (
    simulation.simulationFinishedAt.toMillis() + 20_000 - simulation.simulationStartedAt.toMillis()
  );
};
interface Options {
  id?: string;
  own: boolean;
  simulation?: KaeplaSimulation;
  simulationRulesets?: KaeplaSimulationRulesetWithParameters[];
  targetsForSimulation: KaeplaTargets[];
  setReset: Dispatch<SetStateAction<boolean>>;
  setCreateTargets: Dispatch<SetStateAction<boolean>>;
  createTargets: boolean;
  setRuleset: Dispatch<SetStateAction<KaeplaSimulationRulesetWithParameters | undefined>>;
  preview: boolean;
  setPreview: Dispatch<SetStateAction<boolean>>;
  addingParameter: boolean;
  setAddingParameter: Dispatch<SetStateAction<boolean>>;
  preparing: boolean;
  setPreparing: Dispatch<SetStateAction<boolean>>;
  setSimulationCountDownStart: Dispatch<SetStateAction<number>>;
  setApproxDuration: Dispatch<SetStateAction<number>>;
}

export const SimulationEditorHeaderOwner = ({
  id,
  own,
  simulation,
  simulationRulesets,
  targetsForSimulation,
  setReset,
  setCreateTargets,
  createTargets,
  setRuleset,
  preview,
  setPreview,
  addingParameter,
  setAddingParameter,
  preparing,
  setPreparing,
  setSimulationCountDownStart,
  setApproxDuration,
}: Options) => {
  const currentScopePath = useRecoilValue(currentScopePathState);
  const project = useRecoilValue(projectState);

  const unsetPreparing = useDebouncedCallback(() => {
    setPreparing(false);
  }, 1000);

  const changeSimulationName = useDebouncedCallback((newName: string) => {
    if (!simulation || !currentScopePath) return;
    const simulationUpdate = {
      id: simulation.id,
      name: newName,
    };

    void updateSimulationWithScope({
      project,
      simulation: simulationUpdate,
      scopePath: currentScopePath,
    });
  }, 500);

  const changeSimulationDescription = useDebouncedCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!project || !simulation || !currentScopePath) return;
      const simulationUpdate = {
        id: simulation.id,
        description: event.target.value,
      };
      void updateSimulationWithScope({
        project,
        simulation: simulationUpdate,
        scopePath: currentScopePath,
      });
    },
    500,
  );

  if (!simulation || !id || !own) {
    return null;
  }

  return (
    <Grid size={12}>
      <Toolbar disableGutters variant="dense">
        <Stack direction="row" alignItems="center" spacing={1}>
          <SimulationIcon sx={{ color: simulationsColor, mt: 1 }} />
          <Box>
            <Box component="span" sx={{ fontSize: '120%', fontWeight: '500', mr: 3 }}>
              <InlineEdit
                fullWidth
                value={simulation.name}
                changeCallback={(newValue) => {
                  changeSimulationName(newValue);
                }}
                callback={(newValue) => {
                  changeSimulationName(newValue);
                }}
              />
            </Box>
          </Box>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <Badge overlap="circular" badgeContent={targetsForSimulation.length} color="primary">
          <Button
            variant="outlined"
            size="small"
            sx={{ mr: 1 }}
            disabled={!!simulation.isBeingSimulated}
            onClick={() => setCreateTargets(!createTargets)}
          >
            <TargetsIcon />
          </Button>
        </Badge>
        <Button
          variant="outlined"
          size="small"
          sx={{ mr: 1 }}
          disabled={preparing || !preview || !!simulation.isBeingSimulated}
          onClick={() => setReset(true)}
        >
          reset
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ mr: 1 }}
          disabled={preparing || !preview || !!simulation.isBeingSimulated}
          onClick={() => {
            if (!project || !simulation) return;
            setPreparing(true);
            setSimulationCountDownStart(Date.now());
            setApproxDuration(durationInMS(simulation));
            updateSimulationParametersOnCall({
              params: {
                projectId: project.id,
                simulationId: id,
                rulesets: simulationRulesets,
              },
              callBack: () => {
                unsetPreparing();
                runSimulationOnCall({
                  params: {
                    projectId: project.id,
                    simulationId: id,
                  },
                });
              },
            });
            setPreview(false);
          }}
        >
          simulate
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            const _ruleset: KaeplaSimulationRulesetWithParameters = {
              simulationId: simulation.id,
              id: uuidv4(),
              position: simulationRulesets?.length ?? 0,
              parameters: [],
            };
            setRuleset(_ruleset);
            setAddingParameter(true);
          }}
          disabled={preparing || addingParameter || !!simulation.isBeingSimulated}
        >
          +
        </Button>
      </Toolbar>
      <Typography variant="caption">
        <Input
          sx={{ fontSize: '110%' }}
          disableUnderline
          fullWidth
          defaultValue={simulation.description}
          onChange={changeSimulationDescription}
        />
      </Typography>
    </Grid>
  );
};
