import { KaeplaBranding } from '@kaepla/types';

export const broughtToYou = (branding: KaeplaBranding | undefined) => {
  let title = 'Kaepla, a service by Atrigam';
  let forBy = '';
  if (branding?.appRealm && branding?.appProvider) {
    if (branding?.appRealm === branding?.appProvider) {
      forBy = ` is brought to you by`;
    } else {
      forBy = ` for ${branding?.appRealm} is brought to you by`;
    }
  }
  if (branding?.appName && branding?.appRealm && branding?.appProvider) {
    title = `${branding?.appName}${forBy}`;
  }
  return title;
};
