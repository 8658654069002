import { Timestamp } from 'firebase/firestore';
import { SetterOrUpdater } from 'recoil';
import {
  KaeplaPerspective,
  KaeplaProject,
  KaeplaPerspectiveFavorite,
  FirestoreTimestamp,
} from '@kaepla/types';

import { togglePerspectiveFavorite } from '../../../../services/firestore/togglePerspectiveFavorite';

interface UOptions {
  uid: string;
  perspective: KaeplaPerspective;
  project: KaeplaProject;
  currentScopePath: string[];
  isFav: boolean;
  perspectiveFavorites: KaeplaPerspectiveFavorite[];
  setPerspectiveFavorites: SetterOrUpdater<KaeplaPerspectiveFavorite[]>;
}
export const updatePerspectiveFavorites = ({
  uid,
  perspective,
  project,
  currentScopePath,
  isFav,
  perspectiveFavorites,
  setPerspectiveFavorites,
}: UOptions) => {
  const newFavorites = [...perspectiveFavorites].map((f) => {
    if (f.perspectiveId === perspective.id) {
      const newF = { ...f };
      newF.isFavorite = isFav;
      return newF;
    }
    return f;
  });
  const key = `${uid}-${perspective.id}`;
  if (!newFavorites.some((f) => f.id === key)) {
    newFavorites.push({
      id: key,
      uid,
      projectId: project.id,
      perspectiveId: perspective.id,
      scopePath: currentScopePath,
      scopePathStringified: JSON.stringify(currentScopePath),
      isFavorite: isFav,
      updatedAt: Timestamp.now() as FirestoreTimestamp,
    });
  }
  setPerspectiveFavorites(newFavorites);

  void togglePerspectiveFavorite({
    uid,
    perspective,
    scopePath: currentScopePath,
    isFavorite: isFav,
    project,
  });
};
