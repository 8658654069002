import { KaeplaProject, KaeplaFilter } from '@kaepla/types';
import { getApp } from 'firebase/app';
import { Timestamp, deleteDoc, doc, getFirestore } from 'firebase/firestore';

interface Options {
  project: KaeplaProject;
  filter: KaeplaFilter<Timestamp>;
}

export const deleteFilter = async ({ project, filter }: Options) => {
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects/${project.id}/filters/${filter.id}`;
  const firestoreDocumentReference = doc(firestoreDatabase, fireStorePath);
  return deleteDoc(firestoreDocumentReference);
};
