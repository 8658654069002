import { Box } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-enterprise';
import numbro from 'numbro';

import { formatNumber } from '../helpers/formatNumber';

interface CompareValueCellRendererParameters extends ICellRendererParams {
  color: string;
  isCurrency: boolean;
}

const formatNumberAndCurrency = (
  value: number,
  isCurrency: boolean,
  forceSign: boolean = false,
) => {
  if (!isCurrency) return formatNumber(value, forceSign);
  return `€ ${formatNumber(value, forceSign)}`;
};

const roundEpsilon = (value: number) => {
  return Math.round(value + Number.EPSILON) / 100;
};

// const relativePercentageDifference = (a: number, b: number) =>
//   Math.abs(((a - b) / ((a + b) / 2)) * 100);

const percentageChange = (a: number, b: number) => (b / a) * 100 - 100;

export const CompareValuesCellRenderer = (properties: CompareValueCellRendererParameters) => {
  const value = properties.value as number;
  const isCurrency = properties.isCurrency;

  if (!properties.column || !properties.data) {
    return <Box component="span">{formatNumberAndCurrency(value, isCurrency)}</Box>;
  }
  const data = properties.data as Record<string, unknown>;
  const columnId = properties.column.getColId();
  // console.log('columnId', columnId);
  let compareColumnId = `${columnId}__Simulated`;
  const pivotCompareColumnIdElements = columnId.split('_');
  const pivotBy = pivotCompareColumnIdElements.pop();
  const pivotCompareColumnId = pivotCompareColumnIdElements.join('_') + '__Simulated_' + pivotBy;
  // console.log('pivotCompareColumnId', pivotCompareColumnId);
  const firstValue = (data[columnId] as number) || 0;
  let secondValue = (data[compareColumnId] as number) || 0;
  // console.log('??->', columnId, compareColumnId, firstValue, secondValue);
  if (columnId.includes('_P_') && data[pivotCompareColumnId]) {
    compareColumnId = pivotCompareColumnId;
    secondValue = (data[pivotCompareColumnId] as number) || 0;
    // console.log('!!->', columnId, pivotCompareColumnId, firstValue, secondValue);
  }
  // if (!data[compareColumnId]) {
  //   compareColumnId = `${columnId}__Precalculated`;
  //   secondValue = (data[compareColumnId] as number) || 0;
  // }
  const color = properties.color;
  // const gap = secondValue / (firstValue / 100) - 100;
  let gapInPercent = 0;
  let gapRelative = 0;
  let gapAbsolute = 0;
  if (firstValue !== secondValue) {
    gapInPercent = percentageChange(firstValue, secondValue);
    gapRelative =
      Number.parseFloat(firstValue.toFixed(4)) - Number.parseFloat(secondValue.toFixed(4));
    gapAbsolute = Math.abs(gapRelative);
  }

  // console.log('---');
  // console.log('firstValue', firstValue);
  // console.log('compareColumnId', compareColumnId);
  // console.log('data', data);
  // console.log(
  //   'Object.keys(data).includes(compareColumnId)',
  //   Object.keys(data).includes(compareColumnId),
  // );
  // console.log('secondValue', compareColumnId, data[compareColumnId], secondValue);
  // console.log('gap', gap);
  // when there's nothing to compare with (compare value is undefined) OR the values are identical, we just chow the original value
  // when the compare value is 0 or null and there is a gap there IS a change e.g. priority shift
  if (!Object.keys(data).includes(compareColumnId) || gapAbsolute < 0.001) {
    return <Box component="span">{formatNumberAndCurrency(value, isCurrency)}</Box>;
  }
  // TODO: see how we deal with those rounding errors
  // if (gapAbsolute >= 0.001) {
  //   return (
  //     <Box component="span">
  //       <Box component="span" sx={{ color: 'orange', fontSize: 12, ml: 1 }}>
  //         ⚠
  //       </Box>{' '}
  //       {formatCurrency(value, isCurrency)}
  //     </Box>
  //   );
  // }
  return (
    <Box sx={{ lineHeight: 'normal', mt: 0.3 }}>
      <Box component="span">{formatNumberAndCurrency(firstValue, isCurrency)}</Box>
      <br />
      {gapInPercent !== Infinity && (
        <Box
          component="span"
          sx={{ color: gapInPercent > 0 ? 'green' : 'red', fontSize: 12, ml: 1 }}
        >
          {numbro(roundEpsilon(gapInPercent)).format({
            output: 'percent',
            mantissa: 0,
            forceSign: true,
          })}
          {' · '}
          {formatNumberAndCurrency(gapRelative, isCurrency, true)}
        </Box>
      )}
      <Box component="span" sx={{ color, fontSize: 12, ml: 1 }}>
        {formatNumberAndCurrency(secondValue, isCurrency)}
      </Box>
    </Box>
  );
};
