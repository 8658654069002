import AdminIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { KaeplaUser } from '@kaepla/types';

import { clearUserPreferencesForUserAndProject } from '../../services/firestore/clearUserPreferencesForUserAndProject';
import { projectState } from '../../services/recoil/nonpersistent/projectState';
import { kaeplaAssignmentState } from '../../services/recoil/persistent/kaeplaAssignmentState';

interface Options {
  user: KaeplaUser;
}

export const UserMenuAdmin = ({ user }: Options) => {
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const project = useRecoilValue(projectState);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorElement);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const clearUserPreferences = () => {
    void clearUserPreferencesForUserAndProject({ uid: user.uid, projectId: project.id });
    handleClose();
  };

  if (!kaeplaAssignment) return null;

  return (
    <Box>
      <Menu
        data-testid="user-dialog-admin-menu"
        open={open}
        anchorEl={anchorElement}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} disableRipple>
          close
        </MenuItem>
        <MenuItem onClick={clearUserPreferences} disableRipple>
          Clear User Preferences
        </MenuItem>
      </Menu>
      <IconButton size="small" data-testid="user-dialog-admin-menu-button" onClick={handleClick}>
        <AdminIcon />
      </IconButton>
    </Box>
  );
};
