import { KaeplaDimensionDetails } from '@kaepla/types';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { matrixFilteredState } from '../../../../../../services/recoil/nonpersistent/matrixFilteredState.js';
import { cleanColumnName } from '../../../../../helpers/cleanColumnName.js';

interface Options {
  setDimension: Dispatch<SetStateAction<string | undefined>>;
  dimension?: string;
  isEligibleForDimension: (detail: KaeplaDimensionDetails) => boolean;
}

export const DimensionSelect = ({ setDimension, dimension, isEligibleForDimension }: Options) => {
  const matrixFiltered = useRecoilValue(matrixFilteredState);
  const [dimensionValue, setDimensionValue] = useState(dimension ?? '');

  const eligibleDimensions = (details?: KaeplaDimensionDetails[]) => {
    if (!details) return [];
    const eligible = details
      .filter((detail) => isEligibleForDimension(detail))
      .map((detail) => detail.columnName)
      .slice()
      .sort((a, b) => a.localeCompare(b));
    eligible.unshift('');
    return eligible;
  };

  return (
    <FormControl sx={{ m: 0, minWidth: 220 }} size="small" margin="dense">
      <Autocomplete
        size="small"
        value={dimensionValue}
        getOptionLabel={(option) => cleanColumnName(option)}
        options={eligibleDimensions(matrixFiltered?.details) ?? []}
        onChange={(_event: unknown, newValue: string | null) => {
          if (!newValue) return;
          setDimension(newValue);
          setDimensionValue(newValue);
        }}
        renderInput={(parameters) => <TextField {...parameters} label="Select Dimension" />}
        onInputChange={(_event, newInputValue) => {
          if (newInputValue === '') {
            setDimension(undefined);
            setDimensionValue('');
          }
        }}
      />
    </FormControl>
  );
};
