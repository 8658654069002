import { Timestamp } from 'firebase/firestore';
import { DateTime } from 'luxon';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { FirestoreTimestamp, KaeplaOpsUpdateStatus } from '@kaepla/types';

import { erpOrdersImportsState } from '../../../../services/recoil/nonpersistent/dataSourceErpOrdersImportState';
import { ihsVehicleImportsState } from '../../../../services/recoil/nonpersistent/dataSourceIhsVehicleImportsState';
import { dataSourceOrdersState } from '../../../../services/recoil/nonpersistent/dataSourceOrdersState';
import { dataSourceState } from '../../../../services/recoil/nonpersistent/dataSourceState';
import { dataSourceVehiclesState } from '../../../../services/recoil/nonpersistent/dataSourceVehiclesState';
import { DataSourceApiImport } from '../components/DataSourceApiImport';
import { DataSourceUpload } from '../components/DataSourceUpload';
import { IHS_VEHICLES_UPLOAD_SPEED, ERP_ORDERS_IMPORT_SPEED } from '../config';
import { generateErpOrders } from '../generators/generateErpOrders';
import { generateIhsVehicles } from '../generators/generateIhsVehicles';
import { MatchType } from '../types/MatchType';

interface IOptions {
  setNewDataSourceImport: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NewDataSourceImport = ({ setNewDataSourceImport }: IOptions) => {
  // IHS Vehicle Import
  const [ihsVehicleImports, setIhsVehicleImports] = useRecoilState(ihsVehicleImportsState);
  const setIhsVehicles = useSetRecoilState(dataSourceVehiclesState);
  // ERP Orders Import
  const [erpOrderImports, setErpOrderImports] = useRecoilState(erpOrdersImportsState);
  const setErpOrders = useSetRecoilState(dataSourceOrdersState);

  const selectedDataSource = useRecoilValue(dataSourceState);

  const handleIhsVehicleUpload = () => {
    setNewDataSourceImport(false);
    const generatedVehicles = generateIhsVehicles();
    setIhsVehicles(generatedVehicles);
    const matched = generatedVehicles.filter(
      (vehicle) =>
        vehicle.matchType === MatchType.ONE2ONE || vehicle.matchType === MatchType.ONE2ZERO,
    ).length;
    const unmatched = generatedVehicles.filter(
      (vehicle) => vehicle.matchType === MatchType.N2ONE || vehicle.matchType === MatchType.ZERO2N,
    ).length;
    const nextId = `${ihsVehicleImports.length + 1}`;
    setIhsVehicleImports((oldImports) =>
      [
        {
          id: nextId,
          createdAt: Timestamp.fromDate(DateTime.now().toJSDate()) as FirestoreTimestamp,
          createdBy: 'user4',
          archived: false,
          updateStatus: KaeplaOpsUpdateStatus.started,
          matched,
          unmatched,
        },
        ...oldImports,
      ].sort((a, b) => a.id.localeCompare(b.id)),
    );

    setTimeout(() => {
      setIhsVehicleImports((oldImports) =>
        oldImports
          .map((imported) => {
            if (imported.id === nextId) {
              const newComponent = {
                ...imported,
                updateStatus: KaeplaOpsUpdateStatus.finished,
              };
              return newComponent;
            }
            return imported;
          })
          .sort((a, b) => a.id.localeCompare(b.id)),
      );
    }, IHS_VEHICLES_UPLOAD_SPEED);
  };

  const handleErpOrdersImport = () => {
    setNewDataSourceImport(false);
    const generatedOrders = generateErpOrders();
    setErpOrders(generatedOrders);

    const matched = generatedOrders.filter(
      (order) => order.matchType === MatchType.ONE2ONE || order.matchType === MatchType.ONE2ZERO,
    ).length;
    const unmatched = generatedOrders.filter(
      (order) => order.matchType === MatchType.N2ONE || order.matchType === MatchType.ZERO2N,
    ).length;

    const nextId = `${erpOrderImports.length + 1}`;
    setErpOrderImports((oldImports) =>
      [
        {
          id: nextId,
          createdAt: Timestamp.fromDate(DateTime.now().toJSDate()) as FirestoreTimestamp,
          createdBy: 'user4',
          archived: false,
          updateStatus: KaeplaOpsUpdateStatus.started,
          matched,
          unmatched,
        },
        ...oldImports,
      ].sort((a, b) => a.id.localeCompare(b.id)),
    );

    setTimeout(() => {
      setErpOrderImports((oldImports) =>
        oldImports
          .map((imported) => {
            if (imported.id === nextId) {
              const newComponent = {
                ...imported,
                updateStatus: KaeplaOpsUpdateStatus.finished,
              };
              return newComponent;
            }
            return imported;
          })
          .sort((a, b) => a.id.localeCompare(b.id)),
      );
    }, ERP_ORDERS_IMPORT_SPEED);
  };

  if (selectedDataSource.key === 'ihsVehicles') {
    return <DataSourceUpload handleFileUpload={handleIhsVehicleUpload} />;
  } else if (selectedDataSource.key === 'erpOrders') {
    return <DataSourceApiImport handleApiImport={handleErpOrdersImport} />;
  }
  return null;
};
