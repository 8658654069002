import { Avatar, CircularProgress, Stack, Typography, useTheme } from '@mui/material';

import { useCustomerQueryFind } from '../../../hooks/query';

export interface ColumnCustomerProperties {
  customerId?: string;
}

export function ColumnCustomer({ customerId }: ColumnCustomerProperties) {
  const theme = useTheme();
  const customerQuery = useCustomerQueryFind(customerId);

  if (customerQuery.isLoading) {
    return (
      <Stack spacing={1} display={'flex'} direction={'row'} alignItems={'center'} height={'100%'}>
        <CircularProgress size={14} />
        <Typography>{customerId}</Typography>
      </Stack>
    );
  }

  if (!customerQuery.data) {
    <Stack spacing={1} display={'flex'} direction={'row'} alignItems={'center'} height={'100%'}>
      <Typography>{customerId}</Typography>
    </Stack>;
  }

  return (
    <Stack spacing={1} display={'flex'} direction={'row'} alignItems={'center'} height={'100%'}>
      <Avatar
        data-testid="customer-avatar"
        sx={{
          width: 22,
          height: 22,
          bgcolor: 'transparent',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor:
            theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[800],
        }}
        alt={customerQuery.data?.name}
        src={customerQuery.data?.iconBase64}
        variant="square"
      />
      <Typography>{customerQuery.data?.name}</Typography>
    </Stack>
  );
}
