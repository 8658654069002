import { KaeplaImport } from '@kaepla/types';

import { getDuration } from '../../../helpers/getDuration';

export const dataImportRuntime = (dataImportUpdate: KaeplaImport) => {
  let duration = '';
  if (dataImportUpdate.endedAt && dataImportUpdate.startedAt) {
    duration = getDuration(dataImportUpdate.startedAt, dataImportUpdate.endedAt);
  } else if (dataImportUpdate.updatedAt && dataImportUpdate.startedAt) {
    duration = getDuration(dataImportUpdate.startedAt, dataImportUpdate.updatedAt);
  } else {
    return '';
  }
  return `⟷ ${duration}`;
};
