import { getDocs } from 'firebase/firestore';

import { getImportListQuery } from './import.query';

export const getImportList = async (parameters: Parameters<typeof getImportListQuery>[number]) => {
  const q = getImportListQuery(parameters);
  const snapshot = await getDocs(q);

  return snapshot.docs.map((d) => d.data());
};
export type GetImportListParameters = Parameters<typeof getImportList>[number];
