import { Box } from '@mui/material';

import { AssistantMessage } from '../../../../Frontend/AI/AiMessage/AssistantMessage';
import { UserMessage } from '../../../../Frontend/AI/AiMessage/UserMessage';
import { ContentExpander } from '../../../../Frontend/features/ContentExpander';

import { ThreadItem } from './AiThreadItems';
import { AiToolCall } from './AiToolCall';

interface Options {
  threadItem: ThreadItem;
  showMarkdown: boolean;
}

export const AiThreadItem = ({ threadItem, showMarkdown }: Options) => {
  if (threadItem.message) {
    return (
      <Box sx={{ pb: 1, m: 2 }}>
        {threadItem.message.data.role === 'user' ? (
          <UserMessage aiMessage={threadItem.message} />
        ) : (
          <Box>
            {showMarkdown && (
              <ContentExpander label="Original Markdown">
                <Box sx={{ fontFamily: 'monospace', whiteSpace: 'pre-wrap' }}>
                  {threadItem.message.data.content.map((element) => (
                    <Box key={element.type}>
                      {element.type === 'text' && <Box>{element.text.value}</Box>}
                      {element.type !== 'text' && <Box>not implemented yet</Box>}
                    </Box>
                  ))}
                </Box>
              </ContentExpander>
            )}
            <AssistantMessage aiMessage={threadItem.message} />
          </Box>
        )}
      </Box>
    );
  } else if (threadItem.toolCall) {
    return <AiToolCall toolCall={threadItem.toolCall} showMarkdown={showMarkdown} />;
  }
  return null;
};
