import { Alert, Grid2 as Grid, Paper } from '@mui/material';

import { ProjectSummary } from '../features/ProjectSummary/ProjectSummary';

export const WaitFirstSyncToComplete = () => {
  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12, md: 5, lg: 4 }}>
        <ProjectSummary />
      </Grid>
      <Grid size={{ xs: 12, md: 5, lg: 4 }}>
        <Paper sx={{ p: 2, width: '100%', height: '100%' }}>
          <Alert severity="info">
            Import is configured, please wait for your first data sync to complete.
          </Alert>
        </Paper>
      </Grid>
    </Grid>
  );
};
