import { clientEventService } from '@kaepla/events';
import { useQuery } from '@tanstack/react-query';
import { GetKaeplaEventDistinctValuesOnCallParameters } from 'packages/events/src/cloudFunctions/cloudFunctions.types';

import { useCreateQueryKey } from '../../../../hooks/useCreateQueryKey';

export function useEventDistinctValuesQuery(options: GetKaeplaEventDistinctValuesOnCallParameters) {
  const { queryKey } = useCreateQueryKey('eventDistinctValues', {
    column: options.column,
    ...options.filterBy,
  });
  const query = useQuery({
    queryKey,
    queryFn: () => {
      return clientEventService.getDistinctValues(options);
    },
  });

  return query;
}
