import { FirestoreTimestamp } from '@kaepla/types';
import TourIcon from '@mui/icons-material/HelpCenterOutlined';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { Timestamp } from 'firebase/firestore';
import { DateTime } from 'luxon';
import { ReactElement } from 'react';
import Joyride, { Step } from 'react-joyride';
import { useRecoilValue } from 'recoil';

import { useUserPreferences } from '../../UserPreferencesProvider';
import { IS_CI } from '../../mode';
import { pageState } from '../../services/recoil/persistent/pageState';
import { defaultFeatureTourOptions } from '../features/defaultFeatureTourOptions';

interface Properties {
  children: ReactElement | string | number;
}

const TOUR_RESTART_GRACE_TIME_IN_DAYS = 30; // days

const TourTooltipContent = ({ children }: Properties) => {
  return (
    <Box sx={{ textAlign: 'left' }}>
      <Typography variant="body1">{children}</Typography>
    </Box>
  );
};

interface JOptions {
  mainTour: boolean;
  setMainTour: React.Dispatch<React.SetStateAction<boolean>>;
  guiTour: boolean;
  setGuiTour: React.Dispatch<React.SetStateAction<boolean>>;
  disabled: boolean;
}

export const Tour = ({ mainTour, setMainTour, guiTour, setGuiTour, disabled }: JOptions) => {
  const { getGlobalPreferences, setGlobalPreferences } = useUserPreferences();
  const page = useRecoilValue(pageState);
  const preferences = getGlobalPreferences();

  const tourSteps: Step[] = [
    {
      target: '#tour-start',
      content: (
        <Box sx={{ textAlign: 'left' }}>
          <Typography variant="body1">
            Whenever you see this icon, you can click it to start a quick feature tour. Click it!
          </Typography>
          <Button
            sx={{ mt: 1 }}
            variant="contained"
            data-test-id="tour-got-it"
            onClick={() => {
              const now = Timestamp.now() as FirestoreTimestamp;
              setGlobalPreferences({ tourLastStartedAt: now });
            }}
          >
            Got it.
          </Button>
          <Button
            sx={{ mt: 1, ml: 1 }}
            variant="outlined"
            onClick={() => {
              setMainTour(false);
              setGuiTour(true);
            }}
          >
            Cool, start the tour!
          </Button>
        </Box>
      ),
      disableBeacon: true,
      hideFooter: true,
      hideCloseButton: true,
    },
  ];

  const guiTourSteps: Step[] = [
    {
      target: '#burger-menu',
      content: (
        <TourTooltipContent>Expand the menu to see all available options.</TourTooltipContent>
      ),
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target: '#customer-selector',
      content: <TourTooltipContent>Show only projects from a chosen customer.</TourTooltipContent>,
      disableBeacon: true,
    },
    {
      target: '#account-menu',
      content: (
        <TourTooltipContent>
          Click your avatar to upload a profile picture, manage your profile, toggle dark mode or
          log out.
        </TourTooltipContent>
      ),
      disableBeacon: true,
    },
    {
      target: '#left-menu',
      content: (
        <TourTooltipContent>
          The side menu contains all the main features of the application.
        </TourTooltipContent>
      ),
      disableBeacon: true,
      placement: 'right',
    },
    {
      target: '.project-card',
      content: <TourTooltipContent>Select a project to view its details.</TourTooltipContent>,
      disableBeacon: true,
    },
    {
      target: '#project-add-fab',
      content: <TourTooltipContent>Add a new planning project.</TourTooltipContent>,
      disableBeacon: true,
    },
  ];

  const tourShouldStart = () => {
    if (['/', '/Projects'].includes(page)) return false;
    // console.log('--------------tourLastStartedAt', preferences?.id, preferences?.tourLastStartedAt);
    if (!preferences?.tourLastStartedAt) return false;
    const tourLastStarted = DateTime.fromJSDate(preferences.tourLastStartedAt.toDate());
    if (
      preferences?.tourLastStartedAt &&
      DateTime.now().diff(tourLastStarted).days > TOUR_RESTART_GRACE_TIME_IN_DAYS
    ) {
      return true;
    }
    return false;
  };

  const tourJustStarted = () => {
    if (page !== '/') return false;
    // console.log('--------------tourLastStartedAt', preferences?.id, preferences?.tourLastStartedAt);
    if (!preferences?.tourLastStartedAt) return true;
    const tourLastStarted = DateTime.fromJSDate(preferences.tourLastStartedAt.toDate());
    if (preferences?.tourLastStartedAt && DateTime.now().diff(tourLastStarted).days < 1) {
      return true;
    }
    return false;
  };

  if (IS_CI) return null; // for now, don't show the tour in CI
  if (page !== 'turn_off') return null; // turn off completely for now

  if (disabled) return null;

  return (
    <Box>
      {tourShouldStart() && (
        <Joyride run={mainTour} steps={tourSteps} styles={{ options: defaultFeatureTourOptions }} />
      )}
      <Joyride
        run={guiTour}
        steps={guiTourSteps}
        styles={{ options: defaultFeatureTourOptions }}
        continuous
        showProgress
        showSkipButton
      />
      {(tourShouldStart() || tourJustStarted()) && (
        <IconButton
          id="tour-start"
          color="info"
          sx={{ ml: 2 }}
          onClick={() => {
            setMainTour(false);
            setGuiTour(false);
            setTimeout(() => {
              setGuiTour(true);
            }, 100);
          }}
        >
          <TourIcon />
        </IconButton>
      )}
    </Box>
  );
};
