import { KaeplaConnector, KaeplaUser } from '@kaepla/types';
import SucceededIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import FailedIcon from '@mui/icons-material/ErrorOutlineRounded';
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { useAuth } from '../../../../AuthReactProvider';
import { Image } from '../../../../primitive/Image/Image';
import { manageConnector } from '../../../../services/api/manageConnector';
import { knownUsersState } from '../../../../services/recoil/persistent/knownUsersState';
import { UserAvatar } from '../../../features/UserAvatar';
import { avatarAppearDuration } from '../../defaults';
import { connectorTypes } from '../connectorTypes';
import { KaeplaConnectorType } from '../types';

interface Options {
  connector: KaeplaConnector;
  setConnectorForEdit?: React.Dispatch<React.SetStateAction<KaeplaConnector | undefined>>;
  setConnectorType?: React.Dispatch<React.SetStateAction<KaeplaConnectorType | undefined>>;
  setNewConnector?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ConnectorCard = ({
  connector,
  setConnectorForEdit,
  setConnectorType,
  setNewConnector,
}: Options) => {
  const { kaeplaUser } = useAuth();
  const knownUsers = useRecoilValue(knownUsersState);
  const [user, setUser] = useState<KaeplaUser>();
  const [thisConnectorType, setThisConnectorType] = useState<KaeplaConnectorType>();
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    const _user = knownUsers.find((k) => k.uid === connector.createdBy);
    setUser(_user);
    setThisConnectorType(connectorTypes.find((ct) => ct.id === connector.connectorTypeId));
  }, [connector, knownUsers, setConnectorType]);

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <CardHeader
        title={connector.name}
        subheader={connector.id}
        avatar={user ? <UserAvatar user={user} /> : null}
        action={
          thisConnectorType && (
            <Image
              width={30}
              style={{ maxHeight: '30px', maxWidth: '30px' }}
              alt={thisConnectorType.name}
              src={thisConnectorType.base64Icon}
              duration={avatarAppearDuration}
            />
          )
        }
      />
      <CardContent sx={{ minHeight: 75 }}>
        {!deleting && <Typography color="textSecondary">{connector.description}</Typography>}
        {deleting && <Alert severity="info">This connector is being removed, hold on...</Alert>}
      </CardContent>
      {setConnectorForEdit && setConnectorType && setNewConnector && (
        <CardActions>
          <Button
            variant="contained"
            size="small"
            disabled={deleting}
            onClick={() => {
              setConnectorForEdit(connector);
              setConnectorType(thisConnectorType);
              setNewConnector(false);
            }}
          >
            Edit
          </Button>
          <IconButton
            size="small"
            disabled={deleting}
            onClick={() => {
              if (!kaeplaUser) return;
              void manageConnector({
                params: {
                  uid: kaeplaUser.uid,
                  customerId: connector.customerId,
                  connector,
                  deleteConnector: true,
                },
                setLoading: setDeleting,
              });
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="row" spacing={1} alignItems="center">
            {connector.credentialsAreValid ? (
              <SucceededIcon color="success" />
            ) : (
              <FailedIcon color="error" />
            )}
          </Stack>
        </CardActions>
      )}
    </Card>
  );
};
