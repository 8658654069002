import { KaeplaGetDataParameters } from '@kaepla/types';
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { requestLog } from '../../Frontend/helpers/logger.js';

interface KOptions {
  params: KaeplaGetDataParameters;
  uid?: string;
  setError: (error: string) => void;
}

export const getDataForGridView = async ({ params, uid, setError }: KOptions) => {
  const functions = getFunctions(getApp('kaepla'), 'europe-west3');
  const callable = httpsCallable(functions, 'getKaeplaProjectData');

  requestLog.log(`getKaeplaProjectData({data:${JSON.stringify({ ...params, uid })}})`);
  const result = await callable(params).catch((error: Error) => {
    requestLog.warn('Error calling getKaeplaProjectData', error);
    setError(error.toString().replaceAll('FirebaseError: ', ' '));
  });

  if (!result?.data) {
    return [];
  }
  if (params.downloadAsFile) {
    return result.data as Record<string, unknown>;
  }

  return (result.data as Record<string, unknown>).rows;
};
