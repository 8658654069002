import { useRecoilValue } from 'recoil';
import { KaeplaPerspectiveBlock } from '@kaepla/types';

import { knownProjectFiltersState } from '../../../../../../../services/recoil/nonpersistent/knownProjectFiltersState.js';
import { cleanAggregation } from '../../../../../../helpers/cleanAggregation.js';
import { cleanColumnName } from '../../../../../../helpers/cleanColumnName.js';

interface ABOptions {
  block: KaeplaPerspectiveBlock;
}
export const DonutChartTitle = ({ block }: ABOptions) => {
  const valueDimension = cleanColumnName(block.valueDimension ?? 'n/a');
  const aggregation = cleanAggregation(block.aggregation);
  const aggregationDimension = cleanColumnName(block.aggregationDimension ?? 'n/a');
  const filterId = block.filterId;
  const knownProjectFilters = useRecoilValue(knownProjectFiltersState);
  const filter = knownProjectFilters?.find((k) => k.id === filterId);

  return (
    <>
      {valueDimension} <u>{aggregation}</u> per <b>{aggregationDimension}</b>
      {filter && ` for ${filter.name}`}
    </>
  );
};
