import { KaeplaProject, KaeplaPerspective, FirestoreTimestamp } from '@kaepla/types';
import { getApp } from 'firebase/app';
import { collection, DocumentData, getFirestore, doc, setDoc, Timestamp } from 'firebase/firestore';
import { DateTime } from 'luxon';

interface Options {
  project: KaeplaProject;
  createdBy: string;
  scopePath: string[];
  perspective?: Partial<KaeplaPerspective>;
}

export const createNewPerspective = async ({
  project,
  scopePath,
  createdBy,
  perspective,
}: Options) => {
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects/${project.id}/perspectives`;
  const firestoreCollectionReference = collection(firestoreDatabase, fireStorePath);
  const documentReference = doc(firestoreCollectionReference);
  const date = DateTime.now();
  const humanReadable = date.toLocaleString(DateTime.DATETIME_MED);
  const _perspective: Partial<KaeplaPerspective> = {
    ...perspective,
    id: documentReference.id,
    projectId: project.id,
    name: `New dashboard`,
    createdBy,
    createdAt: Timestamp.now() as FirestoreTimestamp,
    updatedAt: Timestamp.now() as FirestoreTimestamp,
    scopePath,
    scopePathStringified: JSON.stringify(scopePath),
    description: `New dashboard ${humanReadable}`,
  };
  await setDoc(documentReference, _perspective as DocumentData);
  return _perspective as KaeplaPerspective;
};
