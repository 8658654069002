import { KaeplaReseller } from '@kaepla/types';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Grid2 as Grid, IconButton } from '@mui/material';
import { useState } from 'react';

import { ResellerDomain } from './ResellerDomain.js';

interface Options {
  reseller: KaeplaReseller;
}
export const ResellerDomains = ({ reseller }: Options) => {
  const [open, setOpen] = useState(false);

  return (
    <Grid data-testid="reseller-domains" container spacing={0}>
      <Grid size={10}>
        <ResellerDomain reseller={reseller} printAll={open} />
      </Grid>
      <Grid size={2} container justifyContent="flex-end">
        {reseller.domains && reseller.domains.length > 1 && (
          <Box sx={{ mr: -1 }}>
            <IconButton
              size="small"
              edge="end"
              data-testid="reseller-domains-toggle"
              onClick={() => {
                setOpen(!open);
              }}
            >
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
