import { ISODateTime } from '@kaepla/types';
import { DateTime } from 'luxon';

export enum HumanReadableTimestampType {
  date = 'Date',
  timeago = 'TimeAgo',
  dateAndTimeago = 'DateAndTimeAgo',
}

interface Options {
  dateTime: ISODateTime;
  type?: HumanReadableTimestampType;
}

export const convertISODateTime = ({ dateTime, type }: Options) => {
  const humanReadableTimeStamp = DateTime.fromISO(dateTime).toFormat("dd LLL yyyy 'at' HH:mm");
  if (type === HumanReadableTimestampType.date) {
    return `${humanReadableTimeStamp}`;
  }
  const relative = DateTime.fromISO(dateTime).toRelative() ?? 'now';
  if (type === HumanReadableTimestampType.timeago) {
    return `${relative}`;
  }
  return `${humanReadableTimeStamp} - ${relative}`;
};
