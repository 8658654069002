import { WhereClause } from '@atrigam/atrigam-service-firebase-watcher';
import { clientEventService } from '@kaepla/events';
import { KaeplaCustomer, KaeplaCustomerAssignment, KaeplaUser } from '@kaepla/types';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  AvatarGroup,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  FormControlLabel,
  Grid2 as Grid,
  IconButton,
  LinearProgress,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import pluralize from 'pluralize';
import { MouseEvent, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { useAuth } from '../../../../AuthReactProvider.js';
import { resendInvite } from '../../../../services/api/resendInviteEmail.js';
import { addFirestoreCollectionListener } from '../../../../services/firestore/addFirestoreCollectionListener.js';
import { createCustomerAssignmentForEmail } from '../../../../services/firestore/createCustomerAssignmentForEmail.js';
import { getUsers } from '../../../../services/firestore/getUsers.js';
import { removeCustomerAssignment } from '../../../../services/firestore/removeCustomerAssignment.js';
import { updateCanCreateProjectForCustomerAssignment } from '../../../../services/firestore/updateCanCreateProjectForCustomerAssignment.js';
import { customerAssignmentState } from '../../../../services/recoil/nonpersistent/customerAssignmentState.js';
import { knownResellersState } from '../../../../services/recoil/nonpersistent/knownResellersState.js';
import { selectedCustomerState } from '../../../../services/recoil/nonpersistent/selectedCustomer.js';
import { watcherKeysState } from '../../../../services/recoil/nonpersistent/watcherKeysState';
import { kaeplaAssignmentState } from '../../../../services/recoil/persistent/kaeplaAssignmentState.js';
import { AddTeamMember } from '../../../features/AddTeamMember.js';
import { AddTeamMemberToggle } from '../../../features/AddTeamMemberToggle.js';
import { CustomerAvatar } from '../../../features/CustomerAvatar.js';
import { ResellerAvatar } from '../../../features/ResellerAvatar.js';
import { UserAvatar } from '../../../features/UserAvatar.js';
import { convertTimestamp, HumanReadableTimestampType } from '../../../helpers/convertTimestamp.js';
import { logger } from '../../../helpers/logger.js';
import { avatarGroupSlot } from '../../defaults.js';

import { CustomerCardMenu } from './CustomerCardMenu.js';
import { CustomerDeleteDialog } from './CustomerDeleteDialog.js';
import { CustomerDomains } from './CustomerDomains.js';
import { CustomerRenameDialog } from './CustomerRenameDialog.js';

interface Options {
  customer: KaeplaCustomer;
}

export const CustomerCard = ({ customer }: Options) => {
  const { kaeplaUser } = useAuth();
  const selectedCustomer = useRecoilValue(selectedCustomerState);
  const knownResellers = useRecoilValue(knownResellersState);
  const [customerAssignments, setCustomerAssignments] = useRecoilState(customerAssignmentState);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const setWatcherKeys = useSetRecoilState(watcherKeysState);
  const [customerTeam, setCustomerTeam] = useState<KaeplaUser[]>([]);
  const reseller = knownResellers.find((r) => r.id === customer.resellerId);
  const [hover, setHover] = useState(false);
  const [openAddTeamMember, setOpenAddTeamMember] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openRename, setOpenRename] = useState(false);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState(true);

  const userHasCustomerAssignment =
    customerAssignments.some((r) => r.customerId === customer.id && r.uid === kaeplaUser?.uid) ||
    kaeplaAssignment !== undefined;

  const handleMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorElement(null);
  };

  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleClickOpenRename = () => {
    setOpenRename(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseRename = () => {
    setOpenRename(false);
  };

  useEffect(() => {
    if (!customer.id) return;
    logger.log('Init CustomerCard Assignments Listener fires', customer.id);
    const fireStorePath = `customerAssignments`;
    const queryWhere: WhereClause[] = [
      {
        fieldPath: 'customerId',
        opStr: '==',
        value: customer.id,
      },
    ];

    const unsubscribe = addFirestoreCollectionListener({
      fireStorePath,
      queryWhere,
      callback: (_customerAssignments) => {
        const assignments = _customerAssignments as KaeplaCustomerAssignment[];
        logger.log('CustomerCard Assignments Listener fires', customer.id);
        const load = async () => {
          const userIds = assignments.map((c) => c.uid);
          const uniqueUserIds = userIds.filter((v, index, a) => a.indexOf(v) === index);
          const assignedUsers = await getUsers({ userIds: uniqueUserIds });
          setCustomerTeam(assignedUsers);
          setCustomerAssignments(assignments);
          setLoading(false);
        };

        void load();
      },
      watcherKeyCallback: (key) => {
        setWatcherKeys((_watcherKeys) => {
          const keyId = `customerTeam-${customer.id}`;
          const newWatcherKeys = { ..._watcherKeys };
          newWatcherKeys[keyId] = key;
          return newWatcherKeys;
        });
      },
    });
    return () => {
      unsubscribe();
    };
  }, [customer.id, setCustomerAssignments, setWatcherKeys]);

  const openAddTeamMemberDialog = () => {
    setOpenAddTeamMember(true);
  };

  const closeAddTeamMemberDialog = (email: string) => {
    setOpenAddTeamMember(false);
    if (!kaeplaUser?.uid || !reseller || email.length === 0) return;
    const sendInvite = async () => {
      let emailToInvite = email;
      if (!emailToInvite.includes('@')) {
        emailToInvite = `${email}@kaepla.atrigam.com`;
      }

      await createCustomerAssignmentForEmail({
        customerId: customer.id,
        resellerId: reseller.id,
        email: emailToInvite,
        assignedBy: kaeplaUser.uid,
      });

      void clientEventService.createEvent({
        assignmentScope: clientEventService.assignmentScope.CUSTOMER,
        eventGroup: clientEventService.eventGroup.CUSTOMER,
        eventName: clientEventService.eventName.PROJECT_TEAM_INVITE_USER_TO_TEAM,
        uid: kaeplaUser.uid,
        customerId: customer.id,
        resellerId: reseller.id,
        payload: {
          affectedUserEmail: email,
        },
      });
    };
    void sendInvite();
  };

  const wasAssignedByMe = (user: KaeplaUser) => {
    const customerAssignment = customerAssignments.find(
      (r) => r.customerId === customer.id && r.uid === user.uid,
    );
    if (!customerAssignment) return false;
    return customerAssignment.assignedBy === kaeplaUser?.uid;
  };

  const lastInviteSent = (user: KaeplaUser) => {
    const customerAssignment = customerAssignments.find(
      (r) => r.customerId === customer.id && r.uid === user.uid,
    );
    if (!customerAssignment) return;
    const time = convertTimestamp(
      customerAssignment.lastInviteSentAt ?? customerAssignment.assignedAt,
      HumanReadableTimestampType.timeago,
    );
    return time;
  };

  const removeTeamMemberFromCustomer = (user: KaeplaUser) => {
    const customerAssignment = customerAssignments.find(
      (r) => r.customerId === customer.id && r.uid === user.uid,
    );
    if (!customerAssignment) return;
    void removeCustomerAssignment({ assignmentId: customerAssignment.id });
    void clientEventService.createEvent({
      assignmentScope: clientEventService.assignmentScope.CUSTOMER,
      eventGroup: clientEventService.eventGroup.CUSTOMER,
      eventName: clientEventService.eventName.CUSTOMER_TEAM_REMOVE_USER_FROM_TEAM,
      uid: kaeplaUser?.uid,
      customerId: customer.id,
      resellerId: customer.resellerId,
      payload: {
        affectedUserUid: customerAssignment.uid,
      },
    });
  };

  const toggleUserCanCreateProject = ({
    customerId,
    uid,
    canCreateProject,
  }: {
    customerId: string;
    uid: string;
    canCreateProject: boolean;
  }) => {
    const customerAssignment = customerAssignments.find(
      (r) => r.customerId === customerId && r.uid === uid,
    );
    if (!customerAssignment) return;
    void updateCanCreateProjectForCustomerAssignment({
      assignmentId: customerAssignment.id,
      canCreateProject,
    });
    void clientEventService.createEvent({
      assignmentScope: clientEventService.assignmentScope.CUSTOMER,
      eventGroup: clientEventService.eventGroup.CUSTOMER,
      eventName: canCreateProject
        ? clientEventService.eventName.CUSTOMER_TEAM_ALLOW_USER_TO_CREATE_PROJECT
        : clientEventService.eventName.CUSTOMER_TEAM_DISALLOW_USER_TO_CREATE_PROJECT,
      uid: kaeplaUser?.uid,
      customerId: customer.id,
      resellerId: customer.resellerId,
      payload: {
        affectedUserUid: uid,
      },
    });
  };

  const resendEmailInvite = (user: KaeplaUser) => {
    const customerAssignment = customerAssignments.find(
      (r) => r.customerId === customer.id && r.uid === user.uid,
    );
    if (!customerAssignment) return;
    void resendInvite({ params: { customerAssignmentId: customerAssignment.id } });
  };

  if (loading) return <LinearProgress />;

  return (
    <Card
      data-testid="customer-card"
      data-customerid={customer.id}
      elevation={selectedCustomer?.id === customer.id || hover ? 8 : 2}
      onFocus={() => {
        setHover(true);
      }}
      onBlur={() => {
        setHover(false);
      }}
      sx={{
        height: '100%',
      }}
    >
      <CardHeader
        avatar={<CustomerAvatar customer={customer} />}
        title={
          <Tooltip title={customer.id}>
            <Box data-testid="customer-name">{customer.name}</Box>
          </Tooltip>
        }
        subheader={<Box data-testid="customer-appname">{customer.appName}</Box>}
        action={
          <IconButton
            data-testid="customer-menu-toggle"
            edge="end"
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              handleMenuClick(event);
            }}
          >
            <MoreVertIcon data-testid="customer-menu-icon" />
          </IconButton>
        }
      />
      <CardContent sx={{ pt: 0, pb: 0 }}>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
          <Grid size={12} />
          <Grid size={12}>
            {reseller && (
              <IconButton size="small" aria-label="settings" edge="start">
                <ResellerAvatar
                  title={reseller.name}
                  icon={reseller.iconBase64}
                  small
                  id={reseller.id}
                />
              </IconButton>
            )}
            <Chip
              label={`${pluralize('project', customer.projectCount, true)}`}
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid size={12}>
            <Typography variant="caption">Team</Typography>
            <Divider />
          </Grid>
          <Grid size={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              // divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
              sx={{ pl: 1.5 }}
            >
              <AvatarGroup max={4} spacing="small" slotProps={avatarGroupSlot}>
                {customerTeam.map((customerUser) => (
                  <UserAvatar
                    key={customerUser.uid}
                    user={customerUser}
                    self={customer.createdBy === customerUser.uid}
                    lastInviteSent={lastInviteSent(customerUser)}
                    removeCallback={
                      wasAssignedByMe(customerUser) || kaeplaAssignment
                        ? removeTeamMemberFromCustomer
                        : undefined
                    }
                    resendInviteCallback={resendEmailInvite}
                  >
                    <Box>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={
                              customerAssignments.find(
                                (r) => r.customerId === customer.id && r.uid === customerUser.uid,
                              )?.canCreateProject
                            }
                            onChange={() => {
                              toggleUserCanCreateProject({
                                customerId: customer.id,
                                uid: customerUser.uid,
                                canCreateProject: !customerAssignments.find(
                                  (r) => r.customerId === customer.id && r.uid === customerUser.uid,
                                )?.canCreateProject,
                              });
                            }}
                          />
                        }
                        label="allow to create projects"
                      />
                    </Box>
                  </UserAvatar>
                ))}
              </AvatarGroup>
              <AddTeamMemberToggle callback={openAddTeamMemberDialog} />
              <AddTeamMember open={openAddTeamMember} onClose={closeAddTeamMemberDialog} />
            </Stack>
          </Grid>
          <Grid size={12}>
            <Typography variant="caption">Access Domains</Typography>
            <Divider />
          </Grid>
          <Grid size={12}>
            <CustomerDomains customer={customer} />
          </Grid>

          <CustomerDeleteDialog
            openDelete={openDelete}
            handleCloseDelete={handleCloseDelete}
            customer={customer}
            reseller={reseller}
          />

          <CustomerRenameDialog
            openRename={openRename}
            handleCloseRename={handleCloseRename}
            customer={customer}
            reseller={reseller}
          />

          <CustomerCardMenu
            anchorEl={anchorElement}
            open={!!anchorElement}
            canRename={customer.createdBy === kaeplaUser?.uid || kaeplaAssignment !== undefined}
            canDelete={customer.createdBy === kaeplaUser?.uid || kaeplaAssignment !== undefined}
            canManageConnectors={userHasCustomerAssignment}
            onClose={handleMenuClose}
            handleClickOpenDelete={handleClickOpenDelete}
            handleClickOpenRename={handleClickOpenRename}
            customer={customer}
          />
        </Grid>
      </CardContent>
    </Card>
  );
};
