import { Card, CardContent } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { projectState } from '../../../../../services/recoil/nonpersistent/projectState.js';

import { ProjectInfo } from './features/ProjectInfo.js';

interface Options {
  disableSimulationSelect?: boolean;
  disableFilters?: boolean;
}

export const ProjectSummary = ({ disableSimulationSelect, disableFilters }: Options) => {
  const project = useRecoilValue(projectState);

  if (!project) {
    return null;
  }

  return (
    <Card
      data-testid="project-summary"
      sx={{
        minWidth: 275,
        minHeight: 320,
        height: '100%',
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <ProjectInfo
          disableSimulationSelect={disableSimulationSelect}
          disableFilters={disableFilters}
          title={project.name}
        />
      </CardContent>
    </Card>
  );
};
