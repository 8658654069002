import {
  KaeplaProject,
  KaeplaQueryType,
  KaeplaPerspectiveBlock,
  MatrixTimeSlice,
  KaeplaSimulation,
  KaeplaDataSnapshot,
  KaeplaApiParameters,
} from '@kaepla/types';

interface Options {
  project: KaeplaProject;
  queryType: KaeplaQueryType;
  currentScopePath: string[];
  filterSql?: string;
  filterActive: boolean;
  block: KaeplaPerspectiveBlock;
  timeSlice?: MatrixTimeSlice;
  simulation?: Partial<KaeplaSimulation>;
  snapshot?: Partial<KaeplaDataSnapshot>;
}

export const chartParameters = ({
  project,
  queryType,
  currentScopePath,
  filterSql,
  filterActive,
  block,
  timeSlice,
  simulation,
  snapshot,
}: Options) => {
  const parameters: KaeplaApiParameters = {
    s: 'chartParameters',
    q: queryType,
    p: currentScopePath,
    projectId: project.id,
    info: JSON.stringify(block),
  };

  if (simulation) {
    parameters.simulationId = simulation.id;
  }

  if (snapshot) {
    parameters.snapshotId = snapshot.id;
  }

  if (timeSlice) {
    parameters.timeSlice = timeSlice;
  }

  if (filterActive) {
    parameters.filterSql = filterSql;
  }

  return parameters;
};
