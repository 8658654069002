import { useQuery } from '@tanstack/react-query';

import { getProject, getProjectPath } from '../../repository/Project';
import { useCreateQueryKey } from '../useCreateQueryKey';

interface UseQueryOptions {
  refetchInterval?: number;
}

export function useProjectQueryFind(id?: string, options?: UseQueryOptions) {
  const { queryKey } = useCreateQueryKey(getProjectPath, { id });
  const query = useQuery({
    queryKey,
    queryFn: () => {
      if (!id) {
        return;
      }
      return getProject(id);
    },
    refetchInterval: options?.refetchInterval,
    enabled: !!id,
  });
  return query;
}
