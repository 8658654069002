import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { Box, Checkbox, IconButton, Paper, Stack, Toolbar, Typography } from '@mui/material';
import { KaeplaTargetsFigure, MatrixTimeSlice } from '@kaepla/types';

import { formatDateWithTimeSlice } from '../../helpers/formatDateWithTimeSlice';
import { formatValueWithCurrency } from '../../helpers/formatValueWithCurrency';
import {
  matrixDataColor,
  targetsDataColor,
  targetsDataColorLowerLight,
  targetsDataColorUpperLight,
} from '../defaults';

import { targetsColumnWidth } from './defaults.js';


interface Options {
  targetFigure: KaeplaTargetsFigure;
  deleteTargetFigure: (_targetFigure: KaeplaTargetsFigure) => void;
  editable: boolean;
  batchActionsEnabled: boolean;
  setSelectedTargetFigures: React.Dispatch<React.SetStateAction<KaeplaTargetsFigure[]>>;
}

export const TargetFigureLine = ({
  targetFigure,
  deleteTargetFigure,
  editable,
  batchActionsEnabled,
  setSelectedTargetFigures,
}: Options) => {
  return (
    <Box
      component={Paper}
      square
      elevation={0}
      borderBottom={1}
      borderColor="lightgray"
      sx={{ px: 2 }}
    >
      {/* <Toolbar disableGutters variant="dense">
        {JSON.stringify(targetFigure)}
      </Toolbar> */}
      <Toolbar disableGutters variant="dense">
        <Typography variant="body1" sx={{ width: targetsColumnWidth }}>
          {formatDateWithTimeSlice(
            targetFigure.validAsOf,
            targetFigure.timeSlice as MatrixTimeSlice,
          )}
        </Typography>
        <Typography variant="body1" sx={{ width: targetsColumnWidth, color: matrixDataColor }}>
          {formatValueWithCurrency(
            targetFigure.originalValue,
            targetFigure.currency,
            targetFigure.decimalPositions,
          )}
        </Typography>
        <Typography variant="body1" sx={{ width: targetsColumnWidth, color: targetsDataColor }}>
          {formatValueWithCurrency(
            targetFigure.absoluteValue,
            targetFigure.currency,
            targetFigure.decimalPositions,
          )}
        </Typography>
        <Stack sx={{ my: 1 }} spacing={1}>
          <Typography
            variant="caption"
            sx={{ width: targetsColumnWidth, color: targetsDataColorUpperLight }}
          >
            {formatValueWithCurrency(
              targetFigure.absoluteValue +
                Math.abs(targetFigure.absoluteValue - targetFigure.originalValue) * 0.5,
              targetFigure.currency,
              targetFigure.decimalPositions,
            )}
          </Typography>
          <Typography
            variant="caption"
            sx={{ width: targetsColumnWidth, color: targetsDataColorLowerLight }}
          >
            {formatValueWithCurrency(
              targetFigure.absoluteValue -
                Math.abs(targetFigure.absoluteValue - targetFigure.originalValue) * 0.25,
              targetFigure.currency,
              targetFigure.decimalPositions,
            )}
          </Typography>
        </Stack>
        <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
        {batchActionsEnabled && (
          <Checkbox
            size="small"
            edge="end"
            onChange={() => {
              setSelectedTargetFigures((previous) => [...previous, targetFigure]);
            }}
          />
        )}
        {!batchActionsEnabled && (
          <IconButton
            edge="end"
            aria-label="delete"
            color="primary"
            size="small"
            disabled={!editable}
            sx={{ mr: -1 }}
            onClick={() => {
              deleteTargetFigure(targetFigure);
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        )}
      </Toolbar>
    </Box>
  );
};
