import { Box, Grid2 as Grid, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import { useRecoilValue } from 'recoil';

import { projectState } from '../../../../../../services/recoil/nonpersistent/projectState.js';
import { selectedCustomerState } from '../../../../../../services/recoil/nonpersistent/selectedCustomer.js';
import { CustomerAvatar } from '../../../../../features/CustomerAvatar.js';
import { camelToSentenceCase } from '../../../../../helpers/camelToSentenceCase.js';

import { ProjectInfoNavigation } from './ProjectInfoNavigation.js';
import { ProjectTeam } from './ProjectTeam.js';

interface Options {
  title: string;
  disableSimulationSelect?: boolean;
  disableFilters?: boolean;
}

export const ProjectInfo = ({ title, disableSimulationSelect, disableFilters }: Options) => {
  const project = useRecoilValue(projectState);
  const selectedCustomer = useRecoilValue(selectedCustomerState);

  return (
    <Grid container spacing={0}>
      <Grid size={12}>
        <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
          {selectedCustomer && <CustomerAvatar customer={selectedCustomer} size={30} />}
          <Typography
            sx={{
              fontSize: 20,
              color: grey[900],
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
            color="text.secondary"
            gutterBottom
          >
            {title ?? project.name}
          </Typography>
          {project.disabledReason && (
            <Typography
              color="error"
              sx={{
                fontSize: 20,
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                pl: 2,
                marginLeft: 'auto !important',
              }}
            >
              {camelToSentenceCase(project.disabledReason)}
            </Typography>
          )}
        </Stack>
      </Grid>
      <Grid size={12}>
        <ProjectTeam project={project} />
      </Grid>
      <Grid size={12}>
        <Box sx={{ m: 1 }} />
      </Grid>
      <ProjectInfoNavigation
        disableSimulationSelect={disableSimulationSelect}
        disableFilters={disableFilters}
      />
      <Grid size={12}>
        <Box sx={{ m: 1 }} />
      </Grid>
    </Grid>
  );
};
