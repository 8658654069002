import { Alert, Box, Button, Grid2 as Grid, Typography } from '@mui/material';
import { MultiFactorInfo, PhoneMultiFactorInfo, RecaptchaVerifier } from 'firebase/auth';
import { Dispatch, SetStateAction, useEffect } from 'react';

import { useAuth } from '../../../../../AuthReactProvider.js';
import { mfaLog } from '../../../../helpers/logger.js';

import { AuthenticationFactor } from './AuthenticationFactor.js';
import { SecondFactor } from './SecondFactor.js';
import { Step0ReAuthenticateWithPassword } from './Step0ReAuthenticateWithPassword.js';
import { Step1AddPhoneNumber } from './Step1AddPhoneNumber.js';
import { VerifyNewFactorWithCode } from './VerifyNewFactorWithCode.js';

interface Options {
  addFactor: boolean;
  editable: boolean;
  enrolledFactors: MultiFactorInfo[];
  isReAuthenticated: boolean;
  passwordRequired: boolean;
  phoneNumber: string;
  recaptchaVerifier: RecaptchaVerifier | undefined;
  setAddFactor: Dispatch<SetStateAction<boolean>>;
  setEditable: Dispatch<SetStateAction<boolean>>;
  setEnrolledFactors: Dispatch<SetStateAction<MultiFactorInfo[]>>;
  setIsReAuthenticated: Dispatch<SetStateAction<boolean>>;
  setPasswordRequired: Dispatch<SetStateAction<boolean>>;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  setVerificationCodeRequired: Dispatch<SetStateAction<boolean>>;
  setVerificationId: Dispatch<SetStateAction<string>>;
  verificationCodeRequired: boolean;
  verificationId: string;
  setActiveStep: Dispatch<SetStateAction<number | undefined>>;
}

export const MfaActions = ({
  addFactor,
  editable,
  enrolledFactors,
  isReAuthenticated,
  passwordRequired,
  phoneNumber,
  recaptchaVerifier,
  setAddFactor,
  setEditable,
  setEnrolledFactors,
  setIsReAuthenticated,
  setPasswordRequired,
  setPhoneNumber,
  setVerificationCodeRequired,
  setVerificationId,
  verificationCodeRequired,
  verificationId,
  setActiveStep,
}: Options) => {
  const { user, secondFactorRequired } = useAuth();

  useEffect(() => {
    mfaLog.log('useEffect MfaActions');
    // deactivate the stepper
    if (!editable) {
      mfaLog.log('activate Stepper: step 0');
      setActiveStep(undefined);
      setAddFactor(false);
      setIsReAuthenticated(false);
    }

    // activate step 1
    if (!addFactor && isReAuthenticated && enrolledFactors.length === 0) {
      mfaLog.log('activate step 1');
      setAddFactor(true);
      setActiveStep(1);
    }

    // activate step 2
    if (verificationCodeRequired && enrolledFactors.length === 0) {
      mfaLog.log('activate step 2');
      setAddFactor(true);
      setActiveStep(2);
    }
  }, [
    addFactor,
    editable,
    enrolledFactors.length,
    isReAuthenticated,
    setActiveStep,
    setAddFactor,
    setIsReAuthenticated,
    verificationCodeRequired,
  ]);

  if (!user) return null;

  // activate stepper with step 0
  if (enrolledFactors.length === 0 && !editable) {
    mfaLog.log('No enrolled Factors');
    return (
      <Grid
        container
        spacing={3}
        // direction="column"
        // justifyContent="center"
        alignItems="center"
      >
        <Grid size={12}>
          <Alert data-testid="mfa-info" severity="info">
            <Typography variant="caption">
              You do not have any additional authentication factor enabled. For most features of
              this application, a second factor is required.
            </Typography>
            <Box mt={2} />
            <Typography variant="body2">
              Start to set up your phone number as a second factor now!
            </Typography>
          </Alert>
        </Grid>
        <Grid size={12}>
          <Button
            data-testid="mfa-button-start"
            variant="contained"
            onClick={() => {
              setEditable(true);
              setActiveStep(0);
            }}
          >
            start
          </Button>
        </Grid>
      </Grid>
    );
  }

  // Step 1: add a phone number as the second factor
  if (addFactor && !passwordRequired && !verificationCodeRequired) {
    return (
      <Step1AddPhoneNumber
        phoneNumber={phoneNumber}
        recaptchaVerifier={recaptchaVerifier}
        setAddFactor={setAddFactor}
        setPasswordRequired={setPasswordRequired}
        setPhoneNumber={setPhoneNumber}
        setVerificationCodeRequired={setVerificationCodeRequired}
        setVerificationId={setVerificationId}
        setEditable={setEditable}
      />
    );
  }

  // id we need a second factor for ReAuthenticateWithPassword
  if (editable && !isReAuthenticated && secondFactorRequired) {
    return (
      <SecondFactor
        recaptchaVerifier={recaptchaVerifier}
        setIsReAuthenticated={setIsReAuthenticated}
        enrolledFactors={enrolledFactors}
        setEditable={setEditable}
      />
    );
  }

  // we always get a fresh authentication token befor we change MFA
  if (editable && !isReAuthenticated) {
    mfaLog.log('Start re-authentication');
    return (
      <Step0ReAuthenticateWithPassword
        setIsReAuthenticated={setIsReAuthenticated}
        setEditable={setEditable}
      />
    );
  }

  // verify the new phone number with code
  if (verificationCodeRequired && verificationId) {
    return (
      <VerifyNewFactorWithCode
        setAddFactor={setAddFactor}
        setEditable={setEditable}
        setEnrolledFactors={setEnrolledFactors}
        setVerificationCodeRequired={setVerificationCodeRequired}
        verificationId={verificationId}
      />
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid size={12}>
        <Alert data-testid="mfa-info" severity="info">
          <Typography variant="body2">
            To remove your phone number, you need to unlock fist.
          </Typography>
        </Alert>
      </Grid>
      {enrolledFactors.map((factor) => (
        <Grid size={12} key={factor.factorId}>
          <AuthenticationFactor
            factor={factor as PhoneMultiFactorInfo}
            editable={isReAuthenticated}
            setEnrolledFactors={setEnrolledFactors}
            setEditable={setEditable}
          />
        </Grid>
      ))}
    </Grid>
  );
};
