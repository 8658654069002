import { ErpOrder } from '../types/ErpOrder';
import { MatchType } from '../types/MatchType';

import { One2OneMatchDetailRenderer } from './MatchRenderers/One2OneMatchDetailRenderer';
import { OneAndN2ZeroMatchDetailRenderer } from './MatchRenderers/OneAndN2ZeroMatchDetailRenderer';

interface Options {
  erpOrder: ErpOrder;
  setHighlight: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ErpOrdersMatchDetail = ({ erpOrder, setHighlight }: Options) => {
  if (!erpOrder.matchType) return null;

  return renderMatchType(erpOrder.matchType);

  function renderMatchType(matchType: MatchType) {
    switch (matchType) {
      case MatchType.ONE2ZERO: {
        return <OneAndN2ZeroMatchDetailRenderer erpOrder={erpOrder} />;
      }
      case MatchType.N2ZERO: {
        return <OneAndN2ZeroMatchDetailRenderer erpOrder={erpOrder} />;
      }
      case MatchType.ZERO2N: {
        return <OneAndN2ZeroMatchDetailRenderer erpOrder={erpOrder} />;
      }
      case MatchType.ONE2ONE: {
        return <One2OneMatchDetailRenderer erpOrder={erpOrder} setHighlight={setHighlight} />;
      }
      case MatchType.N2ONE: {
        return <One2OneMatchDetailRenderer erpOrder={erpOrder} setHighlight={setHighlight} />;
      }
      default: {
        return null;
      }
    }
  }
};
