import { Button, Grid2 as Grid, TextField } from '@mui/material';
import { RecaptchaVerifier } from 'firebase/auth';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';

import { useAuth } from '../../../../../AuthReactProvider.js';

import { getVerificationCode } from './getVerificationCode.js';

interface Options {
  phoneNumber: string;
  recaptchaVerifier: RecaptchaVerifier | undefined;
  setAddFactor: Dispatch<SetStateAction<boolean>>;
  setEditable: Dispatch<SetStateAction<boolean>>;
  setPasswordRequired: Dispatch<SetStateAction<boolean>>;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  setVerificationCodeRequired: Dispatch<SetStateAction<boolean>>;
  setVerificationId: Dispatch<SetStateAction<string>>;
}
export const Step1AddPhoneNumber = ({
  phoneNumber,
  recaptchaVerifier,
  setAddFactor,
  setEditable,
  setPasswordRequired,
  setPhoneNumber,
  setVerificationCodeRequired,
  setVerificationId,
}: Options) => {
  const { user } = useAuth();
  return (
    <Grid size={12}>
      <Grid container spacing={2} sx={{ maxWidth: 300 }}>
        <Grid size={12}>
          <TextField
            label="Phone Number"
            variant="standard"
            name="factor-phone-number"
            fullWidth
            value={phoneNumber}
            autoComplete="off"
            autoFocus
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setPhoneNumber(event.target.value);
            }}
            inputProps={{
              'data-testid': 'mfa-phone-number',
              autoComplete: 'off',
            }}
          />
        </Grid>
        <Grid size={6}>
          <Button
            size="small"
            data-testid="mfa-button-cancel"
            onClick={() => {
              setAddFactor(false);
              setVerificationCodeRequired(false);
              setEditable(false);
            }}
          >
            cancel
          </Button>
        </Grid>
        <Grid size={6} container justifyContent="flex-end">
          <Button
            size="small"
            variant="contained"
            data-testid="mfa-button-add"
            onClick={() => {
              void getVerificationCode({
                recaptchaVerifier,
                user,
                phoneNumber,
                setPasswordRequired,
                setVerificationCodeRequired,
                setVerificationId,
              });
            }}
          >
            Add
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
