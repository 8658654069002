import { Stack } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { UserAvatar } from '../../../Frontend/features/UserAvatar';
import { Icon } from '../../../primitive/Icon/Icon';
import { knownUsersState } from '../../../services/recoil/persistent/knownUsersState';

interface TitleCreatedByProperties {
  createdBy?: string;
}

export function ColumnCreatedBy(properties: TitleCreatedByProperties) {
  const knownUsers = useRecoilValue(knownUsersState);
  if (!properties.createdBy) return;
  const user = knownUsers.find((k) => k.uid === properties.createdBy);
  if (user) {
    return (
      <Stack spacing={2} direction={'row'} alignItems={'center'}>
        <UserAvatar user={user} size={25} />
        <p>{user.displayName}</p>
      </Stack>
    );
  }
  if (properties.createdBy === 'kaepla-admin') {
    return (
      <Icon center={true} name="AdminIcon" titleAccess={properties.createdBy} fontSize="medium" />
    );
  }
  return (
    <Icon center={true} name="AutomatedIcon" titleAccess={properties.createdBy} fontSize="medium" />
  );
}
