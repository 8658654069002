import { clientEventService } from '@kaepla/events';
import { KaeplaSimulation } from '@kaepla/types';
import DeleteIcon from '@mui/icons-material/Delete';
import PerspectivesIcon from '@mui/icons-material/QueryStatsOutlined';
import FavoriteIcon from '@mui/icons-material/StarRounded';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Link,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { grey, yellow } from '@mui/material/colors';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { useAuth } from '../../../../AuthReactProvider';
import { useUserPreferences } from '../../../../UserPreferencesProvider';
import { deleteSimulation } from '../../../../services/firestore/deleteSimulation.js';
import { toggleSimulationFavorite } from '../../../../services/firestore/toggleSimulationFavorite';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState.js';
import { scopePathsState } from '../../../../services/recoil/nonpersistent/scopePathsState';
import { simulationFavoritesState } from '../../../../services/recoil/nonpersistent/simulationFavoritesState';
import { simulationState } from '../../../../services/recoil/nonpersistent/simulationState';
import { projectAssignmentsState } from '../../../../services/recoil/nonpersistent/userAssignmentState';
import { currentScopePathState } from '../../../../services/recoil/persistent/currentScopePathState';
import { kaeplaAssignmentState } from '../../../../services/recoil/persistent/kaeplaAssignmentState.js';
import { knownUsersState } from '../../../../services/recoil/persistent/knownUsersState.js';
import { UserAvatar } from '../../../features/UserAvatar.js';
import { HumanReadableTimestampType, convertTimestamp } from '../../../helpers/convertTimestamp.js';
import { scopePathSimplified } from '../../../helpers/scopePathSimplified';
import { agoColor } from '../../ExperimentalAi/helpers/agoColor';
import { checkPathIsInScope } from '../../Scopes/helpers/checkPathIsInScope.js';
import { perspectivesColor } from '../../defaults.js';
import { isSimulationOutdated } from '../SimulationEditor/_helpers/isSimulationOutdated';

interface Options {
  simulation: KaeplaSimulation;
  own?: boolean;
}

export const SimulationCard = ({ simulation, own }: Options) => {
  const theme = useTheme();
  const { kaeplaUser } = useAuth();
  const navigate = useNavigate();
  const { setPreferences } = useUserPreferences();
  const project = useRecoilValue(projectState);
  const knownUsers = useRecoilValue(knownUsersState);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const simulationFavorites = useRecoilValue(simulationFavoritesState);
  const projectAssignments = useRecoilValue(projectAssignmentsState);

  // get & set
  const [currentScopePath, setCurrentScopePath] = useRecoilState(currentScopePathState);
  const [scopePaths, setScopePaths] = useRecoilState(scopePathsState);
  // set
  const setSimulation = useSetRecoilState(simulationState);

  const owner = knownUsers.find((user) => user.uid === simulation.createdBy);
  const [hover, setHover] = useState(false);
  const isFavorite = () => {
    return simulationFavorites
      .filter((s) => s.isFavorite)
      .some((s) => s.simulationId === simulation.id);
  };

  const isInScope = () => {
    return checkPathIsInScope({
      project,
      projectAssignments,
      scopePath: simulation.scopePath,
    });
  };

  const elevate = () => {
    if (hover) return 8;
    if (project.currentImportId) return 0;
    return 2;
  };

  return (
    <Card
      elevation={elevate()}
      sx={{
        cursor: project.currentImportId ? 'inherit' : 'pointer',
      }}
      onClick={() => {
        if (!kaeplaUser?.uid) return;
        if (project.currentImportId) return;

        setSimulation(simulation);
        setPreferences({ lastSimulationId: simulation.id });

        void toggleSimulationFavorite({
          uid: kaeplaUser.uid,
          simulation,
          scopePath: currentScopePath,
          isFavorite: true,
        });
        navigate(`/Simulation/${project.id}/${simulation.id}`);
      }}
      onFocus={() => {
        if (project.currentImportId) return;
        setHover(true);
      }}
      onBlur={() => {
        if (project.currentImportId) return;
        setHover(false);
      }}
      onMouseEnter={() => {
        if (project.currentImportId) return;
        setHover(true);
      }}
      onMouseLeave={() => {
        if (project.currentImportId) return;
        setHover(false);
      }}
    >
      <CardHeader
        title={simulation.name}
        avatar={owner && <UserAvatar user={owner} />}
        subheader={
          simulation.simulatedAt
            ? `updated ${convertTimestamp(
                simulation.simulatedAt,
                HumanReadableTimestampType.timeago,
              )}`
            : 'did not run yet'
        }
      />
      <CardContent sx={{ minHeight: 150 }}>
        <PerspectivesIcon
          sx={{ color: perspectivesColor, fontSize: 14, mr: 0.5, verticalAlign: 'middle' }}
        />
        <Tooltip title={simulation.simulationMatrix}>
          <Link
            variant="caption"
            underline="hover"
            color="inherit"
            sx={{
              opacity: isInScope() ? 1 : 0.6,
            }}
            onClick={(event) => {
              event.stopPropagation();
              if (!kaeplaUser?.uid) return;
              if (project.currentImportId) return;
              if (!isInScope()) {
                return;
              }
              // TODO: ask user if it is okay to switch the scope if we're not already here
              setCurrentScopePath(simulation.scopePath);
              const newScopePaths = { ...scopePaths };
              newScopePaths[simulation.projectId] = simulation.scopePath;
              setScopePaths(newScopePaths);

              setSimulation(simulation);
              setPreferences({ lastSimulationId: simulation.id });

              void toggleSimulationFavorite({
                uid: kaeplaUser.uid,
                simulation,
                scopePath: currentScopePath,
                isFavorite: true,
              });

              void clientEventService.createEvent({
                assignmentScope: clientEventService.assignmentScope.PROJECT,
                eventGroup: clientEventService.eventGroup.ASSIGNMENTS,
                eventName: clientEventService.eventName.ASSIGNMENT_SELECT_ASSIGNMENT,
                uid: kaeplaUser.uid,
                projectId: project.id,
                customerId: project.customerId,
                resellerId: project.resellerId,
                simulationId: simulation.id,
                scopePath: simulation.scopePath,
              });
              navigate('/Perspective');
            }}
          >
            {[project.name, ...scopePathSimplified(simulation.scopePath)].join(' ➔ ')}
          </Link>
        </Tooltip>
        {isSimulationOutdated(project, simulation) &&
          project.lastUpdatedAt &&
          simulation.simulatedAt && (
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ mt: 1, color: agoColor(theme, simulation.simulatedAt, project.lastUpdatedAt) }}
            >
              outdated
            </Typography>
          )}
      </CardContent>
      <CardActions sx={{ m: 0, pt: 0 }}>
        <IconButton
          aria-label="add to favorites"
          disabled={!!project.currentImportId}
          onClick={(event) => {
            if (!kaeplaUser?.uid) return;
            event.stopPropagation();
            void toggleSimulationFavorite({
              uid: kaeplaUser.uid,
              simulation,
              scopePath: currentScopePath,
              isFavorite: !isFavorite(),
            });
          }}
        >
          <FavoriteIcon sx={{ color: isFavorite() ? yellow[800] : grey[400] }} />
        </IconButton>
        <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
        {(own === true || kaeplaAssignment) && (
          <Tooltip title={simulation.id}>
            <IconButton
              sx={{ alignSelf: 'flex-end' }}
              color="primary"
              disabled={!!project.currentImportId}
              onClick={(event) => {
                const _delete = async () => {
                  event.stopPropagation();
                  await deleteSimulation({ project, simulation });
                  navigate(`/Simulations/${project.id}`);
                };
                void _delete();
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </CardActions>
    </Card>
  );
};
