import { getApp } from 'firebase/app';
import { doc, DocumentData, getFirestore, updateDoc } from 'firebase/firestore';
import { KaeplaReseller } from '@kaepla/types';

interface Options {
  reseller: Partial<KaeplaReseller>;
}

export const updateReseller = async ({ reseller }: Options) => {
  if (!reseller.id) return;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `resellers/${reseller.id}`;
  const firestoreDocumentReference = doc(firestoreDatabase, fireStorePath);
  await updateDoc(firestoreDocumentReference, reseller as DocumentData);
};
