import { Box, Button, Stack } from '@mui/material';
import { Dispatch, MutableRefObject, SetStateAction, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { KaeplaPerspectiveBlock } from '@kaepla/types';

import { updatePerspectiveBlock } from '../../../../../../services/firestore/updatePerspectiveBlock.js';
import { perspectiveState } from '../../../../../../services/recoil/nonpersistent/perspectiveState.js';
import { projectState } from '../../../../../../services/recoil/nonpersistent/projectState.js';

import { ChartEditorFromType } from './ChartEditorFromType.js';
import { BlockTypeSelect } from './BlockTypeSelect.js';

interface Options {
  chartReference: MutableRefObject<undefined>;
  block: KaeplaPerspectiveBlock;
  blockNumber: number;
  setEditing: Dispatch<SetStateAction<boolean>>;
}

export const ChartEditor = ({ block, setEditing, blockNumber }: Options) => {
  const project = useRecoilValue(projectState);
  const [perspective, setPerspective] = useRecoilState(perspectiveState);
  const [blockType, setBlockType] = useState(block.type);
  const [aggregation, setAggregation] = useState(block.aggregation);
  const [valueDimension, setValueDimension] = useState(block.valueDimension);
  const [timeDimension, setTimeDimension] = useState(block.timeDimension);
  const [splitDimension, setSplitDimension] = useState(block.splitDimension);
  const [aggregationDimension, setAggregationDimension] = useState(block.aggregationDimension);

  const updateBlock = async () => {
    const newBlock: KaeplaPerspectiveBlock = {
      ...block,
      aggregation,
      valueDimension,
      timeDimension,
      aggregationDimension,
      type: blockType,
    };
    if (splitDimension) {
      newBlock.splitDimension = splitDimension;
    } else {
      delete newBlock.splitDimension;
    }
    const newPerspective = await updatePerspectiveBlock({
      project,
      perspective,
      block: newBlock,
      blockNumber,
    });
    setPerspective(newPerspective);
  };

  return (
    <Box sx={{ p: 2 }}>
      <BlockTypeSelect setBlockType={setBlockType} blockType={blockType} />
      <ChartEditorFromType
        block={block}
        setAggregation={setAggregation}
        setValueDimension={setValueDimension}
        setAggregationDimension={setAggregationDimension}
        setTimeDimension={setTimeDimension}
        setSplitDimension={setSplitDimension}
      />
      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <Button
          variant="outlined"
          onClick={() => {
            setEditing(false);
          }}
        >
          cancel
        </Button>
        <Button
          variant="contained"
          disabled={!blockType}
          onClick={() => {
            void updateBlock();
            setEditing(false);
          }}
        >
          save
        </Button>
      </Stack>
    </Box>
  );
};
