import { getApp } from 'firebase/app';
import { doc, DocumentData, getFirestore, updateDoc } from 'firebase/firestore';
import { KaeplaProject, KaeplaSimulation } from '@kaepla/types';

import { mapUndefinedToNullValue } from './mapUndefinedToNullValue.js';

interface Options {
  project: KaeplaProject;
  simulation: Partial<KaeplaSimulation>;
  scopePath: string[];
}

export const updateSimulationWithScope = async ({ project, scopePath, simulation }: Options) => {
  if (!simulation?.id) return;
  // console.log(simulation);
  // if (true === true) return;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects/${project.id}/simulations/${simulation.id}`;
  const simulationScope = {
    scopePath,
    scopePathStringified: JSON.stringify(scopePath),
  };
  const firestoreDocumentReference = doc(firestoreDatabase, fireStorePath);

  return updateDoc(
    firestoreDocumentReference,
    mapUndefinedToNullValue({
      ...simulation,
      ...simulationScope,
    }) as DocumentData,
  );
};
