import AskAgainIcon from '@mui/icons-material/ReplayOutlined';
import { Box, Paper, Stack, useTheme } from '@mui/material';
import { blue } from '@mui/material/colors';
import TimeAgo from 'react-timeago';
import { AiQuestionLog } from '@kaepla/types';

import { agoColor } from '../helpers/agoColor';

interface Options {
  aiQuestionLog: AiQuestionLog;
  setCurrentQuestion: React.Dispatch<React.SetStateAction<string>>;
}

export const Question = ({ aiQuestionLog, setCurrentQuestion }: Options) => {
  const theme = useTheme();
  return (
    <Paper
      elevation={0}
      sx={{ backgroundColor: blue[50], p: 2 }}
      onClick={() => {
        setCurrentQuestion(aiQuestionLog.question);
      }}
    >
      <Box>{aiQuestionLog.question}</Box>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        sx={{ fontSize: 12 }}
      >
        {aiQuestionLog?.askedAt && (
          <TimeAgo
            style={{ color: agoColor(theme, aiQuestionLog?.askedAt) }}
            date={aiQuestionLog.askedAt.toDate()}
            max={Number.MAX_SAFE_INTEGER}
          />
        )}{' '}
        <AskAgainIcon sx={{ fontSize: 14, cursor: 'pointer' }} />
      </Stack>
    </Paper>
  );
};
