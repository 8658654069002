import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { CircularProgress } from '@mui/material';
import React from 'react';

export interface GenericButtonProperties extends Omit<LoadingButtonProps, 'color'> {
  target?: string;
  color?: LoadingButtonProps['color'];
}

const GenericButtonComponent: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  GenericButtonProperties
> = (
  { loading = false, startIcon, disabled, color, variant = 'contained', ...properties },
  reference,
) => {
  return (
    <LoadingButton
      {...properties}
      ref={reference}
      variant={variant}
      color={color}
      loading={loading}
      loadingPosition={loading || startIcon ? 'start' : undefined}
      startIcon={loading ? <CircularProgress size={24} /> : startIcon}
      disabled={loading ? true : disabled}
    />
  );
};

export const GenericButton = React.forwardRef(GenericButtonComponent);
