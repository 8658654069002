import { KaeplaConnector } from '@kaepla/types';
import { Divider, Grid2 as Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { addFirestoreCollectionListener } from '../../../services/firestore/addFirestoreCollectionListener';
import { knownCustomersState } from '../../../services/recoil/nonpersistent/knownCustomersState';
import { selectedCustomerState } from '../../../services/recoil/nonpersistent/selectedCustomer';
import { Layout } from '../../Layout/Layout';
import { CustomerAvatar } from '../../features/CustomerAvatar';

import { AvailableConnectors } from './components/AvailableConnectors';
import { ConnectorCard } from './components/ConnectorCard';
import { CreateConnectorFab } from './components/CreateConnectorFab';
import { SetupWizard } from './components/SetupWizard';
import { KaeplaConnectorType } from './types';

export const Connectors = () => {
  const { customerId } = useParams();
  const [selectedCustomer, setSelectedCustomer] = useRecoilState(selectedCustomerState);
  const knownCustomers = useRecoilValue(knownCustomersState);

  const [newConnector, setNewConnector] = useState(false);
  const [connectorType, setConnectorType] = useState<KaeplaConnectorType>();
  const [connectors, setConnectors] = useState<KaeplaConnector[]>([]);
  const [connectorForEdit, setConnectorForEdit] = useState<KaeplaConnector>();

  useEffect(() => {
    if (!selectedCustomer) return;

    const fireStorePath = `customers/${selectedCustomer.id}/connectors`;
    const unsubscribe = addFirestoreCollectionListener({
      fireStorePath,
      callback: (data) => {
        const _connectorsUpdate = data as KaeplaConnector[];
        setConnectors(_connectorsUpdate);
      },
    });
    return () => {
      unsubscribe();
    };
  }, [selectedCustomer]);

  useEffect(() => {
    if (customerId) {
      const _selectedCustomer = knownCustomers.find((c) => c.id === customerId);
      setSelectedCustomer(_selectedCustomer);
    }
  }, [customerId, knownCustomers, selectedCustomer, setSelectedCustomer]);

  return (
    <Layout>
      <Grid container spacing={1}>
        <Grid size={8}>
          <Typography variant="h1">Connectors for {selectedCustomer?.name}</Typography>
        </Grid>
        <Grid size={4} container justifyContent="flex-end">
          {selectedCustomer && <CustomerAvatar customer={selectedCustomer} />}
        </Grid>
        {connectorType && (
          <Grid size={12}>
            <SetupWizard
              connectorType={connectorType}
              setConnectorType={setConnectorType}
              connectorForEdit={connectorForEdit}
              setConnectorForEdit={setConnectorForEdit}
            />
          </Grid>
        )}
        {newConnector && (
          <AvailableConnectors
            setNewConnectorType={setConnectorType}
            setNewConnector={setNewConnector}
          />
        )}
        {selectedCustomer && connectors.length > 0 && !connectorType && newConnector && (
          <Grid size={12}>
            <Divider sx={{ my: 2 }} />
          </Grid>
        )}
        {selectedCustomer && !newConnector && !connectorType && (
          <CreateConnectorFab setNewConnector={setNewConnector} />
        )}
        {selectedCustomer &&
          !connectorType &&
          connectors.map((connector) => (
            <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }} key={connector.id}>
              <ConnectorCard
                connector={connector}
                setConnectorForEdit={setConnectorForEdit}
                setConnectorType={setConnectorType}
                setNewConnector={setNewConnector}
              />
            </Grid>
          ))}
      </Grid>
    </Layout>
  );
};
