import { atom } from 'recoil';
import { MatrixTimeSlice } from '@kaepla/types';

import { localForageEffect } from './localForageEffect.js';

const defaultTimeSliceState: MatrixTimeSlice = MatrixTimeSlice.month;

export const timeSliceState = atom<MatrixTimeSlice>({
  key: 'timeSlice',
  default: defaultTimeSliceState,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  effects_UNSTABLE: [localForageEffect],
});
