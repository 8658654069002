import CloseIcon from '@mui/icons-material/Close';
import { Alert, Grid2 as Grid, IconButton, List, Paper, Typography } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAuth } from '../../../AuthReactProvider.js';
import { Layout } from '../../Layout/Layout.js';

import { AuthProviders } from './features/AuthProviders/AuthProviders.js';
import { ManageMFA } from './features/MFA/ManageMFA.js';
import { ManagePassword } from './features/ManagePassword.js';
import { ResetPassword } from './features/ResetPassword.js';
import { UserAvatarEditor } from './features/UserAvatarEditor.js';

export const User = () => {
  //  User?action=resetPasswordWithLink&token=${token}
  const [authMessage, setAuthMessage] = useState<string | null>(null);
  const { action, token } = useParams();
  const { user } = useAuth();

  if (!user) return null;

  return (
    <Layout>
      <Grid container sx={{ maxWidth: 500 }} spacing={2}>
        <Grid size={12}>
          <List data-testid="user-avatar-editor" component={Paper}>
            <UserAvatarEditor />
          </List>
        </Grid>
        {!action && (
          <>
            <Grid size={12}>
              <ManageMFA />
            </Grid>

            {authMessage && (
              <Grid size={12}>
                <Alert
                  data-testid="pw-reset-info"
                  severity="info"
                  sx={{ mb: 2 }}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setAuthMessage(null);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  <Typography variant="body2">{authMessage}</Typography>
                </Alert>
              </Grid>
            )}

            {user.providerData.find((p) => p.providerId === 'password') && (
              <Grid size={12}>
                <ManagePassword authMessage={authMessage} setAuthMessage={setAuthMessage} />
              </Grid>
            )}
            <Grid size={12} sx={{ whiteSpace: 'pre' }}>
              <AuthProviders setAuthMessage={setAuthMessage} />
            </Grid>
          </>
        )}
        {action === 'resetPasswordWithLink' && token && (
          <Grid size={12}>
            <ResetPassword token={token} />
          </Grid>
        )}
      </Grid>
    </Layout>
  );
};
