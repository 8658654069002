import {
  KaeplaDimensionDetails,
  KaeplaSimulationParameterText,
  KaeplaSimulationRulesetWithParameters,
  KaeplaSimulationTextValue,
} from '@kaepla/types';
import { Box, Button, Grid2 as Grid, Stack } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { matrixFilteredState } from '../../../../services/recoil/nonpersistent/matrixFilteredState';

import { DimensionValueSelect } from './_features/DimensionValueSelect.js';

interface Options {
  parameter: KaeplaSimulationParameterText;
  ruleset: KaeplaSimulationRulesetWithParameters;
  simulationRulesets: KaeplaSimulationRulesetWithParameters[];
  setSimulationRulesets: Dispatch<
    SetStateAction<KaeplaSimulationRulesetWithParameters[] | undefined>
  >;
  setPreview: Dispatch<SetStateAction<boolean>>;
  own: boolean;
  addValue: boolean;
  setAddValue: Dispatch<SetStateAction<boolean>>;
}

export const ParameterText = ({
  parameter,
  ruleset,
  simulationRulesets,
  setSimulationRulesets,
  setPreview,
  own,
  addValue,
  setAddValue,
}: Options) => {
  const matrixFiltered = useRecoilValue(matrixFilteredState);
  const [originalValue, setOriginalValue] = useState('');
  const [simulationValue, setSimulationValue] = useState('');
  const [details, setDetails] = useState<KaeplaDimensionDetails>();

  useEffect(() => {
    if (!matrixFiltered?.details) return;
    const _details = matrixFiltered.details.find((d) => d.columnName === parameter.dimension);
    setDetails(_details);
  }, [matrixFiltered?.details, parameter.dimension]);

  const saveOriginalValue = (_originalValue: string) => {
    setOriginalValue(_originalValue);
  };

  const saveSimulationValue = (_simulationValue: string) => {
    setSimulationValue(_simulationValue);
  };

  const removeValue = (_value: KaeplaSimulationTextValue) => {
    const newRulesets = [...simulationRulesets].map((r) => {
      if (r.id === ruleset.id) {
        const newRuleset = { ...ruleset };
        const _parameter = [...newRuleset.parameters].find(
          (p) => p.dimension === parameter.dimension,
        );

        if (!_parameter) return r;
        const newParameter = { ..._parameter } as KaeplaSimulationParameterText;

        let newValues: KaeplaSimulationTextValue[] = [];

        if (newParameter?.values) {
          newValues = [...newParameter.values].filter(
            (_textValue) => _textValue.key !== `${originalValue}-${simulationValue}`,
          );
        }

        newParameter.values = newValues;

        const newParameters = newRuleset.parameters.filter(
          (p) => p.dimension !== parameter.dimension,
        );
        newParameters.push(newParameter);
        newRuleset.parameters = newParameters.sort((a, b) =>
          a.dimension.localeCompare(b.dimension),
        );
        return newRuleset;
      }
      return r;
    });

    setSimulationRulesets(newRulesets);
    setPreview(true);
  };

  const doAddValue = () => {
    const newRulesets = [...simulationRulesets].map((r) => {
      if (r.id === ruleset.id) {
        const newRuleset = { ...ruleset };
        const _parameter = [...newRuleset.parameters].find(
          (p) => p.dimension === parameter.dimension,
        );

        if (!_parameter) return r;
        const newParameter = { ..._parameter } as KaeplaSimulationParameterText;

        let newValues: KaeplaSimulationTextValue[] = [];

        if (newParameter?.values) {
          newValues = [...newParameter.values].filter(
            (_textValue) => _textValue.key !== `${originalValue}-${simulationValue}`,
          );
        }

        const newValue: KaeplaSimulationTextValue = {
          key: `${originalValue}-${simulationValue}`,
          originalTextValue: originalValue,
          simulatedTextValue: simulationValue,
        };

        newValues.push(newValue);
        newParameter.values = newValues;

        const newParameters = newRuleset.parameters.filter(
          (p) => p.dimension !== parameter.dimension,
        );
        newParameters.push(newParameter);
        newRuleset.parameters = newParameters.sort((a, b) =>
          a.dimension.localeCompare(b.dimension),
        );
        return newRuleset;
      }
      return r;
    });

    setSimulationRulesets(newRulesets);
    setPreview(true);
    setAddValue(false);
  };

  return (
    <Grid container>
      {parameter.values
        ?.filter((v) => v.key !== 'NULL-NULL')
        .map((_value) => (
          <Grid size={12} key={_value.key}>
            <Stack direction="row" spacing={3}>
              <Box sx={{ p: 2 }}>Change</Box>
              <Box sx={{ p: 2 }}>{_value.originalTextValue}</Box>
              <Box sx={{ p: 2 }}>to</Box>
              <Box sx={{ p: 2 }}>{_value.simulatedTextValue}</Box>
              <Box sx={{ flexGrow: 1 }} />
              <Button disabled={!own} onClick={() => removeValue(_value)}>
                remove
              </Button>
            </Stack>
          </Grid>
        ))}
      <Grid size={12}>
        {details && addValue && (
          <Stack direction="row" spacing={2}>
            <Box sx={{ p: 2 }}>Change</Box>
            <Box sx={{ p: 1 }}>
              <DimensionValueSelect
                label="original value"
                value={originalValue}
                dimensionDetails={details}
                callback={saveOriginalValue}
              />
            </Box>
            <Box sx={{ p: 2 }}>to</Box>
            <Box sx={{ p: 1 }}>
              <DimensionValueSelect
                label="simulation value"
                value={simulationValue}
                dimensionDetails={details}
                callback={saveSimulationValue}
              />
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              disabled={!own}
              onClick={() => {
                setAddValue(false);
              }}
            >
              cancel
            </Button>
            <Button disabled={!own || !simulationValue} onClick={doAddValue}>
              Add
            </Button>
          </Stack>
        )}
      </Grid>
    </Grid>
  );
};
