import ReloadUsersIcon from '@mui/icons-material/CachedOutlined';
import ClearIcon from '@mui/icons-material/ClearOutlined';
import { Box, IconButton, InputBase, Paper, Stack, Typography } from '@mui/material';
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { DateTime } from 'luxon';
import { clone } from 'rambda';
import { useMemo, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useDebouncedCallback } from 'use-debounce';
import { KaeplaUser } from '@kaepla/types';

import { useAuth } from '../../../AuthReactProvider.js';
import { getUsersForUser } from '../../../services/api/getUsersForUser.js';
import { applicationState } from '../../../services/recoil/nonpersistent/applicationState.js';
import { kaeplaAssignmentState } from '../../../services/recoil/persistent/kaeplaAssignmentState.js';
import { knownUsersState } from '../../../services/recoil/persistent/knownUsersState.js';
import { Layout } from '../../Layout/Layout.js';

import { UserUpdatedAtCellRenderer } from './renderers/UserUpdatedAtRenderer.js';
import { UserMainCellRenderer } from './renderers/UserMainCellRenderer.js';

export const Users = () => {
  const { kaeplaUser } = useAuth();
  const [app, setApp] = useRecoilState(applicationState);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const [knownUsers, setKnownUsers] = useRecoilState(knownUsersState);
  const [searchTerm, setSearchTerm] = useState<string>();
  const containerStyle = useMemo(() => ({ width: '100%', height: '90%' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const [colDefs] = useState<ColDef<KaeplaUser>[]>([
    { field: 'displayName', autoHeight: true, width: 400, cellRenderer: UserMainCellRenderer },
    {
      field: 'updatedAt',
      autoHeight: true,
      cellRenderer: UserUpdatedAtCellRenderer,
    },
    { field: 'email', hide: true },
    { field: 'uid', hide: true },
    { field: 'acronym', hide: true },
  ]);

  const gridReference = useRef<AgGridReact>(null);

  const onSearchTermChange = useDebouncedCallback(() => {
    gridReference.current!.api.setGridOption('quickFilterText', searchTerm);
  }, 1000);

  const loadUsers = (users: KaeplaUser[]) => {
    setKnownUsers(users);
  };

  const getUsers = () => {
    if (!kaeplaUser) return;
    const newApplication = clone(app);
    const date = DateTime.now();
    const humanReadable = date.toLocaleString(DateTime.DATETIME_MED);
    newApplication.usersLastFetched = humanReadable;
    setApp(newApplication);
    getUsersForUser({ params: { uid: kaeplaUser.uid }, callBack: loadUsers });
  };

  if (!kaeplaUser?.uid) return null;

  return (
    <Layout>
      <Paper>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
          sx={{ p: 2 }}
        >
          <Box border={1} borderRadius={1} borderColor="ActiveBorder">
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              name="search"
              inputProps={{ 'aria-label': 'search google maps' }}
              value={searchTerm}
              onChange={(event) => {
                setSearchTerm(event.target.value);
                if (event.target.value.length < 3) return;
                onSearchTermChange();
              }}
            />
            <IconButton
              type="button"
              sx={{ p: '10px' }}
              aria-label="search"
              onClick={() => {
                setSearchTerm('');
                onSearchTermChange();
              }}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          </Box>
          <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
          <Box>
            {app.usersLastFetched && (
              <Typography variant="caption">
                {knownUsers.length} users, last reload {app.usersLastFetched}
              </Typography>
            )}
            {!kaeplaAssignment && (
              <IconButton color="primary" onClick={getUsers}>
                <ReloadUsersIcon />
              </IconButton>
            )}
          </Box>
        </Stack>
        <Box sx={{ height: 600 }}>
          <div style={containerStyle}>
            <div style={gridStyle} className="ag-theme-quartz">
              <AgGridReact
                ref={gridReference}
                rowData={knownUsers}
                columnDefs={colDefs}
                headerHeight={0}
                autoSizeStrategy={{ type: 'fitCellContents' }}
                cacheQuickFilter={true}
                includeHiddenColumnsInQuickFilter={true}
              />
            </div>
          </div>
        </Box>
      </Paper>
    </Layout>
  );
};
