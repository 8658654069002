import { Route, Routes } from 'react-router-dom';

import { AdminLayout } from './AdminLayout';
import { AiThreadDetail } from './AiThreads/AiThreadDetail';
import { AiThreadList } from './AiThreads/AiThreadList';
import { ImportList } from './Imports/ImportList';

export function AdminRoute() {
  return (
    <Routes>
      <Route path="/" element={<AdminLayout />}>
        <Route index element={<ImportList />} />
        <Route path="/import" element={<ImportList />} />

        <Route path="/ai-thread" element={<AiThreadList />} />
        <Route path="/ai-thread/:threadId" element={<AiThreadDetail />} />
      </Route>
    </Routes>
  );
}
