import {
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  Typography,
  Box,
  InputLabel,
} from '@mui/material';
import { JsonTree, Utils as QbUtils } from '@react-awesome-query-builder/mui';
import { Timestamp } from 'firebase/firestore';
import { clone } from 'rambda';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { KaeplaFilter } from '@kaepla/types';

import { useAuth } from '../../../../../../AuthReactProvider.js';
import { applicationState } from '../../../../../../services/recoil/nonpersistent/applicationState.js';
import { knownProjectFiltersState } from '../../../../../../services/recoil/nonpersistent/knownProjectFiltersState.js';
import { projectState } from '../../../../../../services/recoil/nonpersistent/projectState.js';
import { currentScopePathState } from '../../../../../../services/recoil/persistent/currentScopePathState.js';
import { filterSettingsState } from '../../../../../../services/recoil/persistent/filterSettingState.js';
import { filterSqlState } from '../../../../../../services/recoil/persistent/filterSqlState.js';
import { filterTreeState } from '../../../../../../services/recoil/persistent/filterTreeState.js';
import { filterConfigInit } from '../../../../../features/Filters/helpers/filterConfig.js';
import { SelectAvatar } from '../../../../../features/SelectAvatar.js';

interface Options {
  disabled?: boolean;
}

export const SelectFilter = ({ disabled }: Options) => {
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const knownProjectFilters = useRecoilValue(knownProjectFiltersState);
  const resetFilterTree = useResetRecoilState(filterTreeState);
  const resetFilterSettings = useResetRecoilState(filterSettingsState);
  const setApp = useSetRecoilState(applicationState);
  const setFilterSql = useSetRecoilState<string | undefined>(filterSqlState);
  const resetFilterSql = useResetRecoilState(filterSqlState);
  const [filterTree, setFilterTree] = useRecoilState(filterTreeState);
  const [filterSettings, setFilterSettings] = useRecoilState(filterSettingsState);
  const [filters, setFilters] = useState<KaeplaFilter<Timestamp>[]>();
  const [selectedFilter, setSelectedFilter] = useState<string>();

  useEffect(() => {
    if (!kaeplaUser) return;
    const loadFilters = () => {
      const filterFromState = knownProjectFilters?.find(
        (_filter) => _filter.id === filterSettings.filterPresetId,
      );

      if (filterFromState) {
        setSelectedFilter(filterFromState.id);
      } else {
        setSelectedFilter(undefined);
      }
      setFilters(knownProjectFilters);
    };
    void loadFilters();
  }, [
    kaeplaUser?.uid,
    project.id,
    currentScopePath,
    kaeplaUser,
    project,
    filterSettings.filterPresetId,
    filterSettings.updatedAt,
    knownProjectFilters,
  ]);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedFilter(event.target.value);
    const chosenFilter = clone(filters)?.find((_filter) => _filter.id === event.target.value);

    if (chosenFilter) {
      chosenFilter.id = event.target.value;
      if (filterTree.id !== chosenFilter.id) {
        const chosenFilterTree = JSON.parse(chosenFilter.filterTreeStringified) as JsonTree;
        setFilterTree({ ...chosenFilterTree, id: chosenFilter.id });
        const immutableTree = QbUtils.loadTree(chosenFilterTree);
        const newFilterSql = QbUtils.sqlFormat(immutableTree, filterConfigInit);
        setFilterSql(newFilterSql);
      }
      setFilterSettings({
        ...filterSettings,
        filterPresetId: chosenFilter.id,
        isActive: true,
        filterCreatedBy: chosenFilter.createdBy,
      });
    } else {
      resetFilterTree();
      resetFilterSql();
      resetFilterSettings();
      setFilterSettings({
        ...filterSettings,
        filterPresetId: undefined,
        filterCreatedBy: undefined,
        isActive: false,
      });
      setApp((app) => ({ ...app, showFilter: false }));
    }
  };

  if (filters?.length === 0) {
    return (
      <Typography sx={{ fontSize: '100%', opacity: disabled ? 0.5 : 1 }} color="text.secondary">
        {disabled ? 'filters are disabled' : 'no saved filters yet'}
      </Typography>
    );
  }

  return (
    <FormControl
      fullWidth
      sx={{ mt: 0, p: 0, minWidth: 160 }}
      size="small"
      margin="dense"
      variant="standard"
    >
      <InputLabel size="small" id="filter-label">
        Filter
      </InputLabel>
      {filters && (
        <Select
          id="filterSelect"
          value={selectedFilter ?? ''}
          label="Select a Filter"
          onChange={handleChange}
          size="small"
          SelectDisplayProps={{ style: { marginTop: 0, padding: 0, paddingRight: 16 } }}
          disableUnderline
          autoFocus={false}
          disabled={disabled}
          inputProps={{ name: 'filterSelectInput' }}
        >
          <MenuItem dense value="">
            <em>No Filter</em>
          </MenuItem>
          {filters?.map((_filter) => (
            <MenuItem dense key={_filter.id} value={_filter.id} sx={{ whiteSpace: 'nowrap' }}>
              <Box component="span" mr={1}>
                <SelectAvatar uid={_filter.createdBy} />
              </Box>
              {_filter.name}
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
};
