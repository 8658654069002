import { Box } from '@mui/material';
import { AiAnswerType, PromptAnswer } from '@kaepla/types';

import { AnswerNumber } from './AnswerTypes/AnswerNumber';
import { AnswerRecord } from './AnswerTypes/AnswerRecord';
import { AnswerTable } from './AnswerTypes/AnswerTable';

interface OptionsAT {
  answer: PromptAnswer;
}
export const AnswerPerAnswerType = ({ answer }: OptionsAT) => {
  const { answerType } = answer;
  if (answer && answerType === AiAnswerType.Number) {
    return <AnswerNumber answer={answer} />;
  } else if (answer && answerType === AiAnswerType.Record) {
    return <AnswerRecord answer={answer} />;
  } else if (answer && answerType === AiAnswerType.Table) {
    return <AnswerTable answer={answer} />;
  } else {
    return <Box>Can not process the answer for this question.</Box>;
  }
};
