import { KaeplaProjectClone } from '@kaepla/types';
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { requestLog } from '../../../Frontend/helpers/logger';

interface KOptions {
  clone: KaeplaProjectClone;
  uid: string;
}

export const cloneProject = async ({ clone, uid }: KOptions) => {
  const functions = getFunctions(getApp('kaepla'), 'europe-west3');
  const cloneProjectOnCall = httpsCallable(functions, 'cloneProject');

  requestLog.log(`cloneProject({data:${JSON.stringify({ clone, uid })}})`);
  await cloneProjectOnCall({ clone, uid }).catch((error) => {
    requestLog.warn('Error calling cloneProject', error);
  });
};
