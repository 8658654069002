import { Box } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { dataSourceVehiclesState } from '../../../../services/recoil/nonpersistent/dataSourceVehiclesState';
import { LinearProgressWithLabel } from '../components/LinearProgressWithLabel';

import { manualReviewVehicles } from './IhsVehiclesMatchBuckets';

export const IhsVehiclesManualReviewIndicator = () => {
  const vehicles = useRecoilValue(dataSourceVehiclesState);
  return (
    <Box sx={{ width: 250 }}>
      <LinearProgressWithLabel
        value={
          (vehicles.filter((v) => v.isReviewed).length / manualReviewVehicles(vehicles).length) *
          100
        }
      />
    </Box>
  );
};
