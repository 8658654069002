import { KaeplaFirestoreProjectSettingsPath, KaeplaProjectSettings } from '@kaepla/types';
import { getApp } from 'firebase/app';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

interface Options {
  projectId: string;
  settingId: string;
}
export const getProjectSettingQuery = async ({ projectId, settingId }: Options) => {
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath: KaeplaFirestoreProjectSettingsPath = `/projects/${projectId}/settings/${settingId}`;
  const firestoreDocumentReference = doc(firestoreDatabase, fireStorePath);
  const snapshot = await getDoc(firestoreDocumentReference);
  return snapshot.data() as KaeplaProjectSettings;
};
