import numbro from 'numbro';

numbro.registerLanguage({
  languageTag: 'de-DE',
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal() {
    return '.';
  },
  spaceSeparated: true,
  currency: {
    symbol: '€',
    position: 'postfix',
    code: 'EUR',
  },
  currencyFormat: {
    totalLength: 4,
    thousandSeparated: true,
  },
  formats: {
    fourDigits: {
      totalLength: 4,
      spaceSeparated: true,
      average: true,
    },
    fullWithTwoDecimals: {
      output: 'currency',
      mantissa: 2,
      spaceSeparated: true,
      thousandSeparated: true,
    },
    fullWithTwoDecimalsNoCurrency: {
      mantissa: 2,
      thousandSeparated: true,
    },
    fullWithNoDecimals: {
      output: 'currency',
      spaceSeparated: true,
      thousandSeparated: true,
      mantissa: 0,
    },
  },
});
numbro.setLanguage('de-DE');

export function formatNumber(number: number, forceSign: boolean = false): string {
  if (!number) return '0';
  const abbreviated = ` (~${numbro(number).format({ average: true, totalLength: 2, forceSign })})`;
  if (Math.abs(number) > 1000) {
    return (
      numbro(number).format({
        thousandSeparated: true,
        mantissa: 0,
        forceSign,
      }) + abbreviated
    );
  }

  if (Math.abs(number) < 10) {
    return numbro(number).format({
      thousandSeparated: true,
      mantissa: 5,
      trimMantissa: true,
      forceSign,
    });
  }

  return numbro(number).format({
    thousandSeparated: true,
    mantissa: 2,
    trimMantissa: true,
    forceSign,
  });
}
