import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { AiResponse, PromptOptions } from '@kaepla/types';

import { requestLog } from '../../Frontend/helpers/logger.js';

type DataReturn = (data: AiResponse) => void;

interface KOptions {
  callBack?: DataReturn;
  errorCallBack?: (error: unknown) => void;
  setLoading?: (loading: boolean) => void;
  params: PromptOptions;
  uid?: string;
}
export const promptScopeOnCall = ({
  callBack,
  errorCallBack,
  setLoading,
  params,
  uid,
}: KOptions) => {
  const functions = getFunctions(getApp('kaepla'), 'europe-west3');
  const askAiQuestion = httpsCallable(functions, 'askAiQuestion', { timeout: 700_000 });

  // promptScope({"scopePathStringified": "[]", "question": "total turnover for all actuals in 2024", "projectId": "PID_Elmos_A1"})
  requestLog.log(`askAiQuestion(${JSON.stringify({ ...params, uid })})`);

  void askAiQuestion(params)
    .then((result) => {
      // console.log("loading", params.q, loading);
      if (typeof setLoading === 'function') {
        setLoading(false);
      }
      if (callBack) {
        callBack(result.data as AiResponse);
      }
    })
    .catch((error) => {
      if (errorCallBack) {
        errorCallBack(error);
      }
    });
};
