import AddIcon from '@mui/icons-material/AddCircleOutline';
import SimulationIcon from '@mui/icons-material/AutoGraph';
import { Box, Grid2 as Grid, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { useAuth } from '../../../../../../AuthReactProvider.js';
import { useUserPreferences } from '../../../../../../UserPreferencesProvider';
import { watcherServiceKaepla } from '../../../../../../firebaseInit.js';
import { createNewSimulation } from '../../../../../../services/firestore/createNewSimulation.js';
import { toggleSimulationFavorite } from '../../../../../../services/firestore/toggleSimulationFavorite';
import { perspectiveState } from '../../../../../../services/recoil/nonpersistent/perspectiveState.js';
import { projectState } from '../../../../../../services/recoil/nonpersistent/projectState.js';
import { scopePathsState } from '../../../../../../services/recoil/nonpersistent/scopePathsState.js';
import { simulationState } from '../../../../../../services/recoil/nonpersistent/simulationState.js';
import { currentScopePathState } from '../../../../../../services/recoil/persistent/currentScopePathState';
import { logger } from '../../../../../helpers/logger.js';

import { SelectSimulation } from './SelectSimulation.js';

interface SOptions {
  disableSimulationSelect?: boolean;
}
export const ProjectSelectSimulation = ({ disableSimulationSelect }: SOptions) => {
  const { kaeplaUser } = useAuth();
  const navigate = useNavigate();
  const { setPreferences } = useUserPreferences();
  const project = useRecoilValue(projectState);
  const perspective = useRecoilValue(perspectiveState);
  const scopePath = useRecoilValue(scopePathsState);
  const simulation = useRecoilValue(simulationState);
  const currentScopePath = useRecoilValue(currentScopePathState);

  const createSimulation = async () => {
    if (!kaeplaUser) return;
    const _simulation = await createNewSimulation({
      project,
      scopePath: scopePath[project.id] ?? [],
      createdBy: kaeplaUser.uid,
    });

    void toggleSimulationFavorite({
      uid: kaeplaUser.uid,
      simulation: _simulation,
      scopePath: currentScopePath,
      isFavorite: true,
    });
    setPreferences({ lastSimulationId: perspective.id });
    navigate(`/Simulation/${project.id}/${_simulation.id}`);
  };

  const goToSimulation = async () => {
    if (!simulation?.id) return;
    // unmount the simulation listener
    const watcherKeys = watcherServiceKaepla.get().getSubscriptionKeys();
    let simulationWatcherKey;
    watcherKeys.forEach((watcher) => {
      const [watcherType] = watcher.split(':');
      if (watcherType === `projects/${project.id}/simulations/${simulation.id ?? 'x'}`) {
        simulationWatcherKey = watcher;
      }
    });
    if (simulationWatcherKey) {
      watcherServiceKaepla.get().unsubscribe(simulationWatcherKey);
    }
    logger.log('watcher keys', watcherKeys, simulationWatcherKey);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    navigate(`/Simulation/${project.id}/${simulation.id}`);
  };

  return (
    <>
      <Grid size={8}>
        <SelectSimulation disabled={disableSimulationSelect} />
      </Grid>
      <Grid size={2} container sx={{ justifyContent: { xs: 'flex-end' } }}>
        {simulation && (
          <Box sx={{ alignSelf: { xs: 'end' } }}>
            <IconButton
              size="small"
              color="primary"
              onClick={() => {
                void goToSimulation();
              }}
            >
              <SimulationIcon />
            </IconButton>
          </Box>
        )}
        {!simulation && (
          <Box sx={{ alignSelf: { xs: 'end' } }}>
            <IconButton
              size="small"
              color="primary"
              onClick={() => {
                navigate(`/Simulations/${project.id}`);
              }}
            >
              <SimulationIcon />
            </IconButton>
          </Box>
        )}
      </Grid>
      <Grid size={2} container sx={{ justifyContent: { xs: 'flex-end' } }}>
        <Box sx={{ alignSelf: { xs: 'end' } }}>
          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              void createSimulation();
            }}
          >
            <AddIcon />
          </IconButton>
        </Box>
      </Grid>
    </>
  );
};
