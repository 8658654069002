export const cleanColumnName = (dimension: string, replacement = ' ') => {
  return `${dimension}`.replaceAll('__', replacement).replaceAll('_', replacement);
};

// TODO: have a concept for this
export const getColumnAbbreviation = (dimension: string) => {
  return `${dimension}`
    .replaceAll('Corporate_', 'Corp_')
    .replaceAll('Volume', 'Vol')
    .replaceAll('Turnover', 'TO')
    .replaceAll('Reference_Object__', 'RO_')
    .replaceAll('Global_Nameplate', 'Nameplate')
    .replaceAll('Global_Sales_Segment', 'Sales_Segment')
    .replaceAll('Production_Brand', 'Brand')
    .replaceAll('Reference_Object__Monthly', 'Vehicle');
};
