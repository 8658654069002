import { KaeplaDataSnapshot, KaeplaOpsUpdateStatus } from '@kaepla/types';
import ArchiveIcon from '@mui/icons-material/ArchiveOutlined';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import RenameIcon from '@mui/icons-material/EditOutlined';
import AdminIcon from '@mui/icons-material/Person2Outlined';
import AutomatedIcon from '@mui/icons-material/ScheduleOutlined';
import UnArchiveIcon from '@mui/icons-material/UnarchiveOutlined';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid2 as Grid,
  IconButton,
  IconButtonProps,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { DateTime } from 'luxon';
import numbro from 'numbro';
import prettyBytes from 'pretty-bytes';
import { useState } from 'react';
import TimeAgo from 'react-timeago';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { useUserPreferences } from '../../../UserPreferencesProvider';
import { dataSnapshotMarkForDeletion } from '../../../services/firestore/dataSnapshotMarkForDeletion';
import { toggleArchiveDataSnapshot } from '../../../services/firestore/toggleArchiveDataSnapshot';
import { projectState } from '../../../services/recoil/nonpersistent/projectState';
import { simulationState } from '../../../services/recoil/nonpersistent/simulationState';
import { snapShotState } from '../../../services/recoil/nonpersistent/snapshotState';
import { knownUsersState } from '../../../services/recoil/persistent/knownUsersState';
import { UserAvatar } from '../../features/UserAvatar';

import { DataSnapshotRenameDialog } from './DataSnapshotRenameDialog';

interface Options {
  dataSnapShot: KaeplaDataSnapshot;
}

const ToggleArchive = styled((properties: IconButtonProps) => {
  const { ...other } = properties;
  return <IconButton {...other} />;
})(() => ({
  marginLeft: 'auto',
}));

export const DataSnapshotCard = ({ dataSnapShot }: Options) => {
  const theme = useTheme();
  const { setPreferences } = useUserPreferences();
  const project = useRecoilValue(projectState);
  const knownUsers = useRecoilValue(knownUsersState);
  const [snapShot, setSnapShot] = useRecoilState(snapShotState);
  const resetSnapshot = useResetRecoilState(snapShotState);
  const resetSimulation = useResetRecoilState(simulationState);
  const [open, setOpen] = useState(false);
  const [showDescription, setShowDescription] = useState(false);

  const owner = knownUsers.find((user) => user.uid === dataSnapShot.createdBy);

  return (
    <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}>
      <DataSnapshotRenameDialog
        dataSnapShot={dataSnapShot}
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
      />
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          border: '1px solid',
          borderColor: snapShot?.id === dataSnapShot.id ? theme.palette.info.light : 'transparent',
        }}
      >
        <CardHeader
          avatar={
            owner ? (
              <UserAvatar sx={{ mr: 1 }} user={owner} />
            ) : dataSnapShot.createdBy === 'kaepla-scheduler' ? (
              <AutomatedIcon fontSize="large" />
            ) : (
              <AdminIcon fontSize="large" />
            )
          }
          title={
            <Box>
              {dataSnapShot?.name && (
                <Typography variant="h5" color="textPrimary">
                  {dataSnapShot.name}
                </Typography>
              )}
              {!dataSnapShot?.name && (
                <Typography variant="h6" color="textPrimary">
                  {dataSnapShot.createdAt.toDate().toDateString()}
                  {' @ '}
                  {DateTime.fromJSDate(dataSnapShot.createdAt.toDate()).toFormat('hh:mm')}
                </Typography>
              )}
            </Box>
          }
          subheader={DateTime.fromMillis(dataSnapShot.createdAt.toMillis())
            .setLocale('en')
            .toLocaleString({
              ...DateTime.DATETIME_MED_WITH_SECONDS,
            })}
        />
        <Tooltip title={dataSnapShot.id}>
          <CardContent
            onMouseEnter={() => {
              if (dataSnapShot?.description) {
                setShowDescription(false);
              }
            }}
            onMouseLeave={() => {
              if (dataSnapShot?.description) {
                setShowDescription(false);
              }
            }}
          >
            <Box>
              {dataSnapShot?.description && showDescription && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{
                    color: theme.palette.text.disabled,
                  }}
                >
                  {dataSnapShot.description}
                </Typography>
              )}
            </Box>
            {!showDescription && (
              <Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="body2" color="textSecondary">
                    {dataSnapShot.createdAt.toDate().toTimeString()}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {dataSnapShot.archived ? 'Archived' : ''}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="body2" color="textSecondary">
                    <TimeAgo date={dataSnapShot.createdAt.toDate()} max={Number.MAX_SAFE_INTEGER} />
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {dataSnapShot.updateStatus}
                  </Typography>
                </Box>
                {dataSnapShot?.metaData && (
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    {dataSnapShot?.metaData?.numBytes && (
                      <Typography variant="body2" color="textSecondary">
                        {prettyBytes(Number.parseInt(dataSnapShot?.metaData?.numBytes, 10)).replace(
                          ' ',
                          '',
                        )}
                      </Typography>
                    )}
                    {dataSnapShot?.metaData?.numRows && (
                      <Typography variant="body2" color="textSecondary">
                        {numbro(Number.parseInt(dataSnapShot?.metaData?.numRows, 10)).format({
                          average: true,
                          mantissa: 2,
                        })}{' '}
                        records
                      </Typography>
                    )}
                    {dataSnapShot?.dimensions && (
                      <Typography variant="body2" color="textSecondary">
                        {dataSnapShot.dimensions.length} dimensions
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
            )}
          </CardContent>
        </Tooltip>
        <CardActions disableSpacing>
          {dataSnapShot.updateStatus !== KaeplaOpsUpdateStatus.finished &&
            dataSnapShot.updateStatus !== KaeplaOpsUpdateStatus.failed && (
              <CircularProgress size={30} />
            )}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
            <ToggleArchive
              aria-label={dataSnapShot.archived ? 'unarchive' : 'archive'}
              onClick={() => {
                void toggleArchiveDataSnapshot({ project, dataSnapShot });
              }}
              disabled={dataSnapShot.markedForDeletion}
            >
              {dataSnapShot.archived ? <UnArchiveIcon /> : <ArchiveIcon color="primary" />}
            </ToggleArchive>
            <Divider sx={{ marginY: 1.5 }} orientation="vertical" flexItem />
            <Button
              size="small"
              variant="text"
              onClick={() => {
                // void dataSnapshotRename({ project, dataSnapShot });
                setOpen(true);
              }}
              endIcon={<RenameIcon />}
            >
              rename
            </Button>
            <Divider sx={{ marginY: 1.5 }} orientation="vertical" flexItem />
            <Button
              size="small"
              variant="text"
              disabled={dataSnapShot.markedForDeletion}
              onClick={() => {
                void dataSnapshotMarkForDeletion({ project, dataSnapShot });
              }}
              endIcon={<DeleteIcon />}
            >
              {dataSnapShot.markedForDeletion ? 'Deleting...' : 'Delete'}
            </Button>
          </Box>
          {snapShot?.id !== dataSnapShot.id && (
            <Button
              sx={{ marginLeft: 'auto' }}
              variant="contained"
              size="small"
              disabled={
                dataSnapShot.markedForDeletion ??
                dataSnapShot.updateStatus !== KaeplaOpsUpdateStatus.finished
              }
              onClick={() => {
                resetSimulation();
                setPreferences({ lastSimulationId: undefined });
                setSnapShot(dataSnapShot);
              }}
            >
              Select
            </Button>
          )}
          {snapShot?.id === dataSnapShot.id && (
            <Button
              sx={{ marginLeft: 'auto', color: theme.palette.info.main }}
              size="small"
              onClick={() => resetSnapshot()}
              endIcon={<CancelIcon />}
            >
              Selected
            </Button>
          )}
        </CardActions>
      </Card>
    </Grid>
  );
};
