import { atom } from 'recoil';
import { KaeplaModel } from '@kaepla/types';

const defaultModelState: KaeplaModel = {
  delegation: {} as Record<string, unknown>,
};

export const modelState = atom<KaeplaModel>({
  key: 'model',
  default: defaultModelState,
});
