import { Fields } from '@react-awesome-query-builder/mui';
import { MatrixSummary, KaeplaProject } from '@kaepla/types';

import { getFieldSetting } from './getFieldSetting.js';

interface Options {
  matrixFiltered: MatrixSummary;
  project: KaeplaProject;
  currentScopePath: string[] | undefined;
  uid: string;
  alias?: string;
}
export const getFieldSettings = ({
  alias,
  matrixFiltered,
  project,
  currentScopePath,
  uid,
}: Options) => {
  const fields: Fields = {};

  if (!matrixFiltered?.dimensions) return fields;
  // Autocomplete with simulated Async fetch: https://github.com/ukrbublik/react-awesome-query-builder/blob/master/packages/sandbox/src/demo/config.tsx#L254
  matrixFiltered.dimensions.dimensions
    .filter((d) => d.visible)
    .forEach((dimension) => {
      // console.log('dimension', dimension);
      let fieldName = dimension.columnName;
      if (alias) {
        fieldName = `${alias}.${dimension.columnName}`;
      }
      fields[fieldName] = getFieldSetting({ project, currentScopePath, uid, dimension });
    });
  return fields;
};
