import { getApp } from 'firebase/app';
import { doc, DocumentData, getFirestore, updateDoc } from 'firebase/firestore';
import { KaeplaProject, KaeplaSimulation } from '@kaepla/types';

import { mapUndefinedToNullValue } from './mapUndefinedToNullValue.js';

interface Options {
  project: KaeplaProject;
  simulation: Partial<KaeplaSimulation>;
}

export const updateSimulation = async ({ project, simulation }: Options) => {
  if (!simulation.id || !project.id) return;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects/${project.id}/simulations/${simulation.id}`;
  const firestoreDocumentReference = doc(firestoreDatabase, fireStorePath);

  const update: DocumentData = { projectId: project.id, ...simulation };
  return updateDoc(firestoreDocumentReference, mapUndefinedToNullValue(update));
};
