import { KaeplaEventFilter } from '@kaepla/events';
import React from 'react';

export type EventLogFilterColumn = keyof Partial<KaeplaEventFilter>;

export interface AvailableFilterColumn {
  label: string;
  column: EventLogFilterColumn;
}

export const EventLogFilterContext = React.createContext({
  eventLogFilters: {} as Partial<KaeplaEventFilter>,
  eventLogAvailableFilterColumns: [] as AvailableFilterColumn[],
  disabledFilterColumns: [] as EventLogFilterColumn[],
  hasNonDisabledFilters: false as boolean,
  setFilter: (_options: {
    column: EventLogFilterColumn;
    value: string | string[];
    disabled?: boolean;
  }) => {
    /** structure */
  },
  clearFilter: (_options: { column: EventLogFilterColumn; includingDisabled?: boolean }) => {
    /** structure */
  },
  clearAllFilters: (_options: { includingDisabled?: boolean }) => {
    /** structure */
  },
});
