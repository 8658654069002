import UnfoldLessIcon from '@mui/icons-material/UnfoldLessOutlined';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMoreOutlined';
import { Stack, Box, Divider, IconButton } from '@mui/material';
import { useState } from 'react';

export const ContentExpander = ({
  children,
  label,
}: {
  children: React.ReactNode;
  label?: string;
}) => {
  const [toggleExpand, setToggleExpand] = useState(false);
  return (
    <Stack direction="column">
      <Stack
        direction="row"
        gap={1}
        sx={{
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Divider sx={{ borderStyle: 'dashed' }} />
        </Box>
        {label && <Box sx={{ whiteSpace: 'nowrap' }}>{label}</Box>}
        <Box>
          <IconButton
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setToggleExpand(!toggleExpand);
            }}
          >
            {toggleExpand ? (
              <UnfoldLessIcon fontSize="small" />
            ) : (
              <UnfoldMoreIcon fontSize="small" />
            )}
          </IconButton>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Divider sx={{ borderStyle: 'dashed' }} />
        </Box>
      </Stack>
      {toggleExpand && children}
      {toggleExpand && (
        <Stack
          direction="row"
          gap={2}
          sx={{
            justifyContent: 'space-evenly',
            alignItems: 'center',
          }}
        >
          <Box sx={{ width: '100%' }}>
            <Divider sx={{ borderStyle: 'dashed' }} />
          </Box>
          <Box>
            <IconButton
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setToggleExpand(!toggleExpand);
              }}
            >
              {toggleExpand ? (
                <UnfoldLessIcon fontSize="small" />
              ) : (
                <UnfoldMoreIcon fontSize="small" />
              )}
            </IconButton>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Divider sx={{ borderStyle: 'dashed' }} />
          </Box>
        </Stack>
      )}
    </Stack>
  );
};
