import { clientEventService } from '@kaepla/events';
import { KaeplaApiParameters, KaeplaQueryType, MatrixSummary } from '@kaepla/types';
import ScopeTreeIcon from '@mui/icons-material/AccountTreeOutlined';
import CancelTreeIcon from '@mui/icons-material/CancelOutlined';
import { Box, Divider, Grid2 as Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { clone } from 'rambda';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useAuth } from '../../../AuthReactProvider.js';
import { MermaidReact } from '../../../primitive/MermaidReact/MermaidReact.js';
import { getFromKaepla } from '../../../services/api/getFromKaepla.js';
import { matrixFilteredState } from '../../../services/recoil/nonpersistent/matrixFilteredState.js';
import { modelState } from '../../../services/recoil/nonpersistent/modelState.js';
import { projectState } from '../../../services/recoil/nonpersistent/projectState.js';
import { scopePathsState } from '../../../services/recoil/nonpersistent/scopePathsState.js';
import { currentScopePathState } from '../../../services/recoil/persistent/currentScopePathState.js';
import { kaeplaAssignmentState } from '../../../services/recoil/persistent/kaeplaAssignmentState.js';
import { Layout } from '../../Layout/Layout.js';
import { ProjectDisabled } from '../../features/ProjectDisabled.js';
import { ProjectLoading } from '../../features/ProjectLoading.js';
import { ProjectInfoSmall } from '../Perspectives/features/ProjectSummary/features/ProjectInfoSmall.js';

import { ScopesOverview } from './features/ScopesOverview.js';
import { graphFromScopeModel } from './helpers/graphFromScopeModel.js';

export const Scopes = () => {
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const [matrixFiltered, setMatrixFiltered] = useRecoilState(matrixFilteredState);
  const [model, setModel] = useRecoilState(modelState);
  const [scopePaths, setScopePaths] = useRecoilState(scopePathsState);
  const [scopePath, setScopePath] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [graph, setGraph] = useState<string | null>(null);
  const [showGraph, setShowGraph] = useState<boolean>(false);

  useEffect(() => {
    if (!project?.id) return;
    let newScopePaths = clone(scopePaths);
    if (!newScopePaths) {
      newScopePaths = {};
    }
    if (!newScopePaths[project.id]) {
      newScopePaths[project.id] = [];
    }
    setScopePath(newScopePaths[project.id] ?? []);
    const _graph = graphFromScopeModel({
      model,
      scopePath: newScopePaths[project.id] ?? [],
    });
    setGraph(_graph);

    void clientEventService.createEvent({
      assignmentScope: clientEventService.assignmentScope.PROJECT,
      eventGroup: clientEventService.eventGroup.SCOPES,
      eventName: clientEventService.eventName.SCOPES_ENTER_SCOPES_OVERVIEW,
      uid: kaeplaUser?.uid,
      projectId: project.id,
      customerId: project.customerId,
      resellerId: project.resellerId,
      scopePath: currentScopePath,
    });
  }, [project, model, scopePaths, kaeplaUser?.uid, currentScopePath, setScopePath, setGraph]);

  useEffect(() => {
    if (!project?.id) return;
    const parameters: KaeplaApiParameters = {
      q: 'summary' as KaeplaQueryType,
      p: currentScopePath,
      projectId: project.id,
      s: 'Scopes',
    };
    getFromKaepla({
      callBack: (apiResponse) => {
        if (!apiResponse) return;
        const response = apiResponse.response as MatrixSummary;
        if (!response.details) return;
        const details = response.details.filter((d) => d.enableScope);
        setMatrixFiltered({
          ...response,
          details,
        });
      },
      setLoading,
      params: parameters,
      uid: kaeplaUser?.uid,
    });
  }, [project?.id, currentScopePath, kaeplaUser?.uid, setMatrixFiltered]);

  if (!project || project?.matrixUnavailable) {
    return <ProjectLoading />;
  }

  if (project?.disabledReason && !kaeplaAssignment) {
    return (
      <Layout>
        <ProjectDisabled reason={project?.disabledReason} />
      </Layout>
    );
  }

  return (
    <Layout hasScopeNavigation showCustomerSelector>
      <Grid container spacing={3}>
        <Grid size={12}>
          <Paper sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, md: 4, lg: 3, xl: 2 }}>
                <ProjectInfoSmall title={project.name} />
              </Grid>
              <Grid size={2}>
                <Divider orientation="vertical" />
              </Grid>
              <Grid
                size={{ xs: 12, sm: 6, md: 7 }}
                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
              >
                <Typography variant="h6">Scopes</Typography>
                <Typography variant="body1">
                  Create data scopes that can be delegated to a team to collaboratively work on your
                  planning project.
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          {loading && <LinearProgress />}
        </Grid>
        {!loading && graph && (
          <Grid size={12} sx={{ position: 'relative' }}>
            <Box
              sx={{
                position: 'absolute',
                right: 0,
                top: 0,
                m: 0,
                pt: 1,
                zIndex: 3,
                cursor: 'pointer',
              }}
              onClick={() => setShowGraph(!showGraph)}
            >
              <Paper sx={{ p: 1, pb: 0 }}>
                {showGraph ? (
                  <CancelTreeIcon fontSize="small" />
                ) : (
                  <ScopeTreeIcon fontSize="small" />
                )}
              </Paper>
            </Box>
          </Grid>
        )}
        {!loading && graph && showGraph && (
          <Grid size={12}>
            <Paper sx={{ p: 1, pb: 0 }}>
              <MermaidReact
                id="test"
                mmd={graph}
                // onClick={() => console.log("test Click")}
                // onRender={(svg) => console.log("render content", svg)}
              />
            </Paper>
          </Grid>
        )}
        {!loading && matrixFiltered?.details && (
          <Grid size={12}>
            <ScopesOverview
              {...{
                matrix: matrixFiltered,
                model,
                setModel,
                scopePath,
                scopePaths,
                setScopePaths,
                loading,
              }}
            />
          </Grid>
        )}
      </Grid>
    </Layout>
  );
};
