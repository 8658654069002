import { useQuery } from '@tanstack/react-query';

import { getCustomer, getCustomerPath } from '../../repository/Customer';
import { useCreateQueryKey } from '../useCreateQueryKey';

interface UseQueryOptions {
  refetchInterval?: number;
}

export function useCustomerQueryFind(id?: string, options?: UseQueryOptions) {
  const { queryKey } = useCreateQueryKey(getCustomerPath, { id });
  const query = useQuery({
    queryKey,
    queryFn: () => {
      if (!id) {
        return;
      }
      return getCustomer(id);
    },
    throwOnError: true,
    enabled: !!id,
    refetchInterval: options?.refetchInterval,
  });
  return query;
}
