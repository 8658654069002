import { AddOutlined, DeleteOutline } from '@mui/icons-material';
import SucceededIcon from '@mui/icons-material/CheckCircle';
import FailedIcon from '@mui/icons-material/ErrorOutlineRounded';
import OpenProjectIcon from '@mui/icons-material/OpenInNewOutlined';
import PendingActionsOutlined from '@mui/icons-material/PendingActionsOutlined';
import AdminIcon from '@mui/icons-material/Person2Outlined';
import AutomatedIcon from '@mui/icons-material/ScheduleOutlined';
import { Box, CircularProgress, SvgIconProps } from '@mui/material';
import { PropsWithChildren } from 'react';

interface IconProperties extends SvgIconProps {
  loading?: boolean;
  name:
    | 'SucceededIcon'
    | 'FailedIcon'
    | 'OpenProjectIcon'
    | 'AdminIcon'
    | 'AutomatedIcon'
    | 'RemoveOutline'
    | 'Remove'
    | 'AddOutline'
    | 'Add'
    | 'loading'
    | 'DeleteOutline'
    | 'PendingActionsOutlined';
  center?: boolean;
}

function Center(properties: PropsWithChildren<unknown>) {
  return (
    <Box height={'100%'} display={'flex'} alignItems={'center'}>
      {properties.children}
    </Box>
  );
}
function BaseIcon({ name, ...properties }: Omit<IconProperties, 'center'>) {
  switch (name) {
    case 'SucceededIcon': {
      return <SucceededIcon {...properties} />;
    }
    case 'FailedIcon': {
      return <FailedIcon {...properties} />;
    }

    case 'OpenProjectIcon': {
      return <OpenProjectIcon {...properties} />;
    }
    case 'AdminIcon': {
      return <AdminIcon {...properties} />;
    }
    case 'AutomatedIcon': {
      return <AutomatedIcon {...properties} />;
    }
    case 'PendingActionsOutlined': {
      return <PendingActionsOutlined {...properties} />;
    }
    case 'AddOutline':
    case 'Add': {
      return <AddOutlined {...properties} />;
    }
    case 'Remove':
    case 'RemoveOutline':
    case 'DeleteOutline': {
      return <DeleteOutline {...properties} />;
    }
    case 'loading': {
      return (
        <CircularProgress
          // todo add color to the loading icon
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          color={properties.color ?? 'inherit'}
          {...properties}
        />
      );
    }
    default: {
      return null;
    }
  }
}

export function Icon({ center, loading = false, name, ...properties }: IconProperties) {
  if (loading) {
    return (
      <CircularProgress
        data-loading
        // todo add color to the loading icon
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        color={properties.color ?? 'inherit'}
        size={24}
      />
    );
  }
  if (center) {
    return (
      <Center>
        <BaseIcon name={name} {...properties} />
      </Center>
    );
  }

  return <BaseIcon name={name} {...properties} />;
}
