import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { KaeplaConnector } from '@kaepla/types';

interface COptions {
  connectors: KaeplaConnector[];
  value?: string;
  callback: (value: string) => void;
}

export const ConnectorSelect = ({ connectors, value, callback }: COptions) => {
  const handleChange = (event: SelectChangeEvent) => {
    if (callback) {
      callback(event.target.value);
    }
  };

  return (
    <FormControl sx={{ m: 0, minWidth: 220 }} size="small" margin="dense">
      <InputLabel id={`input-connector`}>Connectors</InputLabel>
      <Select
        label="Connectors"
        id="connectorSelect"
        value={value ?? ''}
        onChange={handleChange}
        size="small"
        inputProps={{ name: 'connectorSelectInput' }}
      >
        <MenuItem value="" dense>
          <em>None</em>
        </MenuItem>
        {connectors.map((connector) => (
          <MenuItem key={connector.id} value={connector.id} dense>
            {connector.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
