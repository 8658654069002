import { KaeplaUser } from '@kaepla/types';
import React from 'react';

import { UserSelectBox } from '../../../../../../../../../primitive/UserSelectBox/UserSelectBox';
import {
  EventLogFilterColumn,
  EventLogFilterContext,
} from '../../../../../../contexts/EventLogFilter.context';

interface Properties {
  column: EventLogFilterColumn;
  options: KaeplaUser[];
  isLoading: boolean;
}

export const FilterItemUser: React.FC<Properties> = ({ column, options, isLoading }) => {
  const { eventLogFilters, setFilter, clearFilter, disabledFilterColumns } =
    React.useContext(EventLogFilterContext);

  const value = eventLogFilters[column]
    ? (options.find((user) => user.uid === eventLogFilters[column]) ?? null)
    : null;
  const [open, setOpen] = React.useState(value === null);

  const onChange = React.useCallback(
    (_: React.SyntheticEvent, user: KaeplaUser | null) => {
      if (user === null) {
        clearFilter({ column });
        return;
      }

      setFilter({ column, value: user.uid });
      setOpen(false);
    },
    [clearFilter, column, setFilter],
  );

  return (
    <UserSelectBox
      options={options}
      value={value}
      label="User"
      isLoading={isLoading}
      onChange={onChange}
      multiple={false}
      isOpen={open}
      onOpen={() => {
        setOpen(true);
      }}
      isDisabled={isLoading || disabledFilterColumns.includes(column)}
    />
  );
};
