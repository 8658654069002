import { KaeplaUser } from '@kaepla/types';
import {
  GetKaeplaEventDistinctValuesOnCallParameters,
  GetKaeplaEventDistinctValuesOnCallResponse,
} from 'packages/events/src/cloudFunctions/cloudFunctions.types';
import React, { useEffect } from 'react';

import {
  EventLogFilterColumn,
  EventLogFilterContext,
} from '../../../../contexts/EventLogFilter.context';
import { useEventDistinctValuesQuery } from '../../../../hooks/useEventDistinctValuesQuery';

import { FilterItemMultiple } from './features/FilterItemMultiple/FilterItemMultiple';
import { FilterItemSingle } from './features/FilterItemSingle/FilterItemSingle';
import { FilterItemUser } from './features/FilterItemUser/FilterItemUser';

interface Properties {
  column: EventLogFilterColumn;
}

export const EventLogFilterItem: React.FC<Properties> = ({ column }) => {
  const { eventLogFilters, clearFilter, setFilter } = React.useContext(EventLogFilterContext);

  const [options, setOptions] = React.useState<GetKaeplaEventDistinctValuesOnCallResponse>([]);

  const distinctValuesQuery = useEventDistinctValuesQuery({
    column: column as unknown as GetKaeplaEventDistinctValuesOnCallParameters['column'],
    filterBy: eventLogFilters,
  });

  // validate options after data has been fetched
  useEffect(() => {
    if (distinctValuesQuery.isFetched) {
      // no data -> disable filter
      if (!distinctValuesQuery.data || distinctValuesQuery.data.length === 0) {
        setOptions([]);
        clearFilter({ column });
        return;
      }

      const values = distinctValuesQuery.data;

      // handle kaepla user objects
      if (typeof values[0] === 'object') {
        setOptions(values);

        // user does not exist in the list -> clear filter
        if (
          eventLogFilters[column] &&
          !(values as KaeplaUser[]).some((user) => user.uid === eventLogFilters[column])
        ) {
          clearFilter({ column });
        }
        return;
      }

      const newOptions = [...new Set(values.filter((item) => item !== null))].sort() as string[];
      setOptions(newOptions);

      // validate filter value
      if (eventLogFilters[column]) {
        // validate multiple values
        if (Array.isArray(eventLogFilters[column])) {
          const validValues = eventLogFilters[column].filter((value) => newOptions.includes(value));
          // there are invalid values
          if (validValues.length !== eventLogFilters[column].length) {
            // clear filter if no valid values
            if (validValues.length === 0) {
              clearFilter({ column });
            } else {
              // set filter with valid values
              setFilter({ column, value: validValues });
            }
          }
        } else if (!newOptions.includes(eventLogFilters[column])) {
          // single value is invalid
          clearFilter({ column });
        }
      }
    }
  }, [
    clearFilter,
    column,
    distinctValuesQuery.data,
    distinctValuesQuery.isFetched,
    eventLogFilters,
    setFilter,
  ]);

  switch (column) {
    case 'uid': {
      return (
        <FilterItemUser
          column={column}
          options={options as KaeplaUser[]}
          isLoading={distinctValuesQuery.isLoading}
        />
      );
    }

    case 'logLevel': {
      return (
        <FilterItemMultiple
          column={column}
          options={options as string[]}
          isLoading={distinctValuesQuery.isLoading}
        />
      );
    }

    default: {
      return (
        <FilterItemSingle
          column={column}
          options={options as string[]}
          isLoading={distinctValuesQuery.isLoading}
        />
      );
    }
  }
};
