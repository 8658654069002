import { KaeplaSimulation, KaeplaSimulationFavorite, FirestoreTimestamp } from '@kaepla/types';
import { getApp } from 'firebase/app';
import { doc, getFirestore, setDoc, Timestamp } from 'firebase/firestore';

interface Options {
  uid: string;
  simulation: Partial<KaeplaSimulation>;
  scopePath: string[] | undefined;
  isFavorite: boolean;
}

export const toggleSimulationFavorite = async ({
  uid,
  simulation,
  isFavorite,
  scopePath,
}: Options) => {
  if (!uid || !simulation.id || !simulation.projectId || !scopePath) return;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `simulationFavorites/${uid}-${simulation.id}`;
  const firestoreDocumentReference = doc(firestoreDatabase, fireStorePath);

  const update: KaeplaSimulationFavorite = {
    uid,
    projectId: simulation.projectId,
    simulationId: simulation.id,
    scopePath,
    scopePathStringified: JSON.stringify(scopePath),
    isFavorite,
    updatedAt: Timestamp.now() as FirestoreTimestamp,
  };

  return setDoc(firestoreDocumentReference, update, {
    merge: true,
  });
};
