import { KaeplaConnector, KaeplaProjectClone } from '@kaepla/types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControlLabel,
  Grid2 as Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useAuth } from '../../../../AuthReactProvider';
import { getProject } from '../../../../repository';
import { cloneProject } from '../../../../services/api/admin/cloneProject';
import { getConnectorsForCustomer } from '../../../../services/firestore/getConnectorsForCustomer';
import { knownCustomersState } from '../../../../services/recoil/nonpersistent/knownCustomersState';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState';
import { Layout } from '../../../Layout/Layout';
import { ConnectorCard } from '../../Connectors/components/ConnectorCard';
import { ProjectCard } from '../features/ProjectCard';

export const CloneProject = () => {
  const { kaeplaUser } = useAuth();
  const { projectId } = useParams();
  const knownCustomers = useRecoilValue(knownCustomersState);
  const [project, setProject] = useRecoilState(projectState);

  const [createNewCustomer, setCreateNewCustomer] = useState(true);
  const [cloneConnector, setCloneConnector] = useState(true);
  const customer = knownCustomers.find((c) => c.id === project?.customerId);
  const [connectorToClone, setConnectorToClone] = useState<KaeplaConnector>();
  const [clonedDefaultPerspectivePreview, setClonedDefaultPerspectivePreview] =
    useState<Record<string, unknown>>();
  const [clone, setClone] = useState<KaeplaProjectClone>();

  useEffect(() => {
    if (!projectId) return;
    if (!kaeplaUser?.uid) return;

    const loadProject = async (id: string) => {
      const projectFromServer = await getProject(id);
      if (!projectFromServer) return;
      setProject(projectFromServer);
      setClone({ project: projectFromServer });
      if (projectFromServer.connectorId) {
        const customerConnectors = await getConnectorsForCustomer({
          customerId: projectFromServer.customerId,
        });
        const connector = customerConnectors.find((c) => c.id === projectFromServer.connectorId);
        if (connector) {
          setConnectorToClone(connector);
          setClone((c) => (c ? { ...c, connector } : undefined));
        }
        if (projectFromServer.defaultPerspective) {
          const perspective = projectFromServer.defaultPerspective;
          const _clonedDefaultPerspective = {
            aggregationOverTimeName: perspective.aggregationOverTimeName,
            identifierDimension: perspective.identifierDimension,
            timeDimension: perspective.timeDimension,
            timeSlice: perspective.timeSlice,
            valueDimension: perspective.valueDimension,
          };
          setClonedDefaultPerspectivePreview(_clonedDefaultPerspective);
        }
      }
    };
    void loadProject(projectId);
  }, [projectId, kaeplaUser?.uid, setProject, setConnectorToClone]);

  const cloneProjectNow = () => {
    if (!clone || !kaeplaUser?.uid) return;
    // create a customer and wait for the triggers to fire
    // console.log('cloneProjectNow', clone);
    void cloneProject({
      clone,
      uid: kaeplaUser.uid,
    });
  };

  if (!project?.id) {
    return (
      <Layout>
        <Box>No project selected</Box>
      </Layout>
    );
  }

  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid size={6}>
          <Typography variant="h4">Clone Project</Typography>
        </Grid>

        <Grid size={6}>
          <FormControlLabel
            control={
              <Switch defaultChecked onChange={() => setCreateNewCustomer(!createNewCustomer)} />
            }
            label="Create New Customer"
          />
          <FormControlLabel
            control={
              <Switch
                defaultChecked
                onChange={() => {
                  if (cloneConnector) {
                    setClone((c) => (c ? { ...c, connector: undefined } : undefined));
                  } else {
                    setClone((c) => (c ? { ...c, connector: connectorToClone } : undefined));
                  }
                  setCloneConnector(!cloneConnector);
                }}
              />
            }
            label="Clone Connector"
          />
        </Grid>

        <Grid size={4}>
          <ProjectCard project={project} selected={false} />
        </Grid>
        {cloneConnector && connectorToClone && (
          <Grid size={4}>
            <ConnectorCard connector={connectorToClone} />
          </Grid>
        )}
        <Grid size={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid size={12}>
                  <Typography variant="h5">Project will be cloned with</Typography>
                </Grid>

                {createNewCustomer && clone && (
                  <>
                    <Grid size={6}>
                      <Typography variant="body1">Customer Name</Typography>
                    </Grid>
                    <Grid size={6}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value={clone.customerName}
                        required
                        onChange={(event) => {
                          setClone((c) =>
                            c ? { ...c, customerName: event.target.value } : undefined,
                          );
                        }}
                      />
                    </Grid>
                    <Grid size={6}>
                      <Typography variant="body1">Customer Icon</Typography>
                    </Grid>
                    <Grid size={6}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        value={clone.customerIcon}
                        onChange={(event) => {
                          setClone((c) =>
                            c ? { ...c, customerIcon: event.target.value } : undefined,
                          );
                        }}
                      />
                    </Grid>
                    <Grid size={6} />
                    <Grid size={6}>
                      <img height="30" alt={clone.customerName} src={clone.customerIcon} />
                    </Grid>
                  </>
                )}

                {!createNewCustomer && customer && (
                  <>
                    <Grid size={6}>
                      <Typography variant="body1">Customer</Typography>
                    </Grid>
                    <Grid size={6}>{customer.name}</Grid>
                    <Grid size={6}>
                      <Typography variant="body1">Customer Icon</Typography>
                    </Grid>
                    <Grid size={6}>
                      <img height="30" alt={customer.name} src={customer.iconBase64} />
                    </Grid>
                  </>
                )}

                <Grid size={12}>
                  <Divider />
                </Grid>

                <Grid size={6}>
                  <Typography variant="body1">Project Name</Typography>
                </Grid>
                <Grid size={6}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    value={clone?.project.name}
                    onChange={(event) => {
                      setClone((c) =>
                        c
                          ? { ...c, project: { ...c.project, name: event.target.value } }
                          : undefined,
                      );
                    }}
                  />
                </Grid>

                <Grid size={6}>
                  <Typography variant="body1">Import configuration</Typography>
                </Grid>
                <Grid size={6}>
                  <Typography variant="body1">{project.importConfigurationId}</Typography>
                </Grid>

                <Grid size={6}>
                  <Typography variant="body1">Access domain</Typography>
                </Grid>
                <Grid size={6}>
                  <Typography variant="body1">{project.accessDomain}</Typography>
                </Grid>

                <Grid size={6}>
                  <Typography variant="body1">Default Perspective</Typography>
                </Grid>
                <Grid size={6}>
                  <Typography variant="caption" sx={{ whiteSpace: 'pre' }}>
                    {JSON.stringify(clonedDefaultPerspectivePreview, null, 2)}
                  </Typography>
                </Grid>
                <Button
                  variant="contained"
                  onClick={() => {
                    cloneProjectNow();
                  }}
                >
                  Clone
                </Button>
                <Grid size={12} sx={{ display: 'none' }}>
                  {JSON.stringify(project)}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
};
