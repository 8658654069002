import { clone } from 'rambda';
import { KaeplaDimensionDetails } from '@kaepla/types';

export const hasDimensionPie = (detail: KaeplaDimensionDetails, totalCount: number | undefined) => {
  if (detail.dimensionDetails.length < 2) return false;

  // console.log('->', detail.columnName, detail.dimensionDetails.length);

  const data = clone(detail.dimensionDetails).slice(
    0,
    detail.dimensionDetails.length > 25 ? 10 : 25,
  );

  const uniqueValues = clone(data)
    .filter((d) => d.value !== 'other')
    .map((r) => r.numberOfRecords)
    .filter((v, index, a) => a.indexOf(v) === index);
  // console.log(detail.dimension, uniqueValues);
  if (uniqueValues.length < 2) return false;

  // if the percentage of "other" values is really high, a pie chart doesn't make sense
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const otherValuesCount = clone(data)
    .filter((d) => d.value !== null) // without null
    .find((d) => d.value === 'other')?.numberOfRecords!;
  if (totalCount && otherValuesCount) {
    const percentageOfNull = otherValuesCount / totalCount;
    // console.log('other->', detail.columnName, otherValuesCount, totalCount, percentageOfNull);
    if (percentageOfNull > 0.9) {
      return false;
    }
  }

  // if the percentage of NULL values is really high, a pie chart doesn't make sense
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const nullValuesCount = clone(data)
    .filter((d) => d.value !== 'other') // without "other"
    .find((d) => d.value === null)?.numberOfRecords!;
  if (totalCount && nullValuesCount) {
    const percentageOfNull = nullValuesCount / totalCount;
    // console.log('null--', detail.columnName, nullValuesCount, totalCount, percentageOfNull);
    if (percentageOfNull > 0.9) {
      return false;
    }
  }

  return true;
};
