import { clientEventService } from '@kaepla/events';
import { Grid2 as Grid, LinearProgress } from '@mui/material';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { useAuth } from '../../../AuthReactProvider.js';
import { applicationState } from '../../../services/recoil/nonpersistent/applicationState.js';
import { projectInCreationState } from '../../../services/recoil/nonpersistent/projectInCreationState.js';
import { projectsOverviewState } from '../../../services/recoil/nonpersistent/projectsOverviewState.js';
import { selectedCustomerState } from '../../../services/recoil/nonpersistent/selectedCustomer.js';
import { lastProjectIdState } from '../../../services/recoil/persistent/lastProjectState.js';
import { Layout } from '../../Layout/Layout.js';

import { CreateProjectFab } from './features/CreateProjectFab.js';
import { NoCustomers } from './features/NoCustomers.js';
import { NoProjects } from './features/NoProjects.js';
import { ProjectCard } from './features/ProjectCard.js';
import { ProjectInCreation } from './features/ProjectInCreation.js';

export const Projects = () => {
  const { kaeplaUser } = useAuth();
  const app = useRecoilValue(applicationState);
  const lastProject = useRecoilValue(lastProjectIdState);
  const projectInCreation = useRecoilValue(projectInCreationState);
  const projectsOverview = useRecoilValue(projectsOverviewState);
  const selectedCustomer = useRecoilValue(selectedCustomerState);

  useEffect(() => {
    if (!kaeplaUser?.uid) return;
    if (!app.initialized) return;
    void clientEventService.createEvent({
      assignmentScope: clientEventService.assignmentScope.CUSTOMER,
      eventGroup: clientEventService.eventGroup.PROJECTS,
      eventName: clientEventService.eventName.PROJECT_ENTER_PROJECT_OVERVIEW,
      uid: kaeplaUser.uid,
      customerId: selectedCustomer?.id,
      resellerId: selectedCustomer?.resellerId,
      payload: {
        projectInCreation,
      },
    });
  }, [
    app.initialized,
    kaeplaUser?.uid,
    projectInCreation,
    selectedCustomer?.id,
    selectedCustomer?.resellerId,
  ]);

  if (!app.initialized) {
    return (
      <Layout>
        <LinearProgress data-testid="projects-loading" />
      </Layout>
    );
  }

  return (
    <Layout showCustomerSelector>
      <Grid container spacing={2}>
        <CreateProjectFab />
        <NoCustomers />
        <NoProjects creating={!!projectInCreation} />
        <ProjectInCreation creating={!!projectInCreation} />
        {projectsOverview.map((_project) => (
          <Grid key={_project.id} size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
            <ProjectCard project={_project} selected={_project.id === lastProject} />
          </Grid>
        ))}
      </Grid>
    </Layout>
  );
};
