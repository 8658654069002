import { Box } from '@mui/material';
import { useMemo, useRef } from 'react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { useRecoilValue } from 'recoil';

import { dataSourceVehiclesState } from '../../../../services/recoil/nonpersistent/dataSourceVehiclesState';
import { bucketState } from '../../../../services/recoil/nonpersistent/ihsBucketState';
import { MatchBucketPanel } from '../switcher/MatchBucketPanel';
import { MatchBucketType } from '../types/MatchBucketType';

import {
  deletedVehicles,
  manualReviewVehicles,
  newVehicles,
  unchangedVehicles,
  updatedVehicles,
} from './IhsVehiclesMatchBuckets';
import { IhsVehicleView } from './IhsVehicleView';

interface Options {
  fullScreen: boolean;
}

export const IhsDataSourceImportView = ({ fullScreen }: Options) => {
  const vehiclesFromState = useRecoilValue(dataSourceVehiclesState);
  const selectedBucket = useRecoilValue(bucketState);

  const reference = useRef<VirtuosoHandle>(null);
  const vehicles = useMemo(() => {
    if (!selectedBucket) {
      return manualReviewVehicles(vehiclesFromState);
    }
    switch (selectedBucket.type) {
      case MatchBucketType.NEW: {
        return newVehicles(vehiclesFromState);
      }
      case MatchBucketType.UNCHANGED: {
        return unchangedVehicles(vehiclesFromState);
      }
      case MatchBucketType.UPDATED: {
        return updatedVehicles(vehiclesFromState);
      }
      case MatchBucketType.DELETED: {
        return deletedVehicles(vehiclesFromState);
      }
      case MatchBucketType.MANUAL_REVIEW: {
        return manualReviewVehicles(vehiclesFromState);
      }
      // No default
    }
  }, [selectedBucket, vehiclesFromState]);

  if (!selectedBucket || !vehicles) {
    return (
      <Box sx={{ minHeight: fullScreen ? 1500 : 400 }}>
        <MatchBucketPanel explain={true} />
      </Box>
    );
  }

  return (
    <Virtuoso
      ref={reference}
      style={{ height: '400px' }}
      useWindowScroll={fullScreen}
      totalCount={vehicles.length}
      data={vehicles}
      itemContent={(_, ihsVehicle) => <IhsVehicleView ihsVehicle={ihsVehicle} />}
      // can't be sticky in a virtuoso list
      // eslint-disable-next-line @typescript-eslint/naming-convention
      // components={{ Footer: () => <div>footer</div> }}
    />
  );
};
