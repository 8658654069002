import { Alert, Button, Grid2 as Grid, TextField } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';

import { useAuth } from '../../../../../AuthReactProvider.js';

interface Options {
  setIsReAuthenticated: Dispatch<SetStateAction<boolean>>;
  setEditable: Dispatch<SetStateAction<boolean>>;
}

export const Step0ReAuthenticateWithPassword = ({ setIsReAuthenticated, setEditable }: Options) => {
  const [password, setPassword] = useState<string>('');
  const [authFailed, setAuthFailed] = useState(false);
  const { reAuthenticateWithPassword } = useAuth();

  return (
    <Grid size={12}>
      <Grid container spacing={2} sx={{ maxWidth: 300 }}>
        <Grid size={12}>
          <TextField
            variant="standard"
            label="Password"
            type="password"
            name="re-authentication-password"
            fullWidth
            value={password}
            autoComplete="new-password"
            inputProps={{
              autoComplete: 'new-password',
              'data-testid': 'mfa-re-auth-password',
            }}
            // TODO: how do we turn off browser password autocomplete for password managers?
            autoFocus
            onChange={(event) => setPassword(event.target.value)}
          />
        </Grid>
        <Grid size={6}>
          <Button
            size="small"
            data-testid="mfa-button-cancel"
            onClick={() => {
              setEditable(false);
            }}
          >
            cancel
          </Button>
        </Grid>
        <Grid size={6} container justifyContent="flex-end">
          <Button
            data-testid="mfa-button-authenticate"
            variant="contained"
            size="small"
            onClick={() => {
              void reAuthenticateWithPassword(password, (isReAuthenticated: boolean) => {
                setIsReAuthenticated(isReAuthenticated);
                if (!isReAuthenticated) {
                  setAuthFailed(true);
                }
              });
            }}
          >
            authenticate
          </Button>
        </Grid>
        {authFailed && (
          <Grid size={12}>
            <Alert data-testid="mfa-error" severity="error">
              Authentication failed
            </Alert>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
