import { clientEventService } from '@kaepla/events';
import {
  KaeplaApiParameters,
  KaeplaQueryType,
  MatrixApiResponse,
  MatrixPathDetails,
} from '@kaepla/types';
import ScopesIcon from '@mui/icons-material/LayersOutlined';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Box,
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { useAuth } from '../../AuthReactProvider.js';
import { useError } from '../../KaeplaErrorProvider';
import { getFromKaepla } from '../../services/api/getFromKaepla.js';
import { applicationState } from '../../services/recoil/nonpersistent/applicationState.js';
import { matrixFilteredState } from '../../services/recoil/nonpersistent/matrixFilteredState.js';
import { modelState } from '../../services/recoil/nonpersistent/modelState.js';
import { projectState } from '../../services/recoil/nonpersistent/projectState.js';
import { scopePathsState } from '../../services/recoil/nonpersistent/scopePathsState.js';
import { projectAssignmentsState } from '../../services/recoil/nonpersistent/userAssignmentState.js';
import { currentScopePathState } from '../../services/recoil/persistent/currentScopePathState.js';
import { kaeplaAssignmentState } from '../../services/recoil/persistent/kaeplaAssignmentState.js';
import { cleanColumnName } from '../helpers/cleanColumnName.js';

import { PathElement } from './features/PathElement.js';
import { addDimensionToPath } from './helpers/addDimensionToPath.js';
import { getDimensionsAtPath } from './helpers/getDimensionsAtPath.js';
import { handlePathChange } from './helpers/handlePathChange.js';

export const ScopeNavigation = () => {
  const theme = useTheme();
  const { logOut } = useAuth();
  const navigate = useNavigate();
  const breakPointMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const { kaeplaUser } = useAuth();
  const { setError } = useError();
  // get
  const app = useRecoilValue(applicationState);
  const project = useRecoilValue(projectState);
  const matrixFiltered = useRecoilValue(matrixFilteredState);
  const resetMatrixFiltered = useResetRecoilState(matrixFilteredState);
  // const matrixLoading = useRecoilValue(matrixLoadingState);
  const projectAssignments = useRecoilValue(projectAssignmentsState);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  // get & set
  const [model, setModel] = useRecoilState(modelState);
  const [currentScopePath, setCurrentScopePath] = useRecoilState(currentScopePathState);
  const [scopePaths, setScopePaths] = useRecoilState(scopePathsState);
  // local
  const [pathDetails, setPathDetails] = useState<MatrixPathDetails>();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getPathForNavigation = useCallback(() => {
    // scopePaths are set when we navigate the app
    const scopePathFromNavigation = scopePaths[project.id];
    let scopePathFromLastVisit: string[] | undefined;

    // if we can't find a scope path, see ich we have a persisted one
    // check if our current assignments have this path
    if (!scopePathFromNavigation && currentScopePath) {
      const currentPathHasAssignment = projectAssignments
        .filter((a) => a.projectId === project.id)
        .find((assignment) => assignment.scopePathStringified === JSON.stringify(currentScopePath));

      if (currentPathHasAssignment?.id ?? kaeplaAssignment) {
        scopePathFromLastVisit = currentScopePath;
      }
    }
    return scopePathFromNavigation ?? scopePathFromLastVisit;
  }, [currentScopePath, kaeplaAssignment, project?.id, projectAssignments, scopePaths]);

  useEffect(() => {
    if (!project?.id) return;
    // if (project.id !== 'test') return;
    if (!app.initialized) return; // not before app is not initialized
    // if (matrixLoading) return;
    if (project?.model) {
      setModel(project.model);
    }
    const path = getPathForNavigation();
    const parameters: KaeplaApiParameters = {
      q: 'dimensionDetailsForScope' as KaeplaQueryType,
      p: path,
      projectId: project.id,
      s: 'ScopeNavigation',
    };
    getFromKaepla({
      uid: kaeplaUser?.uid,
      params: parameters,
      callBack: (apiResponse) => {
        if (!apiResponse) return;
        const result = apiResponse.response as MatrixApiResponse;
        if (result) {
          setPathDetails(result as MatrixPathDetails);
        }
      },
      errorCallBack: (error) => {
        setError({ message: error.message });
        void clientEventService.createEvent({
          assignmentScope: clientEventService.assignmentScope.PROJECT,
          eventGroup: clientEventService.eventGroup.SCOPES,
          eventName: clientEventService.eventName.SCOPES_REQUEST_PATH_INFO,
          logLevel: clientEventService.logLevel.ERROR,
          uid: kaeplaUser?.uid,
          projectId: project.id,
          customerId: project.customerId,
          resellerId: project.resellerId,
          scopePath: currentScopePath,
          payload: { errorMessage: error.message },
        });

        // eslint-disable-next-line no-console
        console.log(error);

        if (error.message === 'Only for authenticated users!') {
          void logOut();
        }

        navigate('/');
      },
    });
  }, [
    app.initialized,
    currentScopePath,
    getPathForNavigation,
    kaeplaUser?.uid,
    // matrixLoading,
    project,
    setError,
    setModel,
    logOut,
    navigate,
  ]);

  const PathRoot = () => {
    let disabled = false;

    const rootAssignments = projectAssignments.filter(
      (assignment) => assignment.projectId === project.id && assignment.scopePath.length === 0,
    );

    if (rootAssignments.length === 0 && !kaeplaAssignment) {
      disabled = true;
    }

    return (
      <MenuItem
        disabled={disabled}
        component={Box}
        onClick={() => {
          if (scopePaths?.[project.id]?.length === 0) {
            return;
          }
          const newScopePaths: Record<string, string[]> = {};
          newScopePaths[project.id] = [];
          setScopePaths(newScopePaths);
          setCurrentScopePath(newScopePaths[project.id]);
        }}
        dense
        sx={{ p: 0 }}
      >
        {project.name || 'Project'}
      </MenuItem>
    );
  };

  const getBreadCrumbs = () => {
    const _breadcrumbs: ReactElement[] = [];
    if (!project) return _breadcrumbs;
    _breadcrumbs.push(<PathRoot key="root" />);

    const scopePath = getPathForNavigation();

    if (scopePath === undefined) return _breadcrumbs;
    if (scopePath.length > 0) {
      scopePath.forEach((level, index) => {
        if (!level.includes(':')) {
          // console.log("dimension value not selected");
          return;
        }

        const pathPart = [...scopePath].slice(0, index + 1);

        /*
        
        See which path levels should be enabled
        [] = root, then index starts with 0
        Sales Planning 23 -> BL2 -> Lighting -> Europe -> AUDI
        root -> 0 -> 1 -> 2 -> 3
        */

        let disabled = true;
        // see on which paths we have an assignment
        const pathAssignments = projectAssignments
          .filter((assignment) => assignment.projectId === project.id)
          .map((a) => a.scopePath);

        // note the path levels we have assignments for
        const pathAssignmentLevels: number[] = [];
        projectAssignments
          .filter((assignment) => assignment.projectId === project.id)
          .map((a) => a.scopePath.length)
          .forEach((l) => {
            const depth = l - 1;
            if (!pathAssignmentLevels.includes(depth)) {
              pathAssignmentLevels.push(depth);
            }
          });

        const shortestLevel = Math.min(...pathAssignmentLevels);

        // make a map of the assignments we have on each level
        const levelEntries: Record<number, string[]> = {};
        pathAssignmentLevels.forEach((l) => {
          if (!levelEntries[l]) {
            levelEntries[l] = [];
          }
          pathAssignments.forEach((path) => {
            if (path.length === l + 1 && !levelEntries[l].includes(path[l])) {
              levelEntries[l].push(path[l]);
            }
          });
        });

        // if we have at least one assignment on the current level,
        // we need this particular item activated
        const currentLevel = pathPart.length - 1;
        const assignmentsForThisLevel = levelEntries[currentLevel];
        let itemsForThisLevel: string[] = [];
        if (assignmentsForThisLevel && assignmentsForThisLevel.length > 0) {
          disabled = false;
          itemsForThisLevel = assignmentsForThisLevel.map((string_) => string_.split(':')[1]);
        }

        // if we have at least one assignment on a level higher,
        // we need this particular item AND the whole scope activated
        let parentIsEnabled = false;
        if (currentLevel > shortestLevel) {
          disabled = false;
          parentIsEnabled = true;
        }

        // admins can go everywhere
        if (kaeplaAssignment) {
          disabled = false;
          parentIsEnabled = true;
        }

        // get other dimensions
        const temporaryScopePath = [...scopePath].slice(0, index + 1);
        const otherDimensions = getDimensionsAtPath(model, temporaryScopePath);

        /*
        
        filter menu entries for items we have an assignment for

        */

        let items: string[] = [];
        let placeholder = 'Select dimension';
        const dimension = level.split(':')[0];
        let dimensionDetails: string[] = [];
        if (pathDetails?.pathDetails) {
          const pathPartDetail = pathDetails.pathDetails.find(
            (detail) => (detail.pathPart as unknown as string[]).join('-') === pathPart.join('-'),
          );
          if (pathPartDetail?.detail) {
            dimensionDetails = pathPartDetail.detail.map((row) => {
              return row.value;
            });
          }
        }

        if (dimensionDetails) {
          items = dimensionDetails;
          placeholder = cleanColumnName(dimension);
        }

        // make sure items also contain all itemsForThisLevel we have assignments on
        itemsForThisLevel.forEach((itemFromAssignments) => {
          if (!items.includes(itemFromAssignments)) {
            items.push(itemFromAssignments);
          }
        });

        _breadcrumbs.push(
          <PathElement
            disabled={disabled}
            parentIsEnabled={parentIsEnabled}
            placeholder={placeholder}
            level={level}
            index={index}
            items={items}
            activeItems={itemsForThisLevel}
            key={index}
            otherDimensions={otherDimensions}
            truncate={breakPointMdDown ? 12 : 25}
            scopePathLength={scopePath.length}
            callBack={(_index, newValue) => {
              if (!matrixFiltered) return;
              handlePathChange({
                matrix: matrixFiltered,
                projectId: project.id,
                scopePath,
                scopePaths,
                addDimensionToPath,
                setScopePaths,
                setCurrentScopePath,
                resetMatrixFiltered,
                index: _index,
                newValue,
              });
            }}
          />,
        );
      });
    }

    let modelPart: Record<string, unknown> = model.delegation || project?.model?.delegation;
    if (scopePath.length > 0) {
      const cleanPath = [...scopePath].map((item) => {
        return item.split(':')[0];
      });
      cleanPath.forEach((part) => {
        if (modelPart[part]) {
          modelPart = modelPart[part] as Record<string, unknown>;
        }
      });
    }

    if (modelPart) {
      const lastItems: string[] = Object.keys(modelPart);
      if (lastItems && lastItems.length > 0) {
        _breadcrumbs.push(
          <PathElement
            placeholder="dimension"
            key="new"
            level=""
            index={scopePath.length}
            items={lastItems}
            parentIsEnabled={true}
            truncate={breakPointMdDown ? 12 : 25}
            scopePathLength={scopePath.length}
            callBack={(index, newValue) => {
              if (!matrixFiltered) return;
              handlePathChange({
                matrix: matrixFiltered,
                projectId: project.id,
                scopePath,
                scopePaths,
                addDimensionToPath,
                setScopePaths,
                setCurrentScopePath,
                resetMatrixFiltered,
                index,
                newValue,
              });
            }}
          />,
        );
      }
    }
    return _breadcrumbs;
  };

  const breadcrumbs = getBreadCrumbs();

  if (!smUp) {
    let lastPathItem: string | undefined;
    if (currentScopePath) {
      lastPathItem = currentScopePath.at(-1);
    }
    return (
      <>
        <Breadcrumbs sx={{ ml: 2, fontSize: 12 }} onClick={handleClickOpen}>
          {!lastPathItem && (
            <Box component="span">
              <ScopesIcon sx={{ fontSize: 12 }} />
            </Box>
          )}
          {lastPathItem && <Box component="span">{lastPathItem}</Box>}
        </Breadcrumbs>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          aria-labelledby="breadcrumbs-dialog"
          PaperProps={{ style: { overflowY: 'visible' } }}
        >
          <DialogContent style={{ overflowY: 'visible' }}>
            <Breadcrumbs
              sx={{ marginLeft: 5 }}
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Done
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  return (
    <Breadcrumbs
      maxItems={2}
      sx={{ marginLeft: 5 }}
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {breadcrumbs}
    </Breadcrumbs>
  );
};
