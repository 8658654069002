import { KaeplaImport, KaeplaOpsUpdateStatus } from '@kaepla/types';

export const isImportDead = (_dataImport: KaeplaImport) => {
  if (!_dataImport) return false;
  return [
    KaeplaOpsUpdateStatus.failed,
    KaeplaOpsUpdateStatus.canceled,
    KaeplaOpsUpdateStatus.cancelRequested,
  ].includes(_dataImport.updateStatus);
};
