import { KaeplaEventGroup } from '@kaepla/events';
import SimulationIcon from '@mui/icons-material/AutoGraphOutlined';
import AssignmentsIcon from '@mui/icons-material/ChecklistOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExportIcon from '@mui/icons-material/DownloadOutlined';
import TargetsIcon from '@mui/icons-material/FilterCenterFocusOutlined';
import DataSnapshotsIcon from '@mui/icons-material/History';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import ResellerIcon from '@mui/icons-material/KeyOutlined';
import ScopesIcon from '@mui/icons-material/LayersOutlined';
import AiIcon from '@mui/icons-material/ModelTrainingOutlined';
import PerspectivesIcon from '@mui/icons-material/QueryStatsOutlined';
import CustomerIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import ImportIcon from '@mui/icons-material/SyncOutlined';

import { IS_DEV } from '../../../../../../../mode';
import { UnhandledCaseError } from '../../../../../../helpers/UnhandledCaseError';
import {
  alignmentsColor,
  assignmentsColor,
  customersColor,
  dataSnapshotColor,
  experimentalAiColor,
  exportColor,
  importColor,
  perspectivesColor,
  projectsColor,
  resellersColor,
  scopesColor,
  simulationsColor,
  targetsColor,
} from '../../../../../defaults';

interface Options {
  eventGroup: KaeplaEventGroup;
}

export const EventGroupIcon = ({ eventGroup }: Options) => {
  switch (eventGroup) {
    case KaeplaEventGroup.ADMIN: {
      return <InfoIcon fontSize="small" />;
    }
    case KaeplaEventGroup.IMPORT: {
      return <ImportIcon sx={{ color: importColor }} fontSize="small" />;
    }
    case KaeplaEventGroup.DATA_EXPORT: {
      return <ExportIcon sx={{ color: exportColor }} fontSize="small" />;
    }
    case KaeplaEventGroup.DATA_SNAPSHOT: {
      return <DataSnapshotsIcon sx={{ color: dataSnapshotColor }} fontSize="small" />;
    }
    case KaeplaEventGroup.ALIGNMENTS: {
      return <InfoIcon sx={{ color: alignmentsColor }} fontSize="small" />;
    }
    case KaeplaEventGroup.AI: {
      return <AiIcon sx={{ color: experimentalAiColor }} fontSize="small" />;
    }
    case KaeplaEventGroup.ASSIGNMENTS: {
      return <AssignmentsIcon sx={{ color: assignmentsColor }} fontSize="small" />;
    }
    case KaeplaEventGroup.CUSTOMER: {
      return <CustomerIcon sx={{ color: customersColor }} fontSize="small" />;
    }
    case KaeplaEventGroup.PERSPECTIVES: {
      return <PerspectivesIcon sx={{ color: perspectivesColor }} fontSize="small" />;
    }
    case KaeplaEventGroup.PROJECTS: {
      return <DashboardIcon sx={{ color: projectsColor }} fontSize="small" />;
    }
    case KaeplaEventGroup.RESELLER: {
      return <ResellerIcon sx={{ color: resellersColor }} fontSize="small" />;
    }
    case KaeplaEventGroup.SCOPES: {
      return <ScopesIcon sx={{ color: scopesColor }} fontSize="small" />;
    }
    case KaeplaEventGroup.SIMULATIONS: {
      return <SimulationIcon sx={{ color: simulationsColor }} fontSize="small" />;
    }
    case KaeplaEventGroup.TARGETS: {
      return <TargetsIcon sx={{ color: targetsColor }} fontSize="small" />;
    }
    default: {
      if (IS_DEV) {
        throw new UnhandledCaseError(eventGroup);
      }
      return <InfoIcon fontSize="small" />;
    }
  }
};
