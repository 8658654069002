import { KaeplaUser } from '@kaepla/types';

import { UserAvatar } from '../../../../../../features/UserAvatar';

interface Options {
  uid?: string;
  knownUsers: KaeplaUser[];
}

export const EventUser = ({ uid, knownUsers }: Options) => {
  if (!uid) return null;
  const user = knownUsers.find((k) => k.uid === uid);
  if (!user) return null;
  return <UserAvatar user={user} />;
};
