import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Autocomplete,
  AutocompleteCloseReason,
  Box,
  Button,
  ClickAwayListener,
  Popper,
  TextField,
} from '@mui/material';
import React from 'react';

import {
  AvailableFilterColumn,
  EventLogFilterColumn,
  EventLogFilterContext,
} from '../../../../contexts/EventLogFilter.context';

const priorityFilters = new Set<EventLogFilterColumn>(['projectId', 'uid']);

interface Properties {
  activeFilters: EventLogFilterColumn[];
  setActiveFilters: (options: EventLogFilterColumn[]) => void;
}

export const EventLogAddFilter: React.FC<Properties> = ({ activeFilters, setActiveFilters }) => {
  const { eventLogAvailableFilterColumns: eventLogAvailableColumns } =
    React.useContext(EventLogFilterContext);

  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null);

  const [addFilterValue, setAddFilterValue] = React.useState<AvailableFilterColumn>();

  const onAddFilter = React.useCallback(
    (
      _: React.SyntheticEvent,
      newValue: { column: EventLogFilterColumn; label: string; group: string } | null,
    ) => {
      if (newValue?.column) {
        setActiveFilters([...activeFilters, newValue.column]);
        setAddFilterValue(newValue);
      }
      // eslint-disable-next-line unicorn/no-useless-undefined
      setAddFilterValue(undefined);
      setAnchorElement(null);
    },
    [activeFilters, setActiveFilters],
  );

  const availableFilterNames = React.useMemo(() => {
    return eventLogAvailableColumns
      .filter((filter) => !activeFilters.includes(filter.column))
      .map((filter) => ({
        group: priorityFilters.has(filter.column) ? 'Top' : 'Others',
        column: filter.column,
        label: filter.label,
      }))
      .sort((a, b) => b.group.localeCompare(a.group));
  }, [activeFilters, eventLogAvailableColumns]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    if (anchorElement) {
      anchorElement.focus();
    }
    setAnchorElement(null);
  };

  const open = Boolean(anchorElement);
  const id = open ? 'add-filter' : undefined;

  return (
    <>
      <Button disableRipple aria-describedby={id} onClick={handleClick}>
        <AddCircleOutlineIcon />
        <Box component="span" paddingLeft={1}>
          Add Filter
        </Box>
      </Button>

      <Popper
        id={id}
        open={open}
        anchorEl={anchorElement}
        placement="bottom-start"
        sx={{
          backgroundColor: 'background.paper',
          border: '1px solid #e1e4e8',
          borderRadius: '6px',
          boxShadow: '0 8px 24px rgba(149, 157, 165, 0.2)',
          color: '#24292e',
          fontSize: '13px',
          width: '300px',
          maxWidth: '100%',
          zIndex: 1300,
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Autocomplete
            open
            size="small"
            options={availableFilterNames}
            onChange={onAddFilter}
            getOptionKey={(option) => option.column}
            getOptionLabel={(option) => option.label}
            groupBy={(option) => option.group}
            value={addFilterValue ? { ...addFilterValue, group: 'Top' } : undefined}
            onClose={(_, reason: AutocompleteCloseReason) => {
              if (reason === 'escape') {
                handleClose();
              }
            }}
            renderInput={(parameters) => {
              return (
                <TextField
                  color="primary"
                  {...parameters}
                  autoFocus
                  label="add Filter"
                  variant="outlined"
                />
              );
            }}
            disabled={activeFilters.length > 9}
          />
        </ClickAwayListener>
      </Popper>
    </>
  );
};
