import { TextField, Button, Stack, Typography, Box, Link, Alert } from '@mui/material';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { KaeplaConnector } from '@kaepla/types';

import { useAuth } from '../../../../AuthReactProvider';
import { manageConnector } from '../../../../services/api/manageConnector';
import { selectedCustomerState } from '../../../../services/recoil/nonpersistent/selectedCustomer';
import { salesForceConnectorCredentials } from '../salesForceConnectorCredentials';
import { KaeplaConnectorType } from '../types';

// this helps in development
const demo: Record<string, string> = {
  // url: 'https://test.salesforce.com/',
  // clientId: '3MVG9T992fY2Y4vvVe51RJV7QanO7aO9C5Z5Iz_d0tDHSnsqs02yd.XQktxBMWi8yvc7eg9NE_iGcM0inpgGv',
  // clientSecret: '0FEE2F028C6E9E07D30DFBACAADA4D4FBD154CA5B327C1690AFD9D98B2199A18',
  // user: 'andreas.geiger@xotigo.enterprise.demo',
  // password: 'yhu1etp0vwd*VNQ-tzf',
  // securityToken: 'eYsMqghc0nZuBpqRDlvLuSUI',
};

interface Options {
  connectorType: KaeplaConnectorType;
  setConnectorType: React.Dispatch<React.SetStateAction<KaeplaConnectorType | undefined>>;
  connectorForEdit: KaeplaConnector | undefined;
  setConnectorForEdit: React.Dispatch<React.SetStateAction<KaeplaConnector | undefined>>;
}

export const SalesForceSetup = ({
  connectorType,
  setConnectorType,
  connectorForEdit,
  setConnectorForEdit,
}: Options) => {
  const { kaeplaUser } = useAuth();
  const selectedCustomer = useRecoilValue(selectedCustomerState);
  const [formData, setFormData] = useState<Record<string, unknown>>({
    ...connectorForEdit,
    ...demo,
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFormData = { ...formData, [event.target.name]: event.target.value };
    setFormData(newFormData);
  };

  const saveConnector = () => {
    if (!kaeplaUser || !selectedCustomer) return;
    const credentials: Record<string, unknown> = {};
    salesForceConnectorCredentials.forEach((field) => {
      if (formData[field.name]) {
        credentials[field.name] = formData[field.name];
      }
    });
    const connector: Record<string, unknown> = {
      name: formData.name,
      description: formData.description,
      connectorTypeId: connectorType.id,
      customerId: selectedCustomer.id,
    };
    if (connectorForEdit) {
      connector.id = connectorForEdit.id;
    }

    void manageConnector({
      params: {
        uid: kaeplaUser.uid,
        customerId: selectedCustomer.id,
        credentials,
        connector,
      },
      callBack: (data) => {
        if (data.success) {
          setSuccess(true);
          setTimeout(() => {
            setConnectorType(undefined);
            setConnectorForEdit(undefined);
          }, 1000);
          return;
        }
        setError('Could not save connector');
      },
      errorCallBack: (backendError) => {
        setError(backendError);
      },
      setLoading,
    });
  };

  return (
    <Box sx={{ minWidth: 300, maxWidth: 500 }}>
      <Stack direction="column" spacing={2}>
        <Typography variant="h3">Setup SalesForce Connector</Typography>
        <Typography variant="body1">Here you can configure your SalesForce connector.</Typography>
        {connectorType.infoUrl && (
          <Typography variant="body1">
            For more information, please visit the{' '}
            <Link href={connectorType.infoUrl}>SalesForce documentation</Link>
          </Typography>
        )}
        {loading && <Alert severity="info">Setting up your connector...</Alert>}
        {success && (
          <Alert severity="info">
            Connector saved successfully. You will be redirected to the connector list in a few
            seconds.
          </Alert>
        )}
        {error && (
          <Alert
            severity="error"
            onClose={() => {
              setLoading(false);
              setError(undefined);
            }}
          >
            {error}
          </Alert>
        )}
      </Stack>
      <Stack
        direction="column"
        spacing={2}
        sx={{
          mt: 2,
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <Typography variant="caption">
          If you only change name and/or description, the credentials section will be ignored.
        </Typography>
        <TextField
          fullWidth
          size="small"
          label="name"
          name="name"
          disabled={loading}
          value={formData.name}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          size="small"
          label="description"
          name="description"
          disabled={loading}
          value={formData.description}
          onChange={handleChange}
        />
        <Typography variant="body1">Credentials</Typography>
        <Typography variant="caption">
          Credentials are submitted once and stored in a format which cannot be read. Please provide
          a new valid set of credentials each time you want to change one of its items.
        </Typography>
        {salesForceConnectorCredentials.map((field) => (
          <TextField
            key={field.name}
            fullWidth
            size="small"
            placeholder={field.name}
            name={field.name}
            type={field.type}
            disabled={loading}
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autoComplete: 'off',
              },
            }}
            value={formData[field.name] || ''}
            onChange={handleChange}
          />
        ))}
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            disabled={loading}
            onClick={() => {
              saveConnector();
            }}
          >
            Save
          </Button>
          <Button
            disabled={loading}
            onClick={() => {
              setConnectorType(undefined);
              setConnectorForEdit(undefined);
            }}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};
