import { cleanColumnName } from '../../../../../helpers/cleanColumnName.js';
import { KaeplaChartDatasets } from '../features/StackedBarChart/getChartDataForStackedBarChart.js';

import { createPaletteForLabelAndDataset, defaultChartColor } from './chartColors.js';

export interface KaeplaChartLabel {
  label: string;
  datasetName: string;
  color: string;
  isToggled: boolean;
}

export const createChartLabels = (result: KaeplaChartDatasets, label: string) => {
  const uniqueDatasets = Array.from(new Set(result.datasets.map((dataset) => dataset.label)));
  const palette = createPaletteForLabelAndDataset(label, uniqueDatasets);

  const chartLabels: KaeplaChartLabel[] = uniqueDatasets.map((datasetLabel) => ({
    datasetName: datasetLabel,
    label: cleanColumnName(datasetLabel).trim(),
    color: palette.map.get(datasetLabel) ?? defaultChartColor,
    isToggled: false,
  }));

  return chartLabels;
};
