/* eslint-disable @typescript-eslint/ban-ts-comment */

import { KaeplaImport } from '@kaepla/types';
import { useQuery } from '@tanstack/react-query';

import { Pagination } from '../../repository/base';
import { getCustomerPath } from '../../repository/Customer';
import { GetImportListParameters, getImportList } from '../../repository/Import';
import { useCreateQueryKey } from '../useCreateQueryKey';

interface UseQueryOptions {
  refetchInterval?: number;
}

export function useImportQueryFindAll(
  parameters: GetImportListParameters & Pagination<KaeplaImport>,
  options?: UseQueryOptions,
) {
  const { queryKey } = useCreateQueryKey(getCustomerPath, parameters);

  const query = useQuery({
    throwOnError: true,
    queryKey,
    queryFn: () => {
      if (!parameters) {
        return [];
      }
      return getImportList(parameters);
    },
    refetchInterval: options?.refetchInterval,
  });
  return query;
}
