import AddIcon from '@mui/icons-material/Add';
import { Fab } from '@mui/material';

interface Options {
  setNewConnector: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CreateConnectorFab = ({ setNewConnector }: Options) => {
  return (
    <Fab
      color="primary"
      aria-label="add connector"
      data-testid="connector-add"
      sx={{ position: 'fixed', bottom: 20, right: 20 }}
      onClick={() => {
        setNewConnector(true);
      }}
    >
      <AddIcon />
    </Fab>
  );
};
