import { Alert } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { KaeplaProject } from '@kaepla/types';

import { deleteProject } from '../../../../services/firestore/deleteProject.js';
import { projectsOverviewState } from '../../../../services/recoil/nonpersistent/projectsOverviewState.js';

interface Options {
  project: KaeplaProject;
  open: boolean;
  handleClose: () => void;
  handleMenuClose: () => void;
}

export const ProjectDeleteDialog = ({ project, open, handleClose, handleMenuClose }: Options) => {
  const [projectsOverview, setProjectsOverview] = useRecoilState(projectsOverviewState);
  const [deleting, setDeleting] = useState(false);

  const deletingOnServer = async () => {
    setDeleting(true);
    const newProjectsOverview = [...projectsOverview].filter(
      (_project) => _project.id !== project.id,
    );
    setProjectsOverview(newProjectsOverview);
    void deleteProject({ project });
    // to see the new name we need to wait for the trigger on the server to fire
    await new Promise((resolve) => setTimeout(resolve, 6000));
    setDeleting(false);
    handleClose();
    handleMenuClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle data-testid="dialog-title">Delete the project</DialogTitle>
      <DialogContent>
        <DialogContentText data-testid="delete-info">
          This will delete everything in your project and can't be undone. Along with the project
          associated data, project assignments, simulations, targets, commitments will be deleted.
        </DialogContentText>
        {deleting && (
          <Alert
            data-testid="delete-progress-message"
            sx={{ mt: 1, mb: 1 }}
            variant="outlined"
            severity="info"
          >
            Deleting Project in progress...
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button data-testid="button-cancel-delete" onClick={handleClose} disabled={deleting}>
          Cancel
        </Button>
        <Button
          color="error"
          variant="contained"
          data-testid="button-delete"
          disabled={deleting}
          onClick={() => {
            void deletingOnServer();
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
