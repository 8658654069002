import AddIcon from '@mui/icons-material/Add';
import { Fab } from '@mui/material';
import { randCompanyName, randProduct, randImg, randUrl } from '@ngneat/falso';
import { Timestamp } from 'firebase/firestore';
import { useRecoilState, useRecoilValue } from 'recoil';
import { KaeplaReseller, FirestoreTimestamp } from '@kaepla/types';

import { useAuth } from '../../../../AuthReactProvider.js';
import { createReseller } from '../../../../services/firestore/createReseller.js';
import { knownResellersState } from '../../../../services/recoil/nonpersistent/knownResellersState.js';
import { kaeplaAssignmentState } from '../../../../services/recoil/persistent/kaeplaAssignmentState.js';
import { getRandomAvatar } from '../../../helpers/getRandomAvatar.js';

export const CreateResellerFab = () => {
  const { kaeplaUser } = useAuth();
  const [knownResellers, setKnownResellers] = useRecoilState(knownResellersState);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  if (!kaeplaUser) return null;
  const save = async () => {
    if (!kaeplaUser) return;
    const companyName = randCompanyName();
    // console.log(variants[random]);
    const reseller: Partial<KaeplaReseller> = {
      name: companyName,
      appName: randProduct().title,
      logoUrl: randImg(),
      linkUrl: randUrl(),
      createdBy: kaeplaUser.uid,
      createdAt: Timestamp.now() as FirestoreTimestamp,
      updatedAt: Timestamp.now() as FirestoreTimestamp,
      iconBase64: getRandomAvatar(companyName),
      isReadOnly: false,
      projectCount: 0,
    };
    const finalReseller = await createReseller({ reseller });
    setKnownResellers([...knownResellers, finalReseller]);
  };
  return (
    <Fab
      color="primary"
      aria-label="add reseller"
      data-testid="reseller-add"
      sx={{ position: 'fixed', bottom: 20, right: 20 }}
      onClick={() => {
        void save();
      }}
      disabled={!kaeplaAssignment?.canCreateReseller}
    >
      <AddIcon />
    </Fab>
  );
};
