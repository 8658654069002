import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import { KaeplaCustomer, KaeplaReseller } from '@kaepla/types';

import { deleteCustomer } from '../../../../services/firestore/deleteCustomer.js';
import { CustomerAvatar } from '../../../features/CustomerAvatar.js';
import { ResellerAvatar } from '../../../features/ResellerAvatar.js';

interface DDOptions {
  openDelete: boolean;
  handleCloseDelete: () => void;
  customer: KaeplaCustomer;
  reseller?: KaeplaReseller;
}
export const CustomerDeleteDialog = ({
  openDelete,
  handleCloseDelete,
  customer,
  reseller,
}: DDOptions) => {
  return (
    <Dialog open={openDelete} onClose={handleCloseDelete}>
      <DialogTitle data-testid="dialog-title">
        Delete customer?
        <IconButton
          size="small"
          data-testid="button-cancel-delete"
          sx={{ position: 'absolute', top: 0, right: 0 }}
          onClick={handleCloseDelete}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <CardHeader
          avatar={<CustomerAvatar customer={customer} />}
          title={
            <Tooltip title={customer.id}>
              <Box>{customer.name}</Box>
            </Tooltip>
          }
          subheader={customer.appName}
          action={
            <IconButton aria-label="settings" edge="end">
              {reseller && (
                <ResellerAvatar
                  title={reseller.name}
                  icon={reseller.iconBase64}
                  small
                  id={reseller.id}
                />
              )}
            </IconButton>
          }
        />
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="button-delete"
          size="small"
          variant="contained"
          color="error"
          onClick={() => {
            handleCloseDelete();
            void deleteCustomer({ customerId: customer.id });
          }}
        >
          delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
