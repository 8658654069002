import { getApp } from 'firebase/app';
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import { KaeplaConnector } from '@kaepla/types';

interface Options {
  customerId: string;
}

export const getConnectorsForCustomer = async ({ customerId }: Options) => {
  const data: KaeplaConnector[] = [];
  if (!customerId) return data;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `customers/${customerId}/connectors`;
  const firestoreCollection = collection(firestoreDatabase, fireStorePath);
  const snapshot = await getDocs(firestoreCollection);
  snapshot.forEach((document_) => {
    const filter = document_.data();
    filter.id = document_.id;
    data.push(filter as KaeplaConnector);
  });
  return data;
};
