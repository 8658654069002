import StarIcon from '@mui/icons-material/StarOutline';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  IconButton,
  Typography,
} from '@mui/material';
import { Timestamp } from 'firebase/firestore';
import pluralize from 'pluralize';
import { Dispatch, SetStateAction } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { KaeplaFilter } from '@kaepla/types';

import { useAuth } from '../../../../AuthReactProvider.js';
import { deleteFilter } from '../../../../services/firestore/deleteFilter.js';
import { updateFilter } from '../../../../services/firestore/updateFilter.js';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState.js';
import { filterSettingsState } from '../../../../services/recoil/persistent/filterSettingState.js';
import { filterSqlState } from '../../../../services/recoil/persistent/filterSqlState.js';
import { filterTreeState } from '../../../../services/recoil/persistent/filterTreeState.js';
import { kaeplaAssignmentState } from '../../../../services/recoil/persistent/kaeplaAssignmentState.js';
import { knownUsersState } from '../../../../services/recoil/persistent/knownUsersState.js';
import { toDoLog } from '../../../helpers/logger.js';
import { InlineEdit } from '../../InlineEdit.js';
import { UserAvatar } from '../../UserAvatar.js';
import { getRuleCountFromFilterTree } from '../helpers/getRuleCountFromFilterTree.js';

interface Options {
  filter: KaeplaFilter<Timestamp>;
  selected: boolean;
  setChoosePreset: Dispatch<SetStateAction<boolean>>;
}

export const FilterPresetCard = ({ filter, selected, setChoosePreset }: Options) => {
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const knownUsers = useRecoilValue(knownUsersState);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const resetFilterTree = useResetRecoilState(filterTreeState);
  const setFilterSql = useSetRecoilState(filterSqlState);
  const resetFilterSql = useResetRecoilState(filterSqlState);
  const resetFilterSettings = useResetRecoilState(filterSettingsState);
  const [filterSettings, setFilterSettings] = useRecoilState(filterSettingsState);
  const rulesCount = getRuleCountFromFilterTree(filter.filterTreeStringified);
  const user = knownUsers.find((k) => k.uid === filter.createdBy);
  return (
    <Card elevation={0} sx={{ border: '1px solid #ccc' }}>
      <CardContent sx={{ pb: 0 }}>
        <Typography component="div" noWrap sx={{ fontSize: '100%' }}>
          <InlineEdit
            fullWidth
            value={filter.name}
            callback={(newValue) => {
              const newFilterPreset = { ...filter };
              newFilterPreset.name = newValue;
              void updateFilter({ project, filter: newFilterPreset });
            }}
            readOnly={filter.createdBy !== kaeplaUser?.uid}
          />
        </Typography>
      </CardContent>
      <CardContent>
        <IconButton
          size="small"
          edge="start"
          sx={{ mr: 1, ml: -1, display: 'none' }}
          onClick={() => {
            toDoLog.log('set as favorite');
          }}
        >
          <StarIcon />
        </IconButton>
        {user && <UserAvatar sx={{ mr: 1 }} user={user} size={25} />}
        <Chip
          label={`${pluralize('rule', rulesCount, true)}`}
          variant="outlined"
          color="primary"
          size="small"
        />
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            setFilterSql(filter.filterSql);
            setFilterSettings({
              ...filterSettings,
              filterPresetId: filter.id,
              isActive: true,
              filterCreatedBy: filter.createdBy,
            });
            setChoosePreset(false);
          }}
          disabled={selected}
        >
          choose
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            resetFilterTree();
            resetFilterSql();
            resetFilterSettings();
            setFilterSettings({
              ...filterSettings,
              filterPresetId: undefined,
              filterCreatedBy: undefined,
              isActive: false,
            });
            void deleteFilter({ project, filter });
          }}
          disabled={filter.createdBy !== kaeplaUser?.uid && !kaeplaAssignment}
        >
          delete
        </Button>
      </CardActions>
    </Card>
  );
};
