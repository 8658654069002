import { getApp } from 'firebase/app';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { KaeplaTargets } from '@kaepla/types';

interface Options {
  projectId: string;
  targetsId: string;
}
export const getTargets = async ({ projectId, targetsId }: Options) => {
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects/${projectId}/targets/${targetsId}`;
  const firestoreDocumentReference = doc(firestoreDatabase, fireStorePath);
  const snapshot = await getDoc(firestoreDocumentReference);
  return snapshot.data() as KaeplaTargets;
};
