import { Box, Paper, Stack, Typography } from '@mui/material';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

interface ErrorBoundaryProperties {
  scope?: string;
}

export function ErrorBoundary({ scope }: ErrorBoundaryProperties) {
  const error = useRouteError();
  if (isRouteErrorResponse(error)) {
    return (
      <Box display={'flex'} flexDirection={'column'} p={2}>
        <Paper>
          <Stack spacing={2} p={2}>
            <Typography variant="h2" color="textPrimary">
              {error.status} {error.statusText}
            </Typography>

            <Typography variant="inherit" color="textPrimary">
              {error.data}
            </Typography>
          </Stack>
        </Paper>
      </Box>
    );
  } else if (error instanceof Error) {
    return (
      <Box display={'flex'} flexDirection={'column'} p={2}>
        <Stack spacing={2}>
          {scope && (
            <Paper>
              <Typography p={2} variant="h1" color="textPrimary">
                Scope: {scope}
              </Typography>
            </Paper>
          )}
          <Paper>
            <Stack spacing={2} p={2}>
              <Typography variant="h2" color="textPrimary">
                {error.name}
              </Typography>
              <Typography variant="h3" color="textPrimary">
                {error.message}
              </Typography>
              <Typography variant="body2" color="textPrimary" component={'pre'}>
                {error.stack}
              </Typography>
            </Stack>
          </Paper>
        </Stack>
      </Box>
    );
  } else {
    // eslint-disable-next-line no-console
    console.error('Unknown error', error);
    return (
      <Box display={'flex'} flexDirection={'column'} p={2}>
        <Paper>
          <Stack spacing={2} p={2}>
            <Typography variant="caption" color="textPrimary">
              Unknown Error
            </Typography>
          </Stack>
        </Paper>
      </Box>
    );
  }
}
