import SetDefaultColumnsIcon from '@mui/icons-material/SaveOutlined';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { GridApi } from 'ag-grid-community';
import { useRecoilValue } from 'recoil';

import { useAuth } from '../../../../../../AuthReactProvider';
import { saveProjectSettingsMutation } from '../../../../../../services/firestore/projectSettings/saveProjectSettings.mutation';
import { dataViewState } from '../../../../../../services/recoil/nonpersistent/dataViewState';
import { projectState } from '../../../../../../services/recoil/nonpersistent/projectState';

interface GMOptions {
  anchorElement: HTMLElement | null;
  setAnchorElement: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  api: GridApi | undefined;
  setSuccessMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const GridMenuMoreMenu = ({
  anchorElement,
  setAnchorElement,
  api,
  setSuccessMessage,
}: GMOptions) => {
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const dataView = useRecoilValue(dataViewState);

  const saveDefaultTableConfig = () => {
    setSuccessMessage('Default columns saved');
    setTimeout(() => {
      setSuccessMessage(undefined);
    }, 3000);
    const gridState = api?.getState();
    if (gridState && kaeplaUser?.uid) {
      void saveProjectSettingsMutation({
        settingId: `default${dataView}Config`,
        projectId: project.id,
        setting: gridState,
        updatedBy: kaeplaUser.uid,
      });
    }
    setAnchorElement(null);
  };

  return (
    <Menu
      anchorEl={anchorElement}
      open={Boolean(anchorElement)}
      onClose={() => {
        setAnchorElement(null);
      }}
    >
      <MenuItem data-testid="project-rename" onClick={saveDefaultTableConfig}>
        <ListItemIcon>
          <SetDefaultColumnsIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Set default columns</ListItemText>
      </MenuItem>
    </Menu>
  );
};
