import { KaeplaCustomer } from '@kaepla/types';
import { collection, doc, FirestoreDataConverter, QueryDocumentSnapshot } from 'firebase/firestore';

import { FIREBASE } from '../base';

export type Data = KaeplaCustomer;

const converter = {
  toFirestore: (data: Data) => data,
  fromFirestore: (snap: QueryDocumentSnapshot<Data>) => snap.data(),
} satisfies FirestoreDataConverter<Data>;

export const getCustomerPath = 'customers';

export const getCustomerCollectionReference = collection(FIREBASE, getCustomerPath).withConverter(
  converter,
);

export const getCustomerReference = (id: string) => doc(getCustomerCollectionReference, id);
