import { getCleanPath } from '../../../ScopeNavigation/helpers/getCleanPath.js';

import { getScopeGraphNodes } from './getScopeGraphNodes.js';

interface ScopeGraphOption {
  delegation: Record<string, unknown>;
  scopePath: string[];
}
export const getScopeGraph = ({ delegation, scopePath }: ScopeGraphOption) => {
  let graph = ``;
  if (delegation) {
    graph = `graph LR\n`;
    graph = getScopeGraphNodes({
      graph,
      parent: 'root',
      nodes: delegation,
      scopePath: getCleanPath(scopePath),
    });

    graph = graph + `\nlinkStyle default stroke-width:2px,fill:none,stroke:lightgrey;`;
  }
  // console.log("delegation", delegation);
  // console.log("getCleanPath(delegationPath)", getCleanPath(delegationPath));
  // console.log(graph);
  return graph;
};
