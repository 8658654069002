import { atom } from 'recoil';
import { KaeplaPerspective } from '@kaepla/types';

import { localForageEffect } from '../persistent/localForageEffect.js';

const defaultPerspectivesState: KaeplaPerspective[] = [];

export const perspectivesState = atom<KaeplaPerspective[]>({
  key: 'perspectives',
  default: defaultPerspectivesState,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  effects_UNSTABLE: [localForageEffect],
});
