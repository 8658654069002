import { KaeplaFilter } from '@kaepla/types';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import { Divider, Grid2 as Grid, IconButton, Typography } from '@mui/material';
import { JsonTree, Utils as QbUtils } from '@react-awesome-query-builder/mui';
import { Timestamp } from 'firebase/firestore';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useAuth } from '../../../../AuthReactProvider.js';
import { knownProjectFiltersState } from '../../../../services/recoil/nonpersistent/knownProjectFiltersState.js';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState.js';
import { currentScopePathState } from '../../../../services/recoil/persistent/currentScopePathState.js';
import { filterSettingsState } from '../../../../services/recoil/persistent/filterSettingState.js';
import { filterSqlState } from '../../../../services/recoil/persistent/filterSqlState.js';
import { filterTreeState } from '../../../../services/recoil/persistent/filterTreeState.js';
import { filterConfigInit } from '../helpers/filterConfig.js';

import { FilterPresetCard } from './FilterPresetCard.js';

interface Options {
  setChoosePreset: Dispatch<SetStateAction<boolean>>;
}

export const FilterPresetSelector = ({ setChoosePreset }: Options) => {
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const filterSettings = useRecoilValue(filterSettingsState);
  const knownProjectFilters = useRecoilValue(knownProjectFiltersState);
  const setFilterSql = useSetRecoilState<string | undefined>(filterSqlState);
  const setFilterTree = useSetRecoilState(filterTreeState);
  const [filterPresets, setFilterPresets] = useState<KaeplaFilter<Timestamp>[]>([]);
  const [selectedFilterPreset, setSelectedFilterPreset] = useState<KaeplaFilter<Timestamp>>();

  useEffect(() => {
    if (!kaeplaUser) return;
    const loadFilterPresets = () => {
      if (filterSettings.filterPresetId) {
        const filterFromState = knownProjectFilters?.find(
          (_perspective) => _perspective.id === filterSettings.filterPresetId,
        );
        if (filterFromState) {
          const filterTreeStringified = filterFromState.filterTreeStringified;
          const knownTree = JSON.parse(filterTreeStringified) as JsonTree;
          setFilterTree({ ...knownTree, id: filterSettings.filterPresetId });

          const immutableTree = QbUtils.loadTree(knownTree);

          const filterSql = QbUtils.sqlFormat(immutableTree, filterConfigInit);
          setFilterSql(filterSql);

          setSelectedFilterPreset(filterFromState);
        } else {
          setSelectedFilterPreset(undefined);
        }
      }
      setFilterPresets(knownProjectFilters || []);
    };
    void loadFilterPresets();
  }, [
    kaeplaUser?.uid,
    project.id,
    currentScopePath,
    kaeplaUser,
    filterSettings.filterPresetId,
    knownProjectFilters,
    setFilterSql,
    setFilterTree,
  ]);

  return (
    <Grid container spacing={2} sx={{ p: 1 }}>
      <Grid size={12}>
        <Divider />
      </Grid>
      <Grid size={11}>
        <Typography noWrap sx={{ fontSize: '120%' }}>
          Filter Presets
        </Typography>
      </Grid>
      <Grid size={1} container justifyContent="flex-end">
        <IconButton
          data-testid="customer-menu-toggle"
          edge="end"
          size="small"
          onClick={(event) => {
            event.stopPropagation();
            setChoosePreset(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      {filterPresets.map((filter) => (
        <Grid key={filter.id} size={3}>
          <FilterPresetCard
            setChoosePreset={setChoosePreset}
            filter={filter}
            selected={filter.id === selectedFilterPreset?.id}
          />
        </Grid>
      ))}
      <Grid size={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};
