import { KaeplaProject } from '@kaepla/types';
import { collection, doc, FirestoreDataConverter, QueryDocumentSnapshot } from 'firebase/firestore';

import { FIREBASE } from '../base';

export type Data = KaeplaProject;

const converter = {
  toFirestore: (data: Data) => data,
  fromFirestore: (snap: QueryDocumentSnapshot<Data>) => snap.data(),
} satisfies FirestoreDataConverter<Data>;

export const getProjectPath = 'projects';

export const getProjectCollectionReference = collection(FIREBASE, getProjectPath).withConverter(
  converter,
);

export const getProjectReference = (id: string) => {
  return doc(getProjectCollectionReference, id);
};
