import { query as firestoreQuery, limit, where } from 'firebase/firestore';
import { isEmpty } from 'rambda';

import { Pagination } from '../base';

import { Data, getAiThreadCollectionReference } from './aiThread.base';

export interface GetAiThreadListQueryFilterBy {
  projectId?: string[];
  // TODO improve naming
  uid?: string[];
}

const DEFAULT_LIMIT = 100;

export const getAiThreadListQuery = ({
  projectId = [],
  uid = [],
  ...parameters
}: GetAiThreadListQueryFilterBy & Pagination<Data>) => {
  let q = firestoreQuery(
    getAiThreadCollectionReference,

    limit(parameters.limit ?? DEFAULT_LIMIT),
  );

  if (!isEmpty(uid)) {
    q = firestoreQuery(q, where('uid', 'in', uid));
  }

  if (!isEmpty(projectId)) {
    q = firestoreQuery(q, where('projectId', 'in', projectId));
  }
  return q;
};
