import { Timestamp } from 'firebase/firestore';
import { DateTime } from 'luxon';
import { FirestoreTimestamp, KaeplaOpsUpdateStatus } from '@kaepla/types';

import { DataSourceImport } from '../../types/DataSourceImport';

export const initialIhsVehicleImports: DataSourceImport[] = [
  {
    id: '3',
    createdAt: Timestamp.fromDate(
      DateTime.now().minus({ days: 30 }).toJSDate(),
    ) as FirestoreTimestamp,
    createdBy: 'user1',
    archived: false,
    inProduction: true,
    updateStatus: KaeplaOpsUpdateStatus.finished,
    matched: 8366,
    unmatched: 0,
  },
  {
    id: '2',
    createdAt: Timestamp.fromDate(
      DateTime.now().minus({ days: 62 }).toJSDate(),
    ) as FirestoreTimestamp,
    createdBy: 'user3',
    archived: false,
    inProduction: true,
    updateStatus: KaeplaOpsUpdateStatus.finished,
    matched: 8851,
    unmatched: 0,
  },
  {
    id: '1',
    createdAt: Timestamp.fromDate(
      DateTime.now().minus({ days: 97 }).toJSDate(),
    ) as FirestoreTimestamp,
    createdBy: 'user2',
    archived: false,
    inProduction: true,
    updateStatus: KaeplaOpsUpdateStatus.finished,
    matched: 8988,
    unmatched: 0,
  },
];
