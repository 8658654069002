import { brandModelsMap } from '../IHS-Vehicles/data/brandModelsMap';
import { fuelSystems } from '../IHS-Vehicles/data/fuelSystems';
import { fuelTypes } from '../IHS-Vehicles/data/fuelTypes';
import { regions } from '../IHS-Vehicles/data/regions';
import { vehicleSchema } from '../IHS-Vehicles/data/vehicleSchema';
import { IHS_VEHICLE_MATCH_TYPE_COUNTS } from '../config';
import { IhsVehicleFull } from '../types/IhsVehicleFull';
import { MatchType } from '../types/MatchType';

import { generateIhsMatch } from './generateIhsMatch';

const generateVin = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let vin = '';
  for (let index = 0; index < 17; index++) {
    vin += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return vin;
};

export const generateIhsVehicles = () => {
  const vehicles: IhsVehicleFull[] = [];
  const matchTypeCountsTotal = IHS_VEHICLE_MATCH_TYPE_COUNTS.reduce(
    (accumulator, current) => accumulator + current.count,
    0,
  );

  const brands = Object.keys(brandModelsMap);
  for (let index = 0; index < matchTypeCountsTotal; index++) {
    const brand = brands[Math.floor(Math.random() * brands.length)];
    const models = brandModelsMap[brand];
    const model = models[Math.floor(Math.random() * models.length)];
    const sop = Math.floor(Math.random() * (2024 - 2019 + 1)) + 2019;
    const eop = Math.floor(Math.random() * (2039 - 2028 + 1)) + 2028;
    const vin = generateVin();
    const matchTypePercentage = Math.random();
    let cumulativePercentage = 0;
    let matchType: MatchType | undefined;

    for (const matchTypeCount of IHS_VEHICLE_MATCH_TYPE_COUNTS) {
      const matchTypePercentageRange = matchTypeCount.count / matchTypeCountsTotal;
      cumulativePercentage += matchTypePercentageRange;

      if (matchTypePercentage <= cumulativePercentage) {
        matchType = matchTypeCount.matchType;
        break;
      }
    }

    const updates = matchType === MatchType.ONE2ONE ? Math.random() < 0.5 : undefined;
    const ihsVehicle: IhsVehicleFull = {
      id: String(index + 1),
      make: brand,
      model,
      sop,
      eop,
      vin,
      matchType,
      updates,
      volume: 0,
      matches: [],
      isFieldsExpanded: false,
      volumePercentChange: 0,
    };
    // this generates random volumes
    let totalVolume = 0;
    vehicleSchema.forEach((field) => {
      if (field.isCollapsible && field.isValueField) {
        const key = field.fieldName;
        const randomNumber = Math.floor(Math.random() * (5000 - 100 + 1)) + 100;
        ihsVehicle[key] = randomNumber;
        totalVolume += randomNumber;
      }
    });
    ihsVehicle.volume = totalVolume;
    const fuelType = fuelTypes[Math.floor(Math.random() * fuelTypes.length)];
    ihsVehicle.fuelType = fuelType;
    const fuelSystem = fuelSystems[Math.floor(Math.random() * fuelSystems.length)];
    ihsVehicle.fuelSystem = fuelSystem;
    ihsVehicle.volumePercentChange = Math.floor(Math.random() * 100) - 50;
    let region = regions[Math.floor(Math.random() * regions.length)];
    // ihsVehicle.region = region;

    // generate the matches

    // N2Zero -----> NEW VEHICLE
    switch (matchType) {
      case MatchType.ZERO2N: {
        ihsVehicle.matches = [];

        break;
      }
      case MatchType.ONE2ZERO: {
        const n2oneMatches: IhsVehicleFull[] = [];
        // fuelSystem and fuelType should be the same as the parent, region is different
        region = regions[Math.floor(Math.random() * regions.length)];
        n2oneMatches.push(
          generateIhsMatch(1, { ...ihsVehicle, fuelType, fuelSystem, region }, true),
        );
        ihsVehicle.matches = n2oneMatches;

        break;
      }
      case MatchType.N2ZERO: {
        const ihsVehicleCopy = { ...ihsVehicle };
        const n2oneMatches: IhsVehicleFull[] = [];
        // fuelSystem and fuelType should be the same as the parent, region is different
        const numberItems = Math.floor(Math.random() * 4) + 2; // Generate a random number between 2 and 5
        const usedRegions: string[] = [region];
        for (let indexItems = 0; indexItems < numberItems; indexItems++) {
          const regionsLeft = regions.filter((r) => !usedRegions.includes(r));
          region = regionsLeft[Math.floor(Math.random() * regionsLeft.length)];

          let itemVolume = 0;
          vehicleSchema.forEach((field) => {
            if (field.isCollapsible && field.isValueField) {
              const key = field.fieldName;
              const randomNumber = Math.floor(Math.random() * (5000 - 100 + 1)) + 100;
              ihsVehicleCopy[key] = randomNumber;
              itemVolume += randomNumber;
            }
          });

          usedRegions.push(region);
          n2oneMatches.push(
            generateIhsMatch(
              indexItems,
              { ...ihsVehicleCopy, fuelType, fuelSystem, region, volume: itemVolume },
              true,
            ),
          );
        }
        ihsVehicle.matches = n2oneMatches;

        vehicleSchema.forEach((field) => {
          if (field.isCollapsible && field.isValueField) {
            const key = field.fieldName;
            ihsVehicle[key] = n2oneMatches.reduce(
              (accumulator, current) => accumulator + (current[key] as number),
              0,
            );
          }
        });
        ihsVehicle.volume = n2oneMatches.reduce(
          (accumulator, current) => accumulator + current.volume,
          0,
        );

        break;
      }
      case MatchType.N2ONE: {
        ihsVehicle.region = region;
        const ihsVehicleCopy = { ...ihsVehicle };
        // fuelSystem and fuelType should be the same as the parent, region is different
        region = regions.filter((r) => r !== ihsVehicleCopy.region)[
          Math.floor(Math.random() * regions.length)
        ];
        const n2oneMatches: IhsVehicleFull[] = [];
        const numberItems = Math.floor(Math.random() * 4) + 2; // Generate a random number between 2 and 5
        const usedRegions: string[] = [region];
        for (let jdex = 0; jdex < numberItems; jdex++) {
          const regionsLeft = regions.filter((r) => !usedRegions.includes(r));
          region = regionsLeft[Math.floor(Math.random() * regionsLeft.length)];

          let itemVolume = 0;
          vehicleSchema.forEach((field) => {
            if (field.isCollapsible && field.isValueField) {
              const key = field.fieldName;
              const randomNumber = Math.floor(Math.random() * (5000 - 100 + 1)) + 100;
              ihsVehicleCopy[key] = randomNumber;
              itemVolume += randomNumber;
            }
          });
          const volumePercentChange = Math.floor(Math.random() * 100) - 50;
          const item = {
            ...ihsVehicleCopy,
            fuelType,
            fuelSystem,
            region,
            volume: itemVolume,
            volumePercentChange,
          };

          usedRegions.push(region);
          n2oneMatches.push(generateIhsMatch(jdex, item, true));
        }

        ihsVehicle.matches = n2oneMatches;

        vehicleSchema.forEach((field) => {
          if (field.isCollapsible && field.isValueField) {
            const key = field.fieldName;
            ihsVehicle[key] = n2oneMatches.reduce(
              (accumulator, current) => accumulator + (current[key] as number),
              0,
            );
          }
        });
        ihsVehicle.volume = n2oneMatches.reduce(
          (accumulator, current) => accumulator + current.volume,
          0,
        );

        break;
      }
      // No default
    }

    // UPDATES

    // ONE2ONE without updates -----> EXISTING UNCHANGED VEHICLE
    if (matchType === MatchType.ONE2ONE && !updates) {
      ihsVehicle.region = region;
      const ihsVehicleCopy = { ...ihsVehicle };
      const one2oneUnchangedMatches: IhsVehicleFull[] = [];
      let itemVolume = 0;
      vehicleSchema.forEach((field) => {
        if (field.isCollapsible && field.isValueField) {
          const key = field.fieldName;
          const randomNumber = Math.floor(Math.random() * (5000 - 100 + 1)) + 100;
          ihsVehicleCopy[key] = randomNumber;
          itemVolume += randomNumber;
        }
      });
      const item = { ...ihsVehicleCopy, fuelType, fuelSystem, region, volume: itemVolume };
      one2oneUnchangedMatches.push(generateIhsMatch(1, item, true));
      ihsVehicle.matches = one2oneUnchangedMatches;
    }

    // ONE2ONE with updates -----> EXISTING CHANGED VEHICLE
    else if (matchType === MatchType.ONE2ONE && updates) {
      ihsVehicle.region = region;
      const ihsVehicleCopy = { ...ihsVehicle };
      // fuelSystem and fuelType should be the same as the parent, region is different
      region = regions.filter((r) => r !== ihsVehicleCopy.region)[
        Math.floor(Math.random() * regions.length)
      ];
      const one2oneUpdatedMatches: IhsVehicleFull[] = [];
      let itemVolume = 0;
      vehicleSchema.forEach((field) => {
        if (field.isCollapsible && field.isValueField) {
          const key = field.fieldName;
          const randomNumber = Math.floor(Math.random() * (5000 - 100 + 1)) + 100;
          ihsVehicleCopy[key] = randomNumber;
          itemVolume += randomNumber;
        }
      });
      const item = { ...ihsVehicleCopy, fuelType, fuelSystem, region, volume: itemVolume };
      one2oneUpdatedMatches.push(generateIhsMatch(1, item, true));
      ihsVehicle.matches = one2oneUpdatedMatches;
    }

    vehicles.push(ihsVehicle);
  }

  return vehicles;
};
