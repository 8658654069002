import {
  KaeplaSimulationRuleset,
  MatrixSimulationParameterNumeric,
  MatrixSimulationParameterText,
  KaeplaSimulationRulesetWithParameters,
  KaeplaSimulationParameter,
  isKaeplaSimulationParameterNumeric,
  KaeplaSimulationPercentage,
  MatrixTimeSlice,
  KaeplaSimulationParameterNumeric,
  isKaeplaSimulationParameterText,
  KaeplaSimulationTextValue,
  KaeplaSimulationParameterText,
} from '@kaepla/types';

interface Options {
  rulesets: KaeplaSimulationRuleset[];
  parameters: (MatrixSimulationParameterNumeric | MatrixSimulationParameterText)[];
}

export const convertSimulationParametersToRulesets = ({ rulesets, parameters }: Options) => {
  const rulesetsWithParameters: KaeplaSimulationRulesetWithParameters[] = [];

  rulesets.forEach((ruleset) => {
    const newRuleset = { ...ruleset } as unknown as KaeplaSimulationRulesetWithParameters;
    const rulesetParameters: KaeplaSimulationParameter[] = [];
    const parameterDimensions: string[] = [];

    parameters
      .filter((p) => p.rulesetId === ruleset.id)
      .filter((sp) => isKaeplaSimulationParameterNumeric(sp))
      .forEach((parameter) => {
        if (!parameterDimensions.includes(parameter.dimension)) {
          const numericParameter = parameter as MatrixSimulationParameterNumeric;
          parameterDimensions.push(parameter.dimension);
          const percentages = parameters
            .filter((p) => p.dimension === parameter.dimension && p.rulesetId === ruleset.id)
            .map((p) => {
              const _numericParameter = p as MatrixSimulationParameterNumeric;
              const percentage: KaeplaSimulationPercentage = {
                key: _numericParameter.key,
                timeSlice: _numericParameter.timeSlice as MatrixTimeSlice,
                dateFrom: _numericParameter.dateFrom,
                dateUntil: _numericParameter.dateUntil,
                percentage: _numericParameter.percentage ?? 0,
                absoluteValue: _numericParameter.absoluteValue,
                originalValue: _numericParameter.originalValue,
                currency: _numericParameter.currency,
                decimalPositions: _numericParameter.decimalPositions,
              };
              return percentage;
            });
          const rulesetParameter: KaeplaSimulationParameterNumeric = {
            dimension: numericParameter.dimension,
            parameterType: numericParameter.parameterType,
            rulesetId: ruleset.id,
            aggregation: numericParameter.aggregation,
            percentages,
          };
          rulesetParameters.push(rulesetParameter);
        }
      });

    parameters
      .filter((p) => p.rulesetId === ruleset.id)
      .filter((sp) => isKaeplaSimulationParameterText(sp))
      .forEach((parameter) => {
        if (!parameterDimensions.includes(parameter.dimension)) {
          const numericParameter = parameter as MatrixSimulationParameterText;
          parameterDimensions.push(parameter.dimension);
          const values = parameters
            .filter((p) => p.dimension === parameter.dimension && p.rulesetId === ruleset.id)
            .map((p) => {
              const _textValue = p as MatrixSimulationParameterText;
              const value: KaeplaSimulationTextValue = {
                key: _textValue.key,
                originalTextValue: _textValue.originalTextValue,
                simulatedTextValue: _textValue.simulatedTextValue,
              };
              return value;
            });
          const rulesetParameter: KaeplaSimulationParameterText = {
            dimension: numericParameter.dimension,
            parameterType: numericParameter.parameterType,
            rulesetId: ruleset.id,
            values,
          };
          rulesetParameters.push(rulesetParameter);
        }
      });

    newRuleset.parameters = rulesetParameters;
    rulesetsWithParameters.push(newRuleset);
  });

  return rulesetsWithParameters;
};
