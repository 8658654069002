import { KaeplaGridSetStored, KaeplaGridSetting } from '@kaepla/types';
import AddGridStateIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import { Alert, Box, IconButton, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useLayoutEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';

import { dataGridSettings } from '../../../../../../services/recoil/nonpersistent/dataGridSets';
import { InlineEdit } from '../../../../../features/InlineEdit';

import { DeleteGridSet } from './DeleteGridSet';
import { SaveGridSet } from './SaveGridSet';

interface Options {
  loadGridState: (gridSettings: KaeplaGridSetting) => void;
  addGridStateToGridSet: () => void;
  selectedGridSet: KaeplaGridSetStored | null;
  setSelectedGridSet: React.Dispatch<React.SetStateAction<KaeplaGridSetStored | null>>;
  tabNumber: number;
  setTabNumber: React.Dispatch<React.SetStateAction<number>>;
}

export const GridSets = ({
  loadGridState,
  addGridStateToGridSet,
  selectedGridSet,
  setSelectedGridSet,
  tabNumber,
  setTabNumber,
}: Options) => {
  const [gridSettings, setGridSettings] = useRecoilState(dataGridSettings);
  const boxReference = useRef<HTMLDivElement>(null);
  const [editName, setEditName] = useState<number>();
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [tabsWidth, setTabsWidth] = useState<number>(250);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    event.preventDefault();
    if (newValue === undefined) return;
    if (tabNumber === newValue) {
      setEditName(newValue);
      return;
    }
    loadGridState(gridSettings[newValue]);
    setTabNumber(newValue);
  };

  useLayoutEffect(() => {
    const { offsetWidth } = boxReference.current!;
    setTabsWidth(offsetWidth - 100);
  }, []);

  return (
    <Box ref={boxReference} sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
        <Box
          sx={{
            width: '90%',
            maxWidth: tabsWidth,
          }}
        >
          {gridSettings.length > 0 && (
            <Tabs
              variant="scrollable"
              // scrollButtons
              // allowScrollButtonsMobile
              value={tabNumber}
              onChange={handleChange}
            >
              {gridSettings.map((gridSet, index) => {
                return (
                  <Box key={gridSet.id}>
                    <Stack direction="row">
                      {editName === index && (
                        <InlineEdit
                          value={gridSet.name}
                          fontSize="90%"
                          disableUnderline
                          autoFocus
                          callback={(newValue) => {
                            setEditName(undefined);
                            const newGridSets = [...gridSettings];
                            newGridSets[index] = { ...newGridSets[index], name: newValue };
                            setGridSettings(newGridSets);
                          }}
                        />
                      )}
                      {editName !== index && (
                        <Box
                          onClick={(event) => {
                            handleChange(event, index);
                          }}
                        >
                          <Tab label={gridSet.name} />
                        </Box>
                      )}
                      <Box sx={{ m: 1 }}>
                        <IconButton
                          size="small"
                          edge="end"
                          onClick={() => {
                            const newGridSets = gridSettings.filter(
                              (_, index_) => index_ !== index,
                            );
                            setGridSettings(newGridSets);
                          }}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Stack>
                  </Box>
                );
              })}
            </Tabs>
          )}
          {gridSettings.length === 0 && (
            <Alert severity="info" sx={{ mb: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <span>Create a new grid set by clicking the</span>
                <AddGridStateIcon sx={{ mx: 1 }} fontSize="small" />
                <span>button on the right.</span>
              </Box>
            </Alert>
          )}
        </Box>
        <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
        {gridSettings.length > 0 && (
          <IconButton
            size="small"
            color="primary"
            sx={{ p: '10px' }}
            onClick={() => {
              setOpenDeleteDialog(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
        )}
        {gridSettings.length > 0 && (
          <IconButton
            size="small"
            color="primary"
            sx={{ p: '10px' }}
            onClick={() => {
              setOpenSaveDialog(true);
            }}
          >
            <SaveIcon />
          </IconButton>
        )}
        <IconButton
          size="small"
          color="primary"
          sx={{ p: '10px' }}
          onClick={() => {
            addGridStateToGridSet();
          }}
        >
          <AddGridStateIcon />
        </IconButton>
      </Stack>
      <SaveGridSet
        openSaveDialog={openSaveDialog}
        setOpenSaveDialog={setOpenSaveDialog}
        selectedGridSet={selectedGridSet}
        setSelectedGridSet={setSelectedGridSet}
      />
      <DeleteGridSet
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        selectedGridSet={selectedGridSet}
      />
    </Box>
  );
};
