import { getApp } from 'firebase/app';
import { collection, doc, DocumentData, getFirestore, setDoc } from 'firebase/firestore';
import { KaeplaCustomer } from '@kaepla/types';

interface Options {
  customer: Partial<KaeplaCustomer>;
}

export const createCustomer = async ({ customer }: Options) => {
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `customers`;
  const firestoreCollectionReference = collection(firestoreDatabase, fireStorePath);
  const firestoreDocumentReference = doc(firestoreCollectionReference);
  const _customer = {
    id: firestoreDocumentReference.id,
    creationAccessDomain: globalThis.location.hostname,
    ...customer,
  } as KaeplaCustomer;
  await setDoc(firestoreDocumentReference, _customer as DocumentData);
};
