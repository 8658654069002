interface Customer {
  name: string;
  number: string;
}

function generateRandomString(length: number): string {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let result = '';
  for (let index = 0; index < length; index++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
}

const customers: Customer[] = [
  { name: 'FORD GROUP', number: generateRandomString(10) },
  { name: 'SAIC GROUP', number: generateRandomString(10) },
  { name: 'NEW EV GROUP', number: generateRandomString(10) },
  { name: 'INDIAN OEM', number: generateRandomString(10) },
  { name: 'TIERN', number: generateRandomString(10) },
  { name: 'INDIAN OTHERS', number: generateRandomString(10) },
  { name: 'GENERAL MOTORS GROUP', number: generateRandomString(10) },
  { name: 'Dongfeng', number: generateRandomString(10) },
  { name: 'OTHER OEM', number: generateRandomString(10) },
  { name: 'McLaren', number: generateRandomString(10) },
  { name: 'Togg', number: generateRandomString(10) },
  { name: 'Lucid Motors', number: generateRandomString(10) },
  { name: 'Hefei Changan', number: generateRandomString(10) },
  { name: 'BMW GROUP', number: generateRandomString(10) },
  { name: 'GEELY GROUP', number: generateRandomString(10) },
  { name: 'Lixiang', number: generateRandomString(10) },
  { name: 'Honda', number: generateRandomString(10) },
  { name: 'RENAULT-NISSAN-MITSUBISHI GROUP', number: generateRandomString(10) },
  { name: 'Huaxiang (Chengdu)', number: generateRandomString(10) },
  { name: 'MERCEDES-BENZ GROUP', number: generateRandomString(10) },
  { name: 'BUSINESS DEVELOPMENT', number: generateRandomString(10) },
  { name: 'STELLANTIS GROUP', number: generateRandomString(10) },
  { name: 'Arrival', number: generateRandomString(10) },
  { name: 'JIANGHUAI GROUP', number: generateRandomString(10) },
  { name: 'Chongqing Haideshi (Hi-Lex)', number: generateRandomString(10) },
  { name: 'DAIMLER TRUCK AG', number: generateRandomString(10) },
  { name: 'INDIAN TIERN', number: generateRandomString(10) },
  { name: 'HYUNDAI-KIA GROUP', number: generateRandomString(10) },
  { name: 'VOLKSWAGEN GROUP', number: generateRandomString(10) },
  { name: 'CHANGAN GROUP', number: generateRandomString(10) },
  { name: 'FAW GROUP', number: generateRandomString(10) },
  { name: 'BUS & TRUCK', number: generateRandomString(10) },
  { name: 'TOYOTA GROUP', number: generateRandomString(10) },
];

function getCustomerMaterialNumbers(_customers: Customer[]): Record<string, string[]> {
  const materialNumbers: Record<string, string[]> = {};
  _customers.forEach((customer) => {
    const numbers: string[] = [];
    for (let index = 0; index < 20; index++) {
      const generatedNumber = generateRandomString(10);
      const materialNumber = `${generatedNumber}${index + 1}`;
      numbers.push(materialNumber);
    }
    materialNumbers[customer.name] = numbers;
  });
  return materialNumbers;
}

const customerMaterialNumbers = getCustomerMaterialNumbers(customers);

export function getCustomerData(): { customer: Customer; materialNumbers: string[] }[] {
  const customerData: { customer: Customer; materialNumbers: string[] }[] = [];
  customers.forEach((customer) => {
    const materialNumbers = customerMaterialNumbers[customer.name];
    customerData.push({ customer, materialNumbers });
  });
  return customerData;
}
