import { getApp } from 'firebase/app';
import { getFirestore, collection, query, getDocs, where } from 'firebase/firestore';
import { KaeplaProjectAssignment } from '@kaepla/types';

interface Options {
  uid: string;
  projectId: string;
  scopePathStringified: string;
}

export const getProjectAssignmentsForUserAndScopePath = async ({
  uid,
  projectId,
  scopePathStringified,
}: Options) => {
  const data: KaeplaProjectAssignment[] = [];
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projectAssignments`;
  const firestoreCollection = collection(firestoreDatabase, fireStorePath);
  const firestoreQuery = query(
    firestoreCollection,
    where('uid', '==', uid),
    where('projectId', '==', projectId),
    where('scopePathStringified', '==', scopePathStringified),
  );
  const snapshot = await getDocs(firestoreQuery);
  snapshot.forEach((document_) => {
    data.push(document_.data() as KaeplaProjectAssignment);
  });
  return data;
};
