import { KaeplaProject, KaeplaFilter } from '@kaepla/types';
import { getApp } from 'firebase/app';
import { DocumentData, getFirestore, doc, setDoc, Timestamp } from 'firebase/firestore';

interface Options {
  project: KaeplaProject;
  filter: KaeplaFilter<Timestamp>;
}

export const updateFilter = async ({ project, filter }: Options) => {
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects/${project.id}/filters/${filter.id}`;
  const documentReference = doc(firestoreDatabase, fireStorePath);
  const update = { ...filter };
  update.updatedAt = Timestamp.now();
  await setDoc(documentReference, update as DocumentData);
  return filter;
};
