import TourIcon from '@mui/icons-material/HelpCenterOutlined';
import { Divider, Grid2 as Grid, IconButton, Paper, Stack, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { projectState } from '../../../../services/recoil/nonpersistent/projectState';
import { ProjectInfoSmall } from '../../Perspectives/features/ProjectSummary/features/ProjectInfoSmall';

interface Options {
  setTour: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DataSourcesHeader = ({ setTour }: Options) => {
  const project = useRecoilValue(projectState);
  if (!project) {
    return null;
  }
  return (
    <Grid size={12}>
      <Paper sx={{ p: 2 }} data-testid="targets-OverviewHeader">
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 3 }}>
            <ProjectInfoSmall title={project.name} />
          </Grid>
          <Grid size={2}>
            <Divider orientation="vertical" />
          </Grid>
          <Grid
            size={{ xs: 12, sm: 6, md: 7 }}
            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="h6">Data Sources</Typography>
              <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
              <IconButton
                size="small"
                data-testid="user-dialog-table-view"
                color="info"
                onClick={() => {
                  setTour(false);
                  setTimeout(() => {
                    setTour(true);
                  }, 100);
                }}
              >
                <TourIcon />
              </IconButton>
            </Stack>
            <Typography variant="body1">
              Data sources add data from 3rd parties such as market data or publicly available
              statistical data to your planning project.
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
