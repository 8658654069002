import { SetterOrUpdater } from 'recoil';
import { KaeplaReseller } from '@kaepla/types';

interface Options {
  reseller: KaeplaReseller;
  knownResellers: KaeplaReseller[];
  setKnownResellers: SetterOrUpdater<KaeplaReseller[]>;
}

export const updateKnownReseller = ({ reseller, knownResellers, setKnownResellers }: Options) => {
  let updatedKnownResellers = [...knownResellers];
  const knownReseller = knownResellers.filter((k) => !!k).find((k) => k.id === reseller.id);
  if (knownReseller) {
    updatedKnownResellers = [
      ...knownResellers.filter((k) => !!k).filter((k) => k.id !== reseller.id),
      reseller,
    ];
  }

  setKnownResellers(updatedKnownResellers);
};
