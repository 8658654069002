import { KaeplaDimensionDetails, KaeplaModel, MatrixSummary } from '@kaepla/types';
import { Grid2 as Grid, Typography } from '@mui/material';
import { clone } from 'rambda';
import { SetterOrUpdater, useRecoilValue } from 'recoil';

import { projectState } from '../../../../services/recoil/nonpersistent/projectState.js';
import { isDimensionDelegable } from '../../../ScopeNavigation/helpers/isDimensionDelegable.js';

import { ScopeCard } from './ScopeCard.js';

interface Options {
  matrix: MatrixSummary;
  model: KaeplaModel;
  loading: boolean;
  setModel: SetterOrUpdater<KaeplaModel>;
  scopePath: string[];
  scopePaths: Record<string, string[] | undefined>;
  setScopePaths: SetterOrUpdater<Record<string, string[] | undefined>>;
}

export const ScopesOverview = ({
  matrix,
  model,
  loading,
  setModel,
  scopePath,
  scopePaths,
  setScopePaths,
}: Options) => {
  const project = useRecoilValue(projectState);

  if (!matrix.dimensions || !project || !scopePath) return null;
  let delegableScopes: KaeplaDimensionDetails[] = [];
  let sortedScopes: KaeplaDimensionDetails[] = [];
  if (matrix.details && matrix.details.length > 0) {
    delegableScopes = clone(matrix.details)
      .slice()
      .filter((detail) =>
        isDimensionDelegable({
          scopePath,
          detail,
          dimensions: matrix.dimensions,
        }),
      )
      .sort((a, b) => a.dimensionDetails?.length - b.dimensionDetails?.length);
    sortedScopes = clone(matrix.details)
      .slice()
      .filter(
        (detail) =>
          !isDimensionDelegable({
            scopePath,
            detail,
            dimensions: matrix.dimensions,
          }),
      )
      .sort((a, b) => a.columnName.localeCompare(b.columnName));
    // console.log("loading", loading);
    // console.log("sortedDimensions", sortedDimensions);
  }

  return (
    <Grid container spacing={3}>
      <Grid size={12}>
        <Typography variant="h5">Scope Dimensions</Typography>
      </Grid>
      {delegableScopes.map((detail) => (
        <ScopeCard
          key={detail.columnName}
          {...{
            detail,
            matrix,
            model,
            loading,
            setModel,
            scopePath,
            scopePaths,
            setScopePaths,
          }}
        />
      ))}
      {delegableScopes.length === 0 && <Grid size={12}>no dimensions</Grid>}
      <Grid size={12}>
        <Typography variant="h5">Other Dimensions</Typography>
      </Grid>
      {sortedScopes.map((detail) => (
        <ScopeCard
          key={detail.columnName}
          {...{
            detail,
            matrix,
            model,
            loading,
            setModel,
            scopePath,
            scopePaths,
            setScopePaths,
          }}
        />
      ))}
    </Grid>
  );
};
