import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

interface COptions {
  importConfigurations: { name: string; key: string }[];
  value?: string;
  callback: (value: string) => void;
}

export const ImportConfigurationSelect = ({ importConfigurations, value, callback }: COptions) => {
  const handleChange = (event: SelectChangeEvent) => {
    if (callback) {
      callback(event.target.value);
    }
  };

  return (
    <FormControl sx={{ m: 0, minWidth: 220 }} size="small" margin="dense">
      <InputLabel id={`input-connector`}>Import Configuration</InputLabel>
      <Select
        label="Import Configuration"
        id="importConfigurationSelect"
        value={value ?? ''}
        onChange={handleChange}
        size="small"
        inputProps={{ name: 'importConfigurationSelectInput' }}
      >
        <MenuItem value="" dense>
          <em>None</em>
        </MenuItem>
        {importConfigurations.map((importConfiguration) => (
          <MenuItem key={importConfiguration.key} value={importConfiguration.key} dense>
            {importConfiguration.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
