import { KaeplaOperation, KaeplaOperationType } from '@kaepla/types';
import ValidIcon from '@mui/icons-material/CheckCircleOutline';
import InvalidIcon from '@mui/icons-material/StopCircleOutlined';
import { Card, CardContent, CardHeader, Chip, Typography } from '@mui/material';

import { cleanColumnName } from '../../helpers/cleanColumnName';

import { operationTypeColors } from './Operations.js';

interface Options {
  operation: KaeplaOperation;
}

export const OperationCard = ({ operation }: Options) => {
  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader sx={{ pb: 0 }} title={cleanColumnName(operation.resultField)} />
      <CardContent sx={{ minHeight: 100 }}>
        <Chip
          size="small"
          sx={{ bgcolor: operationTypeColors[operation.type], mr: 1 }}
          label={operation.type}
        />
        {operation.importSource && (
          <Chip sx={{ mr: 1 }} size="small" label={operation.importSource} />
        )}
        {operation.type === KaeplaOperationType.calculation && operation.isValid && (
          <Chip
            sx={{ mr: 1 }}
            size="small"
            variant="outlined"
            color="success"
            label="all records valid"
            deleteIcon={<ValidIcon />}
            onDelete={() => {
              return;
            }}
          />
        )}
        {operation.type === KaeplaOperationType.calculation &&
          !operation.isValid &&
          operation.invalidCalculationsCount && (
            <Chip
              sx={{ mr: 1 }}
              size="small"
              variant="outlined"
              color="error"
              label={`${
                operation.invalidCalculationsCount as unknown as string
              } records are invalid`}
              deleteIcon={<InvalidIcon />}
              onDelete={() => {
                return;
              }}
            />
          )}
        <Typography
          variant="body1"
          sx={{ pt: 1, fontFamily: 'monospace', fontSize: 11, overflowWrap: 'break-word' }}
        >
          {operation.operation}
        </Typography>
      </CardContent>
    </Card>
  );
};
