import { KaeplaEvent } from '@kaepla/events';
import WarnIcon from '@mui/icons-material/WarningAmberOutlined';
import { Grid2 as Grid, Toolbar, Tooltip, Typography } from '@mui/material';
import { red } from '@mui/material/colors';

import {
  convertISODateTime,
  HumanReadableTimestampType,
} from '../../../../../../helpers/convertISODateTime';

import { EventGroupIcon } from './EventGroupIcon';

interface Options {
  event: KaeplaEvent;
}

export const EventEntry = ({ event }: Options) => {
  const errorMessage = event.payload?.errorMessage as string | undefined;

  return (
    <Grid container>
      <Grid size={12}>
        <Tooltip title={event.id}>
          <Typography variant="caption">
            {convertISODateTime({
              dateTime: event.dateTime,
              type: HumanReadableTimestampType.date,
            })}
          </Typography>
        </Tooltip>
      </Grid>
      <Grid size={12}>
        <Toolbar sx={{ minHeight: 32 }} disableGutters variant="dense">
          <EventGroupIcon eventGroup={event.eventGroup} />
          <Typography sx={{ ml: 1 }} variant="body2">
            {event.eventName}
          </Typography>
        </Toolbar>
      </Grid>
      {errorMessage && (
        <Grid size={12}>
          <Toolbar sx={{ minHeight: 32 }} disableGutters variant="dense">
            <WarnIcon sx={{ color: red[600] }} fontSize="small" />
            <Typography component="span" variant="body2" sx={{ ml: 1, color: red[600] }}>
              {errorMessage}
            </Typography>
          </Toolbar>
        </Grid>
      )}
    </Grid>
  );
};
