import { Grid2 as Grid } from '@mui/material';

import { connectorTypes } from '../connectorTypes';
import { KaeplaConnectorType } from '../types';

import { ConnectorTypeCard } from './ConnectorTypeCard';

interface Options {
  setNewConnector: React.Dispatch<React.SetStateAction<boolean>>;
  setNewConnectorType: React.Dispatch<React.SetStateAction<KaeplaConnectorType | undefined>>;
}

export const AvailableConnectors = ({ setNewConnectorType, setNewConnector }: Options) => {
  return connectorTypes.map((connectorType) => (
    <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }} key={connectorType.name}>
      <ConnectorTypeCard
        key={connectorType.name}
        connectorType={connectorType}
        setNewConnectorType={setNewConnectorType}
        setNewConnector={setNewConnector}
      />
    </Grid>
  ));
};
