import { useMemo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useCreateQueryKey(repositoryName: string, parameters: Record<string, any>) {
  const queryParameters = useMemo(() => new URLSearchParams(parameters).toString(), [parameters]);
  return {
    queryParameters,
    queryKey: [repositoryName, queryParameters],
  };
}
